import { Col, Row } from 'antd';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { SideBar2 } from '../../';
import { stateSideBar } from '../../../config/Recoil';
import Header2 from '../../Header/Header2';
import './MainLayout.scss';
const MainLayout = ({ children }) => {
  const getStateSideBar = useRecoilValue(stateSideBar);
  return (
    <div>
      <Row>
        <Col span={getStateSideBar ? 4 : 0} className="sidebar2">
          <SideBar2 />
        </Col>
        <Col span={getStateSideBar ? 20 : 24} className="header-content">
          <Header2 />
          <div style={{ width: '100%' }}>{children}</div>
        </Col>
      </Row>
    </div>
  );
};
export default MainLayout;
