import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Input, Modal, Row, Select, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import {
  ApplyOrganization,
  ApproveUserOrganization,
  GetOrganizationJoinByUser,
  GetUserOrganization,
} from '../../../../api/TSOrganization';
import { AddKeyToLst } from '../../../helper/addKeyToLst';
import './AdminMembers.scss';
import AdminMemberDetail from './detail';
import AdminMember from './member';

const AdminMembers = () => {
  const [orgizations, setOrganizations] = useState([]);
  const [roleAddUser, setRoleAddUser] = useState(3);
  const [orgizationsDisplay, setOrganizationsDisplay] = useState([]);
  const [orgSelected, setOrgSelected] = useState({});
  const [valueSearchOrg, setValueSearchOrg] = useState('');
  const [membersInOrg, setMembersInOrg] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleAddMember, setVisibleAddMember] = useState(false);
  const [userNameAddMember, setUserNameAddMember] = useState('');
  const [resultCheckExistUser, setResultCheckExistUser] = useState();
  const adminMembers = useRef();
  useEffect(() => {
    const fetchData = async () => {
      await GetOrganizationJoinByUser({
        Search: '',
        NumberRow: '',
        PageNumber: 1,
      }).then((res) => {
        setOrganizations(AddKeyToLst(res.OrganizationLst));
        setOrganizationsDisplay(AddKeyToLst(res.OrganizationLst));
        const orgFirst = res.OrganizationLst.length > 0 ? res.OrganizationLst[0] : {};
        setOrgSelected(orgFirst);
        cbGetUserOrg(orgFirst.OrganizationID);
      });
    };
    fetchData();
  }, []);
  useEffect(() => {
    const filterOrg = [...orgizations].filter((org) => {
      return org.OrganizationName.toLowerCase().indexOf(valueSearchOrg.toLowerCase()) !== -1;
    });
    setOrganizationsDisplay(filterOrg);
  }, [valueSearchOrg]);
  const hanleSelectedOrganization = (org) => {
    setOrgSelected(org);
    setLoading(true);
    cbGetUserOrg(org.OrganizationID);
  };
  const cbGetUserOrg = async (orgId) => {
    const detail = await GetUserOrganization({
      OrganizationID: orgId ? orgId : orgSelected.OrganizationID,
    });
    setLoading(false);
    setMembersInOrg(AddKeyToLst(detail.UserOrganizationLst));
  };
  const handleRole = async (record) => {
    await ApproveUserOrganization({
      UserCode: record.UserName,
      OrganizationID: orgSelected.OrganizationID,
      Role: record.UserRole,
    });
    await cbGetUserOrg();
  };
  const hanleModalAddMember = () => {
    setVisibleAddMember(true);
    setResultCheckExistUser();
    setUserNameAddMember('');
  };
  const handleChangeRole = (role) => {
    setRoleAddUser(role);
  };
  const onConfirmAddMember = async () => {
    if (!resultCheckExistUser) {
      const responseAddUser = await ApplyOrganization({
        UserCode: userNameAddMember.trim(),
        OrganizationID: orgSelected.OrganizationID,
      });
      if (responseAddUser.RespCode === 0) {
        await ApproveUserOrganization({
          UserCode: userNameAddMember.trim(),
          OrganizationID: orgSelected.OrganizationID,
          Role: roleAddUser,
        });
      }
      message.success('Thêm thành viên thành công');
      setVisibleAddMember(false);
      await cbGetUserOrg();
    }
  };
  const cancelModal = () => {
    setVisibleAddMember(false);
  };
  const onChangeUserNameAddMember = (userName) => {
    setUserNameAddMember(userName);
    setResultCheckExistUser();
  };
  const checkExistUser = () => {
    const reusult = membersInOrg.find((member) => member.UserName.trim() === userNameAddMember.trim());
    setResultCheckExistUser(reusult);
  };
  const cbDeleteMember = (member) => {
    Modal.confirm({
      title: `Xóa thành viên`,
      icon: <ExclamationCircleOutlined />,
      content: `Bạn có chắc chắn muốn xóa thành viên ${member.FullName} khỏi phòng ${orgSelected.OrganizationName}?`,
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      onOk: async () => {
        await ApproveUserOrganization({
          OrganizationID: orgSelected.OrganizationID,
          UserCode: member.UserName,
          Role: 0,
        });
        await cbGetUserOrg();
      },
    });
  };
  return (
    <div className="admin-members">
      <Modal
        title={'Thêm thành viên vào phòng ' + orgSelected.OrganizationName}
        visible={visibleAddMember}
        onOk={onConfirmAddMember}
        onCancel={cancelModal}
      >
        <div>
          <Row gutter={20}>
            <Col span={16}>
              <b>Số điện thoại/tên đăng nhập</b>
              <Input
                style={{ marginTop: 6 }}
                type="number"
                onChange={(e) => onChangeUserNameAddMember(e.target.value)}
                onBlur={() => checkExistUser()}
                placeholder="Nhập số điện thoại của người đăng ký"
              />
            </Col>
            <Col span={8}>
              <b>Vai trò</b>
              <Select value={roleAddUser} onChange={(e) => handleChangeRole(e)} style={{ marginTop: 6 }}>
                <Select.Option value={3}>Thành viên</Select.Option>
                <Select.Option value={2}>Quản lý</Select.Option>
              </Select>
            </Col>
          </Row>
          {resultCheckExistUser && <div>Số diện thoại này đã tồn tại trong phòng ban</div>}
        </div>
      </Modal>
      <Row gutter={20}>
        <Col span={8}>
          <div className="admin-members__organization-label">Danh sách phòng ban</div>
          <Input
            className="admin-members__search-org"
            onChange={(e) => {
              setValueSearchOrg(e.target.value);
            }}
            placeholder="Hãy nhập tên phòng ban để tìm kiếm"
          />
          <div className="admin-members__organizations">
            {orgizationsDisplay.map((orgization) => (
              <div key={orgization.OrganizationID}>
                <AdminMember
                  organization={orgization}
                  orgSelected={orgSelected}
                  onSelectOrganization={hanleSelectedOrganization}
                />
              </div>
            ))}
          </div>
        </Col>
        <Col span={16} ref={adminMembers}>
          <div className="admin-members__organization-action">
            <div className="admin-members__organization-label-member">Danh sách thành viên</div>
            <div className="admin-members__organization-add-member">
              <Button type="primary" disabled={!orgSelected.OrganizationID} onClick={hanleModalAddMember}>
                Thêm thành viên
              </Button>
            </div>
          </div>
          <AdminMemberDetail
            orgDetail={orgSelected}
            membersInOrg={membersInOrg}
            loading={loading}
            handleRole={handleRole}
            onDeleteMember={cbDeleteMember}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AdminMembers;
