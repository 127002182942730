import { Modal, message } from "antd";
import "./NewMember.scss";
import React, { useState } from "react";
import { Input } from "antd";
import { UserAddOutlined, UserOutlined } from "@ant-design/icons";
import { ApplyOrganization } from "../../../../api/TSOrganization";
import { useRecoilState } from "recoil";
import { manageMember } from "../../../../config/Recoil";

const NewMember = ()=>{
  const [visible, setVisible] = useState(false)
  const [name, setName] = useState('')
  const [getStateMember, setStateMember] = useRecoilState(manageMember)
  const handleOk = ()=>{
    ApplyOrganization({
      OrganizationID: localStorage.getItem('groupId'),
      UserCode: name
    }).then(res =>{
      if(res.RespCode === 0){
        message.success("Đã thêm thành viên thành công");
        setVisible(false)
        setStateMember(!getStateMember)
      }
    })
  }
  const handleCancel = ()=>{
    setVisible(false)
  }
  const changeName = (e)=>{
    setName(e.target.value)
  }
  return(
      <div>
        <div onClick={()=> setVisible(true)} className="btn_add_member">
          <UserAddOutlined />
          Thêm thành viên
        </div>
        <Modal
          title="Mời tham gia tổ chức: "
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText='Xác nhận'
          cancelText='Hủy'
        >
          <h4>Nhập UserName của thành viên mới :</h4>
          <Input
            size="large"
            placeholder="Số điện thoại"
            prefix={<UserOutlined />}
            autoFocus
            value={name}
            onChange={changeName}
          />
        </Modal>
      </div>
  )
}
export default NewMember
