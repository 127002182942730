import { Button, Space, Table } from 'antd'
import React, { useState } from 'react'
import { ExportFormatExcel } from '../../../../config';
import { dateDisplay } from '../../../helper/mapping';
import './AdminDeviceCreate.scss';
const columns = [
  {
    title: "STT",
    dataIndex: "key",
    key: "key",
  },
  {
    title: "Tên TB",
    dataIndex: "DeviceName",
    key: "DeviceName",
  },
  {
    title: "Mã TB",
    dataIndex: "DeviceCode",
    key: "DeviceCode",
  },
  {
    title: "Giá trị",
    dataIndex: "DeviceValue",
    key: "DeviceValue",
  },
  {
    title: "Mã tham chiếu",
    dataIndex: "ReferenceCode",
    key: "ReferenceCode",
  },
  {
    title: "Đơn vị",
    dataIndex: "Unit",
    key: "Unit",
  },
  {
    title: "Nhóm",
    dataIndex: "DeviceGroup",
    key: "DeviceGroup",
  },
  {
    title: "Loại TB",
    dataIndex: "Type",
    key: "Type",
  },
  {
    title: "TG nhập",
    dataIndex: "TimeImport",
    key: "TimeImport",
    render: (record) => {
      return dateDisplay(record)
    }
  },
  {
    title: "TG bắt đầu",
    dataIndex: "TimeStart",
    key: "TimeStart",
    render: (record) => {
      return dateDisplay(record)
    },
  },
  {
    title: "Kiểm định",
    dataIndex: "Accreditation",
    key: "Accreditation"
  },
  {
    title: "Ghi chú",
    dataIndex: "Note",
    key: "Note",
  },
];
const AdminDeviceCreate = () => {
  const [devices, setDevices] = useState([]);
  const [fileDevices, setFileDeivce] = useState(null);
  const handleChange = (e) => {
    setFileDeivce(e.target.files[0]);
  };
  const handleSubmit = () => {

  }
  const resetImport = () => {

  }
  return (
    <div className='admin-device-create'>
      <div className='admin-device-create__header'>
        <Space>
          <ExportFormatExcel />
          <input type="file" onChange={handleChange} className="import_file" />
          <Button
            onClick={() => handleSubmit()}
            type="primary"
          >
            Tải lên
          </Button>
          <Button type='dashed' onClick={() => resetImport()}>
            Reset
          </Button>
        </Space>
        <Space>
          <Button type='dashed'>Số thiết bị đã trùng mã thiết bị</Button>
          <Button type='primary'>Thêm danh sách thiết bị</Button>
        </Space>
      </div>
      <Table dataSource={devices} columns={columns} pagination={false} />
    </div>
  )
}

export default AdminDeviceCreate