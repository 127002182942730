import React from 'react';
import './DeleteDevice.scss';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, Popconfirm, Tooltip } from 'antd';
import { RemoveDeviceInfo } from '../../../../api/Device';
import { useRecoilState } from 'recoil';
import { manageDevice } from '../../../../config/Recoil';
const DeleteEquipment = ({ record, orgId }) => {
  const [getManageDevice, setManageDevice] = useRecoilState(manageDevice);
  const titleDelete = `Bạn có muốn xóa thiết bị ${record.DeviceName}`;
  const confirmDelete = () => {
    RemoveDeviceInfo({
      DeviceId: record.DeviceId,
      OrganizationID: orgId,
    }).then((res) => {
      setManageDevice(!getManageDevice);
    });
  };
  return (
    <div className="get-list__delete">
      <Popconfirm
        title={titleDelete}
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        onConfirm={confirmDelete}
        okText="Xác nhận"
        cancelText="Hủy"
      >
        <Tooltip placement="bottom" title="Xóa">
          <DeleteOutlined style={{ color: '#eb4034' }} />
        </Tooltip>
      </Popconfirm>
    </div>
  );
};
export default DeleteEquipment;
