import baseURL from "./BaseURL";

const reportDeviceAPI = {
  getInfoByUser: () => {
    return `${baseURL()}/Device/GetDeviceReportByUser`;
  },
  getInfoByID: () => {
    return `${baseURL()}/Device/GetDeviceReportById`;
  },
  getInfoByGId: () => {
    return `${baseURL()}/Device/GetDeviceReportByGId`;
  },
  report: () => {
    return `${baseURL()}/Device/ReportDevice`;
  },
  approveReport: () => {
    return `${baseURL()}/Device/ApproveDeviceReportById`;
  },
  uploadImg: () => {
    return `${baseURL()}/File/UploadDeviceReport?`;
  },
};
export default reportDeviceAPI;
