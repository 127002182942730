import { Button, DatePicker, Form, Input, message, Modal, Select, Space, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { GetDeviceByOrganization } from '../../../../api/Device';
import { CreateMoveDeviceDoc, GetDependentOrganization } from '../../../../api/TSOrganization';
import { getGroupId } from '../../../../helpers/auth';
import { AddKeyToLst } from '../../../helper/addKeyToLst';
import { formatDateImport, formatMoneyDisplay, status } from '../../../helper/mapping';
import { companyID } from '../../../../config/URL/Qlts';
const CreateMoveDevice = () => {
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [visible, setVisible] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState([]);
  const [form] = Form.useForm();
  const titleModal = 'Tạo phiếu luân chuyển thiết bị';
  const buttonNameCreate = 'Tạo phiếu luân chuyển';
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  useEffect(() => {
    async function getDeviceByOrg() {
      setLoading(true);
      const responseDevice = await GetDeviceByOrganization({
        OrganizationID: getGroupId(),
      });
      setLoading(false);
      const devicesFilter = responseDevice.DeviceLst.filter((device) => device.Status === 1);
      setDevices(AddKeyToLst(devicesFilter));
    }
    getDeviceByOrg();
  }, []);
  const modalMov = async () => {
    setVisible(true);
    const response = await GetDependentOrganization({
      OrganizationID: companyID,
      Search: '',
      NumberRow: '',
      PageNumber: 0,
    });
    const organizations = response.OrganizationLst.filter((org) => org.OrganizationID !== getGroupId());
    setOrganizations(organizations);
  };
  const onOk = () => {
    form.submit();
  };
  const onCancel = () => {
    setVisible(false);
  };
  const onFinish = async (values) => {
    const req = {
      TimeMove: formatDateImport(values.TimeMove),
      OrganizationIDOld: getGroupId(),
      OrganizationIDNew: values.OrganizationIDNew,
      OrganizationNameNew: values.OrganizationIDNew,
      Description: values.Description,
      DeviceLst: selectedDevice,
    };
    setButtonLoading(true);
    const response = await CreateMoveDeviceDoc({
      MoveDeivceDocInfo: req,
    });
    setButtonLoading(false);
    if (response.RespCode === 0) {
      message.success('Tạo phiếu thành công');
      setVisible(false);
    }
  };
  const columns = [
    {
      title: 'STT',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Tên TB',
      dataIndex: 'DeviceName',
      key: 'DeviceName',
    },
    {
      title: 'Mã TB',
      dataIndex: 'DeviceCode',
      key: 'DeviceCode',
    },
    {
      title: 'Model',
      dataIndex: 'Model',
      key: 'Model',
    },
    {
      title: 'Serial',
      dataIndex: 'Serial',
      key: 'Serial',
    },
    {
      title: 'Giá trị hiện tại',
      dataIndex: 'DeviceValue',
      key: 'DeviceValue',
      render: (record) => {
        return formatMoneyDisplay(record);
      },
    },
    {
      title: 'Tình trạng hoạt động',
      dataIndex: 'HistoryAction',
      key: 'HistoryAction',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'Status',
      key: 'Status',
      render: (record) => {
        return <Tag color={status(record).color}>{status(record).label}</Tag>;
      },
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedDevice(selectedRows);
    },
  };
  return (
    <div className="create_move_device">
      <Modal
        visible={visible}
        title={titleModal}
        onOk={onOk}
        onCancel={onCancel}
        okText="Xác nhận"
        okButtonProps={{
          loading: buttonLoading,
        }}
      >
        <Form onFinish={onFinish} form={form} {...layout}>
          <Form.Item
            name="OrganizationIDNew"
            label="Phòng ban nhận"
            rules={[
              {
                required: true,
                message: 'Không được để trống',
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Phòng ban nhận điều chuyển"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {organizations &&
                organizations.map((or, index) => (
                  <Select.Option value={or.OrganizationID} key={index}>
                    {or.OrganizationName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="TimeMove"
            label="Thời gian luân chuyển"
            rules={[
              {
                required: true,
                message: 'Không được để trống',
              },
            ]}
          >
            <DatePicker showTime placeholder="Thời gian" format="HH:mm DD/MM/YYYY" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="Description"
            label="Lý do"
            rules={[
              {
                required: true,
                message: 'Không được để trống',
              },
            ]}
          >
            <Input.TextArea placeholder="Lý do luân chuyển" />
          </Form.Item>
        </Form>
      </Modal>
      <Space size={6} style={{ marginBottom: 16 }}>
        <b>
          Đã chọn: {selectedDevice.length} / {devices.length}
        </b>
        <Button onClick={modalMov} type="primary" disabled={selectedDevice.length === 0}>
          {buttonNameCreate}
        </Button>
      </Space>
      <Table
        dataSource={devices}
        columns={columns}
        pagination={false}
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      ></Table>
    </div>
  );
};

export default CreateMoveDevice;
