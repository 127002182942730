import { HomeOutlined, MenuOutlined, NotificationOutlined, SettingOutlined } from '@ant-design/icons';
import { Badge, Dropdown, Menu, Row, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { NotiCountByCompany } from '../../api/Noti';
import logo from '../../assets/images/logo-da-khoa.png';
import { organizationInfo } from '../../config/Recoil';
import { companyID } from '../../config/URL/Qlts';
import { Logout } from '../../containers/Auth';
import { About } from '../../containers/Info';
import { setGroupId, setGroupName } from '../../helpers/auth';
import './styles.scss';
import { ADMIN_PATH_MEMBERS } from '../../constants/Admin';

const menu = (
  <Menu key="menu" id="navbar" defaultSelectedKeys="home">
    <Menu.Item key="home" icon={<HomeOutlined />}>
      Trang chủ
    </Menu.Item>
    <Menu.Item key="about">
      <About />
    </Menu.Item>
    <Menu.Item key="logout">
      <Logout />
    </Menu.Item>
  </Menu>
);

export const NavBar = ({ isAdmin }) => {
  const [noti, setNoti] = useState([]);
  const [countNoti, setCountNoti] = useState(0);
  const [orgInfo, setOrgnInfo] = useRecoilState(organizationInfo);

  const getNoti = () => {
    NotiCountByCompany({ CompanyID: companyID }).then((res) => {
      setNoti(res.NotiOrganizationLst);
      const count = res.NotiOrganizationLst.reduce((a, b) => {
        return a + b.Quantity;
      }, 0);
      setCountNoti(count);
    });
  };
  const setGroupInfo = (group) => {
    setOrgnInfo(group);
    setGroupId(group.OrganizationID);
    setGroupName(group.OrganizationName);
  };
  const menuNoti = (
    <div>
      {/* <Input /> */}
      <Menu key="menuNoti">
        {noti.map((org) => (
          <Menu.Item key={org.OrganizationID}>
            <Link to={`group/${org.OrganizationID}`} onClick={() => setGroupInfo(org)}>
              <b className="count-noti">{org.Quantity}</b> {org.OrganizationName}
            </Link>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
  useEffect(() => {
    getNoti();
  }, []);

  return (
    <div className="nav-bar">
      <Row id="navbar-container" justify="space-between" align="middle">
        <div className="logoName">
          <img src={logo} alt="" />
          <div>
            Hệ thống quản lý thiết bị y tế
            <br />
            Bệnh viện đa khoa tỉnh điện biên
          </div>
        </div>
        <Space size={20}>
          {isAdmin && (
            <Tooltip title={'Quản trị hệ thống'}>
              <Link to={ADMIN_PATH_MEMBERS}>
                <SettingOutlined style={{ fontSize: 20, cursor: 'pointer' }} />
              </Link>
            </Tooltip>
          )}
          <Dropdown overlay={menuNoti} trigger={['click']}>
            <Badge count={countNoti}>
              <NotificationOutlined style={{ fontSize: 20, cursor: 'pointer' }} />
            </Badge>
          </Dropdown>
          <Dropdown overlay={menu} trigger={['click']}>
            <MenuOutlined style={{ fontSize: 20 }} />
          </Dropdown>
        </Space>
      </Row>
    </div>
  );
};
