import React from "react";
import { Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
const About = () => {
  const showInfo = () => {
    Modal.info({
      title: "Thông tin hệ thống",
      content: (
        <div>
          Hệ thống quản lý, theo dõi tình trạng hoạt động và tạo lịch bảo dưỡng
          thiết bị
        </div>
      ),
    });
  };
  return (
    <div onClick={showInfo}>
      <InfoCircleOutlined /> Thông tin
    </div>
  );
};
export default About;
