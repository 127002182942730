import React from 'react';
import { Redirect } from 'react-router-dom';
import logo from '../../../assets/images/logo-da-khoa.png';
import LoginForm from './LoginForm/LoginForm';
import RegistrationForm from './RegistrationForm/RegistrationForm';
import './SignIn.scss';
const SignIn2 = () => {
  const [isLoggingIn, setIsLoggingIn] = React.useState(true);
  const handleChange = () => {
    setIsLoggingIn(!isLoggingIn);
  };
  const token = localStorage.getItem('token');

  return (
    <div className="all">
      {token ? (
        <Redirect to="dashboard" />
      ) : (
        <div className="form-signIn2">
          <div className="header">
            <img src={logo} alt="" />
            <div className="name_header">
              <div id="title">
                Hệ thống quản lý thiết bị y tế
                <br />
                Bệnh viện đa khoa tỉnh điện biên
              </div>
            </div>
          </div>
          <div className="login">
            {isLoggingIn ? <LoginForm changeForm={handleChange} /> : <RegistrationForm changeForm={handleChange} />}
          </div>
        </div>
      )}
    </div>
  );
};

export default SignIn2;
