import { Table } from 'antd';
import Column from 'antd/lib/table/Column';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { DelSetDevice, InfoSetDevice } from '..';
import { GetDeviceSetLst } from '../../../../api/DeviceSet';
import { manageSetDevice } from '../../../../config/Recoil';
import { getGroupId } from '../../../../helpers/auth';
import { AddKeyToLst } from '../../../helper/addKeyToLst';
import './LstSetDevice.scss';
const LstSetDevice = () => {
  const [lstSetDevice, setLstSetDevice] = useState([]);
  const [loading, setLoading] = useState(true);
  const stateSetDevice = useRecoilValue(manageSetDevice);
  useEffect(() => {
    const fetchLstSetDevice = async () => {
      const response = await GetDeviceSetLst({
        OrganizationID: getGroupId(),
      });
      if (response.RespCode === 0) {
        setLstSetDevice(AddKeyToLst(response.DeviceSetLst));
      }
    };
    fetchLstSetDevice();
  }, [stateSetDevice]);
  return (
    <div style={{ width: '100%' }}>
      <Table
        dataSource={lstSetDevice}
        loading={loading}
        locale={{
          emptyText: 'Không có dữ liệu',
        }}
      >
        <Column title="STT" dataIndex="key" key="key" />
        <Column title="Tên bộ thiết bị" dataIndex="DeviceName" key="DeviceName" />
        <Column className="col_deviceCode" title="Mã thiết bị" dataIndex="DeviceCode" key="DeviceCode" />
        <Column className="col_referenceCode" title="Mã tham chiếu" dataIndex="ReferenceCode" key="ReferenceCode" />
        {/* <Column
          className="col_deviceValue"
          title="Giá trị"
          dataIndex="DeviceValue"
          key="DeviceValue"
        /> */}
        <Column className="col_location" title="Phòng ban sử dụng" dataIndex="Location" key="Location" />
        <Column
          className="col_timeCreate"
          title="Thời gian tạo"
          dataIndex="TimeCreate"
          key="TimeCreate"
          render={(record) => {
            const date = new Date(record);
            return moment(date.getTime()).format('HH:mm DD-MM-YYYY');
          }}
        />
        <Column
          className="col_shedule"
          title="Chu kỳ bảo dưỡng"
          dataIndex="Schedule"
          key="Schedule"
          render={(record) => {
            if (record === 0) {
              return 'Không có';
            } else {
              return `${record} Tháng/Lần`;
            }
          }}
        />
        <Column className="col_note" title="Ghi chú" dataIndex="Note" key="Note" />
        <Column
          render={(text, record, rowIndex) => (
            <div className="action_setDevice">
              <div>
                <DelSetDevice
                  infoSet={record}
                  rowIndex={rowIndex}
                  lstSetDevice={lstSetDevice}
                  setLstSetDevice={setLstSetDevice}
                />
              </div>
              <div>
                <InfoSetDevice infoSetDevice={record} />
              </div>
            </div>
          )}
        />
      </Table>
    </div>
  );
};

export default LstSetDevice;
