import { Button } from 'antd';
import React, { Component, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { companyName } from '../../../../config/URL/Qlts';
import InvenForm from './InvenForm';
import InvenFormTTYT from './InvenFormTTYT';
class ComponentToPrint extends Component {
  render() {
    let name = companyName;
    return (
      <div>
        {name === 'TTYT' ? (
          <InvenFormTTYT
            contentReport={this.props.contentReport}
            censor={this.props.censor}
            censerInven={this.props.censerInven}
          />
        ) : (
          <InvenForm contentReport={this.props.contentReport} censerInven={this.props.censerInven} />
        )}
      </div>
    );
  }
}
const PrintReportInventory = ({ contentReport, censors }) => {
  const report = useRef();
  return (
    <div>
      <div style={{ display: 'none' }}>
        <ComponentToPrint ref={report} contentReport={contentReport} censerInven={censors} />
      </div>
      <ReactToPrint
        documentTitle="Báo cáo kiểm kê thiết bị"
        trigger={() => <Button type="primary">In báo cáo kiểm kê</Button>}
        content={() => report.current}
      />
    </div>
  );
};

export default PrintReportInventory;
