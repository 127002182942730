import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "./CheckUser.scss";
const CheckUser = () => {
  return (
    <div className="check_user">
      <div className="form">
        <Link to="/">
          <Button style={{ marginLeft: 45 }}>Đăng nhập</Button>
        </Link>
      </div>
    </div>
  );
};
export default CheckUser;
