import Request from "../config/Request";
export function GetDefaultValue(data) {
  return Request({
    url: "/DefaultValue/GetDefaultValue",
    method: "POST",
    data: data,
  });
}

export function AddDefaultValue(data) {
  return Request({
    url: "/DefaultValue/AddDefaultValue",
    method: "POST",
    data: data,
  });
}
export function UpdateDefaultValue(data) {
  return Request({
    url: "/DefaultValue/UpdateDefaultValue",
    method: "POST",
    data: data,
  });
}