import baseURL from "./BaseURL";
const Organization = {
  // create organization
  create: () => {
    return `${baseURL()}/TSOrganization/CreateOrganization`;
  },
  // update infomation organization
  updateInfo: () => {
    return `${baseURL()}/TSOrganization/UpdateOrganization`;
  },
  // delete organization
  delete: () => {
    return `${baseURL()}/TSOrganization/DelOrganization`;
  },
  // get all organization
  getOrganization: () => {
    return `${baseURL()}/TSOrganization/GetOrganization`;
  },
  //get list organization user joined
  getOrganizationJoinByUser: () => {
    return `${baseURL()}/TSOrganization/GetOrganizationJoinByUser`;
  },
  // get list organization dependent
  getDependentOrganization: () => {
    return `${baseURL()}/TSOrganization/GetDependentOrganization`;
  },
  // get infomation organization
  getInfo: () => {
    return `${baseURL()}/TSOrganization/GetOrganizationByID`;
  },
  // invite user
  apply: () => {
    return `${baseURL()}/TSOrganization/ApplyOrganization`;
  },
  // devision/undevision device user
  linkUserDevice: () => {
    return `${baseURL()}/TSOrganization/LinkUserDevice`;
  },
  //get list user joined organization
  getUserOrganization: () => {
    return `${baseURL()}/TSOrganization/GetUserOrganization`;
  },
  approveUser: () => {
    return `${baseURL()}/TSOrganization/ApproveUserOrganization`;
  },
  GetOrganizationOfCompany: () => {
    return `${baseURL()}/TSOrganization/GetOrganizationOfCompany`;
  },
  CreateMoveDeviceDoc: () => {
    return `${baseURL()}/TSOrganization/CreateMoveDeviceDoc`;
  },
  GetMoveDeviceDocLst: () => {
    return `${baseURL()}/TSOrganization/GetMoveDeviceDocLst`;
  },
  GetMoveDeviceDocInfo: () => {
    return `${baseURL()}/TSOrganization/GetMoveDeviceDocInfo`;
  },
};
export default Organization;
