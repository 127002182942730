import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal, Table } from 'antd';
import React from 'react';
import { GetDeviceSetInfo } from '../../../../api/DeviceSet';
import { AddKeyToLst } from '../../../helper/addKeyToLst';
import './InfoSetDevice.scss';
const columns = [
  {
    title: 'STT',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Tên thiết bị',
    dataIndex: 'DeviceName',
    key: 'DeviceName',
  },
  {
    title: 'Mã thiết bị',
    dataIndex: 'DeviceCode',
    key: 'DeviceCode',
  },
  {
    title: 'Mã tham chiếu',
    dataIndex: 'ReferenceCode',
    key: 'ReferenceCode',
  },
  {
    title: 'Loại thiết bị',
    dataIndex: 'Type',
    key: 'Type',
  },
];
const InfoSetDevice = ({ infoSetDevice }) => {
  const showModal = async () => {
    const responseDeviceSet = await GetDeviceSetInfo({
      DeviceSetID: infoSetDevice.DeviceId,
    });
    if (responseDeviceSet.RespCode === 0) {
      Modal.info({
        width: 600,
        style: { padding: 0 },
        title: 'Thông tin bộ sản phẩm',
        content: (
          <div id="content">
            <Table dataSource={AddKeyToLst(responseDeviceSet.DeviceSetParaLst)} columns={columns} />
          </div>
        ),
      });
    }
  };
  return (
    <div>
      <div onClick={showModal} className="info_set_device">
        <a style={{ fontSize: 23 }}>
          <InfoCircleOutlined style={{ color: '#2f76cc' }} />
        </a>
      </div>
    </div>
  );
};

export default InfoSetDevice;
