import { Button, Form, InputNumber, Input, Drawer, Space, notification, Select } from 'antd'
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import ReactSelect from "react-select";
import { AppstoreAddOutlined } from '@ant-design/icons'
import { CreateOfferDeviceHeader, CreateOfferMaintanceHeader, GetOfferDeviceHeaderByID } from '../../../../../../api/DeviceOffer'
import { useRecoilState } from 'recoil'
import { manageOfferDevice } from '../../../../../../config/Recoil'
import { GetDependentOrganization, GetOrganizationOfCompany } from '../../../../../../api/TSOrganization'
import { GetDeviceByOrganization } from '../../../../../../api/Device'
import { GetDeviceErrorByOrgID } from '../../../../../../api/QrCode'
import { companyID } from '../../../../../../config/URL/Qlts'
const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 },
}
const Create = () => {
    const [ visible, setVisible ] = useState(false)
    const [getOffer, setOffer] = useRecoilState(manageOfferDevice)
    const [ deviceChoose, setDeviceChoose] = useState([])
    const [ devices, setDevices ] = useState([])
    const [organizations, setOrganizations] = useState([])
    const [form] = Form.useForm()
    const groupID = localStorage.getItem('groupId')
    const openModal = ()=>{
        GetDependentOrganization({
            OrganizationID: companyID,
            Search: '',
            NumberRow: '',
            PageNumber: 1
        }).then(res => {
            if(res.RespCode === 0){
                setVisible(true)
                let response = res.OrganizationLst.map(or => {
                    return {
                        label: or.OrganizationName,
                        value: or.OrganizationID
                    }
                })
                setOrganizations(response)
            }
        })
        getDeviceByOrg()
    }
    const onOk = ()=>{
        form.submit()
    }
    const onCancel = ()=>{
        setVisible(false)
    }
    const onFinish = (elements)=>{
        CreateOfferMaintanceHeader({
            DeviceOfferHeaderInfo: {
                ...elements,
                OrganizationID: localStorage.getItem('groupId'),
                DeviceOfferLineLst: deviceChoose.map(de => {
                    return {
                        ...de,
                        DeviceID: de.DeviceId
                    }
                })
            }
        }).then(res => {
            if(res.RespCode === 0){
                notification.success({
                    message: 'Tạo phiếu đề xuất thành công',
                    duration: 0.5
                })
                setVisible(false)
                setOffer(!getOffer)
            }
        })
    }
    const getDeviceByOrg = () => {
        GetDeviceByOrganization({
            OrganizationID: groupID,
            RowNumber: '',
            PageNumber: 0,
            Search: '',
            DeviceGroup: '',
            TypeDevice: "Medical",
        }).then(res => {
            const result = res.DeviceLst.map(de => {
                return {
                    ...de,
                    label: `${de.DeviceName} - ${de.DeviceCode}`,
                    value: de.DeviceId
                }
            })
            setDevices(result)
        })
    }
    const getDeviceError = () => {
        GetDeviceErrorByOrgID({
            OrganizationID: groupID
        }).then(res => {
            const result = res.DeviceLst.map(de => {
                return {
                    ...de,
                    label: `${de.DeviceName} - ${de.DeviceCode}`,
                    value: de.DeviceId
                }
            })
            setDevices(result)
        })
    }
    useEffect(()=>{
        GetOrganizationOfCompany({
            NumberRow: '',
            PageNumber: 1,
            OrganizationID: companyID
        }).then(res => {
            setOrganizations(res.OrganizationLst)
        })
    }, [])
    const hanleChangeOption = (elements) => {
        setDeviceChoose(elements)
    }
    const handleTypeDevice = (e) => {
        if (e === 1) {
            getDeviceByOrg()
        } else if (e === 2) {
            getDeviceError()
        }
    }
    return (
        <div>
            <Drawer visible={visible} onClose={onCancel} width={700} title="Tạo phiếu đề xuất thiết bị"
                footer = {
                    <div style={{textAlign: 'center'}}>
                        <Button type="text" onClick={onCancel}>Hủy</Button>
                        <Button type='primary' onClick={onOk}>Tạo phiếu đề xuất</Button>
                    </div>
                }
            >
                <Form form={form} onFinish={onFinish} {...layout}>
                    <Form.Item name="DocumentName" label="Tên phiếu đề xuất"
                        rules={[{
                            required: true,
                            message: 'Hãy nhập tên phiếu'
                        }]}
                    >
                        <Input allowClear/>
                    </Form.Item>
                    <Form.Item name="OrganizationReceive" label="Phòng nhận đề xuất" initialValue="156" rules={[{
                        required: true,
                        message: 'Hãy nhập phòng ban nhận đề xuất'
                    }]}>
                        <b style={{ color: 'red' }}>
                            Phòng Vật tư thiết bị y tế
                        </b>
                    </Form.Item>
                    {/* <Form.Item name="Target" label="Mục đích sử dụng" rules={[{
                        required: true,
                        message: 'Hãy nhập mục dích sử dụng'
                    }]}>
                        <Input.TextArea allowClear/>
                    </Form.Item> */}
                    <Form.Item name="Reason" label="Lý do" rules={[{
                        required: true,
                        message: 'Hãy nhập lý do'
                    }]}>
                        <Input.TextArea allowClear/>
                    </Form.Item>
                    <Form.Item name="Description" label="Mô tả" rules={[{
                        required: true,
                        message: 'Hãy nhập mô tả'
                    }]}>
                        <Input.TextArea allowClear/>
                    </Form.Item>
                    
                </Form>
                <Select onChange={handleTypeDevice} defaultValue={2} style={{ width: '100%', marginBottom: 10 }}>
                    <Select.Option value={1} key="1">Tất cả thiết bị của phòng ban</Select.Option>
                    <Select.Option value={2} key="2">Thiết bị báo hỏng của phòng ban</Select.Option>
                </Select>
                <ReactSelect
                    value={deviceChoose}
                    onChange={hanleChangeOption}
                    options={devices}
                    placeholder="Ds thiết bị"
                    isMulti={true}
                />
                    
                {/* <Form name="devices" onFinish={onFinish} form={form}>
                <Form.List name="DeviceOfferLineLst">
                    {(fields, { add, remove }) => {
                    return (
                        <div>
                        {fields.map(({ key, name, fieldKey, ...field }) => (
                            <div key={key}>
                                <Space>
                                    <Form.Item {...field}
                                        name={[name, "DeviceName"]}
                                        fieldKey={[fieldKey, "DeviceName"]}
                                        rules={[{
                                            required: true,
                                            message: "Tên thiết bị",
                                        },
                                        ]}
                                    >
                                        <Input placeholder="Tên thtết bị"/>
                                    </Form.Item>
                                    <Form.Item {...field}
                                        name={[name, "Quantity"]}
                                        fieldKey={[fieldKey, "Quantity"]}
                                        rules={[{
                                            required: true,
                                            message: "SL",
                                        },
                                        ]}
                                    >
                                        <InputNumber min={0} placeholder="Số lượng"/>
                                    </Form.Item>
                                    <Form.Item {...field}
                                        name={[name, "Amount"]}
                                        fieldKey={[fieldKey, "Amount"]}
                                        rules={[{
                                            required: true,
                                            message: "Giá trị",
                                        },
                                        ]}
                                    >
                                        <InputNumber min={0} 
                                        placeholder="Giá trị"
                                        style={{ width: 120 }}
                                        formatter={(value) =>
                                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        }
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                        />
                                    </Form.Item>
                                    <Form.Item {...field}
                                        name={[name, "Target"]}
                                        fieldKey={[fieldKey, "Target"]}
                                        rules={[{
                                            required: true,
                                            message: "Mục đích",
                                        },
                                        ]}
                                    >
                                        <Input.TextArea placeholder="Mục đích" style={{width: 200}} allowClear/>
                                    </Form.Item>
                                    <MinusCircleOutlined
                                    onClick={() => {
                                        remove(name);
                                    }}
                                    />
                                </Space>
                            </div>
                        ))}
                        <Form.Item className="btn_parameter">
                            <Button
                            type="dashed"
                            onClick={() => {
                                add();
                            }}
                            block
                            >
                            <PlusOutlined /> Thêm thiết bị
                            </Button>
                        </Form.Item>
                        </div>
                    );
                    }}
                </Form.List>
            </Form> */}
            </Drawer>
            <Button type='dashed'danger onClick={openModal} icon={<AppstoreAddOutlined />}>
                Tạo phiếu
            </Button>
        </div>
    )
}

export default Create
