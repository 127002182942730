import { Button, DatePicker, Form, Input, InputNumber, message, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import CreatableSelect from 'react-select/creatable';
import { UpdateDeviceInfo } from '../../../../api/Device';
import { CreateMaintenanceSchedule } from '../../../../api/TSMaintenance';
import { GetUserOrganization } from '../../../../api/TSOrganization';
import { API_DEVICE_MAINTAIN } from '../../../../constants/Device';
import { getUserName } from '../../../../helpers/auth';
import { transformDay } from '../../../helper/mapping';
import './Schedule.scss';
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const Schedule = ({ deviceSelected, repair }) => {
  const [visible, setVisible] = useState(false);
  const [allowCreaate, setAllowCreate] = useState(true);
  const [userReceiveEmail, setUserReceiveEmail] = useState([]);
  const [userOrganization, setUserOrganization] = useState([]);
  const title = `Đặt lịch theo dõi thiết bị`;
  const [form] = Form.useForm();
  const groupId = localStorage.getItem('groupId');
  const history = useHistory();
  const onOk = () => {
    form.submit();
  };
  const openModal = () => {
    setVisible(true);
    GetUserOrganization({
      OrganizationID: localStorage.getItem('groupId'),
      NumberRow: '',
      PageNumber: 1,
    }).then((res) => {
      setUserOrganization(
        res.UserOrganizationLst.map((user) => {
          return user.Email;
        })
      );
    });
  };
  const onCancel = () => {
    setVisible(false);
  };
  useEffect(() => {
    if (deviceSelected.length > 0) {
      setAllowCreate(false);
    } else {
      setAllowCreate(true);
    }
  }, [deviceSelected]);
  const onFinish = (elements) => {
    function scheduleDevice() {
      if (elements.typeDate === 'week') {
        return elements.schedule * 7;
      } else if (elements.typeDate === 'month') {
        return elements.schedule * 30;
      } else if (elements.typeDate === 'year') {
        return elements.schedule * 365;
      } else {
        return elements.schedule;
      }
    }
    const req = {
      CreaterId: getUserName(),
      OrganizationID: groupId,
      TimeStart: elements.timeStart.format('YYYY-MM-DD hh:mm:ss'),
      TimeEnd: elements.timeStart.format('YYYY-MM-DD hh:mm:ss'),
      Description: elements.Description,
      Note: elements.type,
      DeviceLst: deviceSelected,
      Schedule: transformDay(elements.schedule, elements.typeDate),
      LstMail: userReceiveEmail.length > 0 ? userReceiveEmail.join(',') : '',
    };
    CreateMaintenanceSchedule({
      MaintenanceScheduleInfo: req,
    }).then((res) => {
      deviceSelected.map((device, index) => {
        const req = {
          ...device,
          Schedule: scheduleDevice(),
          TimeStart: elements.timeStart.format('YYYY-MM-DD hh:mm:ss'),
        };
        delete req.TimeEnd;
        UpdateDeviceInfo({
          DeviceInfo: req,
        });
        if (index === deviceSelected.length - 1) {
          message.success('Tạo lịch thành công');
          history.push(API_DEVICE_MAINTAIN);
          setVisible(false);
        }
      });
    });
  };
  const handleChangeEmail = (newValue, value) => {
    setUserReceiveEmail(newValue);
  };
  const options =
    userOrganization &&
    userOrganization.map((user) => {
      return {
        label: user,
        value: user,
      };
    });
  return (
    <div style={{ marginBottom: 20 }}>
      <Modal
        title={title}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        okText="Xác nhận"
        cancelText="Hủy"
        width={600}
      >
        <Form form={form} onFinish={onFinish} name="orderSchedule" {...layout}>
          <Form.Item
            name="type"
            label="Loại theo dõi"
            rules={[
              {
                required: true,
                message: 'Hãy nhập loại theo dõi',
              },
            ]}
          >
            <Select
              placeholder="Loại theo dõi"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
              showSearch
              optionFilterProp="children"
            >
              <Select.Option value="0">Bảo dưỡng</Select.Option>
              <Select.Option value="1">Thay mới</Select.Option>
              <Select.Option value="2">Kiểm định</Select.Option>
              <Select.Option value="3">Hiệu chuẩn</Select.Option>
              <Select.Option value="4">Kiểm kê</Select.Option>
              <Select.Option value="5">Thanh lý</Select.Option>
              <Select.Option value="6">Hạn đăng kiểm</Select.Option>
              <Select.Option value="7">Hạn bảo hiểm</Select.Option>
              <Select.Option value="8">Thay dầu xe</Select.Option>
              <Select.Option value="9">Thời hạn bảo hiểm tự nguyện</Select.Option>
              <Select.Option value="10">Thời gian sửa chữa lớn</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="schedule"
            label="Chu kỳ theo dõi"
            rules={[
              {
                required: true,
                message: 'Hãy nhập chu kỳ',
              },
            ]}
          >
            <InputNumber placeholder="Nhập thời gian" min={0} style={{ width: 150 }} />
          </Form.Item>
          <Form.Item
            name="typeDate"
            label="Đơn vị ngày/tháng"
            rules={[
              {
                required: true,
                message: 'Hãy nhập loại ngày',
              },
            ]}
          >
            <Select style={{ width: 150 }} placeholder="Đơn vị">
              <Select.Option value="day">Ngày</Select.Option>
              <Select.Option value="week">Tuần</Select.Option>
              <Select.Option value="month">Tháng</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Thời gian bắt đầu theo dõi"
            name="timeStart"
            rules={[
              {
                required: true,
                message: 'Hãy chọn thời gian bắt đầu theo dõi',
              },
            ]}
          >
            <DatePicker showTime format="hh:mm DD-MM-YYYY" />
          </Form.Item>
          <Form.Item
            name="Description"
            label="Mô tả"
            rules={[
              {
                required: true,
                message: 'Hãy nhập mô tả',
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <CreatableSelect
            isMulti
            onChange={handleChangeEmail}
            options={options}
            placeholder="Danh sách mail nhận thông báo"
          />
        </Form>
      </Modal>
      <Button type="primary" onClick={openModal} disabled={allowCreaate}>
        Đặt lịch theo dõi
      </Button>
    </div>
  );
};

export default Schedule;
