import { EditOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Drawer, Form, Input, InputNumber, message, Select, Space, Tooltip, Upload } from 'antd';
import Axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { GetDefaultValue } from '../../../../api/defaultValue';
import { UpdateDeviceInfo } from '../../../../api/Device';
import { manageDevice } from '../../../../config/Recoil';
import { URL_QLTS } from '../../../../config/URL/Qlts';
import { getToken, getUserName } from '../../../../helpers/auth';
import { transformDate } from '../../../../helpers/mapping';
import { current } from '../../../helper/getTime';
import { dateDisplay, formatDateImport } from '../../../helper/mapping';
import './UpdateEquipment.scss';
const { Option } = Select;
const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 16 },
};
const Update = ({ data, change, orgId }) => {
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getManageDevice, setManageDevice] = useRecoilState(manageDevice);
  const [name, setName] = useState(data.DeviceName);
  const [code, setCode] = useState(data.DeviceCode);
  const [qrcode, setQrcode] = useState(data.QRCode);
  const [referenceCode, SetReferenCode] = useState(data.ReferenceCode);
  const [deviceValue, setDeviceValue] = useState(data.DeviceValue);
  const [unit, setUnit] = useState(data.Unit);
  const [type, setType] = useState(data.Type);
  const [origin, setOrigin] = useState(data.Origin);
  const [manufacturer, setManufacturer] = useState(data.Manufacturer);
  const [timeStart, setTimeStart] = useState(data.TimeStart);
  const [timeEnd, setTimeEnd] = useState(data.TimeEnd);
  const [timeManufacturer, setTimeManufacturer] = useState(data.TimeManufacturer || current());
  const [schedule, setSchedule] = useState(transformDate(data.Schedule));
  const [note, setNote] = useState(data.Note);
  const [accreditation, setAccreditation] = useState(data.Accreditation);
  const [timeImport, setTimeImport] = useState(data.TimeImport);
  const [warrantyStart, setWarrantyStart] = useState(data.WarrantyStart);
  const [warrantyEnd, setWarrantyEnd] = useState(data.WarrantyEnd);
  const [serial, setSerial] = useState(data.Serial);
  const [model, setModel] = useState(data.Model);
  const [importStatus, setImportStatus] = useState(data.ImportStatus == 0 ? 'Đã sử dụng' : 'Mới');
  const [DeviceParameterLst, setDeviceParameterLst] = useState(data.DeviceParameterLst);
  const [linkUpload, setLinkUpload] = useState(null);
  const [beforeUpload, setBeforeUpload] = useState(false);
  const [valueImage, setValueImage] = useState(null);
  const [valueFile, setValueFile] = useState(null);
  const [nameImage, setNameImage] = useState(null);
  const [nameFile, setNameFile] = useState(null);
  const [lstType, setLstType] = useState([]);
  const [deviceGroup, setDeviceGroup] = useState([]);
  const [valueDeviceGroup, SetValueDeviceGroup] = useState(data.DeviceGroup);
  const [selectTypeDate, setSelectTypeDate] = useState(transformDate(data.Schedule).type);
  const [form] = Form.useForm();
  const fomatTime = 'DD/MM/YYYY';
  function showModalUpdate() {
    setVisibleUpdate(true);
    GetDefaultValue({
      TableName: 'Medical',
    }).then((res) => {
      setLstType(res.DefaultValueLst);
    });
    GetDefaultValue({
      TableName: 'DeviceGroup',
    }).then((res) => {
      setDeviceGroup(res.DefaultValueLst);
    });
  }
  const onOke = () => {
    form.submit();
  };
  function hideModalUpdate() {
    setVisibleUpdate(false);
  }
  const changeCode = (e) => {
    setCode(e.target.value);
  };
  const changeQrcode = (e) => {
    setQrcode(e.target.value);
  };
  const handleUnit = (e) => {
    setUnit(e.target.value);
  };
  const changeDeviceValue = (e) => {
    setDeviceValue(e);
  };
  const changeReference = (e) => {
    SetReferenCode(e.target.value);
  };
  const changeName = (e) => {
    setName(e.target.value);
  };
  const changeType = (e) => {
    setType(e);
  };
  const changeOrigin = (e) => {
    setOrigin(e.target.value);
  };
  const changeManufacturer = (e) => {
    setManufacturer(e.target.value);
  };
  const changeTimeStart = (e) => {
    if (e === null) return;
    let timeStart = e.format('yyyy-MM-DD HH:mm:ss');
    setTimeStart(timeStart);
  };
  const changeTimeManufacturer = (e) => {
    if (e === null) return;
    let timeManufacturer = e.format('yyyy-MM-DD HH:mm:ss');
    setTimeManufacturer(timeManufacturer);
  };
  const changeTimeEnd = (e) => {
    if (e === null) return;
    let timeEnd = e.format('yyyy-MM-DD HH:mm:ss');
    setTimeEnd(timeEnd);
  };
  const changeParameter = (index) => (e) => {
    const cloneParameter = [...DeviceParameterLst];
    cloneParameter[index].Parameter = e.target.value;
    setDeviceParameterLst(cloneParameter);
  };
  const changeDescription = (index) => (e) => {
    const cloneParameter = [...DeviceParameterLst];
    cloneParameter[index].Description = e.target.value;
    setDeviceParameterLst(cloneParameter);
  };
  const changeQuantity = (index) => (e) => {
    const cloneParameter = [...DeviceParameterLst];
    cloneParameter[index].Quantity = e;
    setDeviceParameterLst(cloneParameter);
  };
  const changeUnit = (index) => (e) => {
    const cloneParameter = [...DeviceParameterLst];
    cloneParameter[index].Unit = e;
    setDeviceParameterLst(cloneParameter);
  };
  const changeNote = (e) => {
    setNote(e.target.value);
  };
  const DeleteParameter = (e) => {
    const clone = [...DeviceParameterLst];
    const parameterNew = clone.filter((data, index) => {
      return index != e;
    });
    setDeviceParameterLst(parameterNew);
  };
  const changeSerial = (element) => {
    setSerial(element.target.value);
  };
  const changeModel = (element) => {
    setModel(element.target.value);
  };
  const changeImportStatus = (element) => {
    setImportStatus(element);
  };
  const changeTimeImport = (element) => {
    if (element === null) return;
    let timeImport = element.format('yyyy-MM-DD HH:mm:ss');
    setTimeImport(timeImport);
  };
  const changeWarrantyStart = (element) => {
    if (element === null) return;
    let timeWarrantyStart = element.format('yyyy-MM-DD HH:mm:ss');
    setWarrantyStart(timeWarrantyStart);
  };
  const changeWarrantyEnd = (element) => {
    if (element === null) return;
    let timeWarrantyEnd = element.format('yyyy-MM-DD HH:mm:ss');
    setWarrantyEnd(timeWarrantyEnd);
  };
  const handleUpload = (files) => {
    setNameImage(files.file.originFileObj.name);
    const formData = new FormData();
    formData.append('Ảnh thiết bị', files.file.originFileObj);
    setValueImage(formData);
  };
  const handleUploadFile = (files) => {
    if (files.file.originFileObj.type === 'application/pdf') {
      setNameFile(files.file.originFileObj.name);
      const formData = new FormData();
      formData.append('File thiết bị', files.file.originFileObj);
      setValueFile(formData);
    } else {
      message.info('Hãy upload file có định dạng pdf');
    }
  };
  const changeDeviceGroup = (val) => {
    SetValueDeviceGroup(val);
  };
  const handleSheduleDay = (value) => {
    setSchedule(value.target.value);
  };
  const handleSchedule = (value) => {
    setSelectTypeDate(value);
  };
  function onOkeUpdate(values) {
    const userName = getUserName();
    const token = getToken();
    const reqImage = `${URL_QLTS}/File/UploadDeviceImage?DeviceID=${data.DeviceId}&Token=${token}&UserName=${userName}`;
    if (valueImage) {
      Axios.post(reqImage, valueImage).then((res) => {
        if (res.data.RespCode !== 0) {
          message.error('Lỗi upload ảnh');
        }
      });
    }
    const reqFile = `${URL_QLTS}/File/UploadDeviceFile?DeviceID=${data.DeviceId}&Token=${token}&UserName=${userName}&FileName=${nameFile}`;
    if (valueFile) {
      Axios.post(reqFile, valueFile).then((res) => {
        if (res.data.RespCode !== 0) {
          message.error('Lỗi upload file của thiết bị');
        }
      });
    }
    const addParameter = values.parameter_add;
    const fullParameter = [...DeviceParameterLst].concat(addParameter);
    function getSchedule() {
      if (selectTypeDate === 'day') {
        return schedule;
      } else if (selectTypeDate === 'week') {
        return schedule * 7;
      } else if (selectTypeDate === 'month') {
        return schedule * 30;
      } else {
        return schedule * 365;
      }
    }
    var req = {
      DeviceId: data.DeviceId,
      QRCode: qrcode,
      DeviceCode: code,
      ReferenceCode: referenceCode,
      DeviceName: name,
      DeviceValue: deviceValue,
      Unit: unit,
      Type: type,
      Origin: origin,
      Manufacturer: manufacturer,
      Note: note,
      TimeImport: timeImport,
      TimeManufacturer: timeManufacturer,
      Serial: serial,
      Model: model,
      Accreditation: accreditation,
      DeviceGroup: valueDeviceGroup,
      TimeStart: timeStart,
      TimeEnd: formatDateImport(timeEnd),
      WarrantyStart: warrantyStart,
      WarrantyEnd: warrantyEnd,
      ImportStatus: importStatus,
      DeviceParameterLst: addParameter === undefined ? DeviceParameterLst : fullParameter,
      OrganizationID: orgId,
    };
    setLoading(true);
    UpdateDeviceInfo({
      DeviceInfo: req,
    }).then((res) => {
      setLoading(false);
      if (res.RespCode === 0) {
        setManageDevice(!getManageDevice);
        setVisibleUpdate(false);
      }
    });
  }
  return (
    <div>
      <Drawer
        title="Cập nhật thông tin thiết bị"
        width={550}
        onClose={hideModalUpdate}
        visible={visibleUpdate}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <Button onClick={hideModalUpdate} style={{ marginRight: 12 }}>
              Hủy
            </Button>
            <Button onClick={onOke} loading={loading} type="primary">
              Cập nhật
            </Button>
          </div>
        }
      >
        <Form onFinish={onOkeUpdate} {...layout} form={form}>
          <div>
            <div className="value-input">
              <Form.Item label="Tên thiết bị">
                <Input value={name} onChange={changeName} />
              </Form.Item>
            </div>
            <div className="value-input">
              <Form.Item label="Mã thiết bị">
                <Input value={code} onChange={changeCode} disabled />
              </Form.Item>
            </div>
            <Form.Item label="Ảnh thiết bị">
              <Upload showUploadList={false} action={linkUpload} beforeUpload={beforeUpload} onChange={handleUpload}>
                <Button icon={<UploadOutlined />} style={{ marginRight: 10 }}>
                  Upload
                </Button>
                {nameImage && nameImage}
              </Upload>
            </Form.Item>
            <Form.Item label="File đính kèm">
              <Upload
                showUploadList={false}
                action={linkUpload}
                beforeUpload={beforeUpload}
                onChange={handleUploadFile}
              >
                <Button icon={<UploadOutlined />} style={{ marginRight: 10 }}>
                  Upload File
                </Button>
                {nameFile && nameFile}
              </Upload>
            </Form.Item>
            <div className="value-input">
              <Form.Item label="Mã Serial">
                <Input value={serial} onChange={changeSerial} />
              </Form.Item>
            </div>
            <div className="value-input">
              <Form.Item label="Mã Model">
                <Input value={model} onChange={changeModel} />
              </Form.Item>
            </div>
            <div className="value-input">
              <Form.Item label="QRCode">
                <Input value={qrcode} onChange={changeQrcode} />
              </Form.Item>
            </div>
            <div className="value-input">
              <Form.Item label="Mã tham chiếu">
                <Input value={referenceCode} onChange={changeReference} name="referenceCode" />
              </Form.Item>
            </div>
            <div className="value-input">
              <Form.Item label="Giá trị tài sản">
                <InputNumber
                  style={{ width: 160 }}
                  value={deviceValue}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  onChange={changeDeviceValue}
                  min={0}
                />{' '}
                (VNĐ)
              </Form.Item>
            </div>
            <div className="value-input">
              <Form.Item label="Đơn vị">
                <Input value={unit} onChange={handleUnit} />
              </Form.Item>
            </div>
            <div className="value-input">
              <Form.Item label="Tình trạng TB">
                <Select value={importStatus} onChange={changeImportStatus}>
                  <Select.Option value="1">Mới</Select.Option>
                  <Select.Option value="0">Đã sử dụng</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="value-input">
              <Form.Item label="Loại thiết bị">
                <Select value={type} onChange={changeType}>
                  {lstType.map((item, index) => (
                    <Option value={item.DefaultValue} key={index}>
                      {item.DefaultValue}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="value-input">
              <Form.Item label="Nhóm TB">
                <Select
                  allowClear
                  value={valueDeviceGroup}
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Lựa chọn nhóm thiết bị"
                  optionFilterProp="children"
                  onChange={changeDeviceGroup}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {deviceGroup.map((item, index) => (
                    <Select.Option value={item.DefaultValue} key={index}>
                      {item.DefaultValue}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="value-input">
              <Form.Item label="Xuất xứ">
                <Input value={origin} onChange={changeOrigin} />
              </Form.Item>
            </div>
            <div className="value-input">
              <Form.Item label="Nguồn cấp">
                <Input value={manufacturer} onChange={changeManufacturer} />
              </Form.Item>
            </div>
            <div className="value-input">
              <Form.Item label="Thời gian nhập">
                <Space>
                  <DatePicker
                    defaultValue={moment(dateDisplay(timeImport), fomatTime)}
                    name="timeStart"
                    placeholder="Thời gian bắt đầu"
                    onChange={changeTimeImport}
                    format={fomatTime}
                  />
                </Space>
              </Form.Item>
            </div>
            <div className="value-input">
              <Form.Item label="Thời gian bảo hành">
                <Space>
                  <DatePicker
                    defaultValue={moment(dateDisplay(warrantyStart), fomatTime)}
                    name="timeStart"
                    onChange={changeWarrantyStart}
                    format={fomatTime}
                  />
                  <DatePicker
                    defaultValue={moment(dateDisplay(warrantyEnd), fomatTime)}
                    name="timeStart"
                    onChange={changeWarrantyEnd}
                    format={fomatTime}
                  />
                </Space>
              </Form.Item>
            </div>
            <div className="value-input">
              <Form.Item label="Thời gian sản xuất">
                <DatePicker
                  defaultValue={moment(timeManufacturer, 'YYYY')}
                  name="timeManufacturer"
                  placeholder="Thời gian sản xuất"
                  onChange={changeTimeManufacturer}
                  picker="year"
                  format={'YYYY'}
                />
              </Form.Item>
            </div>
            <div className="value-input">
              <Form.Item label="Thời gian bắt đầu">
                <Space>
                  <DatePicker
                    defaultValue={moment(dateDisplay(timeStart), fomatTime)}
                    name="timeStart"
                    placeholder="Thời gian bắt đầu"
                    onChange={changeTimeStart}
                    format={fomatTime}
                  />
                </Space>
              </Form.Item>
            </div>
            {/* <div className="value-input">
              <Form.Item label="Hạn sử dụng">
                <Space>
                  <Input value={timeEnd} />
                  <DatePicker
                    name="timeEnd"
                    placeholder="Hạn sử dụng"
                    onChange={changeTimeEnd}
                    format="DD-MM-YYYY"
                  />
                </Space>
              </Form.Item>
            </div> */}
            {/* <div className="value-input">
              <Form.Item label="Chu kỳ bảo dưỡng">
                <Input min={0} max={30} value={schedule.value} onChange={handleSheduleDay} style={{width: 100}}/>
                <Select style={{width: 100, marginLeft: 10}} onChange={handleSchedule} value={selectTypeDate} defaultValue={schedule.type}>
                  <Select.Option value="day">Ngày</Select.Option>
                  <Select.Option value="week">Tuần</Select.Option>
                  <Select.Option value="month">Tháng</Select.Option>
                  <Select.Option value="year">Năm</Select.Option>
                </Select>
              </Form.Item>
            </div> */}
            <div className="value-input">
              <Form.Item label="Kiểm định">
                <Select value={accreditation} onChange={(val) => setAccreditation(val)}>
                  <Select.Option value="0">Không</Select.Option>
                  <Select.Option value="1">Có</Select.Option>
                </Select>
              </Form.Item>
            </div>

            <div className="value-input">
              <Form.Item label="Ghi chú">
                <Input.TextArea value={note} onChange={changeNote} />
              </Form.Item>
            </div>
            <div className="parameter">
              <p>Thông số thiết bị: </p>
              {
                <Space className="title_lst_param">
                  <p>Thông số</p>
                  <p>Số lượng</p>
                  <p>Đơn vị</p>
                  <p>Mô tả</p>
                </Space>
              }
              {DeviceParameterLst &&
                DeviceParameterLst.map((parameter, index) => (
                  <div key={index}>
                    {
                      <Space style={{ display: 'flex', marginBottom: 5 }} align="start">
                        <Input
                          key={index}
                          name="Parameter"
                          value={parameter.Parameter}
                          onChange={changeParameter(index)}
                        />

                        <InputNumber
                          key={index}
                          name="Quantity"
                          value={parameter.Quantity}
                          onChange={changeQuantity(index)}
                        />
                        <Select key={index} name="Quantity" value={parameter.Unit} onChange={changeUnit(index)}>
                          <Option value="chiếc">Chiếc</Option>
                          <Option value="cái">Cái</Option>
                        </Select>
                        <Input.TextArea
                          key={index}
                          name="Desciption"
                          value={parameter.Description}
                          onChange={changeDescription(index)}
                        />
                        <span onClick={() => DeleteParameter(index)}>
                          <a>
                            <MinusCircleOutlined />
                          </a>
                        </span>
                      </Space>
                    }
                  </div>
                ))}
            </div>
            <div className="parameter">
              <Form name="formParameter" onFinish={onOkeUpdate} form={form}>
                <Form.List name="parameter_add">
                  {(fields, { add, remove }) => {
                    return (
                      <div>
                        {fields.map((field) => (
                          <Space key={field.key} style={{ display: 'flex', marginBottom: 5 }} align="start">
                            <Form.Item
                              {...field}
                              name={[field.name, 'DeviceId']}
                              fieldKey={[field.fieldKey, 'DeviceId']}
                              rules={[{ required: true, message: 'error' }]}
                            >
                              <Select>
                                <Option value={data.DeviceId}>{data.DeviceId}</Option>
                              </Select>
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'Parameter']}
                              fieldKey={[field.fieldKey, 'Parameter']}
                              rules={[{ required: true, message: 'nhập vào' }]}
                            >
                              <Input placeholder="Thông số" />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'Quantity']}
                              fieldKey={[field.fieldKey, 'Quantity']}
                              rules={[{ required: true, message: 'nhập vào' }]}
                            >
                              <InputNumber placeholder="Số lượng" min={0} />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'Unit']}
                              fieldKey={[field.fieldKey, 'Unit']}
                              rules={[{ required: true, message: 'nhập vào' }]}
                            >
                              <Select placeholder="Đơn vị">
                                <Option value="chiếc">Chiếc</Option>
                                <Option value="cái">Cái</Option>
                              </Select>
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'Description']}
                              fieldKey={[field.fieldKey, 'Description']}
                              rules={[{ required: true, message: 'nhập vào' }]}
                            >
                              <Input.TextArea placeholder="Mô tả" />
                            </Form.Item>
                            <MinusCircleOutlined
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          </Space>
                        ))}
                        <Form.Item className="btn_parameter">
                          <Button
                            type="dashed"
                            onClick={() => {
                              add();
                            }}
                            block
                          >
                            <PlusOutlined /> Thêm thông số
                          </Button>
                        </Form.Item>
                      </div>
                    );
                  }}
                </Form.List>
              </Form>
            </div>
          </div>
        </Form>
      </Drawer>
      <div onClick={() => showModalUpdate()} className="icon_action">
        <a>
          <Tooltip placement="bottom" title="Chỉnh sửa">
            <EditOutlined />
          </Tooltip>
        </a>
      </div>
    </div>
  );
};
export default Update;
