import { message } from 'antd';
import axios from 'axios';
import { URL_QLTS } from '../URL/Qlts';
// create an axios instance
const service = axios.create({
    baseURL: URL_QLTS, // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 10000, // request timeout
    headers:{
        Accept: "application/json",
        "Content-Type": "application/json"
    }
})

// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // do something with request error
    if (error.code === 'ECONNABORTED') {
      console.log('Request timed out');
      return Promise.reject(error);
    } else {
      // Handle other errors
      console.log('Request failed:', error.message);
      alert('An error occurred. Please try again later.');
      return Promise.reject(error);
    }
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    if (response.data == null) {
        response.data = {};
      }
      const res = response.data;
      if (res.RespCode !== 0) {
        message.error(res.RespText)
        Promise.reject()
        return res
      }
      else {
        return res;
      }
  },
  error => {
    // do something with request error
    if (error.code === 'ECONNABORTED') {
      console.log('Request timed out');
      return Promise.reject(error);
    } else {
      // Handle other errors
      console.log('Request failed:', error.message);
      alert('An error occurred. Please try again later.');
      return Promise.reject(error);
    }
  }
)

export default service
