import { DeleteOutlined } from '@ant-design/icons';
import { Select, Table } from 'antd';
import React, { useMemo } from 'react';
import { GROUP_ROLE } from '../../../../constants/Group/Roles';
import './AdminMember.scss';

const AdminMemberDetail = ({ membersInOrg = [], loading, handleRole, onDeleteMember }) => {
  const roles = GROUP_ROLE.filter((role) => role.value !== 1);
  const columns = [
    {
      title: 'STT',
      dataIndex: 'key',
      key: 'key',
      width: 60,
    },
    {
      title: 'Tên',
      dataIndex: 'FullName',
      key: 'FullName',
      width: 300,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'UserName',
      key: 'UserName',
      width: 200,
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      key: 'Email',
      width: 200,
    },
    {
      title: 'Quyền',
      render: (record) => (
        <div>
          {record.UserRole === 1 ? (
            <b>{record.UserRoleTxt}</b>
          ) : (
            <div className="admin-membe-detail__column-action">
              <Select defaultValue={record.UserRole} onChange={() => handleRole(record)} style={{ width: '100px' }}>
                {roles.map((role) => (
                  <Select.Option disabled={role.value === 1} key={role.value} value={role.value}>
                    {role.label}
                  </Select.Option>
                ))}
              </Select>
              <DeleteOutlined
                onClick={() => onDeleteMember(record)}
                style={{ fontSize: 20, color: '#f81d22', cursor: 'pointer', marginLeft: 15 }}
              />
            </div>
          )}
        </div>
      ),
    },
  ];
  return useMemo(
    () => (
      <div id="admin-detail">
        <Table dataSource={membersInOrg} columns={columns} loading={loading} pagination={false} />
      </div>
    ),
    [membersInOrg]
  );
};

export default AdminMemberDetail;
