import { Button, Form, Input, message, Col } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './Register.scss';
import FormItem from 'antd/lib/form/FormItem';
import { UserRegister } from '../../../../api/User';
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailLayout = {
  wrapperCol: { offset: 12, span: 12 },
};
export default function RegistrationForm({ changeForm }) {
  const [loading, setLoading] = React.useState(false);
  const onFinish = (values) => {
    setLoading(true);
    UserRegister(values).then((res) => {
      setLoading(false);
      if (res.RespCode === 0) {
        message.success('Đăng kí thành công. Mật khẩu đã được gửi về email của bạn');
        changeForm();
      }
    });
  };

  return (
    <div className="form-register">
      <div
        style={{ textTransform: 'uppercase', textAlign: 'center', paddingBottom: 20, fontSize: 28, fontWeight: 500 }}
      >
        Đăng ký
      </div>
      <Form {...formItemLayout} name="register" onFinish={onFinish} scrollToFirstError>
        <Form.Item
          name="FullName"
          label="Họ & Tên : "
          className="register-input"
          rules={[
            {
              required: true,
              message: 'Hãy nhập tên của bạn!!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="Email"
          label="E-mail"
          className="register-input"
          rules={[
            {
              required: true,
              message: 'Hãy nhập email của bạn!!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="Phone"
          label="SĐT"
          className="register-input"
          rules={[
            {
              required: true,
              message: 'Hãy nhập số điện thoại của bạn!!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="Address"
          label="Địa chỉ"
          className="register-input"
          rules={[
            {
              required: true,
              message: 'Hãy nhập địa chỉ của bạn!!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Đăng ký
          </Button>
        </Form.Item>
        <div onClick={() => changeForm()} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <a>
            <ArrowLeftOutlined />
            Đăng nhập
          </a>
        </div>
      </Form>
    </div>
  );
}
