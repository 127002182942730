import React, { useMemo } from 'react';
import './AdminMember.scss';

const AdminMember = ({ organization = { OrganizationName: '' }, orgSelected = {}, onSelectOrganization }) => {
  const memberSelected = () => {
    return orgSelected.OrganizationID === organization.OrganizationID ? '-selected' : '';
  };
  return useMemo(
    () => (
      <div className={'admin-member' + memberSelected()} onClick={() => onSelectOrganization(organization)}>
        {organization.OrganizationName}
      </div>
    ),
    [orgSelected]
  );
};

export default AdminMember;
