import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal, Table, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { GetMoveDeviceDocInfo } from '../../../../api/TSOrganization';
import { paperMov } from '../../../../config/Recoil';
import { AddKeyToLst } from '../../../helper/addKeyToLst';
import { dateDisplay, formatMoneyDisplay } from '../../../helper/mapping';
import './InfoDeviceMov.scss';
const columnsMoveDevice = [
  {
    title: 'STT',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Tên TB',
    dataIndex: 'DeviceName',
    key: 'DeviceName',
  },
  {
    title: 'Mã TB',
    dataIndex: 'DeviceCode',
    key: 'DeviceCode',
  },
  {
    title: 'Serial',
    dataIndex: 'Serial',
    key: 'Serial',
  },
  {
    title: 'Model',
    dataIndex: 'Model',
    key: 'Model',
  },
  {
    title: 'Giá trị ban đầu',
    dataIndex: 'DeviceValue',
    key: 'DeviceValue',
    render: (record) => {
      return formatMoneyDisplay(record);
    },
  },
  {
    title: 'Giá trị hiện tại',
    dataIndex: 'DeviceValueCurrent',
    key: 'DeviceDeviceValueCurrentValue',
    render: (record) => {
      return formatMoneyDisplay(record);
    },
  },
  {
    title: 'TG bắt đầu sử dụng',
    dataIndex: 'TimeStart',
    key: 'TimeStart',
    render: (record) => {
      return dateDisplay(record, 'YYYY');
    },
  },
];
const InfoMove = ({ infoMove }) => {
  const [visible, setVisible] = useState(false);
  const setInfoPaper = useSetRecoilState(paperMov);
  const [info, setInfo] = useState({
    DeviceLst: [],
  });
  const showInfo = async () => {
    setVisible(true);
    const documentInfo = await GetMoveDeviceDocInfo({
      DocumentID: infoMove.DocumentID,
    });
    setInfoPaper(documentInfo.MoveDeivceDocInfo);
    setInfo(documentInfo.MoveDeivceDocInfo);
  };
  const onCancel = () => {
    setVisible(false);
  };
  return (
    <div>
      <Modal visible={visible} onCancel={onCancel} title="Chi tiết phiếu luân chuyển" width={700}>
        <div style={{ lineHeight: '1.8em' }}>
          <div>Người tạo: {info.CreaterID}</div>
          <div>Tên người tạo: {info.CreateName}</div>
          <div>Người tạo: {info.CreaterID}</div>
          <div>Tên tổ chức yêu cầu: {info.OrganizationNameOld}</div>
          <div>Tên tổ chức nhận: {info.OrganizationNameNew}</div>
          <div>Mô tả: {info.Description}</div>
          <div>
            <b>Danh sách thiết bị:</b>
            <Table dataSource={AddKeyToLst(info.DeviceLst)} columns={columnsMoveDevice} pagination={false} />
          </div>
        </div>
      </Modal>
      <div onClick={showInfo}>
        <Tooltip placement="bottom" title="thông tin">
          <InfoCircleOutlined style={{ fontSize: 20, cursor: 'pointer' }} />
        </Tooltip>
      </div>
    </div>
  );
};
export default InfoMove;
