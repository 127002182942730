import React, { useRef } from "react";
import { QRCode } from "react-qr-svg";
import ReactToPrint from "react-to-print";
import { Button } from "antd";
import { DOMAIN } from "../URL/Qlts";
import './PrintQRCode.scss'
class ComponentToPrint extends React.Component {
  render() {
    const { devices } = this.props
    return (
      <div className="widthScreen">
        {
          devices && devices.map((qrCode, index) => (
            <div style={{ width: 125, display: "inline-block" }}>
              <div className="deviceQR">
                <div className="child">
                  <QRCode
                    value={`${DOMAIN}/qr-code/${qrCode.QRCode}`}
                    width="114px"
                  />
                </div>
                <div className="text">{qrCode.DeviceCode}</div>
              </div>
            </div>
          ))}
      </div>
    );
  }
}
const PrintQrCode = ({ data }) => {
  const valueQrCode = useRef();
  return (
    <div>
      <div style={{ display: "none" }}>
        <ComponentToPrint ref={valueQrCode} devices={data.length > 0 && data} />
      </div>
      <ReactToPrint
        documentTitle="In mã QR thiết bị"
        trigger={() => <Button>In mã QR</Button>}
        content={() => valueQrCode.current}
      />
    </div>
  );
};

export default PrintQrCode;
