import Request from '../config/Request';
export function GetDeviceInfoByQRCode(data) {
  return Request({
    url: '/Device/GetDeviceInfoByQRCode',
    method: 'POST',
    data: data,
  });
}
export function GetDeviceErrorByOrgID(data) {
  return Request({
    url: '/Device/GetDeviceErrorByOrgID',
    method: 'POST',
    data: data,
  });
}
