import Request from '../config/Request';
export function GetCenser(data) {
  return Request({
    url: '/Censer/GetCenser',
    method: 'POST',
    data: data,
  });
}
export function CreateCenser(data) {
  return Request({
    url: '/Censer/CreateCenser',
    method: 'POST',
    data: data,
  });
}
export function UpdateCenser(data) {
  return Request({
    url: '/Censer/UpdateCenser',
    method: 'POST',
    data: data,
  });
}
export function DelCenser(data) {
  return Request({
    url: '/Censer/DelCenser',
    method: 'POST',
    data: data,
  });
}
export function ModifySetupLineCenser(data) {
  return Request({
    url: '/Censer/ModifySetupLineCenser',
    method: 'POST',
    data: data,
  });
}
