import { ArrowLeftOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, message, Modal } from 'antd';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DBGetDeviceInfoByQRCode, GetDeviceInfoByQRCode } from '../../../../api/Device';
import { UserLogin } from '../../../../api/User';
import { dateDisplay } from '../../../helper/mapping';
import './InfoDevice.scss';

const InfoDevice = () => {
  const history = useHistory();
  const { qrCode } = useParams();
  const [typeGet, setTypeGet] = useState(false);
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    setLoading(true);
    const responseLogin = await UserLogin(values);
    if (responseLogin.RespCode === 0) {
      const responseDeviceInfo = await GetDeviceInfoByQRCode({
        QRCode: qrCode,
      });
      if (responseDeviceInfo.RespCode === 0) {
        const valueDevice = responseDeviceInfo.DeviceInfo;
        Modal.info({
          title: 'Thông tin tài sản',
          content: (
            <div>
              <p>Tên thiết bị: {valueDevice.DeviceName}</p>
              <p>Mã thiết bị: {valueDevice.DeviceCode}</p>
              <p>Serial: {valueDevice.Serial}</p>
              <p>Model: {valueDevice.Model}</p>
              <p>Giá trị: {valueDevice.DeviceValue}</p>
              <p>
                Trạng thái:{' '}
                {valueDevice.Status === 1
                  ? 'Đang hoạt động'
                  : valueDevice.Status === 2
                  ? 'Đã báo cáo'
                  : valueDevice.Status === 3
                  ? 'Đang bảo dưỡng'
                  : ''}
              </p>
              <p>Loại: {valueDevice.Type}</p>
              <p>Xuất xứ: {valueDevice.Origin}</p>
              <p>Nguồn cấp: {valueDevice.Manufacturer}</p>
              <p>Phòng ban sử dụng: {valueDevice.Location}</p>
              <p>Thời gian nhập: {dateDisplay(valueDevice.TimeStart)}</p>
              <p>Hạn sử dụng: {valueDevice.TimeEnd}</p>
              <p>
                TG bảo hành: từ {dateDisplay(valueDevice.WarrantyStart)} đến {dateDisplay(valueDevice.WarrantyEnd)}
              </p>
            </div>
          ),
        });
      }
    }
    setLoading(false);
  };
  const modalDeviceInfo = (valueDevice) => {
    Modal.info({
      title: 'Thông tin tài sản',
      content: (
        <div>
          <p>Người tạo: {valueDevice.CreaterId}</p>
          <p>Tên thiết bị: {valueDevice.DeviceName}</p>
          <p>Mã thiết bị: {valueDevice.DeviceCode}</p>
          <p>Mã tham chiếu: {valueDevice.ReferenceCode}</p>
          <p>
            Trạng thái:{' '}
            {valueDevice.Status === 1
              ? 'Đang hoạt động'
              : valueDevice.Status === 2
              ? 'Đã báo cáo'
              : valueDevice.Status === 3
              ? 'Đang bảo dưỡng'
              : ''}
          </p>
          <p>Loại: {valueDevice.Type}</p>
          <p>Xuất xứ: {valueDevice.Origin}</p>
          <p>Nơi sản xuất: {valueDevice.Manufacturer}</p>
          <p>Phòng ban sử dụng: {valueDevice.Location}</p>
          <p>Thời gian bắt đầu: {valueDevice.TimeCreate}</p>
          <p>Hạn sử dụng: {valueDevice.TimeEnd}</p>
          <p>Lịch bảo dưỡng: {valueDevice.Schedule} tháng/lần</p>
        </div>
      ),
      onOk() {
        history.goBack();
      },
      okText: 'Xác nhận',
    });
  };
  useEffect(() => {
    DBGetDeviceInfoByQRCode({
      QRCode: qrCode,
    }).then((res) => {
      setLoading(false);
      if (res.RespCode === 0) {
        const valueDevice = res.data.DeviceInfo;
        modalDeviceInfo(valueDevice);
      }
    });
    if (localStorage.getItem('token')) {
      const reqScanCode = {
        QRCode: qrCode,
      };
      Axios.post('https://emglab.vn/QLTS/Device/GetDeviceInfoByQRCode', reqScanCode).then((res) => {
        if (res.data.RespCode === 0) {
          setLoading(false);
          const valueDevice = res.data.DeviceInfo;
          modalDeviceInfo(valueDevice);
        } else {
          message.error('Hãy đăng nhập để xem thông tin');
          setTypeGet(true);
        }
      });
    } else {
      setTypeGet(true);
    }
  }, []);
  return (
    <div className="qr-login">
      {typeGet && (
        <div className="form-login">
          <div>
            <h3>Đăng nhập</h3>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                name="UserName"
                rules={[
                  {
                    required: true,
                    message: 'Hãy nhập số điện thoại của bạn',
                  },
                ]}
              >
                <Input
                  prefix={
                    <div>
                      <UserOutlined className="site-form-item-icon" />
                      <Divider type="vertical"></Divider>
                    </div>
                  }
                  placeholder="Số điện thoại"
                  className="text-input"
                />
              </Form.Item>
              <Form.Item
                name="Password"
                rules={[
                  {
                    required: true,
                    message: 'Hãy nhập mật khẩu của bạn!',
                  },
                ]}
              >
                <Input
                  prefix={
                    <div>
                      <LockOutlined className="site-form-item-icon" />
                      <Divider type="vertical"></Divider>
                    </div>
                  }
                  type="password"
                  placeholder="Mật khẩu"
                  className="text-input"
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                  Đăng nhập
                </Button>
              </Form.Item>
            </Form>
            <div
              className="link_go_back"
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowLeftOutlined style={{ marginTop: 5, paddingRight: 10 }} /> Quay lại
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoDevice;
