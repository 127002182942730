import Request from '../config/Request';
export function GetDeviceSetLst(data) {
  return Request({
    url: '/DeviceSet/GetDeviceSetLst',
    method: 'POST',
    data: data,
  });
}
export function CreateDeviceSet(data) {
  return Request({
    url: '/DeviceSet/CreateDeviceSet',
    method: 'POST',
    data: data,
  });
}
export function UpdateDeviceSet(data) {
  return Request({
    url: '/DeviceSet/UpdateDeviceSet',
    method: 'POST',
    data: data,
  });
}
export function DelDeviceSet(data) {
  return Request({
    url: '/DeviceSet/DelDeviceSet',
    method: 'POST',
    data: data,
  });
}
export function GetDeviceSetByCode(data) {
  return Request({
    url: '/DeviceSet/GetDeviceSetByCode',
    method: 'POST',
    data: data,
  });
}
export function GetDeviceSetByQRCode(data) {
  return Request({
    url: '/DeviceSet/GetDeviceSetByQRCode',
    method: 'POST',
    data: data,
  });
}
export function GetDeviceSetInfo(data) {
  return Request({
    url: '/DeviceSet/GetDeviceSetInfo',
    method: 'POST',
    data: data,
  });
}
