import { Button, Checkbox, DatePicker, Form, Input, InputNumber, message, Modal, Row, Select, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { GetDefaultValue } from '../../../../api/defaultValue';
import { GetDeviceByOrganization } from '../../../../api/Device';
import deviceAPI from '../../../../api/DeviceApi';
import { CreateDeviceSet } from '../../../../api/DeviceSet';
import { manageSetDevice } from '../../../../config/Recoil';
import { createUid } from '../../../helper/createUid';
import jsUcfirst from '../../../helper/jsUcfirst';
import { formatDateImport, formatMoneyDisplay } from '../../../helper/mapping';
const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const columns = [
  {
    title: 'Tên thiết bị',
    dataIndex: 'DeviceName',
    key: 'DeviceName',
  },
  {
    title: 'Mã thiết bị',
    dataIndex: 'DeviceCode',
    key: 'DeviceCode',
  },
  {
    title: 'Mã tham chiếu',
    dataIndex: 'ReferenceCode',
    key: 'ReferenceCode',
  },
  {
    title: 'Giá trị (VNĐ)',
    dataIndex: 'DeviceValue',
    key: 'DeviceValue',
    render: (record) => {
      return formatMoneyDisplay(record);
    },
  },
  {
    title: 'Chu kỳ bảo dưỡng',
    dataIndex: 'Schedule',
    key: 'Schedule',
    render: (record) => {
      return `${record} Tháng/Lần`;
    },
  },
  {
    title: 'Ghi chú',
    dataIndex: 'Note',
    key: 'Note',
  },
];
const CreateSetDevice = () => {
  const [setDevice, createSetDevice] = useRecoilState(manageSetDevice);
  const [loading, setLoading] = useState(true);
  const organizationID = localStorage.getItem('groupId');
  const [visible, setVisible] = useState(false);
  const [schedule, setSchedule] = useState('');
  const [lstDeviceLink, setLstDeviceLink] = useState([]);
  const [deviceChoose, setDeviceChoose] = useState([]);
  const [lstType, setLstType] = useState([]);
  const [valueSearch, setValueSearch] = useState(null);
  const [typeSetDevice, setTypeSetDevice] = useState('');
  const [valueSetDevice, setValueSetDevice] = useState('');
  const [form] = Form.useForm();
  const showModal = async () => {
    setVisible(true);
    const responseDefaultValue = await GetDefaultValue({ TableName: 'TypeDevice' });
    setLstType(responseDefaultValue.DefaultValueLst);
  };
  const onOke = (e) => {
    form.submit();
  };
  const handleChange = (e) => {
    setTypeSetDevice(e);
  };

  const onFinish = async (values) => {
    var TimeStart = formatDateImport(values['TimeStart']);
    var TimeEnd = formatDateImport(values['TimeEnd']);
    var QRCode = createUid();
    const valueRequest = {
      ...values,
      DeviceValue: valueSetDevice,
      TimeStart: TimeStart,
      TimeEnd: TimeEnd,
      QRCode: QRCode,
      Type: typeSetDevice,
      Schedule: schedule,
      DeviceSetParaLst: deviceChoose,
      OrganizationID: organizationID,
    };
    const responseCreateDeviceSet = await CreateDeviceSet({
      DeviceSetInfo: valueRequest,
    });
    if (responseCreateDeviceSet.RespCode === 0) {
      message.success('Tạo bộ thiết bị thành công');
      setVisible(false);
      createSetDevice(true);
    }
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const totalValueSetDevice = selectedRows.reduce((up, down) => {
        return up + down.DeviceValue;
      }, 0);
      setValueSetDevice(totalValueSetDevice);
      setDeviceChoose(selectedRows);
    },
  };
  const deviceSearch = lstDeviceLink.filter((value) => {
    if (valueSearch === null) {
      return;
    }
    return (
      value.DeviceCode.toLowerCase().indexOf(valueSearch.toLowerCase()) !== -1 ||
      value.DeviceName.toLowerCase().indexOf(valueSearch.toLowerCase()) !== -1 ||
      value.Note.toLowerCase().indexOf(valueSearch.toLowerCase()) !== -1
    );
  });
  const deviceSearchaddKey = deviceSearch.map((device) => {
    return {
      ...device,
      key: device.DeviceId,
    };
  });
  const handleValueSearch = (e) => {
    setValueSearch(e.target.value);
  };
  useEffect(async () => {
    const api = deviceAPI.getByOrganization();
    const request = {
      OrganizationID: organizationID,
      Search: valueSearch,
      DeviceGroup: '',
      RowNumber: '',
      PageNumber: '',
    };

    const fetchData = async () => {
      const responseDeviceByOrg = await GetDeviceByOrganization(request);
      if (responseDeviceByOrg.RespCode === 0) {
        const deviceLink = responseDeviceByOrg.DeviceLst.map((device) => {
          return {
            ...device,
            key: device.DeviceId,
          };
        });
        const valueFilter = deviceLink.filter((device) => {
          return device.Status === 1;
        });
        setLstDeviceLink(valueFilter);
      }
    };
    fetchData();
    createSetDevice(false);
  }, [setDevice]);
  return (
    <div style={{ width: '100%' }}>
      <Modal
        visible={visible}
        title="Liên kết thiết bị"
        onOk={onOke}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <Form form={form} name="info_link_device" onFinish={onFinish} {...layout}>
          <Form.Item
            label="Tên bộ thiết bị"
            name="DeviceName"
            rules={[
              {
                required: true,
                message: 'Nhập tên bộ thiết bị',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Mã bộ thiết bị"
            name="DeviceCode"
            rules={[
              {
                required: true,
                message: 'Nhập mã bộ thiết bị',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Giá trị bộ thiết bị" name="DeviceValue">
            <InputNumber
              value={valueSetDevice}
              min={0}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              style={{ width: 160 }}
            />{' '}
            (VNĐ)
          </Form.Item>
          <Form.Item label="Mã tham chiếu" name="ReferenceCode">
            <Input />
          </Form.Item>

          <Form.Item
            label="Kiểu bộ thiết bị"
            rules={[
              {
                required: true,
                message: 'Nhập kiểu bộ thiết bị',
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: 250 }}
              optionFilterProp="children"
              onChange={handleChange}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {lstType.map((item) => (
                <Option value={item.DefaultValue}>{jsUcfirst(`${item.DefaultValue}`)}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="TimeStart"
            label="Thời gian bắt đầu"
            rules={[
              {
                required: true,
                message: 'Hãy nhập thời gian bắt đầu',
              },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" style={{ width: 250 }} placeholder="Thời gian bắt đầu" />
          </Form.Item>
          <Form.Item
            name="TimeEnd"
            label="Hạn sử dụng"
            rules={[
              {
                required: true,
                message: 'Hãy nhập hạn sử dụng',
              },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" style={{ width: 250 }} placeholder="Hạn sử dụng" />
          </Form.Item>
          <Form.Item label="Chu kỳ bảo dưỡng">
            <Select style={{ width: 160 }} onChange={(e) => setSchedule(e)}>
              <Option value="1">1 tháng</Option>
              <Option value="3">3 tháng</Option>
              <Option value="6">6 tháng</Option>
              <Option value="12">12 tháng</Option>
              <Option value="24">24 tháng</Option>
              <Option value="36">36 tháng</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Ghi chú" name="Note">
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
      <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button type="primary" onClick={showModal} disabled={deviceChoose.length === 0 ? true : false}>
          Tạo bộ thiết bị
        </Button>
        <Search
          placeholder="Thông tin tìm kiếm"
          onChange={handleValueSearch}
          style={{
            width: 250,
            marginRight: 20,
            marginBottom: 20,
          }}
        />
      </Row>
      {/* <Row> */}
      <Table
        rowSelection={{
          type: Checkbox,
          ...rowSelection,
        }}
        dataSource={valueSearch ? deviceSearchaddKey : lstDeviceLink}
        columns={columns}
        loading={loading}
      />
      {/* </Row> */}
    </div>
  );
};

export default CreateSetDevice;
