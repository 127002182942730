import {
  AppstoreAddOutlined,
  AreaChartOutlined,
  BarChartOutlined,
  InfoCircleOutlined,
  IssuesCloseOutlined,
  LaptopOutlined,
  MenuFoldOutlined,
  OrderedListOutlined,
  PlusSquareOutlined,
  QrcodeOutlined,
  RetweetOutlined,
  ScheduleOutlined,
  SettingOutlined,
  TeamOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { organizationInfo, stateSideBar } from '../../config/Recoil';
import { API_SETTING_DEFAULT_VALUE } from '../../constants';
import {
  API_DEVICE_CREATE,
  API_DEVICE_LINK,
  API_DEVICE_LIQUIDATION,
  API_DEVICE_LST,
  API_DEVICE_LST_ERROR,
  API_DEVICE_LST_REPORT,
  API_DEVICE_MAINTAIN,
  API_DEVICE_MOV,
  API_DEVICE_OFFER,
  API_DEVICE_REPAIR,
  API_DEVICE_REPAIR_QR,
  API_DEVICE_REPORT_ACCREDITATION,
  API_DEVICE_REPORT_REPAIR,
  API_DEVICE_REQUEST_ACCREDITATION,
  API_DEVICE_REQUEST_REPAIR,
} from '../../constants/Device';
import { API_LIQUI_CREATE2, API_LIQUI_LST, LINK_DOCUMENT, LINK_TUTORIAL } from '../../constants/Inventory';
import { API_MEMBER_LST } from '../../constants/Member';
import DeleteGroup from '../../containers/Group/DeleteGroup';
import UpdateGroupInfo from '../../containers/Group/UpdateGroupInfo';
import './styles.scss';
import { VTYT } from '../../config/URL/Qlts';
import { getGroupId } from '../../helpers/auth';
import { allowCreate } from '../../constants/Group/Roles';
const { SubMenu } = Menu;

const Sidebar2 = ({ role }) => {
  const [collapsed, setCollapsed] = useRecoilState(stateSideBar);
  const groupInfo = useRecoilValue(organizationInfo);
  const location = useLocation();
  const allowDisplay = allowCreate(VTYT) && groupInfo.ManagerRole != 3;
  const changeCollapsed = () => {
    setCollapsed(!collapsed);
  };
  useEffect(() => {
    if (window.screen.width < 767) {
      setCollapsed(false);
    }
  }, [location.pathname]);
  return (
    <div
      id="sidebar-container-v2"
      style={{ width: collapsed ? (window.screen.width > 767 ? '16%' : 220) : window.screen.width > 767 ? 80 : 0 }}
    >
      <Menu mode="inline" theme="dark" style={{ height: '100%', overflowY: 'auto' }} className="side_bar">
        <Menu.Item key="overview" icon={<TeamOutlined />}>
          <Link to={`/group/${getGroupId()}`}>Tổng quan</Link>
        </Menu.Item>
        <Menu.Item key="members" icon={<UnorderedListOutlined />} style={{ background: 'rbg(70,70,70)' }}>
          <Link to={API_MEMBER_LST}>Hồ sơ thành viên</Link>
        </Menu.Item>
        <SubMenu key="equipment" icon={<LaptopOutlined />} title="Thiết bị">
          <Menu.Item key="equipments" icon={<UnorderedListOutlined />}>
            <Link to={API_DEVICE_LST}>Hồ sơ thiết bị</Link>
          </Menu.Item>
          {allowDisplay && (
            <Menu.Item key="create_device" icon={<PlusSquareOutlined />}>
              <Link to={API_DEVICE_CREATE}>Tạo mới thiết bị</Link>
            </Menu.Item>
          )}
          <Menu.Item key="offer" icon={<AppstoreAddOutlined />}>
            <Link to={API_DEVICE_OFFER}>Đề xuất</Link>
          </Menu.Item>
          <SubMenu key="deviceMove" title="Hồ sơ điều chuyển" icon={<RetweetOutlined />}>
            <Menu.Item key="deviceMoveOrg">
              <Link to={API_DEVICE_MOV}>Luân chuyển</Link>
            </Menu.Item>
            {/* <Menu.Item key="deviceMoveUser">
              <Link to={API_DEVICE_LST}>Phân bổ người dùng</Link>
            </Menu.Item> */}
            <Menu.Item key="deviceLinkUser">
              <Link to={API_DEVICE_LINK}>Hồ sơ phân bổ</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="list_divide" icon={<ScheduleOutlined />}>
            <Link to={API_DEVICE_MAINTAIN}>Hồ sơ lịch theo dõi</Link>
          </Menu.Item>
          <Menu.Item key="list_divide_repair" icon={<ScheduleOutlined />}>
            <Link to={API_DEVICE_REPAIR}>Hồ sơ thuê sửa chữa</Link>
          </Menu.Item>
          {/* <Menu.Item key="create_lst_device" icon={<FileAddOutlined />}>
                        <Link to={API_DEVICE_ADD_EXCEL}>
                            Thêm Excel
                        </Link>
                    </Menu.Item> */}
          {/* <Menu.Item key="print_qrCode" icon={<QrcodeOutlined />}>
                        <Link to={API_DEVICE_PRINT_QR}>
                            In mã QR
                        </Link>
                    </Menu.Item> */}
          <SubMenu title="Báo cáo QR" icon={<QrcodeOutlined />} key="report-qr">
            <Menu.Item key="qr_code_lst_device_err">
              <Link to={API_DEVICE_LST_ERROR}>Báo lỗi</Link>
            </Menu.Item>
            <Menu.Item key="request__report_repair">
              <Link to={API_DEVICE_REQUEST_REPAIR}>Cần bảo dưỡng</Link>
            </Menu.Item>
            <Menu.Item key="devie_report_accreditation">
              <Link to={API_DEVICE_REQUEST_ACCREDITATION}>Cần kiểm định</Link>
            </Menu.Item>
            <Menu.Item key="devie_report">
              <Link to={API_DEVICE_LST_REPORT}>Kiểm tra</Link>
            </Menu.Item>
            <Menu.Item key="devie_repair">
              <Link to={API_DEVICE_REPAIR_QR}>Cần sửa chữa</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu title="Báo cáo kết quả" icon={<QrcodeOutlined />} key="report-result">
            <Menu.Item key="report_repair">
              <Link to={API_DEVICE_REPORT_REPAIR}>Bảo dưỡng</Link>
            </Menu.Item>
            <Menu.Item key="report_accreditation">
              <Link to={API_DEVICE_REPORT_ACCREDITATION}>Kiểm định</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu title="Kiểm kê" key="liquidation" icon={<BarChartOutlined />}>
            <Menu.Item key="create_liquidation" icon={<AppstoreAddOutlined />}>
              <Link to={API_LIQUI_CREATE2}>Tạo phiếu</Link>
            </Menu.Item>
            <Menu.Item key="lst_liquidation" icon={<OrderedListOutlined />}>
              <Link to={API_LIQUI_LST}>Hồ sơ</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="list-liquidation" icon={<IssuesCloseOutlined />}>
            <Link to={API_DEVICE_LIQUIDATION}>Hồ sơ thiết bị thanh lý</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="report" icon={<AreaChartOutlined />} title="Báo cáo">
          <Menu.Item key="report-device">
            <Link to={`${API_DEVICE_LST}`}>Lý lịch thiết bị</Link>
          </Menu.Item>
          <Menu.Item key="report-total">
            <Link to={`${API_DEVICE_LST}/bao-cao`}>Tổng hợp thiết bị</Link>
          </Menu.Item>
          <Menu.Item key="report-offer">
            <Link to={API_DEVICE_OFFER}>Đề xuất</Link>
          </Menu.Item>
          <Menu.Item key="report-mov">
            <Link to={API_DEVICE_MOV}>Luân chuyển</Link>
          </Menu.Item>
          <Menu.Item key="maintance-repairt">
            <Link to={API_DEVICE_MAINTAIN}>Sổ thiết bị bảo dưỡng</Link>
          </Menu.Item>
          <Menu.Item key="maintance-approve">
            <Link to={API_DEVICE_MAINTAIN}>Lịch theo dõi</Link>
          </Menu.Item>
          <Menu.Item key="report-inventory">
            <Link to={API_LIQUI_LST}>Kiểm kê</Link>
          </Menu.Item>
          <Menu.Item key="report-device-qr">
            <Link to={API_DEVICE_LST_REPORT}>Kiểm tra</Link>
          </Menu.Item>
          <Menu.Item key="report-error">
            <Link to={API_DEVICE_LST_ERROR}>Báo hỏng</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu title="Cài đặt" key="setting" icon={<SettingOutlined />}>
          {allowDisplay && (
            <Menu.Item key="setting_system">
              <Link to={API_SETTING_DEFAULT_VALUE}>Cài đặt</Link>
            </Menu.Item>
          )}

          <Menu.Item key="update_info_group">
            <UpdateGroupInfo />
          </Menu.Item>
          <Menu.Item key="delete_group">
            <DeleteGroup />
          </Menu.Item>
        </SubMenu>
        <SubMenu title="Hỗ trợ" key="tutorial" icon={<InfoCircleOutlined />}>
          {/* <Menu.Item key="file_tutorial">
            <a href={LINK_TUTORIAL} target="_blank">
              Hướng dẫn sử dụng
            </a>
          </Menu.Item> */}
          <Menu.Item key="link_document">
            <a href={LINK_DOCUMENT} target="_blank">
              Tài liệu
            </a>
          </Menu.Item>
        </SubMenu>
      </Menu>
      {window.screen.width < 767 && (
        <MenuFoldOutlined
          key="close"
          style={{ fontSize: 22, paddingTop: 35, paddingLeft: 5 }}
          onClick={changeCollapsed}
          className="humburger_sidebar"
        />
      )}
    </div>
  );
};
export default Sidebar2;
