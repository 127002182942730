import { PlusOutlined } from '@ant-design/icons';
import { Card, Input, Modal, notification } from 'antd';
import React, { Component } from 'react';

import { CreateOrganization } from '../../../api/TSOrganization';

export default class CreateChildGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      name: '',
      description: '',
      address: '',
      phone: '',
      email: '',
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = () => {
    CreateOrganization({
      OrganizationID: localStorage.getItem('groupId'),
      OrganizationInfo: {
        OrganizationName: this.state.name,
        Description: this.state.description,
        Address: this.state.address,
        Phone: this.state.phone,
        Email: this.state.email,
      },
    }).then((res) => {
      this.setState({
        visible: false,
      });
      notification.success({
        message: 'Tạo phòng ban thành công',
        duration: 1,
      });
      window.location.reload();
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  changeName = (e) => {
    this.setState({
      name: e.target.value,
    });
  };
  changeDes = (e) => {
    this.setState({
      description: e.target.value,
    });
  };
  changeAdd = (e) => {
    this.setState({
      address: e.target.value,
    });
  };
  changePhone = (e) => {
    this.setState({
      phone: e.target.value,
    });
  };
  changeEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };
  render() {
    return (
      <div>
        <Card
          hoverable
          className="dashboard-add-card"
          cover={<PlusOutlined style={{ fontSize: 50 }} />}
          onClick={this.showModal}
        >
          <p>
            <b>Tạo phòng ban phụ thuộc</b>
          </p>
        </Card>
        <Modal
          title="Tạo phòng ban phụ thuộc"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <div className="create-group-input">
            <h4>Tên: </h4>
            <Input size="large" value={this.state.name} onChange={this.changeName} />
          </div>
          <div className="create-group-input">
            <h4>Mô tả: </h4>
            <Input size="large" rows={10} value={this.state.description} onChange={this.changeDes} />
          </div>
          <div className="create-group-input">
            <h4>Địa chỉ:</h4>
            <Input size="large" value={this.state.address} onChange={this.changeAdd} />
          </div>
          <div className="create-group-input">
            <h4>SĐT: </h4>
            <Input size="large" value={this.state.phone} onChange={this.changePhone} />
          </div>
          <div className="create-group-input">
            <h4>Email: </h4>
            <Input size="large" value={this.state.email} onChange={this.changeEmail} />
          </div>
        </Modal>
      </div>
    );
  }
}
