import { Button, Tooltip } from 'antd';
import React, { useMemo, useRef } from 'react';
import { PrinterOutlined } from '@ant-design/icons';
import ReactToPrint from 'react-to-print';
import FormDeviceInfo from './FormDeviceInfo';
class ComponentToPrint extends React.Component {
  render() {
    return (
      <div className="report">
        <FormDeviceInfo infoDevice={this.props.infoDevice}></FormDeviceInfo>
      </div>
    );
  }
}

const PrintDeviceInfo = ({ infoDevice }) => {
  const valueQrCode = useRef();
  return useMemo(
    () => (
      <div>
        <div style={{ display: 'none' }}>
          <ComponentToPrint ref={valueQrCode} infoDevice={infoDevice} />
        </div>
        <ReactToPrint
          documentTitle="Thông tin thiết bị"
          trigger={() => (
            <div type="primary">
              <Tooltip title="In phiếu thông tin thiết bị" placement="bottom">
                <Button>
                  <PrinterOutlined style={{ fontSize: 20, cursor: 'pointer', alignSelf: 'center' }} /> In phiếu
                </Button>
              </Tooltip>
            </div>
          )}
          content={() => valueQrCode.current}
        />
      </div>
    ),
    [infoDevice]
  );
};
export default PrintDeviceInfo;
