import { Button, DatePicker, Input, message, Table, Tag } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GetDefaultValue } from '../../../../api/defaultValue';
import { GetDeviceByOrganization } from '../../../../api/Device';
import { DBCreateInventoryDoc } from '../../../../api/TSInventoryDoc';
import { API_LIQUI_LST } from '../../../../constants/Inventory';
import { dateDisplay, formatMoneyDisplay, status } from '../../../helper/mapping';

const InvenDeviceLst = () => {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [types, setTypes] = useState([]);
  const [selectCreate, setSelectCreate] = useState([]);
  const [timeStart, setTimeStart] = useState('');
  const [note, setNote] = useState('');
  const [txtSearch, setTxtSearch] = useState('');
  const history = useHistory();
  const groupID = parseInt(localStorage.getItem('groupId'));
  useEffect(() => {
    const req = {
      OrganizationID: groupID,
      RowNumber: '',
      PageNumber: 0,
      Search: '',
      DeviceGroup: '',
      TypeDevice: 'Medical',
    };
    GetDeviceByOrganization(req).then((res) => {
      setLoading(false);
      setDevices(
        res.DeviceLst.map((device, index) => {
          return {
            ...device,
            key: index + 1,
            Serial: device.Serial ?? '',
            Model: device.Model ?? '',
          };
        })
      );
    });
    GetDefaultValue({
      TableName: 'Medical',
      Reference: '',
    }).then((res) => {
      const result = res.DefaultValueLst.map((def) => {
        return {
          text: def.DefaultValue,
          value: def.DefaultValue,
        };
      });
      setTypes(result);
    });
  }, []);
  const resultSearch = devices.filter((device) => {
    return (
      device.DeviceName.toLowerCase().indexOf(txtSearch.toLowerCase()) !== -1 ||
      device.DeviceCode.toLowerCase().indexOf(txtSearch.toLowerCase()) !== -1 ||
      device.Serial.toLowerCase().indexOf(txtSearch.toLowerCase()) !== -1 ||
      device.Model.toLowerCase().indexOf(txtSearch.toLowerCase()) !== -1
    );
  });
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectCreate(selectedRows);
    },
  };
  const handleModal = () => {
    setVisible(true);
  };
  const createInven = () => {
    if (note && timeStart) {
      DBCreateInventoryDoc({
        InventoryHeaderInfo: {
          OrganizationID: parseInt(localStorage.getItem('groupId')),
          Comment: note,
          TimeStart: timeStart.format('YYYY-MM-DD HH:mm:ss'),
          InventoryLineLst: selectCreate,
        },
      }).then((res) => {
        if (res.RespCode === 0) {
          setVisible(false);
          message.success('Tạo phiếu thành công');
          history.push(`${API_LIQUI_LST}`);
        }
      });
    } else {
      message.warning('Hãy nhập thời gian dự kiến và lý do');
    }
  };
  const columns = [
    // {
    //   title: 'STT',
    //   dataIndex: 'key',
    //   key: 'key'
    // },
    {
      title: 'Tên TB',
      dataIndex: 'DeviceName',
      key: 'DeviceName',
    },
    {
      title: 'Mã TB',
      dataIndex: 'DeviceCode',
      key: 'DeviceCode',
    },
    {
      title: 'Người tạo',
      dataIndex: 'CreaterId',
      key: 'CreaterId',
    },
    {
      title: 'Loại TB',
      dataIndex: 'Type',
      key: 'Type',
      filters: types,
      onFilter: (value, record) => record.Type.includes(value),
    },
    {
      title: 'Giá trị ban đầu',
      dataIndex: 'DeviceValue',
      key: 'DeviceValue',
      render: (record) => {
        return formatMoneyDisplay(record);
      },
    },
    {
      title: 'Giá trị hiện tại',
      dataIndex: 'DeviceValueCurrent',
      key: 'DeviceValueCurrent',
      render: (record) => {
        return formatMoneyDisplay(record);
      },
    },
    {
      title: 'Serial',
      dataIndex: 'Serial',
      key: 'Serial',
    },
    {
      title: 'Model',
      dataIndex: 'Model',
      key: 'Model',
    },
    {
      title: 'TG bắt đầu',
      dataIndex: 'TimeStart',
      key: 'TimeStart',
      render: (record) => {
        return dateDisplay(record);
      },
    },
    {
      title: 'TG bảo hành',
      dataIndex: '',
      key: '',
      render: (record) => {
        return dateDisplay(record.WarrantyStart) + '-' + dateDisplay(record.WarrantyEnd);
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'Status',
      key: 'Status',
      render: (record) => {
        return <Tag color={status(record).color}>{status(record).label}</Tag>;
      },
    },
  ];
  return (
    <div>
      <Modal
        visible={visible}
        title="Tạo phiếu kiểm kê"
        onOk={createInven}
        onCancel={() => setVisible(false)}
        okText="Xác nhận"
        cancelText="Đóng"
      >
        <div style={{ display: 'flex', paddingBottom: 10 }}>
          <strong style={{ marginRight: 10 }}>Thời gian dự kiến: </strong>
          <DatePicker placeholder="Thời gian dự kiến" onChange={(e) => setTimeStart(e)} style={{ width: 300 }} />
        </div>
        <Input.TextArea placeholder="Nhập lý do(*)" onChange={(e) => setNote(e.target.value)} />
      </Modal>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: 10 }}>
        <div>
          <b style={{ paddingRight: 6 }}>Đã chọn: {selectCreate.length} thiết bị</b>
          <Button type="primary" onClick={handleModal} disabled={selectCreate.length === 0}>
            Tạo phiếu kiểm kê
          </Button>
        </div>
        <Input
          onChange={(e) => setTxtSearch(e.target.value)}
          allowClear
          autoFocus
          placeholder="Tìm kiếm"
          style={{ width: 300 }}
        />
      </div>
      <Table
        dataSource={resultSearch}
        columns={columns}
        loading={loading}
        pagination={false}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      />
    </div>
  );
};

export default InvenDeviceLst;
