import { message, Checkbox, Table, Row, Col } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { useEffect, useState } from 'react';
import { GetDeviceByOrganization } from '../../../../api/Device';
import { PrintQrCode } from '../../../../config';
import { AddKeyToLst } from '../../../helper/addKeyToLst';
import './LstPrintQrCode.scss';
import { useRecoilValue } from 'recoil';
import { getInfoGroup } from '../../../../config/Recoil';
import { formatMoneyDisplay } from '../../../helper/mapping';
const columns = [
  {
    title: 'Tên thiết bị',
    dataIndex: 'DeviceName',
    key: 'DeviceName',
  },
  {
    title: 'Mã thiết bị',
    dataIndex: 'DeviceCode',
    key: 'DeviceCode',
  },
  {
    title: 'Phòng ban',
    dataIndex: 'Location',
    key: 'Location',
  },
  {
    title: 'Giá trị (VNĐ)',
    dataIndex: 'DeviceValue',
    key: 'DeviceValue',
    render: (value) => {
      return formatMoneyDisplay(value);
    },
  },
  {
    title: 'Chu kỳ bảo dưỡng',
    dataIndex: 'Schedule',
    key: 'Schedule',
    render: (record) => {
      return `${record} Th/Lần`;
    },
  },
  {
    title: 'Ghi chú',
    dataIndex: 'Note',
    key: 'Note',
  },
];
const ListPrintQrCode = () => {
  const infoGroup = useRecoilValue(getInfoGroup);
  const groupId = localStorage.getItem('groupId');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deviceChoose, setDeviceChoose] = useState([]);
  const [valueSetDevice, setValueSetDevice] = useState([]);
  const [req, setReq] = useState({
    search: '',
    currentPage: 0,
    numberRow: '',
  });
  const [numberPage, setNumberPage] = useState(1);
  useEffect(() => {
    const fetData = async () => {
      setLoading(true);
      await GetDeviceByOrganization({
        OrganizationID: groupId,
        Search: req.search,
        TypeDevice: 'Medical',
        DeviceGroup: '',
        PageNumber: req.currentPage,
        RowNumber: req.numberRow,
      })
        .then((res) => {
          setLoading(false);
          if (res.RespCode === 0) {
            const transfData = AddKeyToLst(res.DeviceLst);
            setData(
              transfData.filter((value) => {
                return value.QRCode;
              })
            );
            setNumberPage(res.NumberPage);
          } else message.error(res.RespText);
        })
        .catch((err) => {
          message.info(err);
        });
    };
    fetData();
  }, [req]);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const totalValueSetDevice = selectedRows.reduce((up, down) => {
        return up + down.DeviceId;
      }, 0);
      setValueSetDevice(totalValueSetDevice);
      setDeviceChoose(selectedRows);
    },
  };
  const handleChangeSearch = (e) => {
    setReq({
      search: e,
    });
  };
  const handleChangeTable = (pagination, filters, sorts) => {
    // setReq({search: '', currentPage: pagination.current, numberRow: pagination.pageSize})
  };
  return (
    <div style={{ width: '100%' }}>
      <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Col>
          <Row>
            <PrintQrCode data={deviceChoose} />
          </Row>
        </Col>
        <Search
          placeholder="Nhập thông tin tìm kiếm"
          onSearch={handleChangeSearch}
          allowClear
          enterButton
          autoFocus
          style={{
            height: '100%',
            width: 250,
            marginRight: 20,
            marginBottom: 20,
          }}
        />
      </Row>
      <Table
        loading={loading}
        rowSelection={{
          type: Checkbox,
          ...rowSelection,
        }}
        onChange={handleChangeTable}
        dataSource={data}
        columns={columns}
        pagination={{
          defaultPageSize: 150,
          hideOnSinglePage: true,
        }}
        locale={{
          emptyText: 'Không có dữ liệu',
        }}
      />
    </div>
  );
};

export default ListPrintQrCode;
