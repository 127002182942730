import Request from "../config/Request/request2";
export function LiquiGetLst(data) {
  return Request({
    url: "/Liquidation/GetLst",
    method: "POST",
    data: data,
  });
}
export function LiquiCreate(data) {
  return Request({
    url: "/Liquidation/Create",
    method: "POST",
    data: data,
  });
}
export function LiquiUpdate(data) {
  return Request({
    url: "/Liquidation/update",
    method: "POST",
    data: data,
  });
}
export function LiquiDelete(data) {
  return Request({
    url: "/Liquidation/Delete",
    method: "POST",
    data: data,
  });
}
export function LiquiGetByDocID(data) {
  return Request({
    url: "/Liquidation/GetByDocID",
    method: "POST",
    data: data,
  });
}
export function LiquiApprove(data) {
  return Request({
    url: "/Liquidation/Approve",
    method: "POST",
    data: data,
  });
}