import { Button, message, Modal } from "antd";
import QrReader from "react-qr-reader";
import React, { useRef } from "react";
import "./ScanQrImg.scss";
import { GetDeviceInfoByQRCode } from "../../api/QrCode";
const ScanQrImg = () => {
  const ref = useRef();
  const handleScan = (data) => {
    if (data) {
      GetDeviceInfoByQRCode({
        QRCode: data,
      })
        .then((res) => {
          if (res) {
            if (res.RespCode === 0) {
              const valueDevice = res.DeviceInfo;
              Modal.info({
                title: "Thông tin thiết bị",
                content: (
                  <div>
                    <p>Người tạo: {valueDevice.CreaterId}</p>
                    <p>Tên thiết bị: {valueDevice.DeviceName}</p>
                    <p>Mã thiết bị: {valueDevice.DeviceCode}</p>
                    <p>Mã tham chiếu: {valueDevice.ReferenceCode}</p>
                    <p>
                      Trạng thái:{" "}
                      {valueDevice.Status === 1
                        ? "Đang hoạt động"
                        : valueDevice.Status === 2
                        ? "Đã báo cáo"
                        : valueDevice.Status === 3
                        ? "Đang bảo dưỡng"
                        : ""}
                    </p>
                    <p>Loại: {valueDevice.Type}</p>
                    <p>Xuất xứ: {valueDevice.Origin}</p>
                    <p>Nơi sản xuất: {valueDevice.Manufacturer}</p>
                    <p>Phòng ban sử dụng: {valueDevice.Location}</p>
                    <p>Thời gian bắt đầu: {valueDevice.TimeCreate}</p>
                    <p>Hạn sử dụng: {valueDevice.TimeEnd}</p>
                    <p>Lịch bảo dưỡng: {valueDevice.Schedule} tháng/lần</p>
                  </div>
                ),
              });
            } else {
              message.error(res.RespText);
            }
          } else {
            message.error("Mã QR không hợp lệ");
          }
        })
        .catch((err) => {
          message.info(err);
        });
    } else {
      message.error("Hãy quét lại mã QR");
    }
  };
  const upLoadImg = () => {
    ref.current.openImageDialog();
  };
  return (
    <div className="img_qrcode">
      <Button className="btn_img_qrcode" onClick={upLoadImg}>
        Ảnh mã QR
      </Button>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: 150,
        }}
        className="scan"
      >
        <QrReader
          ref={ref}
          delay={300}
          onScan={handleScan}
          style={{ width: "100%", height: "50%", display: "flex" }}
          className="qr_reader"
          legacyMode
        />
      </div>
    </div>
  );
};

export default ScanQrImg;
