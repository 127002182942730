import { PrinterOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import FormDeviceMaintance from './FormDeviceMaintance';
class ComponentToPrint extends React.Component {
  render() {
    return (
      <div className="report">
        <FormDeviceMaintance deviceLst={this.props.deviceLst} devices={this.props.devices}></FormDeviceMaintance>
      </div>
    );
  }
}

const PrintDeviceMaintance = ({ deviceLst }) => {
  const [devices, setDevices] = useState([]);
  useEffect(() => {
    let flatDevices = [];
    flatDevices = deviceLst.flatMap((device) => {
      return device.DeviceLst;
    });
    setDevices(flatDevices);
  }, [deviceLst]);

  const valueQrCode = useRef();
  return useMemo(
    () => (
      <div>
        <div style={{ display: 'none' }}>
          <ComponentToPrint ref={valueQrCode} deviceLst={deviceLst} devices={devices.flat()} />
        </div>
        <ReactToPrint
          documentTitle="Thông tin thiết bị"
          trigger={() => (
            <div type="primary">
              <Button>
                <PrinterOutlined style={{ fontSize: 20, cursor: 'pointer', alignSelf: 'center' }} /> Báo cáo
              </Button>
            </div>
          )}
          content={() => valueQrCode.current}
        />
      </div>
    ),
    [deviceLst]
  );
};
export default PrintDeviceMaintance;
