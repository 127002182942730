import { UserSwitchOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { ADMIN_PATH_MEMBERS } from '../../../../constants/Admin';
import './AdminSidebar.scss';

const AdminSidebar = () => {
  return (
    <div className="admin-sidebar">
      <Menu
        style={{
          width: '100%',
          height: '100%',
        }}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        theme="dark"
        mode="inline"
      >
        <Menu.Item key={'1'} icon={<UserSwitchOutlined />}>
          <Link to={`${ADMIN_PATH_MEMBERS}`}>Quản lý thành viên</Link>
        </Menu.Item>
        {/* <Menu.Item key={'2'} icon={<FolderAddOutlined />}>
          <Link to={`${ADMIN_PATH_ADD_USER}`}>Thêm thành viên</Link>
        </Menu.Item> */}
      </Menu>
    </div>
  );
};

export default AdminSidebar;
