import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Input, message, Modal, Space, Table, Tag, Tooltip } from 'antd';
import Column from 'antd/lib/table/Column';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import { DelMaintenanceSchedule, GetMaintenanceScheduleByOrg } from '../../../../api/TSMaintenance';
import { manageDeviceError } from '../../../../config/Recoil';
import { API_DEVICE_CREATE_SCHEDULE, API_DEVICE_MAINTAIN } from '../../../../constants/Device';
import { deCode } from '../../../../helpers/mappingCode';
import HanleCenser from '../../../Censer';
import mapLstTable from '../../../helper/mapLstTable';
import { statusApprove, typeSchedule } from '../../../helper/mapping';
import PrintDeviceMaintance from '../../../Print/DeviceMaintance';
import ReportApproveMaintance from '../../../Print/MaintainApprove';
import ApproveDivide from '../Approve';
import InfoDivide from '../InfoDivide';
import { GetCenser } from '../../../../api/Censer';
import { companyName } from '../../../../config/URL/Qlts';
const { confirm } = Modal;
const ListDiviDe = () => {
  const { documentIdSearch } = useParams();
  const getHeaderDivide = useRecoilValue(manageDeviceError);
  const [listDiviDe, setListDivide] = useState([]);
  const [listDivideDisplay, setListDivideDisplay] = useState([]);
  const [loading, setLoading] = useState(true);
  const [censor, setCensor] = useState([]);
  const [valueSearch, setValueSearch] = useState('');
  const history = useHistory();
  useEffect(() => {
    const fetchData = async () => {
      await GetMaintenanceScheduleByOrg().then((res) => {
        const mappingMaintenceLst = res.MaintenanceScheduleLst.map((schedule) => {
          if (schedule.Note !== '') {
            return schedule;
          } else {
            return {
              ...schedule,
              Note: '0',
            };
          }
        })
          .map((de, index) => {
            return {
              ...de,
              key: index + 1,
              DeviceLstDisplay: de.DeviceLst.map((a) => {
                return `${a.DeviceName}( ${a.DeviceCode} ),`;
              }),
            };
          })
          .filter((a) => a.Type !== '10')
          .sort((a, b) => a.Status - b.Status);
        setListDivide(mappingMaintenceLst);
        setListDivideDisplay(mappingMaintenceLst);
        setLoading(false);
      });
      const response = await GetCenser({
        Type: 'DeviceMaintance',
        CompanyName: companyName,
      });
      setCensor(response.Censers);
      if (documentIdSearch) {
        setValueSearch(deCode(documentIdSearch));
      }
    };
    fetchData();
  }, [getHeaderDivide]);
  useEffect(() => {
    filterDoc(valueSearch);
  }, [valueSearch]);
  const filterDoc = (value = '') => {
    const filterDivide = [...listDiviDe].filter((di) => {
      return (
        di.DocumentCode.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
        di.CreaterID.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    });
    setListDivideDisplay(filterDivide);
  };
  const handleInputSearch = (e) => {
    setValueSearch(e);
    if (documentIdSearch) {
      history.push(API_DEVICE_MAINTAIN);
    }
  };
  const deleteDivide = async (DocumentID, rowIndex) => {
    const response = await DelMaintenanceSchedule({
      DocumentID: Number(DocumentID),
    });
    if (response.RespCode === 0) {
      const cloneData = [...listDiviDe];
      const newData = cloneData.filter((item, index) => {
        return index !== rowIndex;
      });
      setListDivide(mapLstTable(newData));
      message.success('Đã xóa thiết bị thành công');
    }
  };
  const ShowConfirmDelete = (record, rowIndex) => {
    confirm({
      title: `Bạn có muốn xóa lịch bảo dưỡng của thiết bị do ${record.CreaterID} tạo lúc ${record.TimeCreate}??`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        deleteDivide(record.DocumentID, rowIndex);
      },
    });
  };
  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Space>
          <Button type="primary" onClick={() => history.push(API_DEVICE_CREATE_SCHEDULE)}>
            Tạo lịch theo dõi
          </Button>
          <Input
            value={valueSearch}
            allowClear
            placeholder="Tìm kiếm"
            onChange={(e) => handleInputSearch(e.target.value)}
          />
        </Space>
        <Space>
          <PrintDeviceMaintance deviceLst={listDiviDe} censor={censor}></PrintDeviceMaintance>
          <HanleCenser censerType="DeviceMaintance" getCensers={(data) => setCensor(data)} />
        </Space>
      </div>
      <Table
        dataSource={listDivideDisplay}
        className="list_devide"
        loading={loading}
        locale={{
          emptyText: 'Không có dữ liệu',
        }}
      >
        <Column dataIndex="key" key="key" title="STT" />
        <Column dataIndex="CreaterName" key="CreaterName" title="Người tạo" />
        <Column dataIndex="DocumentCode" key="DocumentCode" title="Mã phiếu" />
        <Column
          dataIndex="TimeCreate"
          key="TimeCreate"
          title="Thời gian tạo lịch"
          render={(record) => {
            return moment(record).format('hh:mm DD/MM/YYYY');
          }}
        />
        <Column
          dataIndex="TimeStart"
          key="TimeStart"
          title="Thời gian bắt đầu"
          render={(record) => {
            return moment(record).format('hh:mm DD/MM/YYYY');
          }}
        />
        <Column
          dataIndex="Status"
          key={'Status'}
          title="Trạng thái"
          render={(record) => {
            return <Tag color={statusApprove(record).color}>{statusApprove(record).label}</Tag>;
          }}
        />
        <Column
          dataIndex="Note"
          key="Note"
          title="Loại bảo dưỡng"
          render={(record) => {
            return typeSchedule(parseInt(record)).label;
          }}
          sorter={(a, b) => a.Note - b.Note}
          filters={[
            { text: 'Bảo dưỡng', value: 0 },
            { text: 'Thay mới', value: 1 },
            { text: 'Kiểm định', value: 2 },
            { text: 'Hiệu chuẩn', value: 3 },
            { text: 'Kiểm kê', value: 4 },
            { text: 'Thanh lý', value: 5 },
            { text: 'Hạn đăng kiểm', value: 6 },
            { text: 'Hạn bảo hiểm', value: 7 },
            { text: 'Thay dầu', value: 8 },
            { text: 'Thời hạn bảo hiểm tự nguyện', value: 9 },
            { text: 'Thời gian sửa chữa lớn', value: 10 },
          ]}
          onFilter={(value, record) => record.Note.indexOf(value) === 0}
        />
        <Column dataIndex="DeviceLstDisplay" key="DeviceLstDisplay" title="DS TB" />
        <Column dataIndex="Description" key="Description" title="Mô tả" />
        <Column
          dataIndex=""
          key=""
          render={(text, record, rowIndex) => (
            <Space size={16}>
              {record.Status !== 0 && record.Status !== 2 && <ApproveDivide headerInfo={record} />}
              {record.Status === 1 && (
                <div onClick={() => ShowConfirmDelete(record, rowIndex)} style={{ fontSize: 20, cursor: 'pointer' }}>
                  <Tooltip placement="bottom" title="xóa">
                    <DeleteOutlined style={{ color: '#eb4034' }} />
                  </Tooltip>
                </div>
              )}
              <InfoDivide DocumentID={record.DocumentID} />
              <ReportApproveMaintance infoDevice={record} censor={censor} />
            </Space>
          )}
        />
      </Table>
    </div>
  );
};
export default ListDiviDe;
