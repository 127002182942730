import moment from 'moment';
import React from 'react';
import { TitleReport } from '../../../../config/URL/Qlts';
import { formatYear } from '../../../../constants/DateTime';
import { dateDisplay, formatMoneyDisplay } from '../../../helper/mapping';

const InvenForm = ({ contentReport, censerInven }) => {
  const yearReport = contentReport && moment(contentReport.CreaterTime).year();
  const yearCurrent = moment().year();
  return (
    <div style={{ padding: '40px', fontFamily: '"Times New Roman"', lineHeight: '1.5em' }}>
      <div style={{ textAlign: 'end' }}>
        <span>Mã phiếu: {contentReport.DocumentCode}</span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '30px' }}>
        <div style={{ width: 'max-content', textAlign: 'center' }}>
          <div>
            <strong style={{ textTransform: 'uppercase' }}>SỞ Y TẾ TỈNH ĐIỆN BIÊN</strong>
          </div>
          <div>
            <strong style={{ textTransform: 'uppercase' }}>{TitleReport}</strong>
          </div>
          <div>
            <strong style={{ textTransform: 'uppercase' }}>Phòng ban: {localStorage.getItem('groupName')}</strong>
          </div>
        </div>
        <div style={{ textAlign: 'center', width: '50%' }}>
          <div style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Mẫu số C53 - HD</div>
          <div>
            <i style={{ fontSize: '10px', lineHeight: '1em' }}>
              (Ban hành kèm theo Thông tư số 107/2017/TT-BTC ngày 10/10/2017 của Bộ Tài chính về việc Hướng dẫn chế độ
              kế toán hành chính, sự nghiệp)
            </i>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center' }}>
        <h2 style={{ textTransform: 'uppercase', fontWeight: 'bold', paddingTop: 20 }}>
          BIÊN BẢN KIỂM KÊ VẬT TƯ CỐ ĐỊNH , MÁY MÓC Y TẾ NĂM {yearCurrent}
        </h2>
      </div>
      <div>
        <div>Thời điểm bắt đầu kiểm kê: {dateDisplay(contentReport.TimeStart)}</div>
        <div style={{ textTransform: 'uppercase' }}>
          <strong>I. Thành phần kiểm tra gồm</strong>
        </div>
        {censerInven &&
          censerInven.map((cen) => (
            <div>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '30%' }}>- Ông bà: {cen.FullName}</div>
                <div style={{ width: '40%' }}>Chức vụ: {cen.Position}</div>
                <div style={{ width: '30%' }}>{cen.Department}</div>
              </div>
            </div>
          ))}
        <div style={{ textTransform: 'uppercase' }}>
          <strong>
            II. Nội dung kiểm kê Tài sản cố định, máy móc, vật tư lâu bền và kết quả có trong bảng dưới đây:
          </strong>
        </div>
        <div>
          <table style={{ width: '100%', border: '1px solid #ddd', borderCollapse: 'collapse', tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '8px', width: '40px' }}>
                  STT
                </th>
                <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '8px', width: '100px' }}>
                  Thông tin tài sản
                </th>
                <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '8px' }}>
                  Model
                </th>
                <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '8px' }}>
                  Serial
                </th>
                <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '8px' }}>
                  Năm đưa vào sử dụng
                </th>
                <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '8px' }}>
                  ĐVT
                </th>
                <th colSpan={3} style={{ border: '1px solid #ddd', padding: '8px' }}>
                  Theo sổ kế toán
                </th>
                <th colSpan={3} style={{ border: '1px solid #ddd', padding: '8px' }}>
                  Theo kiểm kê năm {yearReport}
                </th>
                <th colSpan={3} style={{ border: '1px solid #ddd', padding: '8px' }}>
                  Chênh lệch
                </th>
                <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '8px' }}>
                  Nguồn hàng/ Hãng sản xuất
                </th>
                <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '8px', width: '60px' }}>
                  Ghi chú
                </th>
              </tr>
              <tr>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>SL</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Nguyên giá</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Giá trị còn lại</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>SL</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Nguyên giá</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Giá trị còn lại</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>SL</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Nguyên giá</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Giá trị còn lại</th>
              </tr>
              <tr></tr>
            </thead>
            <tbody style={{ fontSize: '9px' }}>
              {contentReport &&
                contentReport.InventoryLineLst.map((device, index) => (
                  <tr>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{index + 1}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd', lineHeight: '1.5em' }}>
                      <div>Tên tài sản: {device.DeviceName}</div>
                      <div>Hãng sản xuất: {device.Manufacturer}</div>
                      <div>Nước sản xuất: {device.Origin}</div>
                      <div>Năm sản xuất: {device.TimeManufacturer}</div>
                      <div>Serial: {device.Serial}</div>
                    </td>
                    <td
                      style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center', wordBreak: 'break-all' }}
                    >
                      {device.Serial}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>{device.Model}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                      {dateDisplay(device.TimeStart, formatYear)}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>{device.Unit}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>1</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                      {formatMoneyDisplay(device.DeviceValue)}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                      {formatMoneyDisplay(device.DeviceValueCurrent)}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>1</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                      {formatMoneyDisplay(device.DeviceValue)}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                      {formatMoneyDisplay(device.DeviceValueCurrent)}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>1</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                      {formatMoneyDisplay(device.DeviceValue)}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                      {formatMoneyDisplay(device.DeviceValueCurrent)}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                      {device.Origin} /{device.Manufactory}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{device.Note}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <div style={{ paddingBottom: '20px', textAlign: 'end', paddingTop: 10 }}>
          Điện Biên, ngày {moment().date()} tháng {moment().month() + 1} năm {yearCurrent}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 0 }}>Phòng VTTBYT</h3>
            <i>(Ký, họ tên)</i>
          </div>
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 0 }}>Đại diện phòng sử dụng</h3>
            <i>(Ký, họ tên)</i>
          </div>
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 0 }}>Đại diện kế toán</h3>
            <i>(Ký, họ tên)</i>
          </div>
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 0 }}>Trưởng ban kiểm tra</h3>
            <i>(Ký, họ tên)</i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvenForm;
