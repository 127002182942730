import { Button, DatePicker, message, Select, Space, Table, Tag } from 'antd';
import Search from 'antd/lib/input/Search';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { GetOfferByOrgReceive, GetOfferDeviceByUser, GetOfferDeviceHeaderByTime } from '../../../../api/DeviceOffer';
import { manageOfferDevice } from '../../../../config/Recoil';
import { getGroupId, getUserName } from '../../../../helpers/auth';
import { AddKeyToLst } from '../../../helper/addKeyToLst';
import { current, yesterday } from '../../../helper/getTime';
import { roleUser, statusApprove, timeDisplay } from '../../../helper/mapping';
import PrintDeviceOffer from '../../../Print/DeviceOffer';
import { ApproveOffer, CancelOffer, Create, Detail } from './components';

const formatTime = 'HH:mm DD-MM-YYYY';

const Offer = () => {
  const [search, setSearch] = useState('');
  const [offers, setOffers] = useState([]);
  const getOffer = useRecoilValue(manageOfferDevice);
  const [loading, setLoading] = useState(false);
  const [timeStart, setTimeStart] = useState(yesterday());
  const [timeEnd, setTimeEnd] = useState(current());
  const groupID = parseInt(getGroupId());
  const createrName = getUserName();
  const getLst = () => {
    const req = {
      TimeStart: timeStart,
      TimeEnd: timeEnd,
    };
    if (roleUser(localStorage.getItem('roleUser'))) {
      setLoading(true);
      GetOfferDeviceHeaderByTime(req).then((res) => {
        setLoading(false);
        if (res.RespCode === 0) {
          let response = AddKeyToLst(res.DeviceOfferHeaderLst);
          let a = response.map((b) => {
            return {
              ...b,
              lstDevice: b.DeviceOfferLineLst.map((c) => {
                return c.DeviceName + '( ' + 'Giá: ' + c.Amount + ' - ' + 'SL: ' + c.Quantity + ' )';
              }),
              totalAmount: b.DeviceOfferLineLst.reduce((p, q) => {
                return p + q.Amount * q.Quantity;
              }, 0),
            };
          });
          setOffers(a);
        } else {
          message.error(res.RespText);
        }
      });
    } else {
      setLoading(true);
      GetOfferDeviceByUser(req).then((res) => {
        setLoading(false);
        if (res.RespCode === 0) {
          let response = AddKeyToLst(res.DeviceOfferHeaderLst);
          let a = response.map((b) => {
            return {
              ...b,
              lstDevice: b.DeviceOfferLineLst.map((c) => {
                return c.DeviceName + '( ' + 'Giá: ' + c.Amount + ' - ' + 'SL: ' + c.Quantity + ' )';
              }),
              totalAmount: b.DeviceOfferLineLst.reduce((p, q) => {
                return p + q.Amount * q.Quantity;
              }, 0),
            };
          });
          setOffers(a);
        } else {
          message.error(res.RespText);
        }
      });
    }
  };
  const getLstReceive = () => {
    setLoading(true);
    GetOfferByOrgReceive().then((res) => {
      setLoading(false);
      if (res.RespCode === 0) {
        let response = AddKeyToLst(res.DeviceOfferHeaderLst);
        let a = response.map((b) => {
          return {
            ...b,
            lstDevice: b.DeviceOfferLineLst.map((c) => {
              return c.DeviceName + '( ' + 'Giá: ' + c.Amount + ' - ' + 'SL: ' + c.Quantity + ' )';
            }),
            totalAmount: b.DeviceOfferLineLst.reduce((p, q) => {
              return p + q.Amount * q.Quantity;
            }, 0),
          };
        });
        setOffers(a);
      } else {
        message.error(res.RespText);
      }
    });
  };
  useEffect(() => {
    getLst();
  }, [getOffer]);
  const getByTime = () => {
    getLst();
  };
  const handleSelect = (value) => {
    value === 1 ? getLst() : getLstReceive();
  };
  const columns = [
    {
      title: 'Mã phiếu',
      dataIndex: 'DocumentCode',
      key: 'DocumentCode',
    },
    {
      title: 'Người tạo',
      dataIndex: 'CreaterName',
      key: 'CreaterName',
    },
    {
      title: 'Phòng ban nhận',
      dataIndex: 'OrganizationReceiveName',
      key: 'OrganizationReceiveName',
    },
    // {
    //   title: "Mục đích",
    //   dataIndex: "Target",
    //   key: "Target",
    // },
    {
      title: 'Lí do',
      dataIndex: 'Reason',
      key: 'Reason',
    },
    // {
    //   title: "Danh sách TB",
    //   dataIndex: "lstDevice",
    //   key: "lstDevice",
    // },
    {
      title: 'Tổng chi phí',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (record) => {
        return new Intl.NumberFormat().format(record) + ' vnđ';
      },
    },
    {
      title: 'Mô tả',
      dataIndex: 'Description',
      key: 'Description',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'Status',
      key: 'Status',
      render: (record) => {
        return <Tag color={statusApprove(record).color}>{statusApprove(record).label}</Tag>;
      },
    },
    {
      title: 'Người xác nhận',
      dataIndex: 'ApproveName',
      key: 'ApproveName',
    },
    {
      title: 'TG xác nhận',
      dataIndex: 'TimeApprove',
      key: 'TimeApprove',
      render: (record) => {
        return timeDisplay(record);
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (record) => {
        return (
          <Space>
            {record.Status === 1 && record.OrganizationReceive === groupID && <ApproveOffer headerInfo={record} />}
            {record.Status === 1 && record.CreaterID === createrName && <CancelOffer headerInfo={record} />}
            <Detail headerInfo={record} />
            {/* <PrintFormOffer infoDocument={record}/> */}
          </Space>
        );
      },
    },
  ];
  const searchResult = offers.filter((o) => {
    return (
      o.DocumentCode.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      o.CreaterName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      o.OrganizationReceiveName.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  });
  const btnSearch = () => {
    getLst();
  };
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 10 }}>
        <Space>
          <DatePicker
            showTime
            format={formatTime}
            value={moment(moment(timeStart).format(formatTime), formatTime)}
            onChange={(e) => {
              setTimeStart(e);
            }}
            placeholder="Thời gian bắt đầu"
          ></DatePicker>
          <span>Đến</span>
          <DatePicker
            showTime
            format={formatTime}
            value={moment(moment(timeEnd).format(formatTime), formatTime)}
            onChange={(e) => {
              setTimeEnd(e);
            }}
            placeholder="Thời gian kết thúc"
          ></DatePicker>
          <Button onClick={btnSearch}>Tìm kiếm</Button>
        </Space>
        <Space>
          <Search onChange={(e) => setSearch(e.target.value)} placeholder="Tìm kiếm" allowClear autoFocus />
          <PrintDeviceOffer deviceLst={searchResult} />
        </Space>
      </div>
      <Space>
        <Select onChange={handleSelect} defaultValue="Đã tạo" style={{ width: 150 }}>
          <Select.Option value={1}>Đã tạo</Select.Option>
          <Select.Option value={2}>Đã nhận</Select.Option>
        </Select>
        <Create />
      </Space>
      <Table
        dataSource={searchResult}
        columns={columns}
        loading={loading}
        expandable={{
          expandedRowRender: (record) => <Detail headerInfo={record} />,
        }}
        locale={{
          emptyText: 'Không có dữ liệu',
        }}
      ></Table>
    </div>
  );
};

export default Offer;
