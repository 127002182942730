import { Button, Select, Space, Table, Tag } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  DBInvenDeviceLst,
  GetDeviceInventoryCompany,
  GetInventoryDocByCompany,
  GetInventoryDocByOrg,
  GetInventoryDocByUser,
} from '../../../api/TSInventoryDoc';
import { GetDependentOrganization } from '../../../api/TSOrganization';
import { manageDeviceError } from '../../../config/Recoil';
import { companyID } from '../../../config/URL/Qlts';
import { getGroupId } from '../../../helpers/auth';
import HanleCenser from '../../Censer';
import { AddKeyToLst } from '../../helper/addKeyToLst';
import { dateDisplay, roleUser, statusApprove, timeDisplay } from '../../helper/mapping';
import PrintDeviceInventory from '../../Print/DeviceInventory';
import { ApproveInventory, CancelInventory } from '../Components';
import InfoInventory from '../Components/Info';
import PrintReportInvenDevice from '../Components/PrintReport/printReport';
const columns = [
  {
    className: 'key',
    title: 'STT',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Mã phiếu',
    dataIndex: 'DocumentCode',
    key: 'DocumentCode',
  },
  {
    className: 'CreaterName',
    title: 'Tên người tạo',
    dataIndex: 'CreaterName',
    key: 'CreaterName',
  },
  {
    className: 'OrganizationName',
    title: 'Tên phòng ban',
    dataIndex: 'OrganizationName',
    key: 'OrganizationName',
  },
  {
    title: 'Thời gian tạo',
    dataIndex: 'CreaterTime',
    key: 'CreaterTime',
    render: (record) => {
      return timeDisplay(record);
    },
  },
  {
    className: 'Comment',
    title: 'Ghi chú tạo',
    dataIndex: 'Comment',
    key: 'Comment',
  },
  {
    title: 'TG xác nhận',
    dataIndex: 'TimeApprove',
    key: 'TimeApprove',
    render: (record) => {
      return timeDisplay(record);
    },
  },
  {
    title: 'Người xác nhận',
    dataIndex: 'ApproveID',
    key: 'ApproveID',
  },
  {
    title: 'Trạng thái',
    dataIndex: 'Status',
    key: 'Status',
    render: (record) => {
      return <Tag color={statusApprove(record).color}>{statusApprove(record).label}</Tag>;
    },
  },
  {
    title: '',
    dataIndex: '',
    key: 'action',
    render: (record) => (
      <Space>
        {record.Status === 1 && <ApproveInventory headerInfo={record} />}
        {record.Status === 1 && <CancelInventory headerInfo={record} />}
        <InfoInventory info={record} />
      </Space>
    ),
  },
];
const columnDevices = [
  {
    title: 'STT',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Tên TB',
    dataIndex: 'DeviceName',
    key: 'DeviceName',
  },
  {
    title: 'Mã TB',
    dataIndex: 'DeviceCode',
    key: 'DeviceCode',
  },
  {
    title: 'Giá trị',
    dataIndex: 'DeviceValue',
    key: 'DeviceValue',
  },
  {
    title: 'Serial',
    dataIndex: 'Serial',
    key: 'Serial',
  },
  {
    title: 'Model',
    dataIndex: 'Model',
    key: 'Model',
  },
  {
    title: 'TG kiểm',
    dataIndex: 'TimeCreate',
    key: 'TimeCreate',
    render: (record) => {
      return dateDisplay(record);
    },
  },
  {
    title: 'TG bắt đầu',
    dataIndex: 'TimeStart',
    key: 'TimeStart',
    render: (record) => {
      return dateDisplay(record);
    },
  },
  {
    title: 'TG bảo dưỡng',
    dataIndex: '',
    key: '',
    render: (record) => {
      return dateDisplay(record.WarrantyStart) + '-' + dateDisplay(record.WarrantyEnd);
    },
  },
];
const ListDeviceInventory = () => {
  const groupID = getGroupId();
  const [lstInventory, setLstInventory] = useState([]);
  const [censers, setCensers] = useState([]);
  const getInventory = useRecoilValue(manageDeviceError);
  const [devices, setDevices] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orgs, setOrgs] = useState([]);
  const [selectOrg, setSelectOrg] = useState(groupID);
  const allow = () => {
    if (groupID === companyID) {
      return true;
    } else {
      return false;
    }
  };
  const getLst = (org) => {
    GetInventoryDocByOrg({ OrganizationID: org ? org : groupID }).then((res) => {
      setLoading(false);
      setLstInventory(
        res.InventoryHeaderLst.map((header, index) => {
          return {
            ...header,
            key: index + 1,
          };
        })
      );
    });
  };
  const getByUser = () => {
    GetInventoryDocByUser().then((res) => {
      setLoading(false);
      setLstInventory(
        res.InventoryHeaderLst.map((header, index) => {
          return {
            ...header,
            key: index + 1,
          };
        })
      );
    });
  };
  const getCompany = () => {
    setLoading(true);
    GetInventoryDocByCompany({
      CompanyID: companyID,
    }).then((res) => {
      setLoading(false);
      setLstInventory(AddKeyToLst(res.InventoryHeaderLst));
    });
  };
  useEffect(() => {
    roleUser(localStorage.getItem('roleUser')) ? getLst() : getByUser();
  }, [getInventory]);
  useEffect(() => {
    allow() &&
      GetDependentOrganization({
        OrganizationID: companyID,
      }).then((res) => {
        const result = [{ OrganizationID: -1, OrganizationName: 'Tất cả' }, ...res.OrganizationLst];
        setOrgs(result);
      });
  }, []);
  const handleModal = () => {
    setVisible(true);
    if (selectOrg === -1) {
      GetDeviceInventoryCompany({
        companyID: companyID,
      }).then((res) => {
        setDevices(AddKeyToLst(res.DeviceLst));
      });
    } else {
      DBInvenDeviceLst({
        OrganizationID: selectOrg,
      }).then((res) => {
        setDevices(AddKeyToLst(res.DeviceLst));
      });
    }
  };
  const handleSelectOrg = (val) => {
    setSelectOrg(val);
    if (val != -1) {
      getLst(val);
    } else {
      getCompany();
    }
  };
  return (
    <div>
      <Modal
        title="Danh sách thiết bị đã kiểm kê"
        onCancel={() => setVisible(false)}
        visible={visible}
        width={800}
        footer={[
          <Space>
            <PrintReportInvenDevice contentReport={devices} censers={censers} />
            <Button onClick={() => setVisible(false)}>Đóng</Button>
          </Space>,
        ]}
      >
        <Table
          dataSource={devices}
          columns={columnDevices}
          locale={{
            emptyText: 'Không có dữ liệu',
          }}
        ></Table>
      </Modal>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {allow() && (
          <Select onChange={handleSelectOrg} style={{ width: 300 }} allowClear placeholder="Phòng ban">
            {orgs &&
              orgs.map((org, index) => (
                <Select.Option value={org.OrganizationID} key={index}>
                  {org.OrganizationName}
                </Select.Option>
              ))}
          </Select>
        )}
        <Space>
          <PrintDeviceInventory deviceLst={lstInventory && lstInventory} />
          <Button type="primary" onClick={handleModal}>
            Thiết bị đã kiểm
          </Button>
          <HanleCenser censerType="DeviceInventory" getCensers={(data) => setCensers(data)} />
        </Space>
      </div>
      <Table
        loading={loading}
        dataSource={lstInventory}
        columns={columns}
        locale={{
          emptyText: 'Không có dữ liệu',
        }}
      ></Table>
    </div>
  );
};
export default ListDeviceInventory;
