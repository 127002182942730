import { PrinterOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { GetMaintenanceScheduleByID } from '../../../api/TSMaintenance';
import FormApproveMaintance from './FormApproveMaintan';
class ComponentToPrint extends React.Component {
  render() {
    return (
      <div className="report">
        <FormApproveMaintance
          deviceLst={this.props.deviceLst}
          infoMaintance={this.props.infoMaintance}
          censor={this.props.censor}
        ></FormApproveMaintance>
      </div>
    );
  }
}

const ReportApproveMaintance = ({ infoDevice, censor }) => {
  const [infoMaintance, setInfoMaintance] = useState({});
  const valueQrCode = useRef();
  const onBeforePrint = async () => {
    const response = await GetMaintenanceScheduleByID({
      DocumentID: infoDevice.DocumentID,
    });
    setInfoMaintance({
      ...response.MaintenanceScheduleInfo,
      CreaterName: infoDevice.CreaterName,
      Status: infoDevice.Status,
    });
  };
  return useMemo(
    () => (
      <div>
        <div style={{ display: 'none' }}>
          <ComponentToPrint
            ref={valueQrCode}
            infoMaintance={infoMaintance && infoMaintance}
            censor={censor}
            deviceLst={infoMaintance.DeviceLst}
          />
        </div>
        <ReactToPrint
          documentTitle="Phiếu theo dõi thiết bị"
          trigger={() => (
            <div type="primary">
              <Tooltip title="In phiếu" placement="bottom">
                <PrinterOutlined style={{ fontSize: 20, cursor: 'pointer', alignSelf: 'center' }} />
              </Tooltip>
            </div>
          )}
          onBeforeGetContent={() => onBeforePrint()}
          content={() => valueQrCode.current}
        />
      </div>
    ),
    [infoMaintance]
  );
};
export default ReportApproveMaintance;
