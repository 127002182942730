import { Button, Input, Modal, Form, notification } from 'antd'
import React, { useState } from 'react'
import { ApproveLinkDevice } from '../../../../../../api/TSOrganization'
import { manageDeviceError } from '../../../../../../config/Recoil'
import { useRecoilState } from 'recoil'
const Approve = ({ deviceInfo }) => {
    const [ visible, setVisible ] = useState(false) 
    const [ getLinkUser, setLinkUser ] = useRecoilState(manageDeviceError)
    const [form] = Form.useForm()
    const openModal = () => {
        setVisible(true)
    }
    const onOk = ()=>{
        form.submit()
    }
    const onCancel = () => {
        setVisible(false)
    }
    const onFinish = (elements) => {
        ApproveLinkDevice({
            DeviceID: deviceInfo.DeviceId,
            Note: elements.Note
        }).then(res => {
            if(res.RespCode === 0){
                notification.success('Đã xác nhận thiết bị thành công')
                setVisible(false)
                setLinkUser(!getLinkUser)
            }
        })
    }
    return (
        <div>
            <Modal visible={visible} onOk={onOk} onCancel={onCancel} title="Xác nhận phân bổ thiết bị">
                <Form form={form} onFinish={onFinish}>
                    <Form.Item name="Note" label="Ghi chú" rules={[{
                        required: true,
                        message: 'Hãy nhập ghi chú'
                    }]}>
                        <Input.TextArea/>
                    </Form.Item>
                </Form>
            </Modal>
            <Button type='dashed' onClick={openModal}>Xác nhận</Button>
        </div>
    )
}

export default Approve
