import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Row, Table, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { QRCode } from 'react-qr-svg';
import { GetDeviceInfo } from '../../../../api/Device';
import { DOMAIN } from '../../../../config/URL/Qlts';
import { AddKeyToLst } from '../../../helper/addKeyToLst';
import { accreditationDisplay, dateDisplay, formatMoneyDisplay, statusApprove } from '../../../helper/mapping';
import PrintDeviceInfo from '../../../Print/DeviceInfo';
import './InfoEquipment.scss';
import { formatYear } from '../../../../constants/DateTime/index';
const columns = [
  {
    title: 'Thời gian tạo',
    dataIndex: 'TimeCreate',
    key: 'TimeCreate',
    render: (record) => {
      return moment(record).format('HH:mm DD:MM:YYYY');
    },
  },
  {
    title: 'Mô tả',
    dataIndex: 'Note',
    key: 'Note',
  },
  {
    title: 'Người tạo',
    dataIndex: 'Creater',
    key: 'Creater',
  },
  {
    title: 'Loại HĐ',
    dataIndex: 'Type',
    key: 'Type',
  },
  {
    title: 'Trạng thái',
    dataIndex: 'Status',
    key: 'Status',
    render: (record) => {
      return <Tag color={statusApprove(record).color}>{statusApprove(record).label}</Tag>;
    },
  },
];

const InfoEquipment = ({ deviceId }) => {
  const [visible, setVisible] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState({});
  const openModal = useCallback(() => {
    GetDeviceInfo({
      DeviceId: deviceId,
    }).then((res) => {
      if (res.RespCode === 0) {
        setDeviceInfo(res.DeviceInfo);
        setVisible(true);
      }
    });
  }, [deviceId]);
  return (
    <div>
      <Modal
        visible={visible}
        title="Thông tin thiết bị"
        onCancel={() => setVisible(false)}
        width={700}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <PrintDeviceInfo infoDevice={deviceInfo} />
            <Button key="submit" style={{ marginLeft: 10 }} type="primary" onClick={() => setVisible(false)}>
              Đóng
            </Button>
          </div>,
        ]}
      >
        <div className="form_show_info">
          {deviceInfo.DeviceId && (
            <div>
              <div>Mã thiết bị: {deviceInfo.DeviceCode}</div>
              <div>Mã tham chiếu: {deviceInfo.ReferenceCode}</div>
              <div>Tên thiết bị: {deviceInfo.DeviceName}</div>
              <div>Giá trị ban đầu: {formatMoneyDisplay(deviceInfo.DeviceValue)}</div>
              <div style={{ corlor: 'red' }}>
                <b>Giá trị hiện tại: {formatMoneyDisplay(deviceInfo.DeviceValueCurrent)}</b>
              </div>
              <div>
                Thiết bị kiểm định:
                <Tag color={accreditationDisplay(deviceInfo.Accreditation).color}>
                  {accreditationDisplay(deviceInfo.Accreditation).label}
                </Tag>
              </div>
              <div>Phòng ban sử dụng: {deviceInfo.Location}</div>
              <div>Thời gian thêm thiết bị: {deviceInfo.TimeCreate}</div>
              <div>Loại thiết bị: {deviceInfo.Type}</div>
              <div>Nhà Cung cấp: {deviceInfo.Manufacturer}</div>
              <div>Xuất xứ: {deviceInfo.Origin}</div>
              <div>Thời gian bắt đầu sử dụng: {dateDisplay(deviceInfo.TimeStart, formatYear)}</div>
              <div>Thời gian bảo dưỡng: {dateDisplay(deviceInfo.TimeEnd, formatYear)}</div>
              <div>Ghi chú: {deviceInfo.Note}</div>
              <div>
                <b>Lịch sử hoạt động: </b>
                <Table dataSource={AddKeyToLst(deviceInfo.HistoryActions)} pagination={false} columns={columns} />
              </div>
            </div>
          )}
          <Row className="qr_code_info">
            {deviceInfo.QRCode === undefined ? (
              'Không có QR Code'
            ) : (
              <QRCode value={`${DOMAIN}/qr-code/${deviceInfo.QRCode}`} width="40%" />
            )}
          </Row>
        </div>
      </Modal>
      <div onClick={() => openModal()} style={{ fontSize: 20 }}>
        <a>
          <Tooltip placement="bottom" title="Thông tin">
            <InfoCircleOutlined style={{ color: '#2f76cc' }} />
          </Tooltip>
        </a>
      </div>
    </div>
  );
};
export default InfoEquipment;
