import { Button, Form, Input, message, Modal, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { GetDeviceErrorByOrgID } from '../../../../api/Device';
import { LiquiCreate } from '../../../../api/liquidation';
import { formatMoneyDisplay, statusError } from '../../../helper/mapping';
const columns = [
  {
    title: 'STT',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Mã TB',
    dataIndex: 'DeviceCode',
    key: 'DeviceCode',
  },
  {
    title: 'Tên TB',
    dataIndex: 'DeviceName',
    key: 'DeviceName',
  },
  {
    title: 'Model',
    dataIndex: 'Model',
    key: 'Model',
  },
  {
    title: 'Serial',
    dataIndex: 'Serial',
    key: 'Serial',
  },
  {
    title: 'Giá trị ban đầu',
    dataIndex: 'DeviceValue',
    key: 'DeviceValue',
    render: (record) => {
      return formatMoneyDisplay(record);
    },
  },
  {
    title: 'Giá trị hiện tại',
    dataIndex: 'DeviceValueCurrent',
    key: 'DeviceValueCurrent',
  },
  {
    title: 'Trạng thái báo cáo',
    dataIndex: 'StatusReport',
    key: 'StatusReport',
    render: (record) => {
      return <Tag color={statusError(record).color}>{statusError(record).label}</Tag>;
    },
  },
  {
    title: 'Giải pháp',
    dataIndex: 'Solution',
    key: 'Solution',
  },
];
const LiquidattionCreate = () => {
  const [devices, setDevices] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [form] = Form.useForm();
  const getDevices = () => {
    GetDeviceErrorByOrgID().then((res) => {
      const result = [];
      res.DeviceLst.forEach((device) => {
        const checkExist = result.find((re) => re.DeviceId === device.DeviceId);
        if (!checkExist) {
          result.push(device);
        }
      });
      const response = result.map((device, index) => {
        return {
          ...device,
          key: index + 1,
        };
      });
      setDevices(response);
    });
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };
  const hanleCreate = (elements) => {
    const params = {
      Reason: elements.Reason,
      OrganizationID: localStorage.getItem('groupId'),
      DeviceLst: selectedRows,
    };
    setBtnLoading(true);
    LiquiCreate({
      LiquidationInfo: params,
    }).then((res) => {
      setBtnLoading(false);
      if (res.RespCode === 0) {
        setVisible(false);
        message.success('Cập nhật phiếu thành công');
      }
    });
  };
  useEffect(() => {
    getDevices();
  }, []);
  return (
    <div>
      <div>
        <b style={{ paddingRight: 10 }}>Đã chọn: {selectedRows.length} thiết bị</b>
        <Button type="primary" disabled={selectedRows.length === 0} onClick={() => setVisible(true)}>
          Tạo phiếu
        </Button>
      </div>
      <Modal
        visible={visible}
        title="Tạo phiếu thanh lý thiết bị"
        okText="Xác nhận"
        confirmLoading={btnLoading}
        onCancel={() => setVisible(false)}
        onOk={() => form.submit()}
      >
        <Form form={form} onFinish={hanleCreate} layout="vertical">
          <Form.Item
            name="Reason"
            label="Lý do"
            rules={[
              {
                required: true,
                message: 'Không được để trống',
              },
            ]}
          >
            <Input.TextArea placeholder="Hãy nhập lý do" />
          </Form.Item>
        </Form>
      </Modal>
      <Table dataSource={devices} columns={columns} rowSelection={rowSelection} pagination={false} />
    </div>
  );
};

export default LiquidattionCreate;
