import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Home, MainLayout } from '../components';
import AdminLayout from '../components/Layout/AdminLayout';
import HomeLayout from '../components/Layout/HomeLayout';
import NotFound from '../components/Layout/NotFound';
import CreateLstItem from '../config/Create';
import { API_ADD_MEMBER_ORGANIZATIONS, API_NOTI_SYSTEMS, API_SETTING_DEFAULT_VALUE } from '../constants';
import { ADMIN_PATH_CREATE_DEVICE, ADMIN_PATH_MEMBERS } from '../constants/Admin';
import { API_OVERVIEW } from '../constants/Api';
import { CONFIRM_OTP_API, DASHBOARD_API, FORGOT_PASS_API } from '../constants/Auth';
import {
  API_DEVICE_ADD_EXCEL,
  API_DEVICE_CREATE,
  API_DEVICE_CREATE_SCHEDULE,
  API_DEVICE_LINK,
  API_DEVICE_LINK_CREATE,
  API_DEVICE_LIQUIDATION,
  API_DEVICE_LIQUI_CREATE,
  API_DEVICE_LST,
  API_DEVICE_LST_ERROR,
  API_DEVICE_LST_REPORT,
  API_DEVICE_MAINTAIN,
  API_DEVICE_MOV,
  API_DEVICE_MOV_CREATE,
  API_DEVICE_OFFER,
  API_DEVICE_PRINT_QR,
  API_DEVICE_REPAIR,
  API_DEVICE_REPAIR_QR,
  API_DEVICE_REPORT_ACCREDITATION,
  API_DEVICE_REPORT_REPAIR,
  API_DEVICE_REQUEST_ACCREDITATION,
  API_DEVICE_REQUEST_CHECK,
  API_DEVICE_REQUEST_REPAIR,
  API_MOVE_DEVICE_USER,
} from '../constants/Device';
import { GROUP_API } from '../constants/Group';
import { API_LIQUI_CREATE, API_LIQUI_CREATE2, API_LIQUI_LST } from '../constants/Inventory';
import { API_MEMBER_LST } from '../constants/Member';
import { QR_INFO, QR_LOGIN_API, QR_REPORT_API } from '../constants/QRcode';
import { API_SET_CREATE, API_SET_LST } from '../constants/SetDevice';
import AdminDeviceCreate from '../containers/Admin/Components/DeviceCreate';
import AdminMembers from '../containers/Admin/Components/Members';
import { CheckUser, ConfirmOTP, DisplayForgotPass } from '../containers/Auth';
import SignIn2 from '../containers/Auth/SignIn/SignIn2';
import Contact from '../containers/Contents/Divide/Contact';
import ListDiviDe from '../containers/Contents/Divide/ListDivide';
import LiquidattionCreate from '../containers/Contents/Liquidation/CreateLiqui';
import ListLiquidation from '../containers/Contents/Liquidation/ListLiquidation';
import ReportRepair from '../containers/Contents/Maintain/Report';
import { CreateDevice, GetList, GetOrgByTime, LinkUser } from '../containers/Contents/ManageEquipment';
import CreateLstDivice from '../containers/Contents/ManageEquipment/Create/LstDevice';
import CreateLinkDevice from '../containers/Contents/ManageEquipment/LinkUser/Components/Create';
import Offer from '../containers/Contents/ManageEquipment/Offer';
import { CreateSchedule } from '../containers/Contents/ManageEquipment/Schedule/components';
import { InfoDevice, LstPrintQrCode, LstReport, QrLogin, QrReport } from '../containers/Contents/QRcode';
import { CreateSetDevice, LstSetDevice } from '../containers/Contents/SetDevice';
import { ListDepartment } from '../containers/Department';
import CreateMoveDevice from '../containers/Department/Components/CreateMove';
import { Overview } from '../containers/Group';
import { MemberList } from '../containers/Group/Members';
import AddMemberOrganizations from '../containers/Group/Members/AddMemberOrganizations';
import { ListDeviceInventory } from '../containers/Inventory';
import { CreateInventory, InvenDeviceLst } from '../containers/Inventory/Components';
import Test from '../containers/Inventory/Test';
import MoveDeviceUser from '../containers/MoveDevice';
import NotiSystem from '../containers/Notifications/Systems';
import Lst from '../containers/Print/Report/LstDevice/Lst';
import SettingSystem from '../containers/Setting';
import AdminRoute from './adminRoute';
import PrivateRoute from './privateRoute';
import PublicRoute from './publicRoute';
const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={CONFIRM_OTP_API} component={ConfirmOTP} />
        <Route exact path="/" component={SignIn2} />
        <PrivateRoute exact path={DASHBOARD_API} component={Home} layout={HomeLayout} />
        <PrivateRoute exact path={API_NOTI_SYSTEMS} component={NotiSystem} layout={MainLayout} />
        <PrivateRoute exact path={API_SETTING_DEFAULT_VALUE} component={SettingSystem} layout={MainLayout} />
        <PrivateRoute
          exact
          path={API_ADD_MEMBER_ORGANIZATIONS}
          component={AddMemberOrganizations}
          layout={MainLayout}
        />
        <PrivateRoute exact path={API_DEVICE_CREATE} component={CreateDevice} layout={MainLayout} />
        <Route exact path={FORGOT_PASS_API} component={DisplayForgotPass} />
        <Route exact path="/check-user" component={CheckUser} />
        <PublicRoute exact path="/test" component={Test} layout={MainLayout} />
        <PublicRoute exact path={`/:groupID${API_OVERVIEW}`} component={Overview} layout={MainLayout} />
        <PublicRoute exact path="/create" component={CreateLstItem} layout={MainLayout} />
        {/* qr code */}
        <Route exact path={QR_LOGIN_API} component={QrLogin} />
        <Route exact path={`${QR_REPORT_API}/:scanCode`} component={QrReport} />
        <Route exact path={`${QR_INFO}/:qrCode`} component={InfoDevice} />
        <PublicRoute exact path="/tao-bao-cao" component={Lst} layout={MainLayout} />
        {/* overview */}
        <PrivateRoute exact path={`${GROUP_API}/:groupID`} component={Overview} layout={MainLayout} />
        {/* member */}
        <PrivateRoute exact path={API_MEMBER_LST} component={MemberList} layout={MainLayout} />
        {/* device */}
        <PrivateRoute exact path={API_DEVICE_LST} component={GetList} layout={MainLayout} />
        <PrivateRoute exact path={API_DEVICE_LST + '/bao-cao'} component={GetOrgByTime} layout={MainLayout} />
        <PrivateRoute exact path={API_DEVICE_MOV} component={ListDepartment} layout={MainLayout} />
        <PrivateRoute exact path={API_MOVE_DEVICE_USER} component={MoveDeviceUser} layout={MainLayout} />
        <PrivateRoute exact path={API_DEVICE_MOV_CREATE} component={CreateMoveDevice} layout={MainLayout} />
        <PrivateRoute exact path={API_DEVICE_LINK} component={LinkUser} layout={MainLayout} />
        <PrivateRoute exact path={API_DEVICE_LINK_CREATE} component={CreateLinkDevice} layout={MainLayout} />
        <PrivateRoute exact path={API_DEVICE_MAINTAIN} component={ListDiviDe} layout={MainLayout} />
        <PrivateRoute
          exact
          path={API_DEVICE_MAINTAIN + '/:documentIdSearch'}
          component={ListDiviDe}
          layout={MainLayout}
        />
        <PrivateRoute exact path={API_DEVICE_REPAIR} component={Contact} layout={MainLayout} />
        <PrivateRoute exact path={API_DEVICE_CREATE_SCHEDULE} component={CreateSchedule} layout={MainLayout} />
        <PrivateRoute exact path={API_DEVICE_ADD_EXCEL} component={CreateLstDivice} layout={MainLayout} />
        <PrivateRoute exact path={API_DEVICE_PRINT_QR} component={LstPrintQrCode} layout={MainLayout} />
        <PrivateRoute exact path={API_DEVICE_LST_ERROR} component={LstReport} layout={MainLayout} />
        <PrivateRoute exact path={API_DEVICE_LST_REPORT} component={LstReport} layout={MainLayout} />
        <PrivateRoute exact path={API_DEVICE_REPAIR_QR} component={LstReport} layout={MainLayout} />
        <PrivateRoute exact path={API_DEVICE_REQUEST_ACCREDITATION} component={LstReport} layout={MainLayout} />
        <PrivateRoute exact path={API_DEVICE_REQUEST_CHECK} component={LstReport} layout={MainLayout} />
        <PrivateRoute exact path={API_DEVICE_REQUEST_REPAIR} component={LstReport} layout={MainLayout} />
        <PrivateRoute exact path={API_DEVICE_REPORT_REPAIR} component={ReportRepair} layout={MainLayout} />
        <PrivateRoute exact path={API_DEVICE_REPORT_ACCREDITATION} component={ReportRepair} layout={MainLayout} />
        {/* <PrivateRoute exact path={API_REPORT} component={LstReport} layout={MainLayout}/> */}
        <PrivateRoute exact path={API_DEVICE_OFFER} component={Offer} layout={MainLayout} />
        {/* set device */}
        <PrivateRoute exact path={API_SET_CREATE} component={CreateSetDevice} layout={MainLayout} />
        <PrivateRoute exact path={API_SET_LST} component={LstSetDevice} layout={MainLayout} />
        {/* inventory */}
        <PrivateRoute exact path={API_LIQUI_CREATE} component={CreateInventory} layout={MainLayout} />
        <PrivateRoute exact path={API_LIQUI_LST} component={ListDeviceInventory} layout={MainLayout} />
        <PrivateRoute exact path={API_LIQUI_CREATE2} component={InvenDeviceLst} layout={MainLayout} />
        {/* liquidation */}
        <PrivateRoute exact path={API_DEVICE_LIQUIDATION} component={ListLiquidation} layout={MainLayout} />
        <PrivateRoute exact path={API_DEVICE_LIQUI_CREATE} component={LiquidattionCreate} layout={MainLayout} />

        {/* admin */}
        <AdminRoute exact path={ADMIN_PATH_MEMBERS} component={AdminMembers} layout={AdminLayout} />
        <AdminRoute exact path={ADMIN_PATH_CREATE_DEVICE} component={AdminDeviceCreate} layout={AdminLayout} />
        {/* <AdminRoute exact path={ADMIN_PATH_ADD_USER} component={AdminAddUsers} layout={AdminLayout} /> */}
        {/* <PrivateRoute exact path={"/test/editer"} component={Tinymce} layout={MainLayout}/> */}

        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};
export default Routes;
