const groupName = 'groupName';
const groupId = 'groupId';
const fullName = 'fullName';
const notiCount = 'notiCount';
const role = 'roleUser';
const userName = 'username';
const token = 'token';
const roleAdmin = 'roleuser';

export function setGroupName(val) {
  return localStorage.setItem(groupName, val);
}
export function getGroupName() {
  return localStorage.getItem(groupName);
}
export function setGroupId(val) {
  return localStorage.setItem(groupId, val);
}
export function getGroupId() {
  return localStorage.getItem(groupId);
}
export function setFullName(val) {
  return localStorage.setItem(fullName, val);
}
export function getFullName() {
  return localStorage.getItem(fullName);
}
export function setNotiCount(val) {
  return localStorage.setItem(notiCount, JSON.stringify(val));
}
export function getNotiCount() {
  return localStorage.getItem(notiCount);
}
export function setRole(val) {
  return localStorage.setItem(role, val);
}
export function getRole() {
  return localStorage.getItem(role);
}

export function setUserName(val) {
  return localStorage.setItem(userName, val);
}
export function getUserName() {
  return localStorage.getItem(userName);
}
export function setToken(val) {
  return localStorage.setItem(token, val);
}
export function getToken() {
  return localStorage.getItem(token);
}
export function setRoleAdmin(val) {
  return localStorage.setItem(roleAdmin, val);
}
export function getRoleAdmin() {
  return localStorage.getItem(roleAdmin);
}
