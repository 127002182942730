import { Button, Form, Input, Modal, Table, message } from 'antd';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { ApproveMoveByDeviceId, GetMoveDeviceDocInfo } from '../../../../api/TSOrganization';
import { manageDeviceError } from '../../../../config/Recoil';
import { AddKeyToLst } from '../../../helper/addKeyToLst';
import { dateDisplay, formatMoneyDisplay } from '../../../helper/mapping';
const columnsMoveDevice = [
  {
    title: 'STT',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Tên TB',
    dataIndex: 'DeviceName',
    key: 'DeviceName',
  },
  {
    title: 'Mã TB',
    dataIndex: 'DeviceCode',
    key: 'DeviceCode',
  },
  {
    title: 'Serial',
    dataIndex: 'Serial',
    key: 'Serial',
  },
  {
    title: 'Model',
    dataIndex: 'Model',
    key: 'Model',
  },
  {
    title: 'Giá trị ban đầu',
    dataIndex: 'DeviceValue',
    key: 'DeviceValue',
    render: (record) => {
      return formatMoneyDisplay(record);
    },
  },
  {
    title: 'Giá trị hiện tại',
    dataIndex: 'DeviceValueCurrent',
    key: 'DeviceDeviceValueCurrentValue',
    render: (record) => {
      return formatMoneyDisplay(record);
    },
  },
  {
    title: 'TG bắt đầu sử dụng',
    dataIndex: 'TimeStart',
    key: 'TimeStart',
    render: (record) => {
      return dateDisplay(record, 'YYYY');
    },
  },
];

const Approve = ({ headerInfo }) => {
  const [visible, setVisible] = useState(false);
  const [getMove, setMove] = useRecoilState(manageDeviceError);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedRowKeys, setSelectedKeyDevices] = useState([]);
  const [infoDoc, setInfoDoc] = useState({
    DeviceLst: [],
  });
  const [form] = Form.useForm();
  const initForm = () => {
    setSelectedKeyDevices([]);
    form.resetFields();
  };
  const openModal = async () => {
    setVisible(true);
    initForm();
    const documentInfo = await GetMoveDeviceDocInfo({
      DocumentID: headerInfo.DocumentID,
    });
    setInfoDoc(documentInfo.MoveDeivceDocInfo);
  };
  const onOk = () => {
    form.submit();
  };
  const onCancel = () => {
    setVisible(false);
  };
  const onFinish = (elements) => {
    ApproveMoveByDeviceId({
      DocumentID: headerInfo.DocumentID,
      Note: elements.Note,
      DeviceLst: selectedDevices,
    }).then((res) => {
      if (res.RespCode === 0) {
        message.success('Đã xác nhận thành công');
        setVisible(false);
        setMove(!getMove);
      }
    });
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedDevices(selectedRows);
      setSelectedKeyDevices(selectedRowKeys);
    },
  };
  return (
    <div>
      <Modal
        visible={visible}
        onOk={onOk}
        okButtonProps={{ disabled: selectedDevices.length === 0 }}
        onCancel={onCancel}
        title="Xác nhận luân chuyển thiết bị"
        width={800}
        okText="Xác nhận"
      >
        <div style={{ marginBottom: 20 }}>
          <div style={{ lineHeight: '1.8em', marginBottom: 20 }}>
            <div>Người tạo: {infoDoc.CreaterID}</div>
            <div>Tên người tạo: {infoDoc.CreateName}</div>
            <div>Người tạo: {infoDoc.CreaterID}</div>
            <div>Tên tổ chức yêu cầu: {infoDoc.OrganizationNameOld}</div>
            <div>Tên tổ chức nhận: {infoDoc.OrganizationNameNew}</div>
            <div>Mô tả: {infoDoc.Description}</div>
            <div>
              <b>Danh sách thiết bị:</b>
              <Table
                dataSource={AddKeyToLst(infoDoc.DeviceLst)}
                columns={columnsMoveDevice}
                pagination={false}
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys,
                  ...rowSelection,
                }}
              />
            </div>
          </div>
          <Form form={form} onFinish={onFinish}>
            <Form.Item
              name="Note"
              label="Ghi chú"
              rules={[
                {
                  required: true,
                  message: 'Hãy nhập ghi chú',
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Button type="dashed" onClick={openModal}>
        Xác nhận
      </Button>
    </div>
  );
};

export default Approve;
