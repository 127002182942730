import { VTYT } from '../../config/URL/Qlts';
import { getRole, getUserName } from '../../helpers/auth';

// 1: Người tạo, 2: Quản lý, 3: Thành viên, 10: Trưởng phòng
const GROUP_ROLE = [
  {
    value: 1,
    label: 'Người tạo',
  },
  {
    value: 2,
    label: 'Quản lý',
  },
  {
    value: 3,
    label: 'Thành viên',
  },
  // {
  //   value: 10,
  //   label: 'Trưởng phòng',
  // },
];

const USER_ROLE_ADMIN = {
  value: 1,
  label: 'Người tạo',
};
const USER_ROLE_MANAGE = {
  value: 2,
  label: 'Quản lý',
};
const USER_ROLE_MEMBER = {
  value: 3,
  label: 'Thành viên',
};
const USER_ROLE_PRESIDENT = {
  value: 10,
  label: 'Trưởng phòng',
};
/*
  1. Xem các thiết bị của cá nhân user
  2. Tạo request đề xuất mua thêm thiết bị
  3. Xem các request tới cá nhân
*/
const getRoleUser = () => {
  return getRole() === USER_ROLE_MEMBER.value.toString();
};

/*
  1. Xem cá thiết bị của tổ chức mà mình đã join
  2. Thêm thành viên
  3. Hanlde các request của phòng ban, user
*/
const getRoleManager = () => {
  return [USER_ROLE_MANAGE.value, USER_ROLE_PRESIDENT.value, USER_ROLE_ADMIN.value].includes(parseInt(getRole()));
};

/*
  1. Tất cả các quyền của user, manager
  2. Xem được các thiết bị, request của tất cả tổ chức
*/
const getRoleAdmin = () => {
  return (
    getRole() === USER_ROLE_ADMIN.value.toString() || getUserName() === '0387332280' || getUserName() === '0988118000'
  );
};
const allowCreate = (groupId = 0) => {
  return groupId === VTYT || getRoleAdmin() === USER_ROLE_PRESIDENT.value.toString();
};
export {
  GROUP_ROLE,
  USER_ROLE_ADMIN,
  USER_ROLE_MANAGE,
  USER_ROLE_MEMBER,
  getRoleUser,
  getRoleManager,
  getRoleAdmin,
  allowCreate,
};
