import { Button, Input, message, Modal, Radio, Space, Table, Tag } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { DeviceRepairApprove, DeviceRepairLst } from '../../../../api/Device';
import { AddKeyToLst } from '../../../helper/addKeyToLst';
import { formatMoneyDisplay, statusReportRepair, timeDisplay, typeSchedule } from '../../../helper/mapping';
import { GetMaintenanceByDeviceID } from '../../../../api/TSMaintenance';
import './ReportRepair.scss';
import { formatDate } from '@telerik/kendo-intl';
import { useLocation, useParams } from 'react-router-dom';
import { API_DEVICE_REPORT_ACCREDITATION } from '../../../../constants/Device';

const ReportRepair = () => {
  const [loading, setLoading] = useState(false);
  const [reportsRepair, setReportsRepair] = useState([]);
  const [noteApprove, setNoteApprove] = useState('');
  const [visible, setVisible] = useState(false);
  const [visibleCancel, setVisibleCancel] = useState(false);
  const [lineInfo, setLineInfo] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [maintances, setMaintances] = useState([]);
  const [documentCode, setDocumentCode] = useState('');
  const location = useLocation();
  const getReportsRepair = () => {
    setLoading(true);
    DeviceRepairLst({
      OrganizationID: localStorage.getItem('groupId'),
    }).then((res) => {
      const type = location.pathname === API_DEVICE_REPORT_ACCREDITATION ? 'accreditation' : 'repair';
      const response = res.QLTSDeviceLst.filter((device) => device.Type === type).sort((a, b) => {
        return a.Status - b.Status;
      });
      setLoading(false);
      setReportsRepair(AddKeyToLst(response));
    });
  };
  const handleModal = (type, record) => {
    setLineInfo(record);
    if (type === 'approve') {
      setVisible(true);
    } else {
      setVisibleCancel(true);
    }
    // GetMaintenanceByDeviceID({
    //   DeviceID: obj.DeviceId,
    // }).then((res) => {
    //   const response = res.MaintenanceScheduleLst.map((maintance) => {
    //     return {
    //       ...maintance,
    //       value: maintance.DocumentID,
    //       label: `${maintance.DocumentCode} - ${maintance.CreaterName} - ${formatDate(maintance.TimeCreate)} - ${
    //         typeSchedule(maintance.Note).label
    //       }`,
    //     };
    //   });
    //   if (response.length > 0) {
    //     setDocumentCode(response[0].value);
    //   }
    //   setMaintances(response);
    // });
    // setLineInfo(obj);
  };
  const handleApprove = (type) => {
    setBtnLoading(true);
    DeviceRepairApprove({
      OrganizationID: localStorage.getItem('groupId'),
      ApproveStatus: type === 'approve' ? 2 : 0,
      DocumentMaintance: documentCode,
      ApproveNote: noteApprove,
      RowID: lineInfo.RowID,
    }).then((res) => {
      setBtnLoading(false);
      if (res.RespCode === 0) {
        message.success('Xác nhận thành công');
        setVisible(false);
        getReportsRepair();
      }
    });
  };
  useEffect(() => {
    getReportsRepair();
  }, [location.pathname]);
  const columns = [
    {
      title: 'STT',
      key: 'key',
      dataIndex: 'key',
    },
    {
      title: 'Tên thiết bị',
      key: 'DeviceName',
      dataIndex: 'DeviceName',
    },
    {
      title: 'Mã thiết bị',
      key: 'DeviceCode',
      dataIndex: 'DeviceCode',
    },
    {
      title: 'TG báo cáo',
      key: 'TimeCreate',
      dataIndex: 'TimeCreate',
      render: (record) => {
        return timeDisplay(record);
      },
    },
    {
      title: 'Giá trị ban đầu',
      key: 'DeviceValue',
      dataIndex: 'DeviceValue',
      render: (record) => {
        return formatMoneyDisplay(record);
      },
    },
    {
      title: 'Giá trị hiện tại',
      key: 'DeviceValueCurrent',
      dataIndex: 'DeviceValueCurrent',
      render: (record) => {
        return formatMoneyDisplay(record);
      },
    },
    {
      title: 'Model',
      key: 'Model',
      dataIndex: 'Model',
    },
    {
      title: 'Serial',
      key: 'Serial',
      dataIndex: 'Serial',
    },
    {
      title: 'Trạng thái',
      key: 'Status',
      dataIndex: 'Status',
      sorter: (a, b) => a.Status - b.Status,
      render: (record) => {
        return <Tag color={statusReportRepair(record).color}>{statusReportRepair(record).label}</Tag>;
      },
    },
    {
      title: 'Người báo cáo',
      key: 'CreaterName',
      dataIndex: 'CreaterName',
    },
    {
      title: 'SDT',
      key: 'ReporterPhone',
      dataIndex: 'ReporterPhone',
    },
    {
      title: 'Mô tả',
      key: 'Description',
      dataIndex: 'Description',
    },
    {
      title: 'Ghi chú',
      key: 'Note',
      dataIndex: 'Note',
    },
    {
      title: 'ID Người phê duyệt',
      key: 'ApproveID',
      dataIndex: 'ApproveID',
    },
    {
      title: 'Người phê duyệt',
      key: 'ApproveName',
      dataIndex: 'ApproveName',
    },
    {
      title: 'TG phê duyệt',
      key: 'TimeApprove',
      dataIndex: 'TimeApprove',
      render: (record) => {
        return timeDisplay(record);
      },
    },
    {
      title: 'Note phê duyệt',
      key: 'ApproveNote',
      dataIndex: 'ApproveNote',
    },
    {
      title: '',
      key: '',
      dataIndex: '',
      fixed: 'right',
      with: 200,
      render: (record) => (
        <div>
          {record.Status === 1 && (
            <Space>
              <Button type="dashed" onClick={() => handleModal('approve', record)}>
                Xác nhận
              </Button>
              <DeleteOutlined className="btn-icon" onClick={() => handleModal('delete', record)} />
            </Space>
          )}
        </div>
      ),
    },
  ];
  return (
    <div>
      <Modal
        visible={visibleCancel}
        title="Hủy báo cáo bảo dưỡng thiết bị"
        confirmLoading={btnLoading}
        onCancel={() => setVisibleCancel(false)}
        onOk={() => handleApprove('delete')}
        okText="Xác nhận"
        cancelText="Hủy"
      >
        <div>
          <Input
            value={noteApprove}
            onChange={(e) => setNoteApprove(e.target.value)}
            placeholder="Nhập ghi chú xác nhận"
          />
        </div>
      </Modal>
      <Modal
        visible={visible}
        title="Xác nhận báo cáo bảo dưỡng thiết bị"
        confirmLoading={btnLoading}
        onCancel={() => setVisible(false)}
        onOk={() => handleApprove('approve')}
        okText="Xác nhận"
        cancelText="Hủy"
      >
        <div>
          <div>
            <b>Thông tin lịch bảo dưỡng</b>
            <Radio.Group
              style={{ padding: '10px 0' }}
              value={documentCode}
              onChange={(e) => setDocumentCode(e.target.value)}
            >
              <Space direction="vertical">
                {maintances.map((maintance) => (
                  <Radio value={maintance.value} key={maintance.DocumentID}>
                    {maintance.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </div>
          <Input
            value={noteApprove}
            onChange={(e) => setNoteApprove(e.target.value)}
            placeholder="Nhập ghi chú xác nhận"
          />
        </div>
      </Modal>
      <Table dataSource={reportsRepair} columns={columns} loading={loading} />
    </div>
  );
};

export default ReportRepair;
