import Request from '../config/Request';
import Request2 from '../config/Request/request2';
export function GetDeviceByOrganization(data) {
  return Request({
    url: '/Device/GetDeviceByOrganization',
    method: 'POST',
    data: data,
  });
}
export function GetDeviceByUser(data) {
  return Request({
    url: '/Device/GetDeviceByUser',
    method: 'POST',
    data: data,
  });
}
export function GetDeviceErrorByOrgID(data) {
  return Request2({
    url: '/Device/GetDeviceErrorByOrgID',
    method: 'POST',
    data: data,
  });
}
export function GetDeviceInfoByQRCode(data) {
  return Request({
    url: '/Device/GetDeviceInfoByQRCode',
    method: 'POST',
    data: data,
  });
}
export function CreateDeviceLst(data) {
  return Request({
    url: '/Device/CreateDeviceLst',
    method: 'POST',
    data: data,
  });
}
export function ReportDeviceError(data) {
  return Request2({
    url: '/Device/ReportDeviceError',
    method: 'POST',
    data: data,
  });
}
export function GetDeviceForReport(data) {
  return Request({
    url: '/Device/GetDeviceForReport',
    method: 'POST',
    data: data,
  });
}
export function GetDeviceInfo(data) {
  return Request({
    url: '/Device/GetDeviceInfo',
    method: 'POST',
    data: data,
  });
}
export function GetDeviceInfoByCode(data) {
  return Request({
    url: '/Device/GetDeviceInfoByCode',
    method: 'POST',
    data: data,
  });
}
export function CreateDeviceInfo(data) {
  return Request2({
    url: '/Device/CreateDeviceInfo',
    method: 'POST',
    data: data,
  });
}
export function UpdateDeviceInfo(data) {
  return Request({
    url: '/Device/UpdateDeviceInfo',
    method: 'POST',
    data: data,
  });
}
export function RemoveDeviceInfo(data) {
  return Request({
    url: '/Device/RemoveDeviceInfo',
    method: 'POST',
    data: data,
  });
}

export function ApproveReportDeviceError(data) {
  return Request2({
    url: '/Device/ApproveReportDeviceError',
    method: 'POST',
    data: data,
  });
}

export function DelReportDeviceError(data) {
  return Request2({
    url: '/Device/DelReportDeviceError',
    method: 'POST',
    data: data,
  });
}

export function GetAllDeviceErrorByOrgID(data) {
  return Request2({
    url: '/Device/GetAllDeviceErrorByOrgID',
    method: 'POST',
    data: data,
  });
}

export function GetDeviceOrgByTime(data) {
  return Request({
    url: '/Device/GetDeviceOrgByTime',
    method: 'POST',
    data: data,
  });
}

export function GetDeviceCompanyByTime(data) {
  return Request({
    url: '/Device/GetDeviceCompanyByTime',
    method: 'POST',
    data: data,
  });
}

export function GetDeviceByCompany(data) {
  return Request({
    url: '/Device/GetDeviceByCompany',
    method: 'POST',
    data: data,
  });
}

export function GetDeviceUserByTime(data) {
  return Request({
    url: '/Device/GetDeviceUserByTime',
    method: 'POST',
    data: data,
  });
}

export function DBGetDeviceInfoByQRCode(data) {
  return Request({
    url: '/Device/DBGetDeviceInfoByQRCode',
    method: 'POST',
    data: data,
  });
}
export function DeviceRepairCreate(data) {
  return Request({
    url: '/Device/DeviceRepairCreate',
    method: 'POST',
    data: data,
  });
}

export function DeviceRepairApprove(data) {
  return Request({
    url: '/Device/DeviceRepairApprove',
    method: 'POST',
    data: data,
  });
}
export function DeviceRepairLst(data) {
  return Request({
    url: '/Device/DeviceRepairLst',
    method: 'POST',
    data: data,
  });
}

export function AccreditationDeviceLst(data) {
  return Request2({
    url: '/Device/AccreditationDeviceLst',
    method: 'POST',
    data: data,
  });
}
export function GetDeviceReportByUser(data) {
  return Request2({
    url: '/Device/GetDeviceReportByUser',
    method: 'POST',
    data: data,
  });
}
export function GetDeviceReportByGId(data) {
  return Request2({
    url: '/Device/GetDeviceReportByGId',
    method: 'POST',
    data: data,
  });
}
export function GetDeviceReportById(data) {
  return Request2({
    url: '/Device/GetDeviceReportById',
    method: 'POST',
    data: data,
  });
}
