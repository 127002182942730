import Axios from 'axios';
import { getUserName } from '../../helpers/auth';
function requestLog(respCode, respText) {
  const api = 'https://hooks.slack.com/services/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';
  Axios.post(
    api,
    {
      text: `• url: ${
        window.location.href
      }\n• RespCode: ${respCode}\n• RespText: ${respText}\n • Người lỗi: ${getUserName()}\n • Tên: ${localStorage.getItem(
        'fullName'
      )}`,
    },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  ).then((res) => {});
}
export { requestLog };
