import { PlusOutlined } from '@ant-design/icons';
import { Button, Radio, Space, Table, Tag } from 'antd';
import Search from 'antd/lib/input/Search';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { GetMoveDeviceDocLst, GetMoveDocLstByCreater, GetMoveDocLstByReceive } from '../../../api/TSOrganization';
import { manageDeviceError } from '../../../config/Recoil';
import { API_DEVICE_MOV_CREATE } from '../../../constants/Device';
import { getRoleManager } from '../../../constants/Group/Roles';
import { allowDel } from '../../../helpers/mapping';
import HanleCenser from '../../Censer';
import PrintDeviceMove from '../../Print/DeviceMove';
import LstDeviceMov from '../../Print/LstDeviceMov';
import { dateDisplay, statusApprove } from '../../helper/mapping';
import { Approve, Cancel } from '../Components';
import InfoMove from '../Components/Info';
import './ListDeviceMov.scss';
const formatTime = 'HH:mm DD/MM/YYYY';

const options = [
  {
    label: 'Đã tạo',
    value: 1,
  },
  {
    label: 'Đã nhận',
    value: 2,
  },
];
const ListDepartment = () => {
  const [lstMoveDevice, setLstMoveDevice] = useState([]);
  const [censers, setCensers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [valueSearch, setValueSearch] = useState('');
  const [selectType, setSelectType] = useState(1);
  const getMove = useRecoilValue(manageDeviceError);
  const history = useHistory();
  const colunms = [
    {
      title: 'STT',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Người tạo',
      dataIndex: 'CreateName',
      key: 'CreateName',
    },
    {
      title: 'TG tạo',
      dataIndex: 'TimeCreate',
      key: 'TimeCreate',
      render: (record) => {
        return dateDisplay(record, formatTime);
      },
    },
    {
      title: 'Người xác nhận',
      dataIndex: 'ApproveName',
      key: 'ApproveName',
    },
    {
      title: 'TG xác nhận',
      dataIndex: 'ApproveTime',
      key: 'ApproveTime',
      render: (record) => {
        return dateDisplay(record, formatTime);
      },
    },
    {
      title: 'Phòng ban nhận',
      dataIndex: 'OrganizationNameNew',
      key: 'OrganizationNameNew',
    },
    {
      title: 'Lý do',
      dataIndex: 'Description',
      key: 'Description',
    },
    {
      title: 'Danh sách TB',
      dataIndex: 'DeviceLst',
      key: 'DeviceLst',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'Status',
      key: 'Status',
      render: (record) => {
        return <Tag color={statusApprove(record).color}>{statusApprove(record).label}</Tag>;
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'key',
      render: (record) => (
        <Space>
          <InfoMove infoMove={record} />
          <LstDeviceMov lstMoveDevice={record} />
          {(record.Status === 1 || record.Status === 3) && selectType === 2 && <Approve headerInfo={record} />}
          {allowDel(record.CreaterID, record.Status) && <Cancel headerInfo={record} />}
        </Space>
      ),
    },
  ];
  const mappingDocument = (documents) => {
    const mappingDoc = documents
      .sort((a, b) => {
        return moment(b.TimeCreate).milliseconds() - moment(a.TimeCreate).milliseconds();
      })
      .map((doc, index) => {
        return {
          ...doc,
          key: index + 1,
          DeviceLst: doc.DeviceLst.map((de) => {
            return de.DeviceName + '(' + de.DeviceCode + ')';
          }).join('-'),
          DeviceLstPrint: doc.DeviceLst,
        };
      });
    return mappingDoc;
  };
  const getMoveDocLstByCreater = async () => {
    const documentByCreator = await GetMoveDocLstByCreater();
    setLstMoveDevice(mappingDocument(documentByCreator.MoveDeivceDocLst));
    setLoading(false);
  };
  const getMoveDocLstByReceive = async () => {
    const documentByReveive = await GetMoveDocLstByReceive();
    setLstMoveDevice(mappingDocument(documentByReveive.MoveDeivceDocLst));
    setLoading(false);
  };
  const getMoveDeviceDocLst = async () => {
    const documentAll = await GetMoveDeviceDocLst();
    setLstMoveDevice(mappingDocument(documentAll.MoveDeivceDocLst));
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    const roleUser = localStorage.getItem('roleUser');
    if (roleUser === '1') {
      if (selectType === 1) {
        getMoveDeviceDocLst();
      } else {
        getMoveDocLstByReceive();
      }
    } else if (roleUser === '2') {
      if (selectType === 1) {
        getMoveDocLstByCreater();
      } else {
        getMoveDocLstByReceive();
      }
    }
  }, [getMove, selectType]);
  const handleSearch = (e) => {
    setValueSearch(e.target.value);
  };
  const infoBillMove = lstMoveDevice.filter((moveDevice) => {
    return (
      moveDevice.OrganizationNameNew.toLowerCase().indexOf(valueSearch.toLowerCase()) !== -1 ||
      moveDevice.CreaterID.toLowerCase().indexOf(valueSearch.toLowerCase()) !== -1 ||
      moveDevice.OrganizationNameOld.toLowerCase().indexOf(valueSearch.toLowerCase()) !== -1
    );
  });
  const onClickRedirectCreateMoveDevice = () => {
    history.push(API_DEVICE_MOV_CREATE);
  };
  return (
    <div>
      <div className="actions">
        <Space>
          <Radio.Group
            value={selectType}
            onChange={(e) => {
              setSelectType(e.target.value);
            }}
          >
            {options.map((option) => (
              <Radio.Button value={option.value} type="primary" key={option.value}>
                {option.label}
              </Radio.Button>
            ))}
          </Radio.Group>
          {getRoleManager() && (
            <Button type="primary" onClick={onClickRedirectCreateMoveDevice} icon={<PlusOutlined />}>
              Tạo phiếu luân chuyển
            </Button>
          )}
        </Space>
        <Space size={10}>
          <Search
            placeholder="Nhập thông tin tìm kiếm"
            onChange={handleSearch}
            allowClear
            autoFocus
            style={{ width: 250 }}
          />
          <PrintDeviceMove documents={infoBillMove} censers={censers} />
          <HanleCenser censerType="DeviceMov" getCensers={(data) => setCensers(data)} />
        </Space>
      </div>
      <Table dataSource={infoBillMove} loading={loading} columns={colunms} pagination={false}></Table>
    </div>
  );
};
export default ListDepartment;
