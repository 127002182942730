import { AccreditationDeviceLst, DeviceRepairLst, GetDeviceErrorByOrgID } from '../../api/Device';
import { GetMaintenanceScheduleNoti } from '../../api/TSMaintenance';

const Notification = async () => {
  const maintance = await GetMaintenanceScheduleNoti().then((res) => {
    return res.DeviceLst;
  });
  const accre = await AccreditationDeviceLst().then((res) => {
    return res.DeviceLst;
  });
  const reportQR = await GetDeviceErrorByOrgID().then((res) => {
    return res.DeviceLst.filter((device) => device.StatusError === 1);
  });
  const resultRepair = await DeviceRepairLst({
    OrganizationID: localStorage.getItem('groupId'),
  }).then((res) => {
    return res.QLTSDeviceLst.filter((device) => device.Status === 1);
  });
  return Promise.all([maintance, accre, reportQR, resultRepair]);
};

export default Notification;
