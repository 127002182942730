import React from "react";
import "./App.css";
import "antd/dist/antd.css";
import "./assets/scss/main.scss";
import Routes from "./routes";
import { RecoilRoot } from 'recoil';
import { ConfigProvider } from 'antd'
import vi_VN from 'antd/lib/locale/vi_VN';
function App() {
  return (
  <ConfigProvider locale={vi_VN}>
    <RecoilRoot>
      <Routes />
    </RecoilRoot>
  </ConfigProvider>
  );
}

export default App;
