import moment from 'moment';
import React from 'react';
import { TitleReport } from '../../../config/URL/Qlts';
import { dateDisplay, displaySerial, formatMoneyDisplay } from '../../helper/mapping';
// import VNnum2words from 'vn-num2words'
const TotalDevice = ({ deviceLst = [], timeStart, timeEnd }) => {
  const a = new Date();
  const day = moment(a).format('DD');
  const month = moment(a).format('MM');
  const year = moment(a).format('YYYY');
  const formatDate = 'YYYY';
  const totalMoney = deviceLst.reduce((a, b) => {
    return a + b.DeviceValue;
  }, 0);
  return (
    <div style={{ padding: '40px', fontFamily: 'Time New Roman' }}>
      <div>
        <p style={{ marginTop: '0pt', marginBottom: '8pt', lineHeight: '108%', fontSize: '13pt' }}>
          <span style={{ fontFamily: '"Times New Roman"' }}>Đơn vị: {TitleReport}</span>
        </p>
        <p style={{ marginTop: '0pt', marginBottom: '8pt', lineHeight: '108%', fontSize: '13pt' }}>
          <span style={{ fontFamily: '"Times New Roman"' }}>Mã QHNS: 1033947</span>
        </p>
        <p style={{ marginTop: '0pt', marginBottom: '8pt', textAlign: 'center', lineHeight: '108%', fontSize: '16pt' }}>
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>PHIẾU TỔNG HỢP THIẾT BỊ</span>
          </strong>
        </p>
        <p style={{ marginTop: '0pt', marginBottom: '8pt', textAlign: 'center', lineHeight: '108%', fontSize: '11pt' }}>
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Từ {moment(timeStart).format('DD/MM/YYYY')} đến {moment(timeEnd).format('DD/MM/YYYY')}
          </span>
        </p>
        <p style={{ marginTop: '0pt', marginBottom: '8pt', textAlign: 'center', lineHeight: '108%', fontSize: '11pt' }}>
          <span style={{ fontFamily: '"Times New Roman"' }}>Phòng ban: {localStorage.getItem('groupName')}</span>
        </p>
      </div>
      <div>
        <b style={{ marginTop: '0pt', marginBottom: '8pt', lineHeight: '108%', fontSize: '11pt', paddingBottom: 10 }}>
          <strong>
            <span style={{ fontFamily: '"Times New Roman"' }}>Danh sách thiết bị:</span>
          </strong>
        </b>
        <table style={{ width: '100%', border: '1px solid #ddd', borderCollapse: 'collapse', tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '8px', width: '45px' }}>
                STT
              </th>
              <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '8px' }}>
                Tên thiết bị
              </th>
              <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '8px' }}>
                Model
              </th>
              <th
                rowSpan={2}
                style={{ border: '1px solid #ddd', padding: '8px', width: '100px', wordBreak: 'break-all' }}
              >
                Serial
              </th>
              <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '8px' }}>
                Loại
              </th>
              <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '8px' }}>
                ĐVT
              </th>
              <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '8px', width: '50px' }}>
                SL
              </th>
              <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '8px' }}>
                Năm sản xuất
              </th>
              <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '8px' }}>
                Năm đưa vào sử dụng
              </th>
              <th colSpan={3} style={{ border: '1px solid #ddd', padding: '8px' }}>
                Giá trị
              </th>
              <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '8px' }}>
                Người nhập
              </th>
            </tr>
            <tr>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Ban đầu</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Hiện tại</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Khấu hao</th>
            </tr>
          </thead>
          <tbody>
            {deviceLst.map((device, index) => (
              <tr key={device.DeviceId} style={{ fontSize: 12 }}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{index + 1}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{device.DeviceName}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{device.Model}</td>
                <td
                  style={{
                    border: '1px solid #ddd',
                    padding: '8px',
                    textAlign: 'center',
                    width: 100,
                    wordBreak: 'break-all',
                  }}
                >
                  {device.Serial}
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{device.Type}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{device.Unit}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>1</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {dateDisplay(device.TimeManufacturer, formatDate)}
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {dateDisplay(device.TimeStart, formatDate)}
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{formatMoneyDisplay(device.DeviceValue)}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {formatMoneyDisplay(device.DeviceValueCurrent)}
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {formatMoneyDisplay(device.DeviceValue - device.DeviceValueCurrent)}
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{device.CreaterName}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p style={{ marginTop: '0pt', marginBottom: '8pt', lineHeight: '108%', fontSize: '11pt' }}>
          <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;</span>
        </p>
        <p style={{ marginTop: '0pt', marginBottom: '8pt', lineHeight: '108%', fontSize: '11pt' }}>
          <span style={{ fontFamily: '"Times New Roman"' }}>Tổng số thiết bị: {deviceLst.length}</span>
        </p>
        <p style={{ marginTop: '0pt', marginBottom: '8pt', lineHeight: '108%', fontSize: '11pt' }}>
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Tổng giá trị: {new Intl.NumberFormat().format(totalMoney)} VNĐ
          </span>
        </p>
        <p style={{ marginTop: '0pt', marginBottom: '8pt', lineHeight: '108%', fontSize: '11pt' }}>
          <span style={{ fontFamily: '"Times New Roman"' }}>Tổng số tiền (viết bằng chữ): </span>
        </p>
        <p style={{ marginTop: '0pt', marginBottom: '8pt', lineHeight: '108%', fontSize: '11pt' }}>
          <span style={{ fontFamily: '"Times New Roman"' }}>Số chứng từ đi kèm:</span>
        </p>
        <p style={{ marginTop: '0pt', marginBottom: '8pt', textAlign: 'right', lineHeight: '108%', fontSize: '11pt' }}>
          <span style={{ fontFamily: '"Times New Roman"' }}>
            Ngày {day} tháng {month} năm {year}
          </span>
        </p>
        <div style={{ textAlign: 'end', paddingRight: '30px' }}>
          <h3 style={{ paddingBottom: '100px', fontWeight: 'bold', fontSize: '16pt', fontFamily: '"Times New Roman"' }}>
            Người tổng hợp
          </h3>
          <div style={{ paddingRight: '20px', fontFamily: '"Times New Roman"' }}>
            {localStorage.getItem('fullName')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalDevice;
