import { Button, Modal, Form, Input, message, Select } from 'antd';
import './Report.scss';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DBGetDeviceInfoByQRCode, ReportDeviceError } from '../../../../api/Device';
import { dateDisplay, formatMoneyDisplay } from '../../../helper/mapping';
import QRCodeReportRepair from '../ReportRepair';
import { REPORT_ERROR_DEVICE_STATUS, REPORT_ERROR_SOLUTION } from '../../../../constants/reportError';
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const stateDevice = REPORT_ERROR_DEVICE_STATUS;
const solutionDevice = REPORT_ERROR_SOLUTION;
const QrReport = () => {
  const { scanCode } = useParams();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const reportDevice = () => {
    setVisible(true);
    form.resetFields();
  };
  const handleSubmit = (values) => {
    const req = {
      QRCode: scanCode,
      Report: values.report,
      StatusReport: values.TypeDevice,
      Solution: values.SolutionDevice,
      ReporterName: values.NameUserReport,
      ReporterPhone: values.PhoneNumberUserReport,
    };
    setLoading(true);
    ReportDeviceError(req).then((res) => {
      setLoading(false);
      if (res.RespCode === 0) {
        setVisible(false);
        message.success('Báo cáo thành công');
      }
    });
  };
  const searchInfo = () => {
    setLoading(false);
    DBGetDeviceInfoByQRCode({
      QRCode: scanCode,
    }).then((res) => {
      setLoading(false);
      if (res.RespCode === 0) {
        const valueDevice = res.DeviceInfo;
        Modal.info({
          title: 'Thông tin tài sản',
          content: (
            <div>
              <p>Tên thiết bị: {valueDevice.DeviceName}</p>
              <p>Mã thiết bị: {valueDevice.DeviceCode}</p>
              <p>Serial: {valueDevice.Serial}</p>
              <p>Model: {valueDevice.Model}</p>
              <p>Giá trị: {formatMoneyDisplay(valueDevice.DeviceValue)}</p>
              <p>
                Trạng thái:{' '}
                {valueDevice.Status === 1
                  ? 'Đang hoạt động'
                  : valueDevice.Status === 2
                  ? 'Đã báo cáo'
                  : valueDevice.Status === 3
                  ? 'Đang bảo dưỡng'
                  : ''}
              </p>
              <p>Loại: {valueDevice.Type}</p>
              <p>Xuất xứ: {valueDevice.Origin}</p>
              <p>Nguồn cấp: {valueDevice.Manufacturer}</p>
              <p>Phòng ban sử dụng: {valueDevice.Location}</p>
              <p>Thời gian nhập: {dateDisplay(valueDevice.TimeStart)}</p>
              <p>Hạn sử dụng: {valueDevice.TimeEnd}</p>
              <p>
                TG bảo hành: từ {dateDisplay(valueDevice.WarrantyStart)} đến {dateDisplay(valueDevice.WarrantyEnd)}
              </p>
            </div>
          ),
        });
      }
    });
  };
  return (
    <div>
      <Modal
        title="Báo cáo hiện trạng thiết bị"
        visible={visible}
        onOk={() => {
          form.submit();
        }}
        onCancel={() => {
          setVisible(false);
        }}
        confirmLoading={loading}
        okText="Xác nhận"
        cancelText="Hủy"
      >
        <Form form={form} onFinish={handleSubmit} {...layout}>
          <Form.Item
            label="Tình trạng"
            name="TypeDevice"
            rules={[
              {
                required: true,
                message: 'Hãy nhập tình trạng',
              },
            ]}
          >
            <Select>
              {stateDevice.map((state) => (
                <Select.Option value={state.value}>{state.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Giải pháp" name="SolutionDevice">
            <Select>
              {solutionDevice.map((solu) => (
                <Select.Option value={solu.label}>{solu.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="report"
            label="Mô tả"
            rules={[
              {
                required: true,
                message: 'Hãy nhập mô tả tình trạng',
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="NameUserReport" label="Họ & Tên">
            <Input />
          </Form.Item>
          <Form.Item name="PhoneNumberUserReport" label="SĐT">
            <Input type="number" />
          </Form.Item>
        </Form>
      </Modal>
      <div className="qr_code">
        <div className="report-content">
          <div className="report-title">
            <h2>Bệnh viện đa khoa tỉnh Điện Biên</h2>
            <h3>Thông tin thiết bị</h3>
          </div>
          <div>
            <Button size="large" type="primary" shape="round" onClick={searchInfo} loading={loading}>
              Tra cứu
            </Button>
            <Button type="primary" shape="round" size="large" danger onClick={reportDevice}>
              Báo hỏng
            </Button>
          </div>
          <div>
            <QRCodeReportRepair type="repair" />
            <QRCodeReportRepair type="accreditation" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default QrReport;
