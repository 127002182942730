import React, { useState, useEffect, useContext } from "react";
import { EditOutlined } from "@ant-design/icons";
import "./ChangeInfo.scss";
import { Modal, Form, Input, message } from "antd";
import Axios from "axios";
import AuthAPI from "../../../api/AuthApi";
const ChangeInfo = (props) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [fullName, setName] = useState(props.userInfo.FullName);
  const [phone, setPhone] = useState(props.userInfo.UserName);
  const [email, setEmail] = useState(props.userInfo.Email);
  const [address, setAddress] = useState(props.userInfo.Address);
  const onOk = () => {
    const api = AuthAPI.updateInfo();
    var userName = localStorage.getItem("username");
    var token = localStorage.getItem("token");
    const request = {
      UserName: userName.slice(1, userName.length - 1),
      Token: token.slice(1, token.length - 1),
      UserInfo: {
        FullName: fullName,
        Email: email,
        address: address,
      },
    };
    Axios.post(api, request)
      .then((res) => {
        if (!res.data.RespCode) {
          message.success("Thay đổi thông tin người dùng thành công");
          setVisible(false);
          window.location.reload();
        } else {
          message.error(res.data.RespText);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };
  const onCancel = () => {
    setVisible(false);
  };
  const showModalEdit = () => {
    setVisible(true);
  };

  const changeName = (e) => {
    setName(e.target.value);
  };
  const changeEmail = (e) => {
    setEmail(e.target.value);
  };
  const changePhone = (e) => {
    setPhone(e.target.value);
  };
  const changeAddress = (e) => {
    setAddress(e.target.value);
  };
  return (
    <div>
      {props.userInfo && (
        <div className="modal-edit">
          <Modal
            title="Chỉnh sửa thông tin người dùng"
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
          >
            <div className="change-info-input">
              Tên:
              <Input value={fullName} onChange={changeName} />
            </div>
            <div className="change-info-input">
              SĐT:
              <Input value={phone} onChange={changePhone} disabled />
            </div>
            <div className="change-info-input">
              Email:
              <Input value={email} onChange={changeEmail} disabled/>
            </div>
            <div className="change-info-input">
              Địa chỉ: <Input value={address} onChange={changeAddress} />
            </div>
          </Modal>
        </div>
      )}
      <div onClick={showModalEdit}>
        <div className="change-info">Sửa thông tin</div>
      </div>
    </div>
  );
};
export default ChangeInfo;
