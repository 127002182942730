import React, { useState }from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { Form, Input, Modal, Tooltip, notification } from 'antd'
import { manageDeviceError } from '../../../../config/Recoil'
import { useRecoilState } from 'recoil'
import { DelMoveDeviceDoc } from '../../../../api/TSOrganization'
const Cancel = ({headerInfo}) => {
    const [visible, setVisible] = useState(false)
    const [getMov, setMove] = useRecoilState(manageDeviceError)
    const [form] = Form.useForm()
    const openModal = ()=>{
        setVisible(true)
    }
    const onOk = () => {
        form.submit()
    }
    const onCancel = () => {
        setVisible(false)
    }
    const onFinish = (elements)=>{
        DelMoveDeviceDoc({
            DocumentID: headerInfo.DocumentID,
            Note: elements.Note
        }).then(res => {
            if(res.RespCode === 0){
                notification.success('Đã hủy phiếu luân chuyển thiết bị thành công')
                setVisible(false)
                setMove(!getMov)
            }
        })
    }
    return (
        <div>
            <Modal visible={visible} onOk={onOk} onCancel={onCancel} title="Hủy phiếu luân chuyển thiết bị">
                <Form form={form} onFinish={onFinish}>
                    <Form.Item name="Note" label="Lý do" rules={[{
                        required: true,
                        message: 'Hãy nhập lý do'
                    }]}>
                        <Input.TextArea/>
                    </Form.Item>
                </Form>
            </Modal>
            <Tooltip placement='bottom' title="Hủy phiếu luân chuyển">
                <DeleteOutlined onClick={openModal} style={{ fontSize: 20, color: 'red' }}/>
            </Tooltip>
        </div>
    )
}

export default Cancel
