import moment from 'moment';
import React from 'react';
import { QRCode } from 'react-qr-svg';
import { DOMAIN } from '../../../config/URL/Qlts';
import { dateDisplay, statusApprove, timeDisplay } from '../../helper/mapping';
import { TitleReport } from '../../../config/URL/Qlts';
import { formatYear } from '../../../constants/DateTime';

const FormDeviceInfo = ({ infoDevice }) => {
  return (
    <div style={{ padding: '40px', fontFamily: '"Times New Roman"', lineHeight: '1.5em' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '30px' }}>
        <div style={{ width: 'max-content', textAlign: 'center' }}>
          <div>
            <strong style={{ textTransform: 'uppercase' }}>SỞ Y TẾ TỈNH ĐIỆN BIÊN</strong>
          </div>
          <div>
            <strong style={{ textTransform: 'uppercase' }}>{TitleReport}</strong>
          </div>
        </div>
        <div>
          <QRCode value={`${DOMAIN}/qr-code/${infoDevice.QRCode}`} width={60} />
        </div>
      </div>
      <div>
        <h2 style={{ textTransform: 'uppercase', textAlign: 'center', fontWeight: 'bold' }}>Lý lịch thiết bị</h2>
      </div>
      <div>
        <h3 style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>Phần 1: Thông tin chung</h3>
        <div>Tên thiết bị: {infoDevice.DeviceName}</div>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%' }}>Seria: {infoDevice.Seria}</div>
          <div style={{ width: '50%' }}>Model: {infoDevice.Model}</div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%' }}>Loại thiết bị: {infoDevice.Type}</div>
          <div style={{ width: '50%' }}>Đơn vị: {infoDevice.Unit}</div>
        </div>
        <div>Hãng sản xuất: {infoDevice.Manufacturer}</div>
        <div>Nước sản xuất: {infoDevice.Origin}</div>
        <div>Ngày mua/nhận: {dateDisplay(infoDevice.TimeImport, formatYear)}</div>
        <div>
          Thời gian bảo hành: từ {dateDisplay(infoDevice.WarrantyStart, formatYear)} đến{' '}
          {dateDisplay(infoDevice.WarrantyEnd, formatYear)}
        </div>
        <div>Khoa phòng sử dụng: {infoDevice.Location}</div>
        <div>Ghi chú: {infoDevice.Note}</div>
      </div>
      <div>
        <h3 style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>Phần 2: Lịch sử hoạt động</h3>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>STT</th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Loại hoạt động</th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Ngày tháng năm</th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  Sự cố kĩ thuật (nguyên nhân, thực trạng)
                </th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Mô tả hoạt động</th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Người xử lý</th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>SDT</th>
              </tr>
            </thead>
            <tbody>
              {infoDevice.HistoryActions.map((history, index) => (
                <tr>
                  <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }}>{index + 1}</td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }}>{history.Type}</td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }}>
                    {timeDisplay(history.TimeCreate)}
                  </td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }}>{history.Note}</td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }}>
                    {history.Status && statusApprove(history.Status).label}
                  </td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }} />
                  <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }}>
                    {history.Creater}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <i>
          <strong>Chú ý:</strong>
          <span>
            Cán bộ kỹ thuật thiết bị phải ký vào cột “Người xử lý” Sau khi đi kiểm tra sửa chữa/bảo dưỡng hoặc thay thế
            vật tư mới.
          </span>
        </i>
      </div>
      <div>
        <div style={{ paddingBottom: '20px', textAlign: 'end' }}>
          Điện Biên, ngày {moment().date()} tháng {moment().month() + 1} năm {moment().year()}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', textTransform: 'uppercase' }}>
          <h3 style={{ fontWeight: 'bold' }}>Phòng vật tư y tế</h3>
          <h3 style={{ fontWeight: 'bold' }}>Trưởng khoa/phòng</h3>
          <h3 style={{ fontWeight: 'bold' }}>Người theo dõi</h3>
        </div>
      </div>
    </div>
  );
};

export default FormDeviceInfo;
