import React, { useState } from "react";
import { Modal, Form, Input, message } from "antd";
import { ChangePassword } from "../../../api/User";
import { useHistory } from "react-router";

const ChangePass = () => {
  const [visible, setVisible] = useState(false);
  const [ loading, setLoading ] = useState(false)
  const [form] = Form.useForm();
  const history = useHistory()
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const onOk = () => {
    form.submit();
  };
  const onCancel = () => {
    setVisible(false);
  };
  const showModalEdit = () => {
    setVisible(true);
  };
  const onFinish = (values) => {
    if(values.PasswordNew === values.PasswordNew2){
      setLoading(true)
      ChangePassword(values).then(res => {
        setLoading(false)
        if(res.RespCode === 0){
          message.success('Đổi mật khẩu thành công')
          localStorage.clear()
          history.push('/')
          setVisible(false)
        }
      })
    }else{
      message.warning('Xác nhận mật khẩu không đúng')
    }
  };
  return (
    <div>
      <div className="modal-edit-pass">
        <Modal
          title="Chỉnh sửa mật khẩu"
          visible={visible}
          onOk={onOk}
          onCancel={onCancel}
          confirmLoading={loading}
        >
          <Form form={form} onFinish={onFinish} name="form-edit" {...layout}>
            <Form.Item name="Password" label="Mật khẩu cũ" rules={[
              { required: true, message: 'Không được để trống'}
            ]}>
              <Input />
            </Form.Item>
            <Form.Item name="PasswordNew" label="Mật khẩu mới" rules={[
              { required: true, message: 'Không được để trống'}
            ]}>
              <Input.Password />
            </Form.Item>
            <Form.Item name="PasswordNew2" label="Xác nhận mật khẩu" rules={[
              { required: true, message: 'Không được để trống'}
            ]}>
              <Input.Password />
            </Form.Item>
          </Form>
        </Modal>
      </div>
      <div onClick={showModalEdit}>
        <div className="change-pass" style={{ fontSize: 14 }}>
          Đổi mật khẩu
        </div>
      </div>
    </div>
  );
};
export default ChangePass;
