import moment from 'moment';
import React from 'react';
import { TitleReport } from '../../../../config/URL/Qlts';
import { dateDisplay } from '../../../helper/mapping';

const FormDevices = ({ contentReport }) => {
  return (
    <div style={{ padding: '40px', fontFamily: '"Times New Roman"', lineHeight: '1.5em' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '30px' }}>
        <div style={{ width: 'max-content', textAlign: 'center' }}>
          <div>
            <strong style={{ textTransform: 'uppercase' }}>SỞ Y TẾ TỈNH ĐIỆN BIÊN</strong>
          </div>
          <div>
            <strong style={{ textTransform: 'uppercase' }}>{TitleReport}</strong>
          </div>
          <div>
            <strong style={{ textTransform: 'uppercase' }}>Phòng ban: {localStorage.getItem('groupName')}</strong>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center' }}>
        <h2 style={{ textTransform: 'uppercase', fontWeight: 'bold', paddingTop: 20 }}>
          Báo cáo danh sách thiết bị đã được kiểm kê
        </h2>
      </div>
      <div>
        <div style={{ textTransform: 'uppercase' }}>
          <strong>I. Thành phần kiểm tra gồm</strong>
        </div>
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%' }}>- Ông bà:</div>
            <div style={{ width: '50%' }}>Chức vụ:</div>
          </div>
        </div>
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%' }}>- Ông bà:</div>
            <div style={{ width: '50%' }}>Chức vụ:</div>
          </div>
        </div>
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%' }}>- Ông bà:</div>
            <div style={{ width: '50%' }}>Chức vụ:</div>
          </div>
        </div>
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%' }}>- Ông bà:</div>
            <div style={{ width: '50%' }}>Chức vụ:</div>
          </div>
        </div>
        <div style={{ textTransform: 'uppercase' }}>
          <strong>II. Danh sách thiết bị</strong>
        </div>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>STT</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Tên thiết bị</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Serial</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Model</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Giá trị ban đầu</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Số lượng</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Đơn vị</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Xuất xứ</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Nước sản xuất</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Năm sản xuất</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Năm đưa vào sử dụng</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Nguồn hàng</th>
                <th style={{ padding: '8px', border: '1px solid #ddd', width: 100 }}>Giá trị hiện tại</th>
                <th style={{ padding: '8px', border: '1px solid #ddd', width: 40 }}>SL</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>TG kiểm</th>
                <th style={{ padding: '8px', border: '1px solid #ddd', width: 100 }}>Ghi chú</th>
              </tr>
            </thead>
            <tbody>
              {contentReport &&
                contentReport.map((device, index) => (
                  <tr>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{index + 1}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{device.DeviceName}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd', wordBreak: 'break-all' }}>
                      {device.Serial}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{device.Model}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{device.DeviceValue}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>1</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{device.Unit}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{device.Manufacturer}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{device.Origin}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{device.TimeImport}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{device.Note}</td>
                    <th style={{ padding: '8px', border: '1px solid #ddd' }}></th>
                    <th style={{ padding: '8px', border: '1px solid #ddd' }}></th>
                    <th style={{ padding: '8px', border: '1px solid #ddd' }}>{dateDisplay(device.TimeManufacturer)}</th>
                    <th style={{ padding: '8px', border: '1px solid #ddd' }}>{dateDisplay(device.TimeCreate)}</th>
                    <th style={{ padding: '8px', border: '1px solid #ddd' }}></th>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <div style={{ paddingBottom: '20px', textAlign: 'end', paddingTop: 10 }}>
          Điện Biên, ngày {moment().date()} tháng {moment().month() + 1} năm {moment().year()}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 0 }}>Đại diện phòng sử dụng</h3>
            <i>(Ký, họ tên)</i>
          </div>
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 0 }}>Đại diện kế toán</h3>
            <i>(Ký, họ tên)</i>
          </div>
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 0 }}>Phòng vật tư y tế</h3>
            <i>(Ký, họ tên)</i>
          </div>
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 0 }}>Trưởng ban kiểm tra</h3>
            <i>(Ký, họ tên)</i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormDevices;
