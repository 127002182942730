import { PrinterOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import FormReport from './FromReport';

class ComponentToPrint extends React.Component {
    render() {
        return (
            <div className='report'>
                <FormReport infoReport = {this.props.info} censers={this.props.censers}/>
            </div>
        );
    }
}
const FormRepair = ({infoPrint}) => {
    const getCenser = []
    const value = useRef();
    return (
        <div style={{ alignSelf:'center', paddingLeft: 10 }}>
            <div style={{ display: "none" }}>
                <ComponentToPrint ref={value} info={infoPrint} censers={getCenser}/>
            </div>
            <ReactToPrint
                documentTitle="Giao nhận sửa chữa thiết bị, công cụ, dụng cụ"
                trigger={() => <div>
                    <Tooltip title="In phiếu liên hệ bảo dưỡng" placement='bottom'>
                        <PrinterOutlined style={{ fontSize: 20, cursor: 'pointer' }}/>
                    </Tooltip>
                </div>}
                content={() => value.current}
            >
            </ReactToPrint>
        </div>
    )
}

export default FormRepair
