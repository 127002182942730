import { useEffect, useState } from "react";
import { GetOrganizationJoinByUser } from "../../../api/TSOrganization";
import mapLstTable from "../../helper/mapLstTable";
export default function GetGroupList() {
  const [groups, setGroups] = useState();
  useEffect(() => {
    const fetchData = async () => {
      GetOrganizationJoinByUser({
        Search: "",
        NumberRow: "",
        PageNumber: 1
      }).then(res => {
        setGroups(mapLstTable(res.OrganizationLst))
      })
    };
    fetchData();
  }, []);
  return groups;
}
