import moment from 'moment';
import React from 'react';
import { TitleReport } from '../../../config/URL/Qlts';
import { formatYear } from '../../../constants/DateTime';
import { dateDisplay, statusApprove, typeSchedule } from '../../helper/mapping';

const FormApproveMaintance = ({ infoMaintance, censor = [], deviceLst = [] }) => {
  return (
    <div style={{ padding: '40px', fontFamily: '"Times New Roman"', lineHeight: '1.5em' }}>
      <div style={{ textAlign: 'end' }}>
        <span>Mã phiếu: {infoMaintance && infoMaintance.DocumentCode}</span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '30px' }}>
        <div style={{ width: 'max-content', textAlign: 'center' }}>
          <div>
            <strong style={{ textTransform: 'uppercase' }}>SỞ Y TẾ TỈNH ĐIỆN BIÊN</strong>
          </div>
          <div>
            <strong style={{ textTransform: 'uppercase' }}>{TitleReport}</strong>
          </div>
          <div>
            <strong style={{ textTransform: 'uppercase' }}>Phòng ban: {localStorage.getItem('groupName')}</strong>
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <div style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Cộng hòa xã hội chủ nghĩa việt nam</div>
          <div style={{ fontWeight: 'bold' }}>Độc lập - Tự do - Hạnh phúc</div>
        </div>
      </div>
      <div style={{ textAlign: 'center' }}>
        <h2 style={{ textTransform: 'uppercase', fontWeight: 'bold', paddingTop: 10 }}>
          Phiếu theo dõi/bảo dưỡng thiết bị
        </h2>
      </div>
      <div>
        <div style={{ textTransform: 'uppercase' }}>
          <strong>I. Thành phần kiểm tra gồm</strong>
        </div>
        <div>
          {censor.map((cen, index) => (
            <div key={index} style={{ display: 'flex' }}>
              <div style={{ width: '30%' }}>- Ông bà: {cen.FullName}</div>
              <div style={{ width: '40%' }}>Chức vụ: {cen.Position}</div>
              <div style={{ width: '30%' }}>{cen.Department}</div>
            </div>
          ))}
        </div>
        <div style={{ textTransform: 'uppercase' }}>
          <strong>II. Nội dung kiểm tra</strong>
        </div>
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%' }}>
              <div>Người tạo: {infoMaintance && infoMaintance.CreaterName}</div>
              <div>Loại: {infoMaintance && typeSchedule(parseInt(infoMaintance.Note)).label}</div>
              <div>Thời gian bắt đầu: {infoMaintance && dateDisplay(infoMaintance.TimeStart)}</div>
            </div>
            <div style={{ width: '50%' }}>
              <div>Người Xác nhận: {infoMaintance && infoMaintance.ApproveName}</div>
              <div>Thời gian Xác nhận: {infoMaintance && dateDisplay(infoMaintance.ApproveTime)}</div>
              <div>Ghi chú Xác nhận: {infoMaintance && infoMaintance.ApproveNote}</div>
            </div>
          </div>
          <div>Mô tả: {infoMaintance && infoMaintance.Description}</div>
          <div style={{ fontSize: 16 }}>
            <strong>Trạng thái:</strong>
            <strong style={{ color: statusApprove(infoMaintance && infoMaintance.Status).color }}>
              {' '}
              {statusApprove(infoMaintance && infoMaintance.Status).label}
            </strong>
          </div>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Tên thiết bị</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Serial</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Model</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Giá trị</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Số lượng</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Đơn vị</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Nguồn gốc</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Năm đưa vào sử dụng</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Ghi chú</th>
              </tr>
            </thead>
            <tbody>
              {infoMaintance.DeviceLst &&
                infoMaintance.DeviceLst.map((device) => (
                  <tr key={device.DeviceId}>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{device.DeviceName}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd', wordBreak: 'break-all' }}>
                      {device.Serial}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{device.Model}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{device.DeviceValue}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>1</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{device.Unit}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{device.Orgigin}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                      {dateDisplay(device.TimeStart, formatYear)}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{device.Note}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <i>
          <strong>Chú ý:</strong>
          <span>
            Cán bộ kỹ thuật thiết bị phải ký vào cột “Người xử lý” Sau khi đi kiểm tra sửa chữa/bảo dưỡng hoặc thay thế
            vật tư mới.
          </span>
        </i>
      </div>
      <div>
        <div style={{ paddingBottom: '20px', textAlign: 'end' }}>
          Điện Biên, ngày {moment().date()} tháng {moment().month() + 1} năm {moment().year()}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 0 }}>Đại diện phòng</h3>
            <i>(Ký, họ tên)</i>
          </div>
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 0 }}>Đại diện kế toán</h3>
            <i>(Ký, họ tên)</i>
          </div>
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 0 }}>Phòng vật tư y tế</h3>
            <i>(Ký, họ tên)</i>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', paddingTop: '90px' }}>
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 0 }}>Kỹ sư kiểm tra</h3>
            <i>(Ký, họ tên)</i>
          </div>
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 0 }}>Người tạo báo cáo</h3>
            <i>(Ký, họ tên)</i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormApproveMaintance;
