import React from "react";
import ContextAuth from "../ContextAuth";
import ForgotPass from "../ForgotPass";
const DisplayForgotPass = () => {
  return (
    <div>
      <ContextAuth>
        <ForgotPass />
      </ContextAuth>
    </div>
  );
};
export default DisplayForgotPass;
