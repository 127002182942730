import { Button, Tooltip } from "antd";
import React, { useRef } from "react";
import {PrinterOutlined} from '@ant-design/icons'
import ReactToPrint from "react-to-print";
import FormOffer from './formOffer';
class ComponentToPrint extends React.Component {
    render() {
        return (
            <div className='report'>
                {this.props.infoDocument && <FormOffer infoDocument={this.props.infoDocument}></FormOffer>}
            </div>
        );
    }
}

const PrintFormOffer = ({ infoDocument }) => {
    const valueQrCode = useRef();
    return (
        <div style={{ paddingLeft: 10 }}>
            <div style={{ display: "none" }}>
                <ComponentToPrint ref={valueQrCode} infoDocument={infoDocument} />
            </div>
            <ReactToPrint
                documentTitle="Đề xuất thiết bị mới"
                trigger={() => <div type="primary"> 
                        <Tooltip placement='bottom' title="In phiếu đề xuất">
                            <Button>
                                <PrinterOutlined style={{ cursor:'pointer', fontSize: 20 }}/> In phiếu
                            </Button>
                        </Tooltip>
                    </div>
                }
                content={() => valueQrCode.current}
            />
        </div>
    );
};
export default PrintFormOffer;
