import React, { Suspense } from 'react';
import { SemipolarLoading } from 'react-loadingg';
import DashBoard from '../Dashboard';
import { NavBar } from '../NavBar/NavBar';
import { useEffect } from 'react';
import { VerifyAdmin } from '../../api/Admin';
import { companyID } from '../../config/URL/Qlts';
import { useState } from 'react';
const Home = (props) => {
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    const verifyAdmin = async () => {
      const adminResponse = await VerifyAdmin({
        CompanyId: companyID,
      });
      setIsAdmin(adminResponse.RespCode === 0);
    };
    verifyAdmin();
  }, []);
  return (
    <Suspense fallback={<SemipolarLoading />}>
      <div id="navbar-conatiner">
        <NavBar isAdmin={isAdmin} />
      </div>
      <div>
        <DashBoard />
      </div>
    </Suspense>
  );
};
export default Home;
