import baseURL from "./BaseURL";
const AuthAPI = {
  login: () => {
    return `${baseURL()}/User/UserLogin`;
  },
  register: () => {
    return `${baseURL()}/User/UserRegister`;
  },
  forgotPass: () => {
    return `${baseURL()}/User/LotPassword`;
  },
  checkOTP: () => {
    return `${baseURL()}/User/CheckOTPLostPassword`;
  },
  changePass: () => {
    return `${baseURL()}/User/ChangePassword`;
  },
  updateInfo: () => {
    return `${baseURL()}/User/UpdateUserInfo`;
  },
  getInfoUser: () => {
    return `${baseURL()}/User/GetUserInfo`;
  },
};
export default AuthAPI;
