import moment from 'moment';
import React from 'react';
import { TitleReport } from '../../../config/URL/Qlts';

const FromReport = ({ infoReport }) => {
  const day = moment(infoReport.TimeCreate).format('DD');
  const month = moment(infoReport.TimeCreate).format('MM');
  const year = moment(infoReport.TimeCreate).format('YYYY');
  return (
    <div style={{ padding: '40px' }}>
      <p style={{ marginTop: '0pt', marginBottom: '8pt', lineHeight: '108%', fontSize: '11pt' }}>
        <span style={{ fontFamily: '"Times New Roman"' }}>Đơn vị: {TitleReport}</span>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '8pt', lineHeight: '108%', fontSize: '11pt' }}>
        <span style={{ fontFamily: '"Times New Roman"' }}>Phòng ban: {localStorage.getItem('groupName')} </span>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '8pt', textAlign: 'center', lineHeight: '108%', fontSize: '15pt' }}>
        <strong>
          <span style={{ fontFamily: '"Times New Roman"' }}>PHIẾU GIAO NHẬN SỬA CHỮA VẬT TƯ, CÔNG CỤ, DỤNG CỤ</span>
        </strong>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '8pt', textAlign: 'center', lineHeight: '108%', fontSize: '11pt' }}>
        <span style={{ fontFamily: '"Times New Roman"' }}>
          Ngày {day} tháng {month} năm {year}
        </span>
      </p>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0' }}>
        <div>Người giao: {infoReport.CreaterName}</div>
        <div>Phòng ban giao: {localStorage.getItem('groupName')} </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0' }}>
        <div>Người nhận: {infoReport.ReceiveName}</div>
        <div>
          Địa chỉ nhận: {infoReport.ReceiveAddress} - {infoReport.ReceivePhone}
        </div>
      </div>
      <p style={{ marginTop: '0pt', lineHeight: '108%', marginBottom: 5, fontSize: '11pt' }}>
        <span style={{ fontFamily: '"Times New Roman"' }}>Mô tả:&nbsp;</span>
      </p>
      {infoReport.Status === 2 && (
        <div style={{ paddingBottom: 10 }}>
          <div>
            Trạng thái: <strong> Đã xác nhận nghiệm thu </strong>
          </div>
          <div>Người xác nhận: {infoReport.ApproveID}</div>
          <div>Nội dung xác nhận: {infoReport.ApproveNote}</div>
        </div>
      )}
      <table
        cellPadding={0}
        cellSpacing={0}
        style={{ width: '100%', border: '0.75pt solid #000000', borderCollapse: 'collapse' }}
      >
        <tbody>
          <tr style={{ height: '68.8pt' }}>
            <td
              style={{
                width: '20pt',
                borderRightStyle: 'solid',
                borderRightWidth: '0.75pt',
                borderBottomStyle: 'solid',
                borderBottomWidth: '0.75pt',
                paddingRight: '5.03pt',
                paddingLeft: '5.03pt',
                verticalAlign: 'middle',
              }}
            >
              <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                <strong>
                  <span style={{ fontFamily: '"Times New Roman"' }}>STT</span>
                </strong>
              </p>
            </td>
            <td
              style={{
                width: '120pt',
                borderRightStyle: 'solid',
                borderRightWidth: '0.75pt',
                borderLeftStyle: 'solid',
                borderLeftWidth: '0.75pt',
                borderBottomStyle: 'solid',
                borderBottomWidth: '0.75pt',
                paddingRight: '5.03pt',
                paddingLeft: '5.03pt',
                verticalAlign: 'middle',
              }}
            >
              <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                <strong>
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Tên nhãn hiệu, quy cách vật liệu, công cụ, dụng cụ
                  </span>
                </strong>
              </p>
            </td>
            <td
              style={{
                width: '36.6pt',
                borderRightStyle: 'solid',
                borderRightWidth: '0.75pt',
                borderLeftStyle: 'solid',
                borderLeftWidth: '0.75pt',
                borderBottomStyle: 'solid',
                borderBottomWidth: '0.75pt',
                paddingRight: '5.03pt',
                paddingLeft: '5.03pt',
                verticalAlign: 'middle',
              }}
            >
              <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                <strong>
                  <span style={{ fontFamily: '"Times New Roman"' }}>Model</span>
                </strong>
              </p>
            </td>
            <td
              style={{
                width: '37.95pt',
                borderRightStyle: 'solid',
                borderRightWidth: '0.75pt',
                borderLeftStyle: 'solid',
                borderLeftWidth: '0.75pt',
                borderBottomStyle: 'solid',
                borderBottomWidth: '0.75pt',
                paddingRight: '5.03pt',
                paddingLeft: '5.03pt',
                verticalAlign: 'middle',
              }}
            >
              <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                <strong>
                  <span style={{ fontFamily: '"Times New Roman"' }}>Serial</span>
                </strong>
              </p>
            </td>
            <td
              style={{
                width: '49.65pt',
                borderRightStyle: 'solid',
                borderRightWidth: '0.75pt',
                borderLeftStyle: 'solid',
                borderLeftWidth: '0.75pt',
                borderBottomStyle: 'solid',
                borderBottomWidth: '0.75pt',
                paddingRight: '5.03pt',
                paddingLeft: '5.03pt',
                verticalAlign: 'middle',
              }}
            >
              <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                <strong>
                  <span style={{ fontFamily: '"Times New Roman"' }}>Phòng ban (cá nhân) sử dụng</span>
                </strong>
              </p>
            </td>
            <td
              style={{
                width: '31.9pt',
                borderRightStyle: 'solid',
                borderRightWidth: '0.75pt',
                borderLeftStyle: 'solid',
                borderLeftWidth: '0.75pt',
                borderBottomStyle: 'solid',
                borderBottomWidth: '0.75pt',
                paddingRight: '5.03pt',
                paddingLeft: '5.03pt',
                verticalAlign: 'middle',
              }}
            >
              <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                <strong>
                  <span style={{ fontFamily: '"Times New Roman"' }}>ĐVT</span>
                </strong>
              </p>
            </td>
            <td
              style={{
                width: '28.5pt',
                borderRightStyle: 'solid',
                borderRightWidth: '0.75pt',
                borderLeftStyle: 'solid',
                borderLeftWidth: '0.75pt',
                borderBottomStyle: 'solid',
                borderBottomWidth: '0.75pt',
                paddingRight: '5.03pt',
                paddingLeft: '5.03pt',
                verticalAlign: 'middle',
              }}
            >
              <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                <strong>
                  <span style={{ fontFamily: '"Times New Roman"' }}>Số lượng</span>
                </strong>
              </p>
            </td>
            <td
              style={{
                width: '36.55pt',
                borderRightStyle: 'solid',
                borderRightWidth: '0.75pt',
                borderLeftStyle: 'solid',
                borderLeftWidth: '0.75pt',
                borderBottomStyle: 'solid',
                borderBottomWidth: '0.75pt',
                paddingRight: '5.03pt',
                paddingLeft: '5.03pt',
                verticalAlign: 'middle',
              }}
            >
              <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                <strong>
                  <span style={{ fontFamily: '"Times New Roman"' }}>Đơn giá</span>
                </strong>
              </p>
            </td>
            <td
              style={{
                width: '42.3pt',
                borderRightStyle: 'solid',
                borderRightWidth: '0.75pt',
                borderLeftStyle: 'solid',
                borderLeftWidth: '0.75pt',
                borderBottomStyle: 'solid',
                borderBottomWidth: '0.75pt',
                paddingRight: '5.03pt',
                paddingLeft: '5.03pt',
                verticalAlign: 'middle',
              }}
            >
              <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                <strong>
                  <span style={{ fontFamily: '"Times New Roman"' }}>Thành tiền</span>
                </strong>
              </p>
            </td>
            <td
              style={{
                width: '34.75pt',
                borderLeftStyle: 'solid',
                borderLeftWidth: '0.75pt',
                borderBottomStyle: 'solid',
                borderBottomWidth: '0.75pt',
                paddingRight: '5.03pt',
                paddingLeft: '5.03pt',
                verticalAlign: 'middle',
              }}
            >
              <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                <strong>
                  <span style={{ fontFamily: '"Times New Roman"' }}>Ghi chú</span>
                </strong>
              </p>
            </td>
          </tr>
          {infoReport &&
            infoReport.DeviceLst.map((device, index) => (
              <tr style={{ height: '13.55pt' }}>
                <td
                  style={{
                    width: '20pt',
                    borderTopStyle: 'solid',
                    borderTopWidth: '0.75pt',
                    borderRightStyle: 'solid',
                    borderRightWidth: '0.75pt',
                    paddingRight: '5.03pt',
                    paddingLeft: '5.03pt',
                    verticalAlign: 'middle',
                  }}
                >
                  <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                    <span style={{ fontFamily: '"Times New Roman"' }}>{index + 1}</span>
                  </p>
                </td>
                <td
                  style={{
                    width: '75pt',
                    borderTopStyle: 'solid',
                    borderTopWidth: '0.75pt',
                    borderRightStyle: 'solid',
                    borderRightWidth: '0.75pt',
                    borderLeftStyle: 'solid',
                    borderLeftWidth: '0.75pt',
                    paddingRight: '5.03pt',
                    paddingLeft: '5.03pt',
                    verticalAlign: 'middle',
                  }}
                >
                  <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                    <span style={{ fontFamily: '"Times New Roman"' }}>{device.DeviceName}</span>
                  </p>
                </td>
                <td
                  style={{
                    width: '36.6pt',
                    borderTopStyle: 'solid',
                    borderTopWidth: '0.75pt',
                    borderRightStyle: 'solid',
                    borderRightWidth: '0.75pt',
                    borderLeftStyle: 'solid',
                    borderLeftWidth: '0.75pt',
                    paddingRight: '5.03pt',
                    paddingLeft: '5.03pt',
                    verticalAlign: 'middle',
                  }}
                >
                  <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                    <span style={{ fontFamily: '"Times New Roman"' }}>{device.Model}</span>
                  </p>
                </td>
                <td
                  style={{
                    width: '37.95pt',
                    borderTopStyle: 'solid',
                    borderTopWidth: '0.75pt',
                    borderRightStyle: 'solid',
                    borderRightWidth: '0.75pt',
                    borderLeftStyle: 'solid',
                    borderLeftWidth: '0.75pt',
                    paddingRight: '5.03pt',
                    paddingLeft: '5.03pt',
                    verticalAlign: 'middle',
                  }}
                >
                  <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                    <span style={{ fontFamily: '"Times New Roman"', wordBreak: 'break-all' }}>{device.Serial}</span>
                  </p>
                </td>
                <td
                  style={{
                    width: '49.65pt',
                    borderTopStyle: 'solid',
                    borderTopWidth: '0.75pt',
                    borderRightStyle: 'solid',
                    borderRightWidth: '0.75pt',
                    borderLeftStyle: 'solid',
                    borderLeftWidth: '0.75pt',
                    paddingRight: '5.03pt',
                    paddingLeft: '5.03pt',
                    verticalAlign: 'middle',
                  }}
                >
                  <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                    <span style={{ fontFamily: '"Times New Roman"' }}>{localStorage.getItem('groupName')}</span>
                  </p>
                </td>
                <td
                  style={{
                    width: '31.9pt',
                    borderTopStyle: 'solid',
                    borderTopWidth: '0.75pt',
                    borderRightStyle: 'solid',
                    borderRightWidth: '0.75pt',
                    borderLeftStyle: 'solid',
                    borderLeftWidth: '0.75pt',
                    paddingRight: '5.03pt',
                    paddingLeft: '5.03pt',
                    verticalAlign: 'middle',
                  }}
                >
                  <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                    <span style={{ fontFamily: '"Times New Roman"' }}>{device.Unit}</span>
                  </p>
                </td>
                <td
                  style={{
                    width: '28.5pt',
                    borderTopStyle: 'solid',
                    borderTopWidth: '0.75pt',
                    borderRightStyle: 'solid',
                    borderRightWidth: '0.75pt',
                    borderLeftStyle: 'solid',
                    borderLeftWidth: '0.75pt',
                    paddingRight: '5.03pt',
                    paddingLeft: '5.03pt',
                    verticalAlign: 'middle',
                  }}
                >
                  <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                    <span style={{ fontFamily: '"Times New Roman"' }}>1</span>
                  </p>
                </td>
                <td
                  style={{
                    width: '36.55pt',
                    borderTopStyle: 'solid',
                    borderTopWidth: '0.75pt',
                    borderRightStyle: 'solid',
                    borderRightWidth: '0.75pt',
                    borderLeftStyle: 'solid',
                    borderLeftWidth: '0.75pt',
                    paddingRight: '5.03pt',
                    paddingLeft: '5.03pt',
                    verticalAlign: 'middle',
                  }}
                >
                  <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                    <span style={{ fontFamily: '"Times New Roman"' }}>{device.DeviceValue}</span>
                  </p>
                </td>
                <td
                  style={{
                    width: '42.3pt',
                    borderTopStyle: 'solid',
                    borderTopWidth: '0.75pt',
                    borderRightStyle: 'solid',
                    borderRightWidth: '0.75pt',
                    borderLeftStyle: 'solid',
                    borderLeftWidth: '0.75pt',
                    paddingRight: '5.03pt',
                    paddingLeft: '5.03pt',
                    verticalAlign: 'middle',
                  }}
                >
                  <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                    <span style={{ fontFamily: '"Times New Roman"' }}>{device.DeviceValue}</span>
                  </p>
                </td>
                <td
                  style={{
                    width: '34.75pt',
                    borderTopStyle: 'solid',
                    borderTopWidth: '0.75pt',
                    borderLeftStyle: 'solid',
                    borderLeftWidth: '0.75pt',
                    paddingRight: '5.03pt',
                    paddingLeft: '5.03pt',
                    verticalAlign: 'middle',
                  }}
                >
                  <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                    <span style={{ fontFamily: '"Times New Roman"' }}>{device.Note}</span>
                  </p>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <p style={{ marginTop: '0pt', marginBottom: '8pt', lineHeight: '108%', fontSize: '11pt' }}>
        <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;</span>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '8pt', textAlign: 'right', lineHeight: '108%', fontSize: '11pt' }}>
        <span style={{ fontFamily: '"Times New Roman"' }}>
          Ngày {moment().format('DD')} tháng {moment().format('MM')} năm {moment().format('YYYY')}
        </span>
      </p>
      <div style={{ display: 'flex', justifyContent: 'space-between', fontFamily: '"Times New Roman"' }}>
        <div style={{ textAlign: 'center' }}>
          <h3 style={{ marginBottom: '5px', fontWeight: 'bold' }}>Người giao</h3>
          <i>(Ký, họ tên)</i>
          <div style={{ paddingTop: 120 }}>{infoReport.CreaterName}</div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <h3 style={{ marginBottom: '5px', fontWeight: 'bold' }}>Người nhận</h3>
          <i>(Ký, họ tên)</i>
        </div>
        <div style={{ textAlign: 'center' }}>
          <h3 style={{ marginBottom: '5px', fontWeight: 'bold' }}>Thủ trưởng đơn vị</h3>
          <i>(Ký, họ tên, đóng dấu)</i>
        </div>
      </div>
    </div>
  );
};

export default FromReport;
