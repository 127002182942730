import React from "react";
import { Form, Input, Button } from "antd";
import "./ConfirmOTP.scss";
import { useHistory } from "react-router-dom";
const formItemLayout = {
  labelCol: {
    xs: {
      span: 6,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 0,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 8,
      offset: 0,
    },
    sm: {
      span: 8,
      offset: 8,
    },
  },
};
const ConfirmOTP = () => {
  var history = useHistory();
  const onFinish = (values) => {
    history.push("/dashboard");
  };
  return (
    <div className="confirm-otp">
      <Form
        {...formItemLayout}
        name="onFinish"
        onFinish={onFinish}
        offsetTop={240}
      >
        <Form.Item
          name="otp"
          label="OTP"
          rules={[
            {
              required: true,
              message: "Hãy nhập OTP đã được gửi về email của bạn",
            },
          ]}
        >
          <Input autoFocus />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Xác nhận OTP
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default ConfirmOTP;
