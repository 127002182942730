import React, { useState } from 'react'
import { Form, Input, Modal, Button, message } from 'antd'
import { ApproveOfferDeviceHeaderByID } from '../../../../../../api/DeviceOffer'
import { manageOfferDevice } from '../../../../../../config/Recoil'
import { useRecoilState } from 'recoil'
const ApproveOffer = ({headerInfo}) => {
    const [visible, setVisible] = useState(false)
    const [getOffer, setOffer] = useRecoilState(manageOfferDevice)
    const [form] = Form.useForm()
    const openModal = ()=>{
        setVisible(true)
    }
    const onOk = () => {
        form.submit()
    }
    const onCancel = () => {
        setVisible(false)
    }
    const onFinish = (elements)=>{
        ApproveOfferDeviceHeaderByID({
            DocumentID: headerInfo.DocumentID,
            Note: elements.Note
        }).then(res => {
            if(res.RespCode === 0){
                message.success('Duyệt phiếu đề xuất thành công')
                setOffer(!getOffer)
                setVisible(false)
            }
        })
    }
    return (
        <div>
            <Modal visible={visible} onOk={onOk} onCancel={onCancel} title="Xác nhận phiếu đề xuất">
                <Form form={form} onFinish={onFinish}>
                    <Form.Item name="Note" label="Ghi chú" rules={[{
                        required: true,
                        message: 'Không được để trống'
                    }]}>
                        <Input.TextArea/>
                    </Form.Item>
                </Form>
            </Modal>
            <Button type='dashed' onClick={openModal}>Xác nhận</Button>
        </div>
    )
}

export default ApproveOffer
