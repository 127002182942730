import React from 'react';
import './NotFound.scss';
import pictureNotFound from '../../../assets/images/notfound-1.jpg';
import { Button } from 'antd';
import { useHistory } from 'react-router';
const NotFound = () => {
  const history = useHistory();
  return (
    <div className="not_found">
      <div>
        <img src={pictureNotFound} alt="Ảnh không tìm thấy yêu cầu" />
        <div className="btn_back">
          <Button type="dashed" onClick={() => history.goBack()}>
            Quay về trang trước
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
