import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Input, notification, Table, Tag } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { CreateInventoryDoc } from '../../../../api/TSInventoryDoc';
import { deviceInventory } from '../../../../config/Recoil';
import { getFullName, getGroupId, getGroupName, getUserName } from '../../../../helpers/auth';
import { AddKeyToLst } from '../../../helper/addKeyToLst';
import { statusDeviceInventory } from '../../../helper/mapping';
import ReaderQR from '../ReadQr';

const CreateInventory = () => {
  const [visible, setVisible] = useState(false);
  const [device, setDevice] = useState(null);
  const getLstDevice = useRecoilValue(deviceInventory);
  const resetLstDevice = useResetRecoilState(deviceInventory);
  const [lst, setLst] = useState([]);
  const [form] = Form.useForm();
  const getInfoDevice = (data) => {
    setDevice(data);
    setLst([...lst, data]);
  };
  const onOke = () => {
    form.submit();
  };
  const openModal = () => {
    setVisible(true);
  };
  const createInventory = (elements) => {
    const req = {
      CreaterID: getUserName(),
      CreaterName: getFullName(),
      OrganizationID: parseInt(getGroupId()),
      OrganizationName: getGroupName(),
      ...elements,
      InventoryLineLst: getLstDevice.map((device) => {
        return {
          ...device,
          DeviceID: device.DeviceId,
        };
      }),
    };
    CreateInventoryDoc({ InventoryHeaderInfo: req }).then((res) => {
      if (res.RespCode === 0) {
        notification.open({
          message: 'Thành công',
          description: 'Đã tạo phiếu kiểm kê thiết bị',
          duration: 1,
        });
        setVisible(false);
        setLst([]);
      }
    });
  };
  const deleteDevice = (deviceId, indexRow) => {
    lst.splice(indexRow, 1);
    setLst([...lst]);
  };
  const columns = [
    {
      clasName: 'key',
      title: 'STT',
      dataIndex: 'key',
      key: 'key',
    },
    // {
    //     clasName: 'CreaterName',
    //     title: 'Tên người tạo',
    //     dataIndex: 'CreaterName',
    //     key: 'CreaterName'
    // },
    {
      clasName: 'DeviceName',
      title: 'Tên TB',
      dataIndex: 'DeviceName',
      key: 'DeviceName',
    },
    // {
    //     clasName: 'DeviceValue',
    //     title: 'Giá trị',
    //     dataIndex: 'DeviceValue',
    //     key: 'DeviceValue'
    // },
    {
      clasName: 'Location',
      title: 'Phòng ban',
      dataIndex: 'Location',
      key: 'Location',
    },
    // {
    //     title: 'TG bắt đầu',
    //     dataIndex: 'TimeStart',
    //     key: 'TimeStart'
    // },
    {
      title: 'Hạn sử dụng',
      dataIndex: 'TimeEnd',
      key: 'TimeEnd',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'StatusWork',
      key: 'StatusWork',
      render: (record) => {
        return <Tag color={statusDeviceInventory(record).color}>{statusDeviceInventory(record).label}</Tag>;
      },
    },
    {
      clasName: 'NoteChek',
      title: 'Mô tả',
      dataIndex: 'NoteChek',
      key: 'NoteChek',
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (record, indexRow) => {
        return (
          <DeleteOutlined
            style={{ fontSize: 22, color: 'red' }}
            onClick={() => deleteDevice(record.DeviceId, indexRow)}
          />
        );
      },
    },
  ];
  return (
    <div style={{ width: '100%' }}>
      <Modal
        visible={visible}
        title="Tạo phiếu kiểm kê thiết bị"
        name="create_inventory"
        onCancel={() => {
          setVisible(false);
        }}
        onOk={onOke}
      >
        <Form form={form} name="info_create" onFinish={createInventory}>
          <Form.Item
            label="Ghi chú"
            name="Comment"
            rules={[
              {
                required: true,
                message: 'Hãy nhập ghi chú',
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
      {/* <Space style={{marginBottom: 20}}> */}
      <div style={{ paddingLeft: 10 }}>
        <ReaderQR resInfoDevice={getInfoDevice} deviceLst={lst} />
        <Button type="primary" onClick={openModal} style={{ marginTop: 10 }} disabled={lst.length === 0}>
          Tạo phiếu kiểm kê
        </Button>
      </div>
      {/* </Space> */}
      <Table dataSource={AddKeyToLst(lst)} columns={columns} pagination={false}></Table>
    </div>
  );
};

export default CreateInventory;
