import { Button, Form, Input, Modal, Table, message } from 'antd';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  ApproveDeviceMaintenanceSchedule,
  ApproveMaintenanceSchedule,
  GetMaintenanceScheduleByID,
} from '../../../../api/TSMaintenance';
import { manageDeviceError } from '../../../../config/Recoil';
import { AddKeyToLst } from '../../../helper/addKeyToLst';
const columns = [
  {
    title: 'Tên thiết bị',
    dataIndex: 'DeviceName',
    key: 'DeviceName',
  },
  {
    title: 'Mã thiết bị',
    dataIndex: 'DeviceCode',
    key: 'DeviceCode',
  },
  {
    title: 'Tên thiết bị',
    dataIndex: 'DeviceName',
    key: 'DeviceName',
  },
  {
    title: 'Serial',
    dataIndex: 'Serial',
    key: 'Serial',
  },
  {
    title: 'Model',
    dataIndex: 'Model',
    key: 'Model',
  },
];
const ApproveDivide = ({ headerInfo }) => {
  const [visible, setVisible] = useState(false);
  const [getHeaderDivide, setHeaderDivide] = useRecoilState(manageDeviceError);
  const [devices, setDivices] = useState([]);
  const [infoDivide, setInfoDivide] = useState({});
  const [devicesSelected, setDivicesSelected] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [form] = Form.useForm();
  const initForm = () => {
    setSelectedRowKeys([]);
    form.resetFields();
  };
  const openModal = async () => {
    setVisible(true);
    initForm();
    const response = await GetMaintenanceScheduleByID({
      DocumentID: headerInfo.DocumentID,
    });
    setDivices(AddKeyToLst(response.MaintenanceScheduleInfo.DeviceLst));
    setInfoDivide(response.MaintenanceScheduleInfo);
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDivicesSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.Status === 3,
    }),
  };
  const onOk = () => {
    form.submit();
  };
  const onCancel = () => {
    setVisible(false);
  };
  const onFinish = async (elements) => {
    await ApproveDeviceMaintenanceSchedule({
      DocumentID: headerInfo.DocumentID,
      DeviceLst: devicesSelected,
    });
    message.success('Đã xác nhận thành công');
    setVisible(false);
    setHeaderDivide(!getHeaderDivide);
    // ApproveMaintenanceSchedule({
    //   DocumentID: headerInfo.DocumentID,
    //   Note: elements.Note,
    // }).then((res) => {
    //   if (res.RespCode === 0) {
    //     message.success('Đã xác nhận thành công');
    //     setVisible(false);
    //     setHeaderDivide(!getHeaderDivide);
    //   }
    // });
  };
  return (
    <div>
      <Modal
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        okText="Xác nhận"
        title="Xác nhận bảo dưỡng thiết bị đã xong"
        width={800}
        okButtonProps={{
          disabled: devicesSelected.length === 0,
        }}
      >
        <div>
          <b>Thông tin phiếu:</b>
          <div style={{ lineHeight: '1.6em' }}>
            <div>{`Người tạo: ${infoDivide.CreaterID}`}</div>
            <div>{`Ghi chú: ${infoDivide.Note}`}</div>
            <div>{`Mô tả: ${infoDivide.Description}`}</div>
          </div>
        </div>
        <div>
          <b>Danh sách thiết bị:</b>
        </div>
        <Table
          style={{ marginTop: 16, marginBottom: 16 }}
          dataSource={devices}
          columns={columns}
          pagination={false}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys,
            ...rowSelection,
          }}
        />
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            name="Note"
            label="Ghi chú"
            rules={[
              {
                required: true,
                message: 'Hãy nhập ghi chú',
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
      <Button type="dashed" onClick={openModal}>
        Xác nhận
      </Button>
    </div>
  );
};

export default ApproveDivide;
