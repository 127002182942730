import { PlusOutlined } from '@ant-design/icons';
import { Button, Radio, Space, Table, Tag } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { GetLinkUserByOrg, GetLinkUserByUserCreate, GetLinkUserReceive } from '../../../../api/TSOrganization';
import { manageDeviceError } from '../../../../config/Recoil';
import { API_DEVICE_LINK_CREATE } from '../../../../constants/Device';
import { getRoleManager, getRoleUser } from '../../../../constants/Group/Roles';
import { getRoleAdmin } from '../../../../helpers/auth';
import PrintDeviceLink from '../../../Print/DeviceLink';
import { AddKeyToLst } from '../../../helper/addKeyToLst';
import { dateDisplay, displaySchedule, status, statusApprove } from '../../../helper/mapping';
import { Approve, Cancel } from './Components';
const columns = [
  {
    title: 'STT',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Tên TB',
    dataIndex: 'DeviceName',
    key: 'DeviceName',
  },
  {
    title: 'Mã TB',
    dataIndex: 'DeviceCode',
    key: 'DeviceCode',
  },
  {
    title: 'Người tạo',
    dataIndex: '',
    key: '',
    render: (record) => {
      return record.CreaterRequest || record.CreaterId || '-';
    },
  },
  {
    title: 'Người nhận',
    dataIndex: 'UserReceive',
    key: 'UserReceive',
    render: (record) => <Tag color="#2db7f5">{record}</Tag>,
  },
  {
    title: 'Bảo dưỡng',
    dataIndex: 'Schedule',
    key: 'Schedule',
    render: (record) => {
      return displaySchedule(record);
    },
  },
  {
    title: 'TG bắt đầu',
    dataIndex: 'TimeStart',
    key: 'TimeStart',
    render: (record) => {
      return dateDisplay(record);
    },
  },
  {
    title: 'Trạng thái thiết bị',
    dataIndex: 'Status',
    key: 'Status',
    render: (record) => {
      return <Tag color={status(record).color}>{status(record).label}</Tag>;
    },
  },
  {
    title: 'Ghi chú',
    dataIndex: 'Note',
    key: 'Note',
  },
  {
    title: 'Trạng thái phiếu',
    dataIndex: 'StatusLink',
    key: 'StatusLink',
    render: (record) => {
      return <Tag color={statusApprove(record).color}>{statusApprove(record).label}</Tag>;
    },
  },
  {
    title: '',
    dataIndex: '',
    key: 'Actions',
    render: (record) => (
      <Space>
        {record.StatusLink === 1 && <Cancel deviceInfo={record} />}
        {record.UserReceive === localStorage.getItem('fullName') && record.StatusLink === 1 && (
          <Approve deviceInfo={record} />
        )}
      </Space>
    ),
  },
];
const options = [
  {
    label: 'Đã tạo',
    value: 1,
  },
  {
    label: 'Đã nhận',
    value: 2,
  },
];
const LinkUser = () => {
  const [requestLink, setRequestLink] = useState([]);
  const [selectType, setSelectType] = useState(1);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const getManagerLink = useRecoilValue(manageDeviceError);
  const history = useHistory();
  const getLinkByOrg = () => {
    GetLinkUserByOrg().then((res) => {
      setLoading(false);
      setRequestLink(AddKeyToLst(res.LinkDeviceLst));
    });
  };
  const getLinkDeviceByUser = () => {
    if (selectType === 1) {
      GetLinkUserByUserCreate().then((res) => {
        setLoading(false);
        setRequestLink(AddKeyToLst(res.LinkDeviceLst));
      });
    } else if (selectType === 2) {
      setLoading(false);
      setRequestLink([]);
    }
  };
  const getLinkDeviceByRecive = async () => {
    const response = await GetLinkUserReceive();
    setRequestLink(AddKeyToLst(response.LinkDeviceLst));
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    if (getRoleManager()) {
      getLinkByOrg();
    } else if (getRoleUser()) {
      getLinkDeviceByUser();
    }
  }, [getManagerLink]);
  useEffect(() => {
    setLoading(true);
    if (selectType === 1) {
      getLinkDeviceByUser();
    } else {
      getLinkDeviceByRecive();
    }
  }, [selectType]);
  const infoBillMove = requestLink.filter((moveDevice) => {
    if (search === '') return requestLink;
    else {
      return (
        moveDevice.DeviceName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        moveDevice.DeviceCode.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        moveDevice.Location.toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    }
  });
  const onChangeType = (value) => {
    setSelectType(value.target.value);
  };
  const onClickRedirectCreateLinkDevice = () => {
    history.push(API_DEVICE_LINK_CREATE);
  };
  return (
    <div>
      <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'space-between' }}>
        <div>
          {(getRoleManager() || getRoleAdmin()) && (
            <Space size={16}>
              <Radio.Group
                options={options}
                optionType="button"
                buttonStyle="solid"
                onChange={onChangeType}
                value={selectType}
              ></Radio.Group>
              <Button onClick={onClickRedirectCreateLinkDevice} type="primary" icon={<PlusOutlined />}>
                Tạo phiếu phân bổ
              </Button>
            </Space>
          )}
        </div>
        <Space size={10}>
          <Search onChange={(e) => setSearch(e.target.value)} placeholder="Tìm kiếm" allowClear autoFocus />
          <PrintDeviceLink deviceLst={infoBillMove} />
        </Space>
      </div>
      <Table loading={loading} dataSource={infoBillMove} columns={columns} pagination={false} />
    </div>
  );
};

export default LinkUser;
