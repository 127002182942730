import { SettingOutlined } from '@ant-design/icons';
import { message, Modal, Select } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import Organization from '../../../../api/OrganizationApi';
import { manageMember } from '../../../../config/Recoil';
import { getGroupId, getToken, getUserName } from '../../../../helpers/auth';

const { Option } = Select;
const ClassifyMember = ({ member }) => {
  const [visible, setVisible] = useState(false);
  const [newRole, setNewRole] = useState(null);
  const [getStateMember, setStateMember] = useRecoilState(manageMember);
  const showModal = () => {
    setVisible(true);
  };
  const handleOk = () => {
    const api = Organization.approveUser();
    axios
      .post(api, {
        UserName: getUserName(),
        Token: getToken(),
        OrganizationID: getGroupId(),
        UserCode: member.UserName,
        Role: newRole,
      })
      .then(function (response) {
        if (response.data.RespCode === 0) {
          setStateMember(!getStateMember);
          message.success('Đã phân quyền người dùng thành công ');
        } else {
          message.error(response.data.RespText);
        }
      })
      .catch(function (error) {
        message.error(error);
      });
  };
  const changeMemberRole = (value) => {
    setNewRole(value);
  };
  return (
    <div>
      <SettingOutlined onClick={showModal} className="action" />
      <Modal title="Phân quyền người dùng" visible={visible} onOk={handleOk} onCancel={() => setVisible(false)}>
        <p>{'Tên: ' + member.FullName}</p>
        <p>{'SĐT: ' + member.UserName}</p>
        <p>{'Email: ' + member.Email}</p>
        <Select defaultValue={member.UserRole === 2 ? 'Quản lý' : 'Thành viên'} onChange={changeMemberRole}>
          {localStorage.getItem('roleUser') === '1' && (
            <Option value="10" key={10}>
              Trưởng phòng
            </Option>
          )}
          <Option value="2" key={2}>
            Quản lý
          </Option>
          <Option value="3" key={3}>
            Thành viên
          </Option>
        </Select>
      </Modal>
    </div>
  );
};
export default ClassifyMember;
