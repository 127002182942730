import { Input, Modal, notification } from "antd";
import React, { useEffect, useState } from "react";
import { GetOrganizationByID, UpdateOrganization } from "../../../api/TSOrganization";
const UpdateGroupInfo = () => {
  const [name, setName] = useState(null)
  const [description, setDescription] = useState(null)
  const [address, setAddress] = useState(null)
  const [phone, setPhone] = useState(null)
  const [email, setEmail] = useState(null)
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    GetOrganizationByID({
      OrganizationID: localStorage.getItem('groupId')
    }).then(res => {
      setName(res.OrganizationInfo.OrganizationName)
      setDescription(res.OrganizationInfo.Description)
      setAddress(res.OrganizationInfo.Address)
      setPhone(res.OrganizationInfo.Phone)
      setEmail(res.OrganizationInfo.Email)
    })
  }, [])
  const openModal = ()=>{
    setVisible(true)
  }
  const handleOk = ()=>{
    UpdateOrganization({
      OrganizationInfo:{
        OrganizationID: localStorage.getItem('groupId'),
        OrganizationName: name,
        Description: description,
        Address: address,
        Phone: phone,
        Email: email,
      }
    }).then(res =>{
      if(res.RespCode === 0){
        setVisible(false)
        notification.success({
          message: 'Cập nhật thông tin phòng ban thành công',
          duration: 1
        })
      }
    })
  }
  const changeName = (element)=>{
    setName(element.target.value)
  }
  const changeDes = (element)=>{
    setDescription(element.target.value)
  }
  const changeAdd = (element)=>{
    setAddress(element.target.value)
  }
  const changePhone = (element)=>{
    setPhone(element.target.value)
  }
  const changeEmail = (element)=>{
    setEmail(element.target.value)
  }
  return <div>
    <div>
      <p onClick={openModal}>
        Cập nhật thông tin
      </p>
      <Modal
        title="Cập nhật thông tin tổ chức - #1"
        visible={visible}
        onOk={handleOk}
        onCancel={()=>{
          setVisible(false)
        }}
        okText="Cập nhật"
        cancelText="Hủy"
      >
        <div className="create-group-input">
          <h4>Tên tổ chức: </h4>
          <Input
            size="large"
            value={name}
            onChange={changeName}
          />
        </div>
        <div className="create-group-input">
          <h4>Mô tả: </h4>
          <Input
            size="large"
            rows={10}
            value={description}
            onChange={changeDes}
          />
        </div>
        <div className="create-group-input">
          <h4>Địa chỉ: </h4>
          <Input
            size="large"
            value={address}
            onChange={changeAdd}
          />
        </div>
        <div className="create-group-input">
          <h4>Số điện thoại: </h4>
          <Input
            size="large"
            value={phone}
            onChange={changePhone}
          />
        </div>
        <div className="create-group-input">
          <h4>Email: </h4>
          <Input
            size="large"
            value={email}
            onChange={changeEmail}
          />
        </div>
      </Modal>
    </div>
  </div>
}
export default UpdateGroupInfo;