import React, { useState } from 'react'

const Lst = () => {
    const [data, setData] = useState([])
    return (
        <div>
            danh sach
        </div>
    )
}

export default Lst
