import moment from 'moment';
import React from 'react'
import { TitleReport } from '../../../config/URL/Qlts';
import { timeDisplay } from '../../helper/mapping';

const FormOffer = ({infoDocument }) => {
    const time = new Date();
    return (
        <div style={{padding: 40}}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <div style={{fontFamily: '"Times New Roman"', fontSize: 16}}>Đơn vị: {TitleReport}</div>
                    <div style={{fontFamily: '"Times New Roman"', fontSize: 16}}>Phòng ban: {localStorage.getItem('groupName')}</div>
                </div>
                <div> Mã phiếu: {infoDocument.DocumentCode}</div>
            </div>
            <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', lineHeight: '150%', fontSize: '16pt' }}><strong><span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;</span></strong></p>
            <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', lineHeight: '150%', fontSize: '16pt' }}><strong><span style={{ fontFamily: '"Times New Roman"' }}>PHIẾU ĐỀ XUẤT SỬA CHỮA/BẢO DƯỠNG THIẾT BỊ - Y DỤNG CỤ</span></strong></p>
            <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', lineHeight: '150%', fontSize: '12pt' }}><em><span style={{ fontFamily: '"Times New Roman"' }}>Ngày {time.getDate() - 1} tháng {time.getMonth() + 1} năm {time.getFullYear()}</span></em></p>
            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14pt', fontFamily: '"Times New Roman"', paddingTop: '10px' }}>
                <div>
                    <div>Người đề xuất: {infoDocument.CreaterName}</div>
                    <div>Phòng đề xuất: {infoDocument.OrganizationName}</div>
                    <div>Thời gian đề xuất: { timeDisplay(infoDocument.TimeCreate) }</div>
                </div>
                <div>
                    <div>Phòng ban nhận đề xuất: {infoDocument.OrganizationReceiveName}</div>
                    <div>Người xác nhận: {infoDocument.ApproveName}</div>
                    <div>Thời gian xác nhận: {timeDisplay(infoDocument.TimeApprove)}</div>
                </div>
            </div>
            <div style={{ fontSize: '14pt', fontFamily: '"Times New Roman"', paddingBottom:'10px' }}>
                {/* <div>
                    Mục đích sử dụng: { infoDocument.Target}
                </div> */}
                {/* <div>
                    Lý do: { infoDocument.Reason }
                </div>
                <div>
                    Mô tả: { infoDocument.Description }
                </div> */}
            </div>
            <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: '0.75pt solid #000000', borderCollapse: 'collapse' }}>
                <tbody>
                    <tr style={{ height: '13.95pt' }}>
                        <td style={{ width: '24.4pt', borderRightStyle: 'solid', borderRightWidth: '0.75pt', borderBottomStyle: 'solid', borderBottomWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                            <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ fontFamily: '"Times New Roman"' }}>STT</span></strong></p>
                        </td>
                        <td style={{ width: '67.05pt', borderRightStyle: 'solid', borderRightWidth: '0.75pt', borderBottomStyle: 'solid', borderBottomWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                            <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ fontFamily: '"Times New Roman"' }}>Tên thiết bị</span></strong></p>
                        </td>
                        <td style={{ width: '49.5pt', borderRightStyle: 'solid', borderRightWidth: '0.75pt', borderBottomStyle: 'solid', borderBottomWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                            <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ fontFamily: '"Times New Roman"' }}>Số lượng</span></strong></p>
                        </td>
                        <td style={{ width: '49.5pt', borderRightStyle: 'solid', borderRightWidth: '0.75pt', borderBottomStyle: 'solid', borderBottomWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                            <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ fontFamily: '"Times New Roman"' }}>Đơn vị</span></strong></p>
                        </td>
                        <td style={{ width: '77.55pt', borderRightStyle: 'solid', borderRightWidth: '0.75pt', borderBottomStyle: 'solid', borderBottomWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                            <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ fontFamily: '"Times New Roman"' }}>Lý do hỏng</span></strong></p>
                        </td>
                        <td style={{ width: '74.5pt', borderRightStyle: 'solid', borderRightWidth: '0.75pt', borderBottomStyle: 'solid', borderBottomWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                            <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ fontFamily: '"Times New Roman"' }}>Nguồn gốc</span></strong></p>
                        </td>
                        <td style={{ width: '74.5pt', borderRightStyle: 'solid', borderRightWidth: '0.75pt', borderBottomStyle: 'solid', borderBottomWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                            <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ fontFamily: '"Times New Roman"' }}>Nội dung báo hỏng</span></strong></p>
                        </td>
                    </tr>
                    {infoDocument.DocumentCode && infoDocument.DeviceLst.map((device, index) =>
                        (
                            <>
                                <tr style={{ height: '13.95pt' }}>
                                    <td style={{ width: '24.4pt', borderRightStyle: 'solid', borderRightWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><span style={{ fontFamily: '"Times New Roman"' }}>{index + 1}</span></p>
                                    </td>
                                    <td style={{ width: '67.05pt', borderRightStyle: 'solid', borderRightWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><span style={{ fontFamily: '"Times New Roman"' }}>{device.DeviceName}</span></p>
                                    </td>
                                    <td style={{ width: '49.5pt', borderRightStyle: 'solid', borderRightWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><span style={{ fontFamily: '"Times New Roman"' }}>1</span></p>
                                    </td>
                                    <td style={{ width: '49.5pt', borderRightStyle: 'solid', borderRightWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><span style={{ fontFamily: '"Times New Roman"' }}>{device.Unit}</span></p>
                                    </td>
                                    <td style={{ width: '77.55pt', borderRightStyle: 'solid', borderRightWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><span style={{ fontFamily: '"Times New Roman"' }}>{infoDocument.Reason}</span></p>
                                    </td>
                                    <td style={{ width: '74.5pt', borderRightStyle: 'solid', borderRightWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><span style={{ fontFamily: '"Times New Roman"' }}>{device.Origin}</span></p>
                                    </td>
                                    <td style={{ width: '74.5pt', borderRightStyle: 'solid', borderRightWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><span style={{ fontFamily: '"Times New Roman"' }}>{infoDocument.Description}</span></p>
                                    </td>
                                </tr>
                            </>
                        ))}
                </tbody>
            </table>
            <div style={{ textAlign: 'end', paddingTop: '20px', fontFamily: '"Times New Roman"' }}>
                <p>Ngày {time.getDate() - 1} tháng {time.getMonth() + 1} năm {time.getFullYear()}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', fontFamily: '"Times New Roman"' }}>
                <div>
                    <h3 style={{fontWeight: 'bold'}}>Trưởng khoa/phòng</h3>
                </div>
                <div>
                    <h3 style={{fontWeight: 'bold'}}>khoa Dược - TTB - Vật tư y tế</h3>
                </div>
                <div>
                    <h3 style={{fontWeight: 'bold'}}>Thủ trưởng đơn vị</h3>
                </div>
                <div>
                    <h3 style={{fontWeight: 'bold'}}>Người tạo phiếu</h3>
                </div>
            </div>
        </div>
    )
}

export default FormOffer
