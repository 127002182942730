import { message, Modal } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Organization from '../../../api/OrganizationApi';
import { getGroupId, getToken, getUserName } from '../../../helpers/auth';
export default function DeleteGroup(props) {
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const showModal = () => {
    setVisible(true);
  };

  const handleOk = (e) => {
    const api = Organization.delete();
    axios
      .post(api, {
        UserName: getUserName(),
        Token: getToken(),
        OrganizationID: getGroupId(),
      })
      .then(function (response) {
        if (response.data.RespCode === 0) {
          message.success(`xóa tổ chức thành công`);
          history.push('/dashboard');
        } else {
          console.log(response.data.RespCode);
          message.error(response.data.RespText);
        }
      })
      .catch(function (error) {
        message.error(error);
      });
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  return (
    <div>
      <p onClick={showModal} style={{ color: 'red' }}>
        Xóa phòng ban
      </p>
      <Modal
        title="Xác nhận"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Xóa phòng ban"
        cancelText="Hủy"
      >
        <h3>Bạn muôn xóa phòng ban này vĩnh viễn?</h3>
      </Modal>
    </div>
  );
}
