import moment from 'moment';
import React from 'react';
import { TitleReport } from '../../../config/URL/Qlts';
import { formatYear } from '../../../constants/DateTime';
import { dateDisplay, statusApprove } from '../../helper/mapping';

const FormDeviceMaintance = ({ documents = [], devices = [] }) => {
  return (
    <div style={{ padding: '40px', fontFamily: '"Times New Roman"', lineHeight: '1.5em' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '30px' }}>
        <div style={{ width: 'max-content', textAlign: 'center' }}>
          <div>
            <strong style={{ textTransform: 'uppercase' }}>SỞ Y TẾ TỈNH ĐIỆN BIÊN</strong>
          </div>
          <div>
            <strong style={{ textTransform: 'uppercase' }}>{TitleReport}</strong>
          </div>
          <div>
            <strong style={{ textTransform: 'uppercase' }}>Phòng ban: {localStorage.getItem('groupName')}</strong>
          </div>
        </div>
      </div>
      <div>
        <h2 style={{ textTransform: 'uppercase', textAlign: 'center', fontWeight: 'bold', padding: 20 }}>
          Sổ luân chuyển thiết bị
        </h2>
      </div>
      <div>
        <h3 style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>Phần 1: Danh sách phiếu</h3>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }}>STT</th>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Mã phiếu</th>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Người tạo</th>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Thời gian tạo</th>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Trạng thái hiện tại</th>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Người xác nhận</th>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Ghi chú xác nhận</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((document, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }}>{index + 1}</td>
                <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }}>
                  {document.DocumentCode}
                </td>
                <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }}>
                  {document.CreaterID}
                </td>
                <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }}>
                  {dateDisplay(document.TimeCreate, formatYear)}
                </td>
                <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }}>
                  {document.Status && statusApprove(document.Status).label}
                </td>
                <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }}>
                  {document.ApproveID}
                </td>
                <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }}>
                  {document.ApproveNote}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <h3 style={{ textTransform: 'uppercase', fontWeight: 'bold', paddingTop: 20 }}>
          Phần 2: Danh sách thiết bị luân chuyển
        </h3>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>STT</th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Tên thiết bị</th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Mã thiết bị</th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Mã phiếu tạo</th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Giá trị</th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Seria</th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Model</th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Ghi chú</th>
              </tr>
            </thead>
            <tbody>
              {devices.map((device, index) => (
                <tr key={device.DeviceId}>
                  <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }}>{index + 1}</td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }}>
                    {device.DeviceName}
                  </td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }}>
                    {device.DeviceCode}
                  </td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }}>
                    {device.DocumentCode}
                  </td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }}>
                    {device.DeviceValue}
                  </td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }}>{device.Seria}</td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }}>{device.Model}</td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }}>{device.Note}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <i>
          <strong>Chú ý:</strong>
          <span>
            Cán bộ kỹ thuật thiết bị phải ký vào cột “Người xử lý” Sau khi đi kiểm tra sửa chữa/bảo dưỡng hoặc thay thế
            vật tư mới.
          </span>
        </i>
      </div>
      <div>
        <div style={{ paddingBottom: '20px', textAlign: 'end' }}>
          Điện Biên, ngày {moment().date()} tháng {moment().month() + 1} năm {moment().year()}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', textTransform: 'uppercase' }}>
          <h3 style={{ fontWeight: 'bold' }}>Phòng vật tư</h3>
          <h3 style={{ fontWeight: 'bold' }}>Trưởng phòng</h3>
          <h3 style={{ fontWeight: 'bold' }}>Người tạo phiếu</h3>
        </div>
      </div>
    </div>
  );
};

export default FormDeviceMaintance;
