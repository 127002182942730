import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, message, Modal, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { GetOfferDeviceHeaderByID, GetOfferMaintanceByID } from '../../../../../../api/DeviceOffer';
import PrintFormOffer from '../../../../../Print/OfferDevice';

const Detail = ({ headerInfo }) => {
  const [visible, setVisible] = useState(false);
  const [documentInfo, setDocumentInfo] = useState({});
  const [detail, setDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    GetOfferDeviceHeaderByID({
      DocumentID: headerInfo.DocumentID,
    }).then((res) => {
      setLoading(false);
      if (res.RespCode === 0) {
        setDetail(
          res.DeviceOfferHeaderInfo.DeviceOfferLineLst.map((line, index) => {
            return {
              ...line,
              key: index + 1,
            };
          })
        );
      } else {
        message.error(res.RespText);
      }
    });
  }, [headerInfo]);

  const columns = [
    {
      title: 'Tên TB',
      dataIndex: 'DeviceName',
      key: 'DeviceName',
    },
    {
      title: 'Mã TB',
      dataIndex: 'DeviceCode',
      key: 'DeviceCode',
    },
    {
      title: 'Đơn vị',
      dataIndex: 'Unit',
      key: 'Unit',
    },
    {
      title: 'Giá trị',
      dataIndex: 'UnitPrice',
      key: 'UnitPrice',
    },
    {
      title: 'Nguồn gốc',
      dataIndex: 'Origin',
      key: 'Origin',
    },
    {
      title: 'Nội dung báo hỏng',
      dataIndex: 'ReportError',
      key: 'ReportError',
    },
    // {
    //     title: '',
    //     dataIndex: '',
    //     key: '',
    //     render: (record) => (
    //         <>
    //             <Upload beforeUpload={hanldeUpload} showUploadList={false} action={`${URL_QLTS}/File/UploadDeviceFile?DocumentID=${record.DocumentID}&Token=${token}&UserName=${userName}&FileName=${`file`}`}>
    //                 <Tooltip placement='bottom' title="Upload file đính kèm">
    //                     <UploadOutlined style={{ cursor:'pointer', fontSize: 20 }}/>
    //                 </Tooltip>
    //             </Upload>
    //         </>
    //     )
    // },
  ];
  const hanldeUpload = (value) => {
    if (value.type !== 'application/pdf') {
      message.error('Không đúng định dạng. Hãy upload file PDF');
      return false;
    } else {
      message.success('Upload file thành công');
    }
  };
  const handleModal = () => {
    setVisible(true);
    GetOfferMaintanceByID({
      DocumentCode: headerInfo.DocumentCode,
    }).then((res) => {
      setDocumentInfo(res.OfferHeaderInfo);
    });
  };
  return (
    <div>
      <Modal
        title="Chi tiết phiếu đề xuất"
        visible={visible}
        width={800}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => setVisible(false)}>Đóng</Button>
            <PrintFormOffer infoDocument={documentInfo} />
          </div>,
        ]}
      >
        <Row>
          <Col span={12}>
            <div>Mã phiếu: {documentInfo.DocumentCode}</div>
            <div>Lý do tạo phiếu: {documentInfo.Reason}</div>
          </Col>
          <Col span={12}>
            <div>Người tạo: {headerInfo.CreaterName}</div>
            <div>Mô tả: {documentInfo.Description}</div>
          </Col>
        </Row>
        <Table columns={columns} dataSource={documentInfo.DeviceLst} pagination={false}></Table>
      </Modal>
      <InfoCircleOutlined style={{ fontSize: 20, cursor: 'pointer' }} onClick={handleModal} />
      {/* <Table dataSource={detail} loading={loading} columns={columns}/> */}
    </div>
  );
};

export default Detail;
