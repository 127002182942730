import { Col, Row } from 'antd';
import React from 'react';
import AdminHeader from './AdminHeader';
import AdminSidebar from './AdminSidebar';
import './AdminLayout.scss';

const AdminLayout = ({ children }) => {
  return (
    <div className="admin-layout">
      <AdminHeader />
      <Row className="admin-layout__sidebar-content">
        <Col span={4}>
          <AdminSidebar />
        </Col>
        <Col span={20} className="admin-layout__content">
          {children}
        </Col>
      </Row>
    </div>
  );
};

export default AdminLayout;
