import { Button, Form, Input, message, Modal, Row } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import { ApproveReportDeviceError } from '../../../../../../api/Device'
import { CreateMoveDeviceDoc } from '../../../../../../api/TSOrganization'
import { manageDeviceError } from '../../../../../../config/Recoil'

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
}
const ApproveReportDevice = ({info}) => {
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [getError, setError] = useRecoilState(manageDeviceError)
    const [form] = Form.useForm()
    const groupId = localStorage.getItem('groupId');
    const openModal = ()=>{
        setVisible(true)
    }
    const confirm = ()=>{
        form.submit();
    }
    const onFinish = (elements) =>{
        const req = {
            ...elements,
            RowID: info.RowID,
            DeviceID: info.DeviceId,
            DocumentID: info.DocumentID,
            Note: elements.Note ?? ''
        }
        setLoading(true)
        ApproveReportDeviceError(req).then(res =>{
            setLoading(false)
            if(res.RespCode === 0){
                setVisible(false)
                setError(!getError)
                message.success('Đã xác nhận thành công')
                if(elements.Solution === 1){
                    const reqMov = {
                        TimeMove: moment().format('YYYY-MM-DD HH:mm:ss'),
                        OrganizationIDOld: groupId,
                        OrganizationIDNew: 53,
                        Description: info.Solution !== undefined ? info.Solution : 'Không có',
                        DeviceLst: [{
                            DeviceId: info.DeviceId
                        }]
                    }
                    CreateMoveDeviceDoc({
                        MoveDeivceDocInfo: reqMov
                    }).then(resp =>{
                        console.log(resp);
                    })

                }
            }else{
                message.error(res.RespText)
            }
        })
    }
    return (
        <div>
            <Modal title={'Xác nhận báo hỏng của thiết bị ' + info.DeviceName} visible={visible} confirmLoading={loading} cancelText = 'Hủy' okText = 'Xác nhận' onCancel={()=> setVisible(false)} onOk = {confirm}>
                <Row style={{paddingBottom: 20, paddingLeft: 20}}>
                    Giải pháp người báo cáo: { info.Solution !== undefined ? info.Solution : 'Không có' }
                </Row>
                <Form onFinish={onFinish} form={form} name="content" {...layout}>
                    <Form.Item name="Note" label="Ghi chú">
                        <Input.TextArea/>
                    </Form.Item>
                </Form>
            </Modal>
            <Button type='dashed' onClick={openModal}>Xác nhận</Button>
        </div>
    )
}

export default ApproveReportDevice
