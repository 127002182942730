import { UserOutlined } from "@ant-design/icons";
import { Affix, Button, Dropdown, Menu, Modal } from "antd";
import React from "react";
import { Logout } from "../../Auth";
import "./DropDown.scss";
const DropDown = () => {
  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Logout />
      </Menu.Item>
    </Menu>
  );
  return (
    <div>
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button className="btn-drop" type="primary">
            <UserOutlined style={{ fontSize: 18 }} />
            <span
              className="name"
              style={{
                fontWeight: "bold",
                textTransform: "capitalize",
                fontSize: 15,
              }}
            >
              {localStorage.getItem("fullName")}
            </span>
          </Button>
        </Dropdown>
    </div>
  );
};
export default DropDown;
