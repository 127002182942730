import baseURL from "./BaseURL";
const deviceAPI = {
  getByUser: () => {
    return `${baseURL()}/Device/GetDeviceByUser`;
  },
  getByOrganization: () => {
    return `${baseURL()}/Device/GetDeviceByOrganization`;
  },
  getForReport: () => {
    return `${baseURL()}/Device/GetDeviceForReport`;
  },
  getInfoByID: () => {
    return `${baseURL()}/Device/GetDeviceInfo`;
  },
  getInfoByCode: () => {
    return `${baseURL()}/Device/GetDeviceInfoByCode`;
  },
  getInfoByQRcode: () => {
    return `${baseURL()}/Device/GetDeviceInfoByQRCode`;
  },
  createInfo: () => {
    return `${baseURL()}/Device/CreateDeviceInfo`;
  },
  updateInfo: () => {
    return `${baseURL()}/Device/UpdateDeviceInfo`;
  },
  remoteInfo: () => {
    return `${baseURL()}/Device/RemoveDeviceInfo`;
  },
  CreateDeviceLst: () => {
    return `${baseURL()}/Device/CreateDeviceLst`;
  },
  getLstTypeDevice: () => {
    return `${baseURL()}/DefaultValue/GetDefaultValue`;
  },
  setDeviceCreate: () => {
    return `${baseURL()}/DeviceSet/CreateDeviceSet`;
  },
  setDeviceUpdate: () => {
    return `${baseURL()}/DeviceSet/UpdateDeviceSet`;
  },
  setDeviceDel: () => {
    return `${baseURL()}/DeviceSet/DelDeviceSet`;
  },
  setDeviceGetLstSet: () => {
    return `${baseURL()}/DeviceSet/GetDeviceSetLst`;
  },
  setDeviceGetInfoSetByID: () => {
    return `${baseURL()}/DeviceSet/GetDeviceSetInfo`;
  },
  setDeviceGetSetByCode: () => {
    return `${baseURL()}/DeviceSet/GetDeviceSetByCode`;
  },
  setDeviceGetSetByQRCode: () => {
    return `${baseURL()}/DeviceSet/GetDeviceSetByQRCode`;
  },
  ReportDeviceError:()=>{
    return `${baseURL()}/Device/ReportDeviceError`
  },
};
export default deviceAPI;
