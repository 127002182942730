import { Button, Tooltip } from "antd";
import React, { useRef } from "react";
import {PrinterOutlined} from '@ant-design/icons'
import ReactToPrint from "react-to-print";
import FormDeviceMaintance from './FormDeviceMaintance';
class ComponentToPrint extends React.Component {
    render() {
        return (
            <div className='report'>
                 <FormDeviceMaintance devices={this.props.devices}></FormDeviceMaintance>
            </div>
        );
    }
}

const PrintDeviceLink = ({ deviceLst }) => {
    const valueQrCode = useRef();
    return (
        <div style={{paddingRight: 20}}>
            <div style={{ display: "none" }}>
                <ComponentToPrint ref={valueQrCode} devices={deviceLst} />
            </div>
            <ReactToPrint
                documentTitle="Thông tin thiết bị"
                trigger={() => <div type="primary" >
                    <Tooltip title="In phiếu danh sách thiết bị phân bổ" placement="bottom">
                      <Button>
                        In phiếu
                      </Button>
                    </Tooltip>
                    </div>}
                content={() => valueQrCode.current}
            />
        </div>
    );
};
export default PrintDeviceLink;
