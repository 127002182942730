import { Button, Dropdown, Menu, Select, Space, Table, Tag } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { GetAllDeviceErrorByOrgID } from '../../../../api/Device';
import { GetDeviceErrorByOrgID } from '../../../../api/QrCode';
import { GetDependentOrganization } from '../../../../api/TSOrganization';
import { manageDeviceError } from '../../../../config/Recoil';
import { companyID } from '../../../../config/URL/Qlts';
import { columnsDevice } from '../../../../constants/columnTable/device';
import {
  API_DEVICE_CREATE_SCHEDULE,
  API_DEVICE_LST_REPORT,
  API_DEVICE_REPAIR_QR,
  API_DEVICE_REQUEST_ACCREDITATION,
  API_DEVICE_REQUEST_CHECK,
  API_DEVICE_REQUEST_REPAIR,
} from '../../../../constants/Device';
import { REPORT_ERROR_SOLUTION } from '../../../../constants/reportError';
import { formatMoneyDisplay, roleUser, statusApprove, statusError, timeDisplay } from '../../../helper/mapping';
import FormPrint from '../InfoReport/components/formPrint';
import { ApproveDeviceError, Cancel } from './components';
import ContactRepair from './components/ContactRepair';
import DivisionRepair from './components/DevisionRepair';

const LstReport = () => {
  const [lstErr, setLstErr] = useState([]);
  const [statusChoose, setStatusChoose] = useState(-1);
  const getError = useRecoilValue(manageDeviceError);
  const [loading, setLoading] = useState(true);
  const organizationID = localStorage.getItem('groupId');
  const [organization, setOrganization] = useState([]);
  const [selectRepair, setSelectRepair] = useState([]);
  const [valueSearch, setValueSearch] = useState('');
  const location = useLocation();
  const allow = () => {
    if (organizationID == companyID && roleUser(localStorage.getItem('roleUser'))) {
      return true;
    } else {
      return false;
    }
  };
  const fetchDataByGID = (gID) => {
    const req = {
      OrganizationID: gID ? gID : organizationID,
    };
    GetDeviceErrorByOrgID(req).then((res) => {
      setLoading(false);
      if (res.RespCode === 0) {
        const response = res.DeviceLst.map((device) => {
          return {
            ...device,
            Description: device.Description ?? '',
          };
        })
          .filter((de) => {
            if (location.pathname === API_DEVICE_LST_REPORT) {
              return (
                (de.Solution === REPORT_ERROR_SOLUTION[0].label || de.Solution === REPORT_ERROR_SOLUTION[5].label) &&
                de.StatusError !== 0
              );
            } else if (location.pathname === API_DEVICE_REPAIR_QR) {
              return de.Solution === REPORT_ERROR_SOLUTION[6].label && de.StatusError !== 0;
            } else if (location.pathname === API_DEVICE_REQUEST_REPAIR) {
              return de.Solution === REPORT_ERROR_SOLUTION[1].label && de.StatusError !== 0;
            } else if (location.pathname === API_DEVICE_REQUEST_ACCREDITATION) {
              return de.Solution === REPORT_ERROR_SOLUTION[2].label && de.StatusError !== 0;
            } else if (location.pathname === API_DEVICE_REQUEST_CHECK) {
              return de.Solution === REPORT_ERROR_SOLUTION[3].label && de.StatusError !== 0;
            } else {
              return de.StatusReport !== 1 && de.Solution !== REPORT_ERROR_SOLUTION[6] && de.StatusError !== 0;
            }
          })
          .sort((a, b) => {
            return new Date(a.TimeImport).getTime() - new Date(b.TimeImport).getTime();
          })
          .map((device, index) => {
            return {
              ...device,
              key: index + 1,
            };
          });
        setLstErr(response);
      }
    });
  };
  const devicesFiltered = useMemo(() => {
    return lstErr.filter((device) => {
      return (
        (device.DeviceName.toLowerCase().indexOf(valueSearch.toLowerCase()) !== -1 ||
          device.DeviceCode.toLowerCase().indexOf(valueSearch.toLowerCase()) !== -1 ||
          device.Description.toLowerCase().indexOf(valueSearch.toLowerCase()) !== -1) &&
        (statusChoose !== -1 ? device.StatusError === statusChoose : true)
      );
    });
  }, [valueSearch, statusChoose]);

  const fetchDataByCompany = () => {
    const req = {
      OrganizationID: organizationID,
    };
    GetAllDeviceErrorByOrgID(req).then((res) => {
      setLoading(false);
      const response = res.DeviceLst.filter((de) => {
        if (location.pathname === API_DEVICE_LST_REPORT) {
          return de.StatusReport === 1;
        } else {
          return de.StatusReport !== 1;
        }
      })
        .sort((a, b) => {
          return b.StatusError - a.StatusError;
        })
        .map((device, index) => {
          return {
            ...device,
            key: index + 1,
          };
        });
      setLstErr(response);
    });
  };
  useEffect(() => {
    setLoading(true);
    setSelectRepair([]);
    if (allow()) {
      fetchDataByCompany();
    } else {
      fetchDataByGID();
    }
  }, [getError, location.pathname]);
  useEffect(() => {
    GetDependentOrganization({
      OrganizationID: companyID,
    }).then((res) => {
      setOrganization(res.OrganizationLst);
    });
  }, []);
  const colunms = [
    ...columnsDevice,
    {
      title: 'Giá trị hiện tại',
      dataIndex: 'DeviceValueCurrent',
      key: 'DeviceValueCurrent',
      render: (record) => {
        return formatMoneyDisplay(record);
      },
    },
    {
      title: 'Loại báo cáo',
      dataIndex: 'StatusReport',
      key: 'StatusReport',
      render: (record) => {
        return <Tag color={statusError(record).color}> {statusError(record).label} </Tag>;
      },
      sorter: (a, b) => a.StatusReport - b.StatusReport,
    },
    {
      title: 'Thời gian báo cáo',
      dataIndex: 'TimeReportError',
      key: 'TimeReportError',
      render: (record) => {
        return timeDisplay(record);
      },
    },
    {
      title: 'Mô tả',
      dataIndex: 'ReportError',
      key: 'ReportError',
    },
    {
      title: 'Giải pháp',
      dataIndex: 'Solution',
      key: 'Solution',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'StatusError',
      key: 'StatusError',
      render: (record) => {
        return <Tag color={statusApprove(record).color}>{statusApprove(record).label}</Tag>;
      },
      sorter: (a, b) => a.StatusError - b.StatusReport,
    },
    {
      title: 'Người xác nhận',
      dataIndex: 'ApproveID',
      key: 'ApproveID',
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      render: (record) => (
        <Space>
          {record.StatusError === 1 && <Cancel deviceInfo={record} />}
          {record.StatusError === 1 && <ApproveDeviceError info={record} />}
        </Space>
      ),
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectRepair(selectedRows);
    },
  };
  const handleChange = (val) => {
    if (val) {
      fetchDataByGID(val);
    } else {
      fetchDataByCompany();
    }
  };
  const handleStatus = (val) => {
    setStatusChoose(val);
  };
  const options = (
    <Menu>
      <Menu.Item key="2">
        <ContactRepair devices={selectRepair} />
      </Menu.Item>
      <Menu.Item key="3">
        <DivisionRepair devices={selectRepair}></DivisionRepair>
      </Menu.Item>
      <Menu.Item key="4">
        <Link to={API_DEVICE_CREATE_SCHEDULE}>Tạo lịch bảo dưỡng định kỳ</Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <div>
      <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Space>
          <Dropdown overlay={options} trigger="click">
            <Button type="dashed">Liên hệ sửa chữa</Button>
          </Dropdown>
          <FormPrint infoPrint={selectRepair} />
        </Space>
        <Space>
          <Search
            onChange={(e) => setValueSearch(e.target.value)}
            placeholder="Nhập nội dung tìm kiếm"
            style={{
              width: 200,
            }}
          />
          {allow() && (
            <Select
              showSearch
              style={{ width: 300 }}
              allowClear
              placeholder="Lựa chọn phòng ban"
              optionFilterProp="children"
              onChange={handleChange}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {organization &&
                organization.map((org, index) => (
                  <Select.Option key={index} value={org.OrganizationID}>
                    {org.OrganizationName}
                  </Select.Option>
                ))}
            </Select>
          )}
          <Select style={{ width: 200 }} placeholder="Trạng thái" defaultValue={statusChoose} onChange={handleStatus}>
            <Select.Option value={0}>Hủy</Select.Option>
            <Select.Option value={1}>Mới tạo</Select.Option>
            <Select.Option value={2}>Đã xác nhận</Select.Option>
            <Select.Option value={-1}>Tất cả</Select.Option>
          </Select>
        </Space>
      </Space>
      <Table
        dataSource={valueSearch || statusChoose !== -1 ? devicesFiltered : lstErr}
        loading={loading}
        columns={colunms}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        locale={{
          emptyText: 'Không có dữ liệu',
        }}
        pagination={{
          defaultPageSize: 150,
          hideOnSinglePage: true,
        }}
      ></Table>
    </div>
  );
};

export default LstReport;
