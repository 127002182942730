import React from 'react';
import './form.scss';
import moment from 'moment';
import { TitleReport } from '../../../../../config/URL/Qlts';
import { dateDisplay, formatMoneyDisplay, timeDisplay } from '../../../../helper/mapping';
import { useLocation } from 'react-router';
import { API_DEVICE_LST_ERROR, API_DEVICE_LST_REPORT } from '../../../../../constants/Device';
const Form = ({ infoReport }) => {
  const location = useLocation();
  const getPath = location && location.pathname;
  const page = getPath === API_DEVICE_LST_ERROR ? 'error' : 'check';
  const formatYear = 'YYYY';
  return (
    <div style={{ padding: '40px' }}>
      <table cellPadding={0} cellSpacing={0} style={{ width: '752.55pt', borderCollapse: 'collapse' }}>
        <tbody>
          <tr>
            <td style={{ width: '435.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
              <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '13pt' }}>
                <strong>
                  <span style={{ fontFamily: '"Times New Roman"' }}>Đơn vị: {TitleReport}</span>
                </strong>
              </p>
            </td>
            <td style={{ width: '295.2pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
              <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '13pt' }}>
                <strong>
                  <span style={{ fontFamily: '"Times New Roman"' }}>Mẫu số: C22-HD</span>
                </strong>
              </p>
            </td>
          </tr>
          <tr>
            <td style={{ width: '435.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
              <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '13pt' }}>
                <strong>
                  <span style={{ fontFamily: '"Times New Roman"' }}>Bộ phận:&nbsp;</span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>{localStorage.getItem('groupName')}</span>
              </p>
            </td>
            <td style={{ width: '295.2pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
              <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '13pt' }}>
                <em>
                  <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </em>
                <em>
                  <span style={{ fontFamily: '"Times New Roman"' }}>( Ban hành theo quyết định số: 19/2006/QĐ-BTC</span>
                </em>
              </p>
            </td>
          </tr>
          <tr>
            <td style={{ width: '435.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
              <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '13pt' }}>
                <strong>
                  <span style={{ fontFamily: '"Times New Roman"' }}>Mã đơn vị SDNS:</span>
                </strong>
                <span style={{ fontFamily: '"Times New Roman"' }}>…………………………</span>
              </p>
            </td>
            <td style={{ width: '295.2pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
              <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '13pt' }}>
                <em>
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </em>
                <em>
                  <span style={{ fontFamily: '"Times New Roman"' }}>Ngày 30/3/2006 của Bộ trưởng Bộ tài chính)</span>
                </em>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '14pt' }}>
        <strong>
          <span style={{ fontFamily: '"Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </strong>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '14pt' }}>
        <strong>
          <span style={{ fontFamily: '"Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </strong>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '14pt' }}>
        <strong>
          <span style={{ fontFamily: '"Times New Roman"', textTransform: 'uppercase' }}>
            {location.pathname === API_DEVICE_LST_REPORT
              ? 'Báo cáo thiết bị kiểm tra'
              : 'GIẤY BÁO HỎNG VẬT TƯ, THIẾT BỊ Y TẾ'}
          </span>
        </strong>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '14pt' }}>
        <strong>
          <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;</span>
        </strong>
      </p>
      <table
        style={{
          width: '100%',
          border: '1px solid #ddd',
          borderCollapse: 'collapse',
          tableLayout: 'fixed',
          fontFamily: '"Times New Roman"',
        }}
      >
        <thead>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px', width: '30px' }}>STT</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Tên thiết bị</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Model</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Serial</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Thời gian sản xuất</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Thời gian đưa vào sử dụng</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Loại</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>ĐVT</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>SL</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Giá trị</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>
              Lý do {page === 'error' ? 'bảo hỏng' : 'kiểm tra'}
            </th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Người yêu cầu</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>SĐT người yêu cầu</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Thời gian kiểm tra</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Ghi chú</th>
          </tr>
        </thead>
        <tbody>
          {infoReport &&
            infoReport.map((device, index) => (
              <tr key={index} style={{ fontSize: 12 }}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{index + 1}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{device.DeviceName}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{device.Model}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', wordBreak: 'break-all' }}>
                  {device.Serial}
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {dateDisplay(device.TimeManufacturer, formatYear)}
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {dateDisplay(device.TimeStart, formatYear)}
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{device.Type}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{device.Unit}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{1}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', width: 'auto' }}>
                  {formatMoneyDisplay(device.DeviceValue)}
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{device.ReportError}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{device.RepoterName}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{device.ReporterPhone}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{timeDisplay(device.TimeReportError)}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}></td>
              </tr>
            ))}
        </tbody>
      </table>
      <div
        style={{
          fontFamily: '"Times New Roman"',
          fontSize: '16px',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          paddingTop: '10px',
        }}
      >
        Tổng cộng: {infoReport.length} khoản
      </div>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '14pt' }}>
        <strong>
          <span style={{ fontFamily: '"Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </strong>
      </p>
      <div style={{ textAlign: 'center' }}>
        <table cellPadding={0} cellSpacing={0} style={{ width: '100%', borderCollapse: 'collapse' }}>
          <tbody>
            <tr style={{ height: '13.05pt' }}>
              <td style={{ width: '255.25pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '14pt' }}>
                  <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;</span>
                  </strong>
                </p>
              </td>
              <td
                colSpan={3}
                style={{ width: '429.1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}
              >
                <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '14pt' }}>
                  <em>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </em>
                  <strong>
                    <em>
                      <span style={{ fontFamily: '"Times New Roman"' }}>Điện Biên,</span>
                    </em>
                  </strong>
                  <em>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      &nbsp;ngày {moment().date()} tháng {moment().month() + 1} năm {moment().year()}
                    </span>
                  </em>
                </p>
              </td>
            </tr>
            <tr style={{ height: '70.1pt' }}>
              <td style={{ width: '255.25pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '14pt' }}>
                  <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;</span>
                  </strong>
                </p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '14pt' }}>
                  <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>Ý KIẾN CỦA NGƯỜI</span>
                  </strong>
                </p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '14pt' }}>
                  <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>PHỤ TRÁCH BỘ PHẬN SỬ DỤNG</span>
                  </strong>
                </p>
              </td>
              <td style={{ width: '236.3pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '14pt' }}>
                  <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;</span>
                  </strong>
                </p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '14pt' }}>
                  <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>PHÒNG TÀI CHÍNH KẾ TOÁN</span>
                  </strong>
                </p>
              </td>
              <td style={{ width: '181.7pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '14pt' }}>
                  <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;</span>
                  </strong>
                </p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '14pt' }}>
                  <strong>
                    <span style={{ fontFamily: '"Times New Roman"' }}>PHÒNG VẬT TƯ - TTBYT</span>
                  </strong>
                </p>
              </td>
              <td style={{ verticalAlign: 'top' }}>
                <br />
              </td>
            </tr>
            <tr style={{ height: '0pt' }}>
              <td style={{ width: '266.05pt' }}>
                <br />
              </td>
              <td style={{ width: '247.1pt' }}>
                <br />
              </td>
              <td style={{ width: '192.5pt' }}>
                <br />
              </td>
              <td style={{ width: '0.3pt' }}>
                <br />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '14pt' }}>
        <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;</span>
      </p>
    </div>
  );
};

export default Form;
