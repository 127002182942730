import { message, Table, Tag } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { useEffect, useState } from 'react';
import Schedule from '../..';
import { GetDeviceByOrganization, GetDeviceByUser } from '../../../../../../api/Device';
import { formatYear } from '../../../../../../constants/DateTime';
import mapLstTable from '../../../../../helper/mapLstTable';
import { dateDisplay, roleUser, status } from '../../../../../helper/mapping';

const columns = [
  {
    title: 'Tên thiết bị',
    dataIndex: 'DeviceName',
    key: 'DeviceName',
  },
  {
    title: 'Mã thiết bị',
    dataIndex: 'DeviceCode',
    key: 'DeviceCode',
  },
  {
    title: 'Người tạo',
    dataIndex: 'CreaterId',
    key: 'CreaterId',
    sorter: (a, b) => a.CreaterId - b.CreaterId,
  },
  {
    title: 'Loại TB',
    dataIndex: 'Type',
    key: 'Type',
    filters: [
      { text: 'Thiết bị văn phòng', value: 'Thiết bị văn phòng' },
      { text: 'Thiết bị quản lý', value: 'Thiết bị quản lý' },
      { text: 'Nhà cửa, vật kiến trúc', value: 'Nhà cửa, vật kiến trúc' },
      { text: 'Phương tiên vận tải', value: 'Phương tiên vận tải' },
      { text: 'Tài sản vô hình', value: 'Tài sản vô hình' },
      { text: 'Thiết bị sản xuất', value: 'Thiết bị sản xuất' },
      { text: 'Thiết bị nghiên cứu', value: 'Thiết bị nghiên cứu' },
    ],
  },
  {
    title: 'Trạng thái',
    dataIndex: 'Status',
    key: 'Status',
    render: (record) => {
      return <Tag color={status(record).color}>{status(record).label}</Tag>;
    },
    sorter: (a, b) => a.Status - b.Stautus,
  },
  {
    title: 'Ghi chú',
    dataIndex: 'Note',
    key: 'Note',
    sorter: (a, b) => a.Note - b.Note,
  },
  {
    title: 'Năm sử dụng',
    dataIndex: 'TimeStart',
    key: 'TimeStart',
    render: (record) => {
      return dateDisplay(record, formatYear);
    },
  },
  {
    title: 'Năm sản xuất',
    dataIndex: 'TimeManufacturer',
    key: 'TimeManufacturer',
    render: (record) => {
      return dateDisplay(record, formatYear);
    },
  },
];

const CreateSchedule = () => {
  const groupID = localStorage.getItem('groupId');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deviceSelected, setDeviceSelected] = useState([]);
  const [valueSearch, setValueSearch] = useState('');
  const fetchDataGroup = async () => {
    await GetDeviceByOrganization({
      OrganizationID: parseInt(groupID),
      RowNumber: 0,
      PageNumber: 0,
      Search: valueSearch,
      DeviceGroup: '',
      TypeDevice: 'Medical',
    }).then((res) => {
      const resData = res.DeviceLst.filter((device) => {
        return device.Status !== 3;
      });
      const dataSorted = resData.sort((a, b) => {
        return new Date(b.TimeCreate) - new Date(a.TimeCreate);
      });
      const dataSortedAddIndex = mapLstTable(dataSorted).map((data, index) => {
        return {
          ...data,
          key: index + 1,
        };
      });
      setData(dataSortedAddIndex);
      setLoading(false);
    });
  };
  const fetchDataUser = async () => {
    GetDeviceByUser({
      OrganizationID: groupID,
      RowNumber: 0,
      PageNumber: 0,
      Search: valueSearch,
      DeviceGroup: '',
      TypeDevice: 'Medical',
    })
      .then((res) => {
        if (res.RespCode === 0) {
          const resData = res.DeviceLst.filter((device) => {
            return device.Status !== 3;
          });
          const dataSorted = resData.sort((a, b) => {
            return new Date(b.TimeCreate) - new Date(a.TimeCreate);
          });
          const dataSortedAddIndex = dataSorted.map((device, index) => {
            return {
              ...device,
              key: index + 1,
            };
          });
          setData(dataSortedAddIndex);
          setLoading(false);
        } else {
          message.error(res.RespText);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };
  useEffect(() => {
    setLoading(true);
    roleUser(localStorage.getItem('roleUser')) ? fetchDataGroup() : fetchDataUser();
  }, [valueSearch]);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDeviceSelected(selectedRows);
    },
  };
  const btnSearch = (value) => {
    setValueSearch(value);
  };
  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Schedule deviceSelected={deviceSelected} />
        <Search allowClear onSearch={btnSearch} placeholder="Tìm kiếm" style={{ width: 300 }} />
      </div>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        rowSelection={{ ...rowSelection }}
        pagination={false}
      ></Table>
    </div>
  );
};

export default CreateSchedule;
