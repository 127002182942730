import { PrinterOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { useRecoilValue } from 'recoil';
import { censersMov } from '../../../config/Recoil';
import FormReportBVDK from './formReportBVDK';
import './LstDeviceMov.scss';
class ComponentToPrint extends React.Component {
  render() {
    return (
      <div className="report">
        <FormReportBVDK lstMoveDevice={this.props.lstMoveDevice} censers={this.props.getCenser}></FormReportBVDK>
      </div>
    );
  }
}

const LstDeviceMov = ({ lstMoveDevice }) => {
  const valueQrCode = useRef();
  const getCenser = useRecoilValue(censersMov);
  return (
    <div>
      <div style={{ display: 'none' }}>
        <ComponentToPrint ref={valueQrCode} lstMoveDevice={lstMoveDevice} getCenser={getCenser} />
      </div>
      <ReactToPrint
        documentTitle="Báo cáo thiết bị luân chuyển"
        trigger={() => (
          <div type="primary">
            <Tooltip title="In phiếu luân chuyển thiết bị" placement="bottom">
              <PrinterOutlined style={{ fontSize: 20, cursor: 'pointer' }} />
            </Tooltip>
          </div>
        )}
        content={() => valueQrCode.current}
      />
    </div>
  );
};
export default LstDeviceMov;
