import { Space, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { GetMaintenanceScheduleByOrg } from '../../../../api/TSMaintenance';
import { manageDeviceError } from '../../../../config/Recoil';
import { formatDateDisplay } from '../../../../constants/DateTime';
import HanleCenser from '../../../Censer';
import { dateDisplay, statusApprove, typeSchedule } from '../../../helper/mapping';
import PrintDeviceMaintance from '../../../Print/DeviceMaintance';
import FormRepair from '../../../Print/Repair';
import ApproveDivide from '../Approve';

const Contact = () => {
  const [data, setData] = useState([]);
  const [censors, setCensers] = useState([]);
  const [loading, setLoading] = useState(true);
  const getHeaderDivide = useRecoilValue(manageDeviceError);
  useEffect(() => {
    GetMaintenanceScheduleByOrg({
      OrganizationID: localStorage.getItem('groupId'),
    }).then((res) => {
      const response = res.MaintenanceScheduleLst.filter((a) => a.Type === '10').map((de, index) => {
        const receive = de.Description.split(',');
        return {
          ...de,
          key: index + 1,
          DeviceLst2: de.DeviceLst.map((a) => {
            return `${a.DeviceName}( ${a.DeviceCode} ),`;
          }),
          ReceiveName: receive[0],
          ReceiveAddress: receive[1],
          ReceivePhone: receive[2],
        };
      });
      setData(response);
      setLoading(false);
    });
  }, [getHeaderDivide]);
  const columns = [
    {
      title: 'STT',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Người tạo',
      dataIndex: 'CreaterName',
      key: 'CreaterName',
    },
    {
      title: 'TG tạo',
      dataIndex: 'TimeCreate',
      key: 'TimeCreate',
      render: (record) => {
        return dateDisplay(record, formatDateDisplay);
      },
    },
    {
      title: 'TG bắt đầu bảo dưỡng',
      dataIndex: 'TimeStart',
      key: 'TimeStart',
      render: (record) => {
        return dateDisplay(record, formatDateDisplay);
      },
    },
    {
      title: 'Loại bảo dưỡng',
      dataIndex: 'Note',
      key: 'Note',
      render: (record) => {
        return typeSchedule(parseInt(record)).label;
      },
    },
    {
      title: 'DS thiết bị',
      dataIndex: 'DeviceLst2',
      key: 'DeviceLst2',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'Status',
      key: 'Status',
      render: (record) => {
        return <Tag color={statusApprove(record).color}>{statusApprove(record).label}</Tag>;
      },
    },
    {
      title: 'Thông tin',
      dataIndex: 'Description',
      key: 'Description',
    },
    {
      title: 'Người xác nhận',
      dataIndex: 'ApproveID',
      key: 'ApproveID',
    },
    {
      title: 'Ghi chú xác nhận',
      dataIndex: 'ApproveNote',
      key: 'ApproveNote',
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (record) => (
        <div style={{ display: 'flex' }}>
          {record.Status === 1 && <ApproveDivide headerInfo={record} />}
          <FormRepair infoPrint={record} />
        </div>
      ),
    },
  ];
  return (
    <div>
      <div style={{ textAlign: 'end' }}>
        <Space>
          <PrintDeviceMaintance deviceLst={data} censor={censors} />
          <HanleCenser censerType="DeviceMaintanceContact" getCensers={(data) => setCensers(data)} />
        </Space>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        locale={{
          emptyText: 'Không có dữ liệu',
        }}
      ></Table>
    </div>
  );
};

export default Contact;
