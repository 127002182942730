import { EditTwoTone, HomeOutlined, ProfileOutlined, TeamOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Dropdown, Input, Menu, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { GetUserInfo } from '../../api/User';
import { organizationInfo } from '../../config/Recoil';
import { GROUP_API } from '../../constants/Group';
import { CreateGroup, GetGroupList } from '../../containers/Group';
import { ChangeInfo, ChangePass, InfoUser } from '../../containers/User';
import { setGroupId, setGroupName } from '../../helpers/auth';
import './dashboard.scss';
const Dashboard = () => {
  const groups = GetGroupList();
  const [userInfo, setUserInfo] = React.useState([]);
  const [orgInfo, setOrgnInfo] = useRecoilState(organizationInfo);
  const [valueSearch, setValueSearch] = useState('');
  useEffect(() => {
    GetUserInfo().then((res) => {
      setUserInfo(res.UserInfo);
    });
  }, []);
  const infoMenu = (
    <Menu>
      <Menu.Item key="change-info">
        <ChangeInfo userInfo={userInfo} />
      </Menu.Item>
      <Menu.Item key="change-pass">
        <ChangePass />
      </Menu.Item>
    </Menu>
  );
  const setGroupInfo = (group) => {
    setOrgnInfo(group);
    setGroupId(group.OrganizationID);
    setGroupName(group.OrganizationName);
  };
  return (
    <div id="container">
      <h1 id="header">TRANG CHỦ</h1>
      <Row justify="space-around" align="top" id="dashboard-content">
        {userInfo && (
          <Col xs={24} sm={24} md={10} lg={8} xl={6} id="dashboard-user-profile">
            <Divider orientation="left">
              <p className="sub-header">
                <ProfileOutlined />
                {' Thông tin người dùng'}
              </p>
            </Divider>
            <div className="user-info-card">
              <InfoUser userInfo={userInfo} />
              <Dropdown overlay={infoMenu} className="dropdown" trigger={['click']}>
                <a>
                  <EditTwoTone style={{ fontSize: 24, color: '#0e0f0e' }} />
                </a>
              </Dropdown>
            </div>
          </Col>
        )}

        <Col xs={24} sm={24} md={12} lg={16} xl={18} id="dashboard-group">
          <Divider orientation="left">
            <p className="sub-header">
              <TeamOutlined />
              {'Phòng ban'}
            </p>
          </Divider>
          <div style={{ textAlign: 'end' }}>
            <Input
              onChange={(e) => setValueSearch(e.target.value)}
              autoFocus
              placeholder="Tìm kiếm phòng ban"
              style={{ width: 300 }}
            />
          </div>
          <Row justify="space-start">
            {localStorage.getItem('roleuser') == '10' && <CreateGroup />}
            {groups && groups.length === 0 && (
              <h3 style={{ margin: 'auto', paddingTop: 30 }}>
                Hiện tại bạn chưa được phân quyền. Hãy liên hệ quản lý để được cấp quyền truy cập
              </h3>
            )}
            {groups &&
              groups
                .filter((group) => group.OrganizationName.toLowerCase().indexOf(valueSearch.toLowerCase()) !== -1)
                .map((group) => (
                  <Link
                    to={`${GROUP_API}/${group.OrganizationID}`}
                    key={group.OrganizationID}
                    onClick={() => setGroupInfo(group)}
                  >
                    <Card hoverable className="dashboard-card" cover={<HomeOutlined style={{ fontSize: 40 }} />}>
                      <p>
                        <b>{group.OrganizationName}</b>
                      </p>
                    </Card>
                  </Link>
                ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default Dashboard;
