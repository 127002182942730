import React from "react";
import { Route, useHistory, Redirect, useLocation } from "react-router-dom";
const PrivateRoute = ({
  path,
  component: Component,
  layout: Layout,
  ...rest
}) => {
  var history = useHistory();
  const tokenUser = localStorage.getItem("token");
  return (
    <Route
      {...rest}
      render={(props) => {
        return(
          <Layout>
            {(tokenUser ? <Component /> : history.push("/check-user"))}
          </Layout>
        )
      }}
    />
  );
};
export default PrivateRoute;
