import { Input, message, Modal } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import {CreateMoveDeviceDoc} from '../../../../../../api/TSOrganization'
import { API_DEVICE_MOV } from '../../../../../../constants/Device'
const DivisionRepair = ({devices}) => {
    const [ visible, setVisible ] = useState(false)
    const [ note, setNote ] = useState('')
    const history = useHistory()
    const openModal = () => {
        setVisible(true)
    }
    const onCancel = () => {
        setVisible(false)
    }
    const onOk = () => {
        if(note){
            const req = {
                TimeMove: moment().format('YYYY-MM-DD HH:mm:ss'),
                OrganizationIDOld: localStorage.getItem('groupId'),
                OrganizationIDNew: 193,
                OrganizationNameNew: 'Phòng vật tư y tế',
                Description: note,
                DeviceLst: devices
            }
            CreateMoveDeviceDoc({
                MoveDeivceDocInfo: req
            }).then(res => {
                if(res.RespCode === 0){
                    message.success('Thành công')
                    history.push(API_DEVICE_MOV)
                    setVisible(false)
                }
            })
        }
        else{
            message.warning('Hãy nhập lý do')
        }
    }
    const handleChange = (e) => {
        setNote(e.target.value)
    }
    return (
        <div>
            <Modal visible={visible} title="Điều chuyển sang phòng vật tư để sửa chữa" onCancel={onCancel} onOk={onOk}>
                <Input.TextArea onChange={handleChange} placeholder="Lý do (*)"/>
            </Modal>
            <div onClick={openModal}>Điều chuyển sang phòng vật tư</div>
        </div>
    )
}

export default DivisionRepair
