import React from "react";
import {
  UserOutlined,
  PhoneOutlined,
  GoogleOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import "./Info.scss";
const InfoUser = (props) => {
  return (
    <div>
      <p>
        <UserOutlined className="icon" />
        {props.userInfo.FullName}
      </p>
      <p>
        <PhoneOutlined className="icon" />
        {props.userInfo.UserName}
      </p>
      <p>
        <GoogleOutlined className="icon" />
        {props.userInfo.Email}
      </p>
      <div>
        <HomeOutlined className="icon" />
        {props.userInfo.Address}
      </div>
    </div>
  );
};

export default InfoUser;
