import {
  DiffOutlined,
  FileAddOutlined,
  FileExclamationOutlined,
  LaptopOutlined,
  RetweetOutlined,
  ScheduleOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserOutlined,
  QrcodeOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Tabs } from 'antd';
import React from 'react';
import CreateLstItem from '../../config/Create';
import { ListDevide } from '../../containers/Contents/Divide';
import ContexProvider from '../../containers/Contents/Maintain/ContextProvider';
import { GetList } from '../../containers/Contents/ManageEquipment';
import CreateLstDivice from '../../containers/Contents/ManageEquipment/Create/LstDevice';
import { LstPrintQrCode, LstReport } from '../../containers/Contents/QRcode';
import { CreateSetDevice, LstSetDevice } from '../../containers/Contents/SetDevice';
import { ListDepartment } from '../../containers/Department';
import { Overview } from '../../containers/Group';
import { MemberList } from '../../containers/Group/Members';
import './Content.scss';
const { TabPane } = Tabs;
const changeTab = (key) => {
  console.log(key);
};
const Content = (props) => {
  return (
    <div className="content">
      <Tabs activeKey={props.currentTab} onChange={changeTab}>
        <TabPane
          tab={
            <span onClick={() => props.toOverview()}>
              <TeamOutlined />
              Tổng Quan
            </span>
          }
          key="overview"
        >
          <Overview
            des={props.des}
            address={props.address}
            phone={props.phone}
            email={props.email}
            groupID={props.id}
          />
        </TabPane>
        <TabPane
          tab={
            <span onClick={() => props.toMemberList()}>
              <UserOutlined />
              Thành viên
            </span>
          }
          key="members"
        >
          <MemberList id={props.id} members={props.members} role={props.role} />
        </TabPane>
        <TabPane
          tab={
            <span onClick={() => props.toEquipmentList()}>
              <LaptopOutlined />
              Thiết bị
            </span>
          }
          key="equipments"
        >
          <GetList role={props.role} groupId={props.groupId} />
        </TabPane>
        <TabPane
          tab={
            <span onClick={() => props.toAddDevice()}>
              <FileAddOutlined />
              Thêm thiết bị
            </span>
          }
          key="addDevice"
        >
          <CreateLstItem organizationID={props.groupId} />
        </TabPane>
        {props.role !== 3 && (
          <TabPane
            tab={
              <span onClick={() => props.toDeviceMove()}>
                <RetweetOutlined />
                Luân chuyển
              </span>
            }
            key="deviceMove"
          >
            <ListDepartment groupId={props.groupId} />
          </TabPane>
        )}
        <TabPane
          tab={
            <span onClick={() => props.toReport()}>
              <FileExclamationOutlined /> Báo cáo
            </span>
          }
          key="report"
        >
          <ContexProvider groupId={props.groupId} role={props.role} />
        </TabPane>
        {props.role !== 3 && (
          <TabPane
            tab={
              <span onClick={() => props.toLstDevide()}>
                <ScheduleOutlined />
                Bảo dưỡng
              </span>
            }
            key="list_divide"
          >
            <ListDevide OrganizationID={props.groupId} />
          </TabPane>
        )}
        {props.role !== 3 && (
          <TabPane
            tab={
              <span onClick={() => props.toCreateLstDevice()}>
                <FileAddOutlined />
                Thêm Excel
              </span>
            }
            key="create_lst_device"
          >
            <CreateLstDivice />
          </TabPane>
        )}
        {props.role !== 3 && (
          <TabPane
            tab={
              <span onClick={() => props.toSetDevice()}>
                <DiffOutlined />
                Tạo bộ thiết bị
              </span>
            }
            key="set_device1"
          >
            <CreateSetDevice organizationID={props.groupId} />
          </TabPane>
        )}
        {props.role !== 3 && (
          <TabPane
            tab={
              <span onClick={() => props.toLstDevice()}>
                <UnorderedListOutlined />
                Danh sách bộ thiết bị
              </span>
            }
            key="lst_set_device"
          >
            <LstSetDevice organizationID={props.groupId} />
          </TabPane>
        )}
        {props.role !== 3 && (
          <TabPane
            tab={
              <span onClick={() => props.toPrintQrCode()}>
                <QrcodeOutlined />
                In mã QR
              </span>
            }
            key="print_qrCode"
          >
            <LstPrintQrCode organizationID={props.groupId} />
          </TabPane>
        )}
        {props.role !== 3 && (
          <TabPane
            tab={
              <span onClick={() => props.toLstReportErr()}>
                <WarningOutlined />
                Hồ sơ thiết bị báo lỗi
              </span>
            }
            key="qr_code_lst_device_err"
          >
            <LstReport organizationID={props.groupId} />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};
export default Content;
