import { formatMoneyDisplay } from '../../containers/helper/mapping';

export const columnsDevice = [
  {
    title: 'STT',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Tên TB',
    dataIndex: 'DeviceName',
    key: 'DeviceName',
  },
  {
    title: 'Mã TB',
    dataIndex: 'DeviceCode',
    key: 'DeviceCode',
  },
  {
    title: 'Đơn vị',
    dataIndex: 'Unit',
    key: 'Unit',
  },
  {
    title: 'Giá trị ban đầu',
    dataIndex: 'DeviceValue',
    key: 'DeviceValue',
    render: (record) => {
      return formatMoneyDisplay(record);
    },
  },
];
