import React from "react";
import { Route } from "react-router-dom";
const PublicRoute = ({
  path,
  component: Component,
  layout: Layout,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <Layout>
            {" "}
            <Component {...props} />{" "}
          </Layout>
        );
      }}
    />
  );
};
export default PublicRoute;
