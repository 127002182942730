import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Modal, Space, Table, Tag } from "antd";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  LiquiApprove,
  LiquiDelete,
  LiquiGetByDocID,
  LiquiGetLst,
} from "../../../../api/liquidation";
import { API_DEVICE_LIQUI_CREATE } from "../../../../constants/Device";
import { AddKeyToLst } from "../../../helper/addKeyToLst";
import { statusApprove, timeDisplay } from "../../../helper/mapping";

const columnsModal = [
  {
    title: "STT",
    dataIndex: "key",
    key: "key",
  },
  {
    title: "Mã TB",
    dataIndex: "DeviceCode",
    key: "DeviceCode",
  },
  {
    title: "Tên TB",
    dataIndex: "DeviceName",
    key: "DeviceName",
  },
  {
    title: "Giá trị ban đầu",
    dataIndex: "DeviceValue",
    key: "DeviceValue",
  },
  {
    title: "Giá trị hiện tại",
    dataIndex: "DeviceValueCurrent",
    key: "DeviceValueCurrent",
  },
  {
    title: "Trạng thái",
    dataIndex: "Status",
    key: "Status",
  },
];

const ListLiquidation = () => {
  const [dataLiquidation, setDataLiquidation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [lineInfo, setLineInfo] = useState({});
  const [documentInfo, setDocumentInfo] = useState({});
  const [deviceSelect, setDeviceSelect] = useState({});
  const [visible, setVisible] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [type, setType] = useState("approve");
  const history = useHistory();
  const [form] = Form.useForm();
  const handleModal = (type, record) => {
    if (type === "approve") {
      setVisible(true);
    } else {
      setVisibleDelete(true);
    }
    setType(type);
    setLineInfo(record);
    LiquiGetByDocID({
      DocumentID: record.DocumentID,
    }).then((res) => {
      setDocumentInfo(res.LiquidationInfo);
    });
  };
  const hanleConfirm = (elements) => {
    setBtnLoading(true);
    if (type === "approve") {
      LiquiApprove({
        DocumentID: lineInfo.DocumentID,
        DeviceLst: deviceSelect,
      }).then((res) => {
          setBtnLoading(false)
        if (res.RespCode === 0) {
          setVisible(false);
          message.success("Xác nhận thành công");
        }
      });
    } else if (type === "delete") {
      LiquiDelete({
        DocumentID: lineInfo.DocumentID,
      }).then((res) => {
          setBtnLoading(false)
        if (res.RespCode === 0) {
          setVisible(false);
          message.success("Xác nhận thành công");
        }
      });
    }
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDeviceSelect(selectedRows);
    },
  };
  useEffect(() => {
    setLoading(true);
    LiquiGetLst().then((res) => {
      setLoading(false);
      if (res.RespCode === 0) {
        setDataLiquidation(AddKeyToLst(res.LiquidationLst));
      }
    });
  }, []);
  const columns = [
    { title: "STT", dataIndex: "key", key: "key" },
    { title: "Mã phiếu", dataIndex: "DocumentID", key: "DocumentID" },
    {
      title: "Thời gian tạo",
      dataIndex: "TimeCreate",
      key: "TimeCreate",
      render: (record) => {
        return timeDisplay(record);
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "Status",
      key: "Status",
      render: (record) => {
        return (
          <Tag color={statusApprove(record).color}>
            {statusApprove(record).label}
          </Tag>
        );
      },
    },
    { title: "Người thực hiện", dataIndex: "CreaterName", key: "CreaterName" },
    { title: "Lý do", dataIndex: "Reason", key: "Reason" },
    {
      title: "",
      dataIndex: "",
      render: (record) => (
        <div>
          {record.Status === 1 && (
            <Space>
              <Button
                type="dashed"
                onClick={() => handleModal("approve", record)}
              >
                Xác nhận
              </Button>
              <DeleteOutlined
                className="btn-icon"
                onClick={() => handleModal("delete", record)}
              />
            </Space>
          )}
        </div>
      ),
    },
  ];
  return (
    <div>
      <Modal
        visible={visible}
        title={`${type === "approve" ? " Xác nhận" : "Hủy"} phiếu thanh lý`}
        confirmLoading={btnLoading}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={() => hanleConfirm()}
        width={700}
      >
        <div>
          <Table
            dataSource={documentInfo.DeviceLst}
            columns={columnsModal}
            pagination={false}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
          ></Table>
        </div>
      </Modal>
      <Modal
        visible={visibleDelete}
        title="Hủy phiếu thanh lý"
        confirmLoading={btnLoading}
        okText="Xác nhận"
        onCancel={() => setVisibleDelete(false)}
        onOk={() => hanleConfirm()}
      >
        <Form form={form} layout="vertical" onFinish={hanleConfirm}>
          <Form.Item label="Ghi chú xác nhận" name="Note">
            <Input.TextArea placeholder="Hãy nhập lý do" />
          </Form.Item>
        </Form>
      </Modal>
      <div style={{ textAlign: "end", paddingRight: 20, paddingBottom: 20 }}>
        <Button
          type="primary"
          onClick={() => history.push(API_DEVICE_LIQUI_CREATE)}
        >
          <PlusOutlined /> Tạo yêu cầu thanh lý
        </Button>
      </div>
      <Table
        dataSource={dataLiquidation}
        columns={columns}
        loading={loading}
      ></Table>
    </div>
  );
};

export default ListLiquidation;
