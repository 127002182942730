import { Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Notification from '../../../config/Noti';
import { AddKeyToLst } from '../../helper/addKeyToLst';
import { formatMoneyDisplay, timeDisplay } from '../../helper/mapping';
import './Systems.scss';
const columns = [
  {
    title: 'STT',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Mã TB',
    dataIndex: 'DeviceCode',
    key: 'DeviceCode',
  },
  {
    title: 'Tên thiết bị',
    dataIndex: 'DeviceName',
    key: 'DeviceName',
  },
  {
    title: 'Giá trị ban đầu',
    dataIndex: 'DeviceValue',
    key: 'DeviceValue',
    render: (record) => {
      return formatMoneyDisplay(record);
    },
  },
  {
    title: 'Giá trị hiện tại',
    dataIndex: 'DeviceValueCurrent',
    key: 'DeviceValueCurrent',
    render: (record) => {
      return formatMoneyDisplay(record);
    },
  },
  {
    title: 'Model',
    dataIndex: 'Model',
    key: 'Model',
  },
  {
    title: 'Serial',
    dataIndex: 'Serial',
    key: 'Serial',
  },
  {
    title: 'Mã phiếu',
    dataIndex: 'DocumentCode',
    key: 'DocumentCode',
  },
];
const columnsAccre = [
  ...columns.filter((column) => column.dataIndex !== 'DocumentCode'),
  {
    title: 'Thời gian bảo dưỡng',
    dataIndex: 'TimeAccreditation',
    key: 'TimeAccreditation',
    render: (record) => {
      return timeDisplay(record);
    },
  },
  {
    title: 'Thời gian chênh lệch',
    dataIndex: '',
    key: '',
    render: (record) => {
      return moment().diff(record.TimeAccreditation, 'days') + ' ngày';
    },
  },
];
const NotiSystem = () => {
  const [devicesMaintance, setDevicesMaintance] = useState([]);
  const [devicesAccreditation, setDevicesAccreditation] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Notification().then((res) => {
      setLoading(false);
      setDevicesMaintance(res[0]);
      setDevicesAccreditation(res[1]);
    });
  }, []);
  return (
    <div className="systems">
      <h3 className="title">Thiết bị đến lịch bảo dưỡng</h3>
      <Table dataSource={AddKeyToLst(devicesMaintance)} columns={columns} loading={loading} pagination={false} />
      <h3 className="title accreditation">Thiết bị đến lịch kiểm định</h3>
      <Table
        dataSource={AddKeyToLst(devicesAccreditation)}
        columns={columnsAccre}
        loading={loading}
        pagination={false}
      />
    </div>
  );
};

export default NotiSystem;
