import React from 'react';
import { ConvertCsvToJson } from '../../../../../config';
import { VTYT } from '../../../../../config/URL/Qlts';
import { allowCreate } from '../../../../../constants/Group/Roles';
const CreateLstDivice = () => {
  return (
    <div style={{ width: '100%' }}>
      {allowCreate(VTYT) ? (
        <ConvertCsvToJson />
      ) : (
        <div style={{ textAlign: 'center' }}>
          <b>Phòng ban không có quyền upload</b>
        </div>
      )}
    </div>
    // <ConvertCsvToJson />
  );
};
export default CreateLstDivice;
