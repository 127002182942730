import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal, Table, Tooltip } from 'antd';
import React from 'react';
import { GetMaintenanceScheduleByID } from '../../../../api/TSMaintenance';
import './InfoDivide.scss';
const columns = [
  {
    title: 'Tên thiết bị',
    dataIndex: 'DeviceName',
    key: 'DeviceName',
  },
  {
    title: 'Mã thiết bị',
    dataIndex: 'DeviceCode',
    key: 'DeviceCode',
  },
  {
    title: 'Tên thiết bị',
    dataIndex: 'DeviceName',
    key: 'DeviceName',
  },
  {
    title: 'Serial',
    dataIndex: 'Serial',
    key: 'Serial',
  },
  {
    title: 'Model',
    dataIndex: 'Model',
    key: 'Model',
  },
];
const InfoDivide = ({ DocumentID }) => {
  const showInfoDive = () => {
    GetMaintenanceScheduleByID({
      DocumentID: DocumentID,
    }).then((res) => {
      let infoDivide = res.MaintenanceScheduleInfo;
      Modal.info({
        title: 'Thông tin lịch bảo dưỡng',
        width: 800,
        content: (
          <div>
            <p>{`Người tạo: ${infoDivide.CreaterID}`}</p>
            <p>{`Ghi chú: ${infoDivide.Note}`}</p>
            <p>Trạng thái: {infoDivide.Status === 1 ? 'Đang bảo dưỡng' : 'Bảo dưỡng xong'}</p>
            <p>{`Mô tả: ${infoDivide.Description}`}</p>

            {infoDivide.DeviceLst.length === 0 ? (
              'Không có thiết bị nào bảo dưỡng'
            ) : (
              <div>
                <b>Danh sách thiết bị bảo dưỡng: </b>
                <Table dataSource={infoDivide.DeviceLst} columns={columns} pagination={false} />
              </div>
            )}
          </div>
        ),
      });
    });
  };
  return (
    <div onClick={showInfoDive} style={{ fontSize: 20 }}>
      <Tooltip placement="bottom" title="thông tin">
        <InfoCircleOutlined style={{ color: '#2f76cc', cursor: 'pointer' }} />
      </Tooltip>
    </div>
  );
};
export default InfoDivide;
