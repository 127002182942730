import { Button, Divider, Input, message, Modal, Tabs } from 'antd'
import { CheckOutlined, SendOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import { CheckOTPLostPassword, LotPassword } from '../../../../api/User';
import { validateMail } from '../../../../helpers/mapping';
const { TabPane } = Tabs;

const ForgotPass = () => {
  const [ visible, setVisible ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ disabledTab, setDisabledTab ] = useState(true)
  const [ valueOTP, setValueOTP ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ tab, setTab ] = useState('1')
  const openModal = () => {
    setVisible(true)
  }
  const getOTP = () => {
    if(validateMail(email)){
      setLoading(true)
      LotPassword({
        Email: email
      }).then(res => {
        setLoading(false)
        if(res.RespCode === 0){
          message.success('Hệ thống đã gửi OTP về email của bạn')
          setDisabledTab(false)
          setTab('2')
        }
      })
    }else{
      message.warning('Định dạng email không đúng')
    }
  }
  const confirmOTP = () => {
    setLoading(true)
    CheckOTPLostPassword({
      Email: email,
      OTPCode: valueOTP
    }).then(res => {
      if(res.RespCode === 0){
        message.success('Hệ thống đã gửi mật khẩu mới về email đăng ký. Hãy lấy mật khẩu đó để đăng nhập')
        setLoading(false)
        setVisible(false)
      }
    })
  }
  return (
    <div>
      <Modal visible={visible} title="Quên mật khẩu" footer={
        <div>
          <Button type='primary' onClick={()=> setVisible(false)}>Đóng</Button>
        </div>
      }>
        <Tabs activeKey={tab}>
          <TabPane tab="Lấy OTP" key="1">
            <p>
              Mã OTP sẽ được gửi về email của bạn đăng ký tài khoản
            </p>
            <Input onChange={(e)=> setEmail(e.target.value)} placeholder="Hãy nhập email của bạn đã đăng ký tài khoản" onPressEnter={getOTP}/>
            <Divider/>
            <Button type='primary' onClick={getOTP} loading={loading}> <SendOutlined /> Lấy OTP</Button>
          </TabPane>
          <TabPane tab="Xác nhận OTP" key="2" disabled={disabledTab}>
            <p>
              Nhập mã OTP đã gửi về email
            </p>
            <Input onChange={(e) => setValueOTP(e.target.value)} placeholder="OTP" onPressEnter={confirmOTP}/>
            <Divider/>
            <Button type='primary' onClick={confirmOTP} loading={loading}> <CheckOutlined /> Xác nhận</Button>
          </TabPane>
        </Tabs>
      </Modal>
      <Button type='text' onClick={openModal}>Quên mật khẩu??</Button>
    </div>
  )
}

export default ForgotPass
