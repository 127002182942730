import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  InputNumber,
  Space,
  Table,
} from "antd";
import {FileAddOutlined} from '@ant-design/icons'
import moment from "moment";
import Column from "antd/lib/table/Column";
import {createUid} from '../../helpers/createUid';
import {CreateDeviceLst} from '../../api/Device';
import { GetDefaultValue } from "../../api/defaultValue";
const columns = [
  {
    title: "STT",
    dataIndex: 'key',
    key: 'key',
  },
    {
        title: "Tên thiết bị",
        dataIndex: 'DeviceName',
        key: 'DeviceName',
    },
    {
        title: "Mã thiết bị",
        dataIndex: 'DeviceCode',
        key: 'DeviceCode',
    },
    {
      title: "Mã tham chiếu",
      dataIndex: 'ReferenceCode',
      key: 'ReferenceCode',
    },
  //   {
  //     title: "Mã CO/CQ",
  //     dataIndex: 'CodeCOCQ',
  //     key: 'CodeCOCQ',
  // },
  {
    title: "Giá trị",
    dataIndex: 'DeviceValue',
    key: 'DeviceValue',
},
{
  title: "Loại thiết bị",
  dataIndex: 'Type',
  key: 'Type',
},
{
  title: "Chu kỳ bảo dưỡng",
  dataIndex: 'Schedule',
  key: 'Schedule',
},
{
  title: "Ghi chú",
  dataIndex: 'Ghi chú',
  key: 'Ghi chú',
}
]
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const CreateLstItem = ({organizationID}) => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [deviceValue, setDeviceValue] = useState(0);
  const [schedule, setSchedule] = useState(0);
  const [type, lstType] = useState([]);
  const [form] = Form.useForm();
  const [selectType, setSelectType] = useState('')
  const [allowPush, setAllowPush] = useState(false);
  const {Option} = Select;
  const onOk = () => {
    form.submit();
  };
  useEffect(()=>{
    GetDefaultValue({
      TableName: "Medical"
    }).then(res =>{
      lstType(res.DefaultValueLst)
    })
  },[])
  const handleChange = (value)=>{
    setSelectType(value)
  }
  const onFinish = (values) => {
    let TimeStart = values["TimeStart"].format("yyyy-MM-DD HH:mm:ss");
    let TimeEnd = values["TimeEnd"].format("yyyy-MM-DD HH:mm:ss");
    const Schedule = parseInt(schedule);
    const QRCode = createUid().slice(0,10);
    const fullValue = {
      ...values,
      DeviceValue: deviceValue,
      TimeStart: TimeStart,
      TimeEnd: TimeEnd,
      Schedule: Schedule,
    }
      const timeCurrent = moment().format('YYMMDD');
      const lstA = [];
      for(let x = 0; x < values.Quantity; x++) {
          lstA.push(
              {
                  key: x +1,
                  ...fullValue,
                  DeviceCode: `${values.DeviceCode}${timeCurrent}${x}`,
                  QRCode: createUid().slice(0,15),
              }
              )
      }
      lstA && setData(lstA);
      setVisible(false);
      setAllowPush(true);
  };
  const pushItems = ()=>{
    const req = {
      OrganizationID: organizationID,
      DeviceLst: data
    }
    CreateDeviceLst(req).then(res =>{
      if(res.RespCode === 0){
        message.success('Đã thêm thiết bị thành công');
      }else{
        message.error(res.RespText)
      }
    })
  }
  return (
    <div style={{width: '100%'}}>
      <Modal
        title="Thêm mới thiết bị"
        visible={visible}
        onOk={onOk}
        onCancel={() => setVisible(false)}
        okText="Thêm mới"
        cancelText="Hủy"
      >
        <Form name="info_device" form={form} onFinish={onFinish} {...layout}>
          <Form.Item
            name="DeviceName"
            label="Tên"
            rules={[
              {
                required: true,
                message: "Hãy nhập tên thiết bị",
              },
            ]}
          >
            <Input autoFocus />
          </Form.Item>
          <Form.Item
            name="DeviceCode"
            label="Mã thiết bị"
            rules={[
              {
                required: true,
                message: "Hãy nhập mã thiết bị",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="ReferenceCode" label="Mã tham chiếu">
            <Input />
          </Form.Item>
          {/* <Form.Item name="CodeCOCQ" label="Mã CO/CQ">
            <Input />
          </Form.Item> */}
          <Form.Item name="Quantity" label="Số lượng" rules={[
            { required: true, message:"Hãy nhập số lượng"}
          ]}>
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
              name="DeviceValue"
              label="Giá trị tài sản"
            >
              <InputNumber
                min={0}
                style={{ width: 160 }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(value) => setDeviceValue(value)}
              />{" "}
              (VNĐ)
            </Form.Item>

            <Form.Item
              name="Type"
              label="Loại thiết bị"
              rules={[
                {
                  required: true,
                  message: "Hãy nhập loại thiết bị",
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: 250 }}
                optionFilterProp="children"
                onChange={handleChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {type.map((item) => (
                  <Option value={item.DefaultValue}>
                    {item.DefaultValue}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="Origin" label="Xuất xứ">
              <Input />
            </Form.Item>

            <Form.Item name="Manufacturer" label="Nhà cung cấp">
              <Input />
            </Form.Item>
            <Form.Item
              name="TimeStart"
              label="Thời gian bắt đầu"
              rules={[
                {
                  required: true,
                  message: "Hãy nhập thời gian bắt đầu",
                },
              ]}
            >
              <DatePicker
                format="DD-MM-YYYY"
                style={{ width: 250 }}
                placeholder="Thời gian bắt đầu"
              />
            </Form.Item>
            <Form.Item
              name="TimeEnd"
              label="Hạn sử dụng"
              rules={[
                {
                  required: true,
                  message: "Hãy nhập hạn sử dụng",
                },
              ]}
            >
              <DatePicker
                format="DD-MM-YYYY"
                style={{ width: 250 }}
                placeholder="Hạn sử dụng"
              />
            </Form.Item>
            <Form.Item
              label="Chu kỳ bảo dưỡng"
              rules={[
                {
                  required: true,
                  message: "Hãy chọn chu kỳ bảo dưỡng định kỳ",
                },
              ]}
            >
              <Select style={{ width: 120 }} onChange={(e) => setSchedule(e)}>
                <Select.Option value="1">1 tháng</Select.Option>
                <Select.Option value="3">3 tháng</Select.Option>
                <Select.Option value="6">6 tháng</Select.Option>
                <Select.Option value="12">12 tháng</Select.Option>
                <Select.Option value="24">24 tháng</Select.Option>
                <Select.Option value="0">Không có</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="Note" label="Ghi chú" >
              <Input.TextArea  style={{height: 'auto'}}/>
            </Form.Item>
          </Form>
      </Modal>
      <div style={{padding: '10px 0', display: 'flex', justifyContent:'space-between'}}>
        <Button onClick={()=>setVisible(true)}>Thêm thiết bị</Button>
        <Button disabled={!allowPush} type="primary" onClick={pushItems} style={{marginRight: 30}}><FileAddOutlined/>Tạo mới</Button>
      </div>
      <Table dataSource={data}>
      
      {columns.map(column =>(
        <Column title={column.title} dataIndex={column.dataIndex} key={column.key}/>
      ))}
        </Table>
    </div>
  );
};

export default CreateLstItem;
