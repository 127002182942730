import {
  FileAddOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Popover,
  Row,
  Select,
  Space,
  Tooltip,
} from 'antd';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { AddDefaultValue, GetDefaultValue } from '../../../../api/defaultValue';
import { CreateDeviceInfo, GetDeviceByCompany, GetDeviceByOrganization, GetDeviceInfo } from '../../../../api/Device';
import { manageDevice } from '../../../../config/Recoil';
import { companyID } from '../../../../config/URL/Qlts';
import { API_DEVICE_ADD_EXCEL } from '../../../../constants/Device';
import { getGroupId, getGroupName } from '../../../../helpers/auth';
import { convertCharAtFirst, toUpperCaseFirstChar } from '../../../../helpers/mapping';
import { createUid } from '../../../helper/createUid';
import jsUcfirst from '../../../helper/jsUcfirst';
import { formatDateImport } from '../../../helper/mapping';
import './Create.scss';
const { Option } = Select;
const { RangePicker } = DatePicker;
const messageValidEmpty = 'Giá trị không hợp lệ';
const messageValidExist = 'Giá trị đã tồn tại';
const Create = () => {
  const [getDevice, setDevice] = useRecoilState(manageDevice);
  const [popoverDeviceName, setPopoverDeviceName] = useState(false);
  const [devices, setDevices] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loadingPopover, setLoadingPopover] = useState(false);
  const [deviceName, setDeviceName] = useState('');
  const [lstType, setLstType] = useState([]);
  const [deviceValue, setDeviceValue] = useState(0);
  const [deviceGroup, setDeviceGroup] = useState([]);
  const [selectDeviceGroup, setSelectDeviceGroup] = useState('');
  const [messageValid, setMessageValid] = useState('');
  const [inputAddDeviceGroup, setInputAddDeviceGroup] = useState('');
  const [validateInputAddDeviceGroup, setValidateInputAddDeviceGroup] = useState(true);
  const [inputAddDeviceType, setInputAddDeviceType] = useState('');
  const [validateInputAddDeviceType, setValidateInputAddDeviceType] = useState(true);
  const [form] = Form.useForm();
  const history = useHistory();
  const OrganizationID = getGroupId();
  const setDeviceCode = convertCharAtFirst(getGroupName());
  useEffect(() => {
    GetDefaultValue({
      TableName: 'Medical',
    }).then((res) => {
      setLstType(res.DefaultValueLst);
    });
    GetDefaultValue({
      TableName: 'DeviceGroup',
    }).then((res) => {
      setDeviceGroup(res.DefaultValueLst);
    });
    form.setFieldsValue({ DeviceCode: setDeviceCode });
  }, []);
  const onOk = () => {
    form.submit();
  };
  const onFinish = async (values) => {
    const TimeStart = formatDateImport(values.TimeStart);
    const TimeImport = formatDateImport(values.TimeImport);
    const TimeManufacturer = formatDateImport(values.TimeManufacturer);
    function Warranty() {
      let WarrantyStart, WarrantyEnd;
      if (values.Warranty !== undefined) {
        WarrantyStart = formatDateImport(values['Warranty'][0]);
        WarrantyEnd = formatDateImport(values['Warranty'][1]);
        return { WarrantyStart, WarrantyEnd };
      } else {
        return {
          WarrantyStart: '',
          WarrantyEnd: '',
        };
      }
    }
    const QRCode = createUid().slice(0, 15);
    var req = {
      ...values,
      DeviceGroup: selectDeviceGroup,
      DeviceValue: deviceValue,
      TimeStart,
      OrganizationID,
      QRCode,
      TimeImport,
      WarrantyStart: Warranty().WarrantyStart,
      WarrantyEnd: Warranty().WarrantyEnd,
      OrganizationID: OrganizationID,
      TimeManufacturer,
    };
    async function request() {
      if (values.DeviceParameterLst === undefined || values.DeviceParameterLst.length === 0) {
        notification.info({
          message: 'Nhập thông số thiết bị',
          duration: 1,
        });
      } else {
        const devices = await GetDeviceByOrganization({
          OrganizationID: OrganizationID,
          RowNumber: 0,
          PageNumber: 0,
          Search: '',
          DeviceGroup: '',
          TypeDevice: 'Medical',
        });
        const countDevicesByOrg = devices.DeviceLst.length;
        if (values.Quantity === 1) {
          setBtnLoading(true);
          await CreateDeviceInfo({ DeviceInfo: { ...req, DeviceCode: setDeviceCode + `${countDevicesByOrg + 1}` } });
        } else {
          const lstA = [];
          for (let x = 0; x < values.Quantity; x++) {
            lstA.push({
              key: x + 1,
              ...req,
              DeviceCode: `${values.DeviceCode}${countDevicesByOrg + x + 1}`,
              ReferenceCode: values.ReferenceCode ? `${values.ReferenceCode}-${countDevicesByOrg + x + 1}` : '',
              QRCode: createUid(),
            });
          }
          setBtnLoading(true);
          await Promise.all(
            lstA.map((device) => {
              CreateDeviceInfo({
                DeviceInfo: device,
              });
            })
          );
        }
        setDevice(!getDevice);
        notification.success({
          message: 'Tạo thiết bị thành công',
          duration: 1,
        });
        setBtnLoading(false);
      }
    }
    request();
  };
  const handleDebounceFn = async (deviceName = '') => {
    const devicesResponse = await GetDeviceByCompany({
      OrganizationID: companyID,
      DeviceGroup: '',
      TypeDevice: 'Medical',
      Search: deviceName.trim(),
      RowNumber: 20,
      PageNumber: 1,
    });
    setDevices(devicesResponse.DeviceLst);
    setLoadingPopover(false);
  };
  const onChangeDeviceName = (deviceName) => {
    form.setFieldsValue({ DeviceName: deviceName });
    setDeviceName(deviceName);
    debounceFn(deviceName);
    setPopoverDeviceName(true);
    setLoadingPopover(true);
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), []);
  const selectDevice = async (device) => {
    const deviceInfo = await GetDeviceInfo({
      DeviceId: device.DeviceId,
    });
    const {
      DeviceName,
      ReferenceCode,
      Serial,
      Model,
      DeviceValue,
      Type,
      Unit,
      Origin,
      Manufacturer,
      DeviceParameterLst,
    } = deviceInfo.DeviceInfo;
    form.setFieldsValue({
      DeviceName,
      ReferenceCode,
      Serial,
      Model,
      DeviceValue,
      Type,
      Unit,
      Origin,
      Manufacturer,
      DeviceParameterLst,
    });
    setDeviceName(DeviceName);
    setPopoverDeviceName(false);
  };
  const contentDeviceList = (
    <ol style={{ height: '50vh', overflow: 'auto', paddingLeft: 15, width: 340 }}>
      {loadingPopover ? (
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingOutlined style={{ fontSize: 20 }} />
        </div>
      ) : (
        <div style={{ height: '100%' }}>
          {devices.length === 0 ? (
            <div
              className="no-data"
              style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <div>
                <div>Không có thiết bị nào</div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button type="link" onClick={() => setPopoverDeviceName(false)}>
                    Đóng
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div>
              {devices.map((device, index) => (
                <li
                  key={device.DeviceId}
                  className="suggest-device"
                  style={{
                    width: '100%',
                    padding: '10px 0',
                    borderBottom: '1px solid #ddd',
                    cursor: 'pointer',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={() => selectDevice(device)}
                >
                  {index + 1}. {device.DeviceName} - Serial: {device.Serial}
                </li>
              ))}
            </div>
          )}
        </div>
      )}
    </ol>
  );
  const getValueInputAddDeviceGroup = (value = '') => {
    setInputAddDeviceGroup(value);
    const checkExist = deviceGroup.some((device) => device.DefaultValue.toLowerCase() === value.trim().toLowerCase());
    setValidateInputAddDeviceGroup(value.trim() === '' || checkExist);
    if (value.trim() === '') {
      setMessageValid(messageValidEmpty);
    } else if (checkExist) {
      setMessageValid(messageValidExist);
    } else {
      setMessageValid('');
    }
  };
  const onAddDeviceGroup = async () => {
    const valueDeviceGroup = toUpperCaseFirstChar(inputAddDeviceGroup);
    await AddDefaultValue({
      TableName: 'DeviceGroup',
      DefaultValue: valueDeviceGroup,
      Type: valueDeviceGroup,
    }).then((res) => {
      if (res.RespCode === 0) {
        setInputAddDeviceGroup('');
        setDeviceGroup([
          ...deviceGroup,
          {
            DefaultValue: valueDeviceGroup,
            Type: valueDeviceGroup,
            TableName: 'DeviceGroup',
          },
        ]);
      }
    });
  };
  const onDropdownVisibleChange = (value) => {
    if (value) {
      setMessageValid('');
      setInputAddDeviceGroup('');
      setValidateInputAddDeviceGroup(true);
    }
  };

  const getValueInputAddDeviceType = (value = '') => {
    setInputAddDeviceType(value);
    const checkExist = lstType.some((device) => device.DefaultValue.toLowerCase() === value.trim().toLowerCase());
    setValidateInputAddDeviceType(value.trim() === '' || checkExist);
    if (value.trim() === '') {
      setMessageValid(messageValidEmpty);
    } else if (checkExist) {
      setMessageValid(messageValidExist);
    } else {
      setMessageValid('');
    }
  };
  const onAddDeviceType = async () => {
    const valueDeviceGroup = toUpperCaseFirstChar(inputAddDeviceGroup);
    await AddDefaultValue({
      TableName: 'TypeDevice',
      DefaultValue: valueDeviceGroup,
      Type: valueDeviceGroup,
    }).then((res) => {
      if (res.RespCode === 0) {
        setInputAddDeviceGroup('');
        setDeviceGroup([
          ...deviceGroup,
          {
            DefaultValue: valueDeviceGroup,
            Type: valueDeviceGroup,
            TableName: 'TypeDevice',
          },
        ]);
      }
    });
  };
  const onDropdownVisibleChangeType = (value) => {
    if (value) {
      setInputAddDeviceType('');
      setValidateInputAddDeviceType(true);
      setMessageValid('');
    }
  };
  return (
    <div className="device-add">
      <Button type="primary" size="large" onClick={onOk} loading={btnLoading} className="btn-create">
        Tạo mới thiết bị
      </Button>
      <div style={{ textAlign: 'end' }}>
        <Button className="btn-add-excel" onClick={() => history.push(API_DEVICE_ADD_EXCEL)}>
          <FileAddOutlined /> Thêm file excel
        </Button>
      </div>
      <Form name="info_device" onFinish={onFinish} form={form} layout="vertical">
        <Row gutter={20}>
          <Col span={12} lg={12} md={12} xs={24}>
            <Form.Item
              name="DeviceName"
              label="Tên"
              rules={[
                {
                  required: true,
                  message: 'Hãy nhập tên thiết bị',
                },
              ]}
            >
              <Popover
                content={contentDeviceList}
                visible={popoverDeviceName}
                title="Danh sách thiết bị"
                trigger="click"
                placement="bottom"
                style={{ height: '50vh', overflow: 'auto' }}
              >
                <Input
                  value={deviceName}
                  onChange={(e) => {
                    onChangeDeviceName(e.target.value);
                  }}
                  autoFocus
                  placeholder="Hãy nhập tên thiết bị"
                />
              </Popover>
              {/* <Input autoFocus placeholder="Hãy nhập tên thiết bị" /> */}
            </Form.Item>
          </Col>
          <Col span={6} lg={6} md={6} xs={24}>
            <Form.Item
              name="DeviceCode"
              label="Mã thiết bị"
              rules={[
                {
                  required: true,
                  message: 'Hãy nhập mã thiết bị',
                },
              ]}
            >
              <Input disabled placeholder="Hãy nhập mã thiết bị" />
            </Form.Item>
          </Col>
          <Col span={6} lg={6} md={6} xs={24}>
            <Form.Item name="ReferenceCode" label="Mã tham chiếu">
              <Input placeholder="Hãy nhập mã tham chiếu" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={6} lg={6} md={6} xs={24}>
            <Form.Item name="Serial" label="Mã Serial">
              <Input placeholder="Hãy nhập serial" />
            </Form.Item>
          </Col>
          <Col span={6} lg={6} md={6} xs={24}>
            <Form.Item name="Model" label="Mã Model">
              <Input placeholder="Hãy nhập model" />
            </Form.Item>
          </Col>
          <Col span={6} lg={6} md={6} xs={24}>
            <Form.Item
              name="Quantity"
              label="Số lượng"
              rules={[
                {
                  required: true,
                  message: 'Hãy nhập số lượng',
                },
              ]}
              initialValue={1}
            >
              <InputNumber min={0} className="width-100" placeholder="Hãy nhập số lượng" />
            </Form.Item>
          </Col>
          <Col span={6} lg={6} md={6} xs={24}>
            <Form.Item name="DeviceValue" label="Giá trị TB" initialValue={1}>
              <InputNumber
                placeholder="Hãy nhập giá trị của thiết bị"
                min={0}
                style={{ width: 160 }}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                onChange={(value) => setDeviceValue(value)}
              />
              (VNĐ)
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={6} lg={6} md={6} xs={24}>
            <Form.Item
              label="Đơn vị"
              name="Unit"
              rules={[
                {
                  required: true,
                  message: 'Hãy nhập đơn vị',
                },
              ]}
            >
              <Input placeholder="Hãy nhập đơn vị" />
            </Form.Item>
          </Col>
          <Col span={6} lg={6} md={6} xs={24}>
            <Form.Item label="Tình trạng TB" name="ImportStatus">
              <Select allowClear className="width-100" placeholder="Hãy chọn tình trạng thiết bị">
                <Select.Option value="1">Mới</Select.Option>
                <Select.Option value="0">Đã sử dụng</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6} lg={6} md={6} xs={24}>
            <Form.Item
              name="Type"
              label="Loại thiết bị"
              rules={[
                {
                  required: true,
                  message: 'Hãy nhập loại thiết bị',
                },
              ]}
            >
              <Select
                placeholder="Hãy chọn loại thiết bị"
                showSearch
                className="width-100"
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onDropdownVisibleChange={onDropdownVisibleChangeType}
                dropdownRender={(menu) => (
                  <>
                    <div style={{ padding: '8px 4px', width: '100%' }}>
                      <Row gutter={[10, 10]} style={{ width: '100%' }}>
                        <Col span={18}>
                          <Input
                            value={inputAddDeviceType}
                            onChange={(e) => getValueInputAddDeviceType(e.target.value)}
                            placeholder="Hãy nhập loại thiết bị"
                            style={{ width: '100%' }}
                          />
                        </Col>
                        <Col span={6}>
                          <Button
                            type="primary"
                            disabled={validateInputAddDeviceType}
                            onClick={onAddDeviceType}
                            style={{ width: '100%' }}
                          >
                            Thêm
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <div style={{ padding: '0 4px', color: '#ff4d4f' }}>{messageValid}</div>
                    <Divider style={{ margin: '0 0 8px 0' }} />
                    {menu}
                  </>
                )}
              >
                {lstType.map((item, index) => (
                  <Option value={item.DefaultValue} key={index + 1}>
                    {jsUcfirst(`${item.DefaultValue}`)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6} lg={6} md={6} xs={24}>
            <Form.Item name="DeviceGroup" label="Nhóm TB">
              <Select
                allowClear
                showSearch
                style={{ width: 250 }}
                onChange={(e) => setSelectDeviceGroup(e)}
                placeholder="Lựa chọn nhóm thiết bị"
                optionFilterProp="children"
                onDropdownVisibleChange={onDropdownVisibleChange}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                dropdownRender={(menu) => (
                  <>
                    <Space style={{ padding: '8px 4px' }}>
                      <Input
                        value={inputAddDeviceGroup}
                        onChange={(e) => getValueInputAddDeviceGroup(e.target.value)}
                        placeholder="Hãy nhập nhóm thiết bị mới"
                      />
                      <Button type="primary" disabled={validateInputAddDeviceGroup} onClick={onAddDeviceGroup}>
                        Thêm
                      </Button>
                    </Space>
                    <div style={{ padding: '0 4px', color: '#ff4d4f' }}>{messageValid}</div>
                    <Divider style={{ margin: '0 0 8px 0' }} />
                    {menu}
                  </>
                )}
              >
                {deviceGroup.map((groupDevice, index) => (
                  <Select.Option value={groupDevice.DefaultValue} key={index + 1}>
                    {groupDevice.DefaultValue}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={6} lg={6} md={6} xs={24}>
            <Form.Item name="Origin" label="Xuất xứ">
              <Input placeholder="Hãy nhập xuất xứ" />
            </Form.Item>
          </Col>
          <Col span={6} lg={6} md={6} xs={24}>
            <Form.Item name="Manufacturer" label="Hãng sản xuất">
              <Input placeholder="Hãy nhập hãng sản xuất" />
            </Form.Item>
          </Col>
          <Col span={6} lg={6} md={6} xs={24}>
            <Form.Item label="Kiểm định" name="Accreditation" initialValue={'0'}>
              <Select style={{ width: 100 }} placeholder="Hãy chọn kiểm định">
                <Select.Option value="0">Không</Select.Option>
                <Select.Option value="1">Có</Select.Option>
              </Select>
              <Tooltip placement="bottom" title="Thời gian kiểm định được tính theo nhóm thiết bị">
                <InfoCircleOutlined style={{ fontSize: 15, cursor: 'pointer', marginLeft: 10 }} />
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={6} lg={6} md={6} xs={24}>
            <Form.Item name="TimeImport" label="Thời gian nhập">
              <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} placeholder="Thời gian nhập" />
            </Form.Item>
          </Col>
          <Col span={6} lg={6} md={6} xs={24}>
            <Form.Item name="TimeStart" label="TG bắt đầu sd">
              <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} placeholder="Thời gian bắt đầu sử dụng" />
            </Form.Item>
          </Col>
          <Col span={6} lg={6} md={6} xs={24}>
            <Form.Item name="Warranty" label="Thời gian bảo hành">
              <RangePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={6} lg={6} md={6} xs={24}>
            <Form.Item name="TimeManufacturer" label="Thời gian sản xuất">
              <DatePicker format="YYYY" style={{ width: '100%' }} picker="year" placeholder="Thời gian sản xuất" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="Note" label="Ghi chú">
          <Input.TextArea placeholder="Hãy nhập ghi chú" />
        </Form.Item>
        <Form name="formParameter" onFinish={onFinish} form={form}>
          <Form.List name="DeviceParameterLst">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field) => (
                    <Row key={field.key} gutter={20}>
                      <Col span={6} lg={6} md={6} xs={24}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'Parameter']}
                          fieldKey={[field.fieldKey, 'Parameter']}
                          rules={[
                            {
                              required: true,
                              message: 'Thông số',
                            },
                          ]}
                        >
                          <Input placeholder="Thông số" />
                        </Form.Item>
                      </Col>
                      <Col span={4} lg={4} md={4} xs={20}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'Quantity']}
                          fieldKey={[field.fieldKey, 'Quantity']}
                          rules={[
                            {
                              required: true,
                              message: 'Số lượng',
                            },
                          ]}
                        >
                          <InputNumber type="number" placeholder="Số lượng" min={0} className="width-100" />
                        </Form.Item>
                      </Col>
                      <Col span={6} lg={6} md={6} xs={24}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'Unit']}
                          fieldKey={[field.fieldKey, 'Unit']}
                          rules={[
                            {
                              required: true,
                              message: 'Đơn vị',
                            },
                          ]}
                        >
                          <Select placeholder="Đơn vị">
                            <Option value="chiếc">Chiếc</Option>
                            <Option value="cái">Cái</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6} lg={6} md={6} xs={24}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'Description']}
                          fieldKey={[field.fieldKey, 'Description']}
                          rules={[
                            {
                              required: true,
                              message: 'Mô tả',
                            },
                          ]}
                        >
                          <Input.TextArea placeholder="Mô tả" />
                        </Form.Item>
                      </Col>
                      <Col span={2} lg={2} md={2} xs={4}>
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                          style={{ fontSize: 18, color: 'red' }}
                        />
                      </Col>
                    </Row>
                  ))}
                  <Form.Item className="btn_parameter">
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> Thêm thông số
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </Form>
      </Form>
    </div>
  );
};
export default Create;
