import { atom, selector } from 'recoil'
import { getGroupId, getGroupName, getNotiCount, getRole } from '../../helpers/auth'
export const groupId = atom({
    key: 'groupId',
    default: [null]
})
export const infoGroup = atom ({
    key:'infoGroup',
    default:[null]
})
export const getInfoGroup =  selector({
    key: 'getInfoGroup',
    get: ({get})=>{
        const info = get(infoGroup)
        return info
    }
})
export const roleUser = atom({
    key: 'roleUser',
    default: [null]
})
export const getRoleUser = selector({
    key:"getRoleUser",
    get: ({get})=>{
        const role = get(roleUser)
        return role
    }
})
export const stateSideBar = atom({
    key: 'stateSideBar',
    default: true
})
export const currentTab = atom({
    key: 'currentTab',
    default:[null]
})

export const deviceInventory = atom({
    key: 'deviceInventory',
    default: []
})
export const addLstDevice =(lstDevice, device)=>{
    const newLstDevice = [...lstDevice];
    newLstDevice.push(device)
    return newLstDevice
}
export const deleteDeviceInven = selector({
    key: "deleteDeviceInven",
    set: ({get, set}, deviceId) =>{
        const lst = get(deviceInventory)
        const checkIndex = lst.findIndex(deviceInven =>{
            return deviceInven.DeviceId === deviceId
        })
        lst.splice(checkIndex, 1)
    }
})
export const deleteDevice = (lstDevice, deviceId) =>{
    const checkIndex = lstDevice.findIndex(deviceInven =>{
        return deviceInven.DeviceID === deviceId
    })
    lstDevice.splice(checkIndex, 1)
}
export const manageDevice = atom({
    key: 'manageDevice',
    default : false
})
export const setManageDevice = selector({
    key: 'setManageDevice',
    get : ({get})=>{
        return get(manageDevice)
    },
    set: ({get, set})=>{
        const state = get(manageDevice)
        set(!state[0])
    }
})
export const manageSetDevice = atom({
    key: "manageSetDevice",
    default: [null]
})
export const contentReport = atom({
    key: 'contentReport',
    default: [null]
})
export const createdChildGroup = atom({
    key: 'createdChildGroup',
    default: [false]
})
export const infoDevice = atom({
    key: 'infoDevice',
    default: null
})
export const manageMember = atom({
    key: 'manageMember',
    default: false
})
export const paperMov = atom({
    key: 'paperMov',
    default: null
})

export const manageDeviceError = atom({
    key: 'manageDeviceError',
    default: false
})

export const manageOfferDevice = atom({
    key: 'manageOfferDevice',
    default: false
})
export const censersInven = atom({
    key: 'censersInven',
    default: []
})
export const censersMov = atom({
    key: 'censersMov',
    default: []
})
export const censersMaintance = atom({
    key: 'censersMaintance',
    default: []
})
export const censersMaintanceContact = atom({
    key: 'censersMaintanceContact',
    default: []
})

export const notiMaintance = atom({
    key: 'notiMaintance',
    default: JSON.parse(getNotiCount()) || []
})
export const organizationInfo = atom({
    key: 'organizationInfo',
    default: {
        OrganizationName: getGroupName(),
        OrganizationID: getGroupId(),
        ManagerRole: getRole()
    }
})