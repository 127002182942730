import React, { useState } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { Form, Input, Modal, Tooltip, notification, message } from 'antd'
import { useRecoilState } from 'recoil'
import { manageOfferDevice } from '../../../../../../config/Recoil'
import { DelOfferDeviceHeaderByID } from '../../../../../../api/DeviceOffer'

const CancelOffer = ({ headerInfo }) => {
    const [visible, setVisible] = useState(false)
    const [getOffer, setOffer] = useRecoilState(manageOfferDevice)
    const [form] = Form.useForm()
    const openModal = ()=>{
        setVisible(true)
    }
    const onOk = () => {
        form.submit()
    }
    const onCancel = () => {
        setVisible(false)
    }
    const onFinish = (elements)=>{
        DelOfferDeviceHeaderByID({
            DocumentID: headerInfo.DocumentID,
            Note: elements.Note
        }).then(res => {
            if(res.RespCode === 0){
                message.success('Đã hủy phiếu thiết bị thành công')
                setVisible(false)
                setOffer(!getOffer)
            }
        })
    }
    return (
        <div>
            <Modal visible={visible} onOk={onOk} onCancel={onCancel} title="Hủy phiếu đề xuất">
                <Form form={form} onFinish={onFinish}>
                    <Form.Item name="Note" label="Lý do" rules={[{
                        required: true,
                        message: 'Không được để trống'
                    }]}>
                        <Input.TextArea/>
                    </Form.Item>
                </Form>
            </Modal>
            <Tooltip placement='bottom' title="Hủy phiếu đề xuất">
                <DeleteOutlined onClick={openModal} style={{ fontSize: 20, color: 'red' }}/>
            </Tooltip>
        </div>
    )
}

export default CancelOffer