import { Button, Tooltip } from 'antd';
import React, { useMemo, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import FormDeviceMaintance from './FormDeviceMaintance';
class ComponentToPrint extends React.Component {
  render() {
    return (
      <div className="report">
        <FormDeviceMaintance documents={this.props.documents} devices={this.props.devices}></FormDeviceMaintance>
      </div>
    );
  }
}

const PrintDeviceMove = ({ documents }) => {
  const devices = [];
  const valueQrCode = useRef();
  documents &&
    documents.forEach((device) => {
      if (device.DeviceLstPrint.length > 0) {
        devices.push(device.DeviceLstPrint);
      }
    });
  return useMemo(
    () => (
      <div style={{ marginRight: 5 }}>
        <div style={{ display: 'none' }}>
          <ComponentToPrint ref={valueQrCode} documents={documents} devices={devices.flat()} />
        </div>
        <ReactToPrint
          documentTitle="Thông tin thiết bị"
          trigger={() => (
            <div type="primary">
              <Tooltip title="In phiếu danh sách thiết bị luân chuyển" placement="bottom">
                <Button>In báo cáo</Button>
              </Tooltip>
            </div>
          )}
          content={() => valueQrCode.current}
        />
      </div>
    ),
    [documents]
  );
};
export default PrintDeviceMove;
