export const REPORT_ERROR_DEVICE_STATUS = [
  {
    label: "Hỏng",
    value: 0,
  },
  {
    label: "Kiểm tra",
    value: 1,
  },
  {
    label: "Nguy hiểm",
    value: 2,
  }
]
export const REPORT_ERROR_SOLUTION = [
  { label: "Kiểm tra", value: 0 }, //API_DEVICE_LST_REPORT
  { label: "Cần bảo dưỡng", value: 1 }, //API_DEVICE_REQUEST_REPAIR
  { label: "Cần kiểm định", value: 2 }, //API_DEVICE_REQUEST_ACCREDITATION
  { label: "Cần hiệu chuẩn thiết bị", value: 3 }, //API_DEVICE_REQUEST_CHECK
  { label: "Thay mới", value: 4 }, //
  { label: "Yêu cầu kiểm tra", value: 5 }, //API_DEVICE_LST_REPORT
  { label: "Cần sửa chữa", value: 6 } //API_DEVICE_REPAIR_QR
]