import firebase from "firebase";
const firebaseConfig = {
    apiKey: "AIzaSyAVH4e2UumXSKUd_bHMkHt1CNbirjVaqdU",
    authDomain: "qlts-4d002.firebaseapp.com",
    projectId: "qlts-4d002",
    storageBucket: "qlts-4d002.appspot.com",
    messagingSenderId: "500470433601",
    appId: "1:500470433601:web:91dc2b5f0f3dc0a4c3c3c0",
    measurementId: "G-Y806FMW0KL"
};
firebase.initializeApp(firebaseConfig)
const db = firebase.firestore()
const errors = db.collection('errors')
const listLiquidations = db.collection('listLiquidations')
const deviceSchedule = db.collection('deviceSchedule')
export { errors, listLiquidations, deviceSchedule }
