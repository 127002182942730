import { Button, Tooltip } from 'antd';
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import FormDeviceMaintance from './FormDeviceMaintance';
class ComponentToPrint extends React.Component {
  render() {
    return (
      <div className="report">
        <FormDeviceMaintance deviceLst={this.props.deviceLst} devices={this.props.devices}></FormDeviceMaintance>
      </div>
    );
  }
}

const PrintDeviceInventory = ({ deviceLst }) => {
  const devices = [];
  const valueQrCode = useRef();
  deviceLst &&
    deviceLst.forEach((device) => {
      if (device.InventoryLineLst.length > 0) {
        devices.push(device.InventoryLineLst);
      }
    });
  return (
    <div>
      <div style={{ display: 'none' }}>
        <ComponentToPrint ref={valueQrCode} deviceLst={deviceLst} devices={devices.flat()} />
      </div>
      <ReactToPrint
        documentTitle="Thông tin thiết bị"
        trigger={() => (
          <div type="primary">
            <Tooltip title="In phiếu danh sách thiết bị kiểm kê" placement="bottom">
              <Button>In báo cáo</Button>
            </Tooltip>
          </div>
        )}
        content={() => valueQrCode.current}
      />
    </div>
  );
};
export default PrintDeviceInventory;
