import React, { useState } from 'react'
import { Form, Input, Modal, Button, notification, message } from 'antd'
import { ApproveInventoryDoc } from '../../../../api/TSInventoryDoc'
import { manageDeviceError } from '../../../../config/Recoil'
import { useRecoilState } from 'recoil'
const Approve = ({headerInfo}) => {
    const [visible, setVisible] = useState(false)
    const [getInventory, setInventory] = useRecoilState(manageDeviceError)
    const [form] = Form.useForm()
    const openModal = ()=>{
        setVisible(true)
    }
    const onOk = () => {
        form.submit()
    }
    const onCancel = () => {
        setVisible(false)
    }
    const onFinish = (elements)=>{
        ApproveInventoryDoc({
            DocumentID: headerInfo.DocumentID,
            Note: elements.Note
        }).then(res =>{
            setVisible(false)
            message.success('Đã xác nhận thiết bị thành công')
            setVisible(false)
            setInventory(!getInventory)
        })
    }
    return (
        <div>
            <Modal visible={visible} onOk={onOk} onCancel={onCancel} title="Xác nhận phiếu kiểm kê">
                <Form form={form} onFinish={onFinish}>
                    <Form.Item name="Note" label="Ghi chú" rules={[{
                        required: true,
                        message: 'Hãy nhập ghi chú'
                    }]}>
                        <Input.TextArea/>
                    </Form.Item>
                </Form>
            </Modal>
            <Button type='dashed' onClick={openModal}>Xác nhận</Button>
        </div>
    )
}

export default Approve
