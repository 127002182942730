import { Dropdown, Menu, Space } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../../../assets/images/logo-da-khoa.png';
import './AdminHeader.scss';
import { Link } from 'react-router-dom';
import { DASHBOARD_API } from '../../../../constants/Group';
import { getFullName } from '../../../../helpers/auth';

const AdminHeader = () => {
  const history = useHistory();
  const movoToDashboard = () => {
    history.push('/dashboard');
  };
  const onClickLogout = () => {
    localStorage.clear();
    history.push('/');
  };
  const items = (
    <Menu>
      <Menu.Item key="change-info">
        <Link to={DASHBOARD_API}>
          <Space>
            <i className="fa-solid fa-house-chimney"></i>
            <div>Trang chủ</div>
          </Space>
        </Link>
      </Menu.Item>
      <Menu.Item key="change-pass">
        <Space onClick={() => onClickLogout()}>
          <i className="fa-solid fa-arrow-right-from-bracket"></i>
          <div>Đăng xuất</div>
        </Space>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="admin-header">
      <Space size={6}>
        <img src={logo} alt="" width={60} height={60} onClick={() => movoToDashboard()} />
        <div className="admin-header__text">
          <div className="admin-header__title">Admin quản lý trang thiết bị</div>
          <div className="admin-header__name">Bệnh viện đa khoa tỉnh điện biên</div>
        </div>
      </Space>
      <Dropdown overlay={items} trigger={['click']}>
        <Space className="admin-header__full-name">
          <b>{getFullName()}</b>
          <i className="fa-solid fa-chevron-down"></i>
        </Space>
      </Dropdown>
    </div>
  );
};

export default AdminHeader;
