import moment from 'moment';
import { isValidDate } from '../../config/convertDateExcelToDate';
export function statusReportRepair(value) {
  switch (value) {
    case 0:
      return { label: 'Hủy', value: 0, color: '#f50' };
    case 1:
      return { label: 'Mới tạo', value: 1, color: '#108ee9' };
    case 2:
      return { label: 'Xác nhận', value: 2, color: '#87d068' };
    default:
      return { label: 'Hủy', value: 0, color: '#f50' };
  }
}

export function roleUser(role) {
  switch (role) {
    case '1':
      return true;
    case '2':
      return true;
    case '3':
      return false;
  }
}
export function displaySchedule(schedule) {
  const num = parseInt(schedule);
  const checkYear = num % 365;
  const checkMonth = num % 30;
  const checkWeek = num % 7;
  if (num === 0) {
    return 'Không có';
  } else {
    if (checkYear === 0) {
      return `${num / 365} năm`;
    } else if (checkMonth === 0) {
      return `${num / 30} tháng`;
    } else if (checkWeek === 0) {
      return `${num / 7} tuần`;
    } else {
      return `${num} ngày`;
    }
  }
}

export function displayTimeUsed(date) {
  const datePar = parseInt(date);
  if (datePar) {
    if (datePar > 12) {
      const parYear = datePar % 12;
      if (parYear === 0) {
        return parseInt(datePar / 12) + ' Năm';
      } else {
        return parseInt(datePar / 12) + ' Năm ' + parYear + ' Tháng';
      }
    } else {
      return datePar + ' Tháng';
    }
  } else {
    return '-';
  }
}

export function transformDay(value, type) {
  const valueNew = parseInt(value);
  switch (type) {
    case 'day':
      return valueNew;
    case 'week':
      return valueNew * 7;
    case 'month':
      return valueNew * 30;
    case 'year':
      return valueNew * 356;
    default:
      return valueNew;
  }
}

export function status(value) {
  switch (value) {
    case 1:
      return { label: 'Hoạt động', color: '#87d068' };
    case 2:
      return { label: 'Bảo tri', color: 'purple' };
    case 3:
      return { label: 'Đang bảo dưỡng', color: 'orange' };
    case 4:
      return { label: 'Luân chuyển - chờ xác nhận', color: 'orange' };
    default:
      return { label: '-', color: 'white' };
  }
}
export function typeSchedule(value) {
  if (value === 0) {
    return {
      label: 'Bảo dưỡng',
      value: 0,
    };
  } else if (value === 1) {
    return {
      label: 'Thay mới',
      value: 1,
    };
  } else if (value === 2) {
    return {
      label: 'Kiểm định',
      value: 2,
    };
  } else if (value === 3) {
    return {
      label: 'Hiệu chuẩn',
      value: 3,
    };
  } else if (value === 4) {
    return {
      label: 'Kiểm kê',
      value: 4,
    };
  } else if (value === 5) {
    return {
      label: 'Thanh lý',
      value: 5,
    };
  } else if (value === 6) {
    return {
      label: 'Hạn đăng kiểm',
      value: 6,
    };
  } else if (value === 7) {
    return {
      label: 'Hạn bảo hiểm',
      value: 7,
    };
  } else if (value === 8) {
    return {
      label: 'Thay dầu xe',
      value: 8,
    };
  } else if (value === 9) {
    return {
      label: 'Thời hạn bảo hiểm tự nguyện',
      value: 9,
    };
  } else if (value === 10) {
    return {
      label: 'Thời gian sửa chữa lớn',
      value: 10,
    };
  } else if (value === 20) {
    return {
      label: 'Thuê bên ngoài',
      value: 20,
    };
  } else {
    return {
      label: 'Bảo dưỡng',
      value: 0,
    };
  }
}

export function solutionDevice(value) {
  switch (parseInt(value)) {
    case 0:
      return { label: 'Kiểm tra', value: 0 };
    case 1:
      return { label: 'Cần bảo dưỡng', value: 1 };
    case 2:
      return { label: 'Cần kiểm định', value: 2 };
    case 3:
      return { label: 'Cần hiệu chuẩn', value: 3 };
    case 4:
      return { label: 'Thay mới', value: 4 };
  }
}

export function statusError(value) {
  switch (parseInt(value)) {
    case 0:
      return { label: 'Hỏng', value: 0, color: 'red' };
    case 1:
      return { label: 'Kiểm tra', value: 1, color: 'orange' };
    case 2:
      return { label: 'Nguy hiểm', value: 2, color: 'purple' };
    default:
      return { label: 'Kiểm tra', value: 1, color: 'orange' };
  }
}

export function timeDisplay(date = '') {
  const validTime = isValidDate(date) && moment(date).year() > 1990;
  if (validTime) {
    return moment(date).format('HH:mm DD/MM/YYYY');
  } else {
    return '';
  }
}

export function dateDisplay(date, format = 'DD/MM/YYYY') {
  const validTime = isValidDate(date) && moment(date).year() > 1990;
  if (validTime) {
    return moment(date).format(format);
  } else {
    return '';
  }
}

export function formatDateImport(date = '', format = 'YYYY-MM-DD HH:mm:ss') {
  return date ? moment(date).format(format) : '';
}

export function formatMoneyDisplay(value) {
  if (value) {
    return Number(value).toLocaleString();
  } else {
    return '-';
  }
}
export function accreditationDisplay(val) {
  switch (val) {
    case 0:
      return {
        label: 'Không',
        color: '#f50',
        value: 0,
      };
    case 1:
      return {
        label: 'Có',
        color: '#87d068',
        value: 1,
      };
    default:
      return {
        label: 'Không',
        color: '#f50',
        value: 0,
      };
  }
}
export function statusApprove(value) {
  switch (parseInt(value)) {
    case 0:
      return { label: 'Hủy', value: 0, color: '#f50' };
    case 1:
      return { label: 'Mới tạo', value: 1, color: '#2db7f5' };
    case 2:
      return { label: 'Đã xác nhận', value: 2, color: '#87d068' };
    case 3:
      return { label: 'Đang bảo dưỡng', value: 3, color: '#fa8c16' };
    case 4:
      return { label: 'Đã bảo dưỡng xong', value: 4, color: '#5b8c00' };
    case 5:
      return { label: 'Đã xong 1 phần', value: 5, color: '#fadb14' };
    default:
      return { label: 'Mới tạo', value: 1, color: '#2db7f5' };
  }
}

export function statusDeviceInventory(value) {
  switch (parseInt(value)) {
    case 1:
      return { label: 'Bình thường', value: 1, color: '#87d068' };
    case 2:
      return { label: 'Lỗi', value: 2, color: 'red' };
  }
}

export function statusDeviceAction(value) {
  switch (parseInt(value)) {
    case 1:
      return { label: 'Bình thường', value: 1, color: '#87d068' };
    case 2:
      return { label: 'Chờ xác nhận(PB)', value: 2, color: 'orange' };
    case 4:
      return { label: 'Chờ xác nhận(LC)', value: 4, color: 'orange' };
    default:
      return 0;
  }
}

export function displaySerial(serial = '', length = 12) {
  const lengthSerial = serial.length;
  if (lengthSerial > length) {
    return '...' + serial.slice(lengthSerial - length, lengthSerial);
  }
  return serial;
}
