import { HomeOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { GetDependentOrganization, GetOrganizationByID, GetUserOrganization } from '../../../api/TSOrganization';
import { infoGroup, organizationInfo, roleUser } from '../../../config/Recoil';
import { GROUP_API } from '../../../constants/Group';
import { getUserName, setGroupId, setGroupName } from '../../../helpers/auth';
import CreateChildGroup from '../CreateChildGroup';

const { Search } = Input;
const Overview = () => {
  const [childGroups, setChildGroups] = React.useState();
  const [valueSearch, setValueSearch] = useState('');
  const [info, setInfo] = useState(null);
  const setInfoGroup = useSetRecoilState(infoGroup);
  const setRoleUser = useSetRecoilState(roleUser);
  const [orgInfo, setOrgInfo] = useRecoilState(organizationInfo);
  const { groupID } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      await GetDependentOrganization({
        OrganizationID: groupID,
      }).then((res) => {
        setChildGroups(res.OrganizationLst);
      });
    };
    const getInfoGroup = () => {
      GetOrganizationByID({
        OrganizationID: groupID,
      }).then((res) => {
        setInfoGroup(res.OrganizationInfo);
        setInfo(res.OrganizationInfo);
        setGroupInfo(res.OrganizationInfo);
      });
    };
    const getRoleUser = () => {
      GetUserOrganization({ OrganizationID: groupID }).then((res) => {
        const resUser = res.UserOrganizationLst.find((user) => {
          return user.UserName === getUserName();
        });
        if (resUser !== undefined) {
          localStorage.setItem('roleUser', resUser.UserRole);
          setRoleUser(resUser.UserRole);
        }
      });
    };
    fetchData();
    getInfoGroup();
    getRoleUser();
  }, [groupID]);
  const setGroupInfo = (group) => {
    setGroupId(group.OrganizationID);
    setGroupName(group.OrganizationName);
    setOrgInfo(group);
  };
  const searchGroup =
    childGroups &&
    childGroups.filter((x) => {
      if (valueSearch === null) return;
      return x.OrganizationName.toLowerCase().indexOf(valueSearch.toLowerCase()) !== -1;
    });
  return (
    <div style={{ width: '100%' }}>
      {info && (
        <div>
          <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col xs={24} md={16}>
              <Divider orientation="left">Mô tả</Divider>
              <p>{`${info.Description}`}</p>
            </Col>
            <Col xs={24} md={6}>
              <Divider orientation="left">Liên Hệ</Divider>
              <h4>
                <HomeOutlined /> {info.Address}
              </h4>
              <h4>
                <PhoneOutlined /> {info.Phone}
              </h4>
              <h4>
                <MailOutlined /> {info.Email}
              </h4>
            </Col>
            {/* <MessengerCustomerChat
            pageId="107552464854897"
            appId="907549203401449"
            language="vi_VN"
            themeColor="#e1564b"
          /> */}
          </Row>
          <Row>
            <Divider orientation="left">Tổ chức phụ thuộc</Divider>
            <Row style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <Search
                placeholder="Nhập tên phòng ban để tìm kiếm"
                onChange={(value) => setValueSearch(value.target.value)}
                autoFocus
                allowClear
                style={{
                  width: 250,
                  marginRight: 20,
                  marginBottom: 20,
                }}
              />
            </Row>
            <Row style={{ display: 'flex', justifyContent: 'space-start' }}>
              {parseInt(localStorage.getItem('roleUser')) !== 3 && <CreateChildGroup groupID={info.groupID} />}
              {searchGroup &&
                searchGroup.map((group) => (
                  <Link
                    to={`${GROUP_API}/${group.OrganizationID}`}
                    key={group.OrganizationID}
                    // target="_blank"
                    onClick={() => setGroupInfo(group)}
                  >
                    <Card hoverable className="dashboard-card" cover={<HomeOutlined style={{ fontSize: 40 }} />}>
                      <p>
                        <b>{group.OrganizationName}</b>
                      </p>
                    </Card>
                  </Link>
                ))}
            </Row>
          </Row>
        </div>
      )}
    </div>
  );
};
export default Overview;
