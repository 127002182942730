import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, notification } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserLogin } from '../../../../api/User';
import { DASHBOARD_API } from '../../../../constants/Group';
import { setFullName, setToken, setUserName } from '../../../../helpers/auth';
import ForgotPass from '../ForgotPass';
import './LoginForm.scss';
export default function LoginForm({ changeForm }) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const handleChangeStatus = () => {
    changeForm();
  };
  const onFinish = async (values) => {
    setLoading(true);
    await UserLogin(values).then((res) => {
      setLoading(false);
      if (res.RespCode === 0) {
        const { UserName, FullName, MoreInfo } = res.UserInfo;
        notification.success({
          message: 'Đăng nhập thành công',
          duration: 1,
        });
        setToken(res.Token);
        setUserName(UserName);
        setFullName(FullName);
        localStorage.setItem('roleuser', MoreInfo);
        history.push(DASHBOARD_API);
      }
    });
  };

  return (
    <Col>
      <div className="name-form">Đăng nhập</div>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="UserName"
          rules={[
            {
              required: true,
              message: 'Hãy nhập số điện thoại của bạn',
            },
          ]}
        >
          <Input
            prefix={
              <div>
                <UserOutlined className="site-form-item-icon" />
                <Divider type="vertical" />
              </div>
            }
            placeholder="Số điện thoại"
            className="text-input"
          />
        </Form.Item>
        <Form.Item
          name="Password"
          rules={[
            {
              required: true,
              message: 'Hãy nhập mật khẩu của bạn!',
            },
          ]}
        >
          <Input
            prefix={
              <div>
                <LockOutlined className="site-form-item-icon" />
                <Divider type="vertical" />
              </div>
            }
            type="password"
            placeholder="Mật khẩu"
            className="text-input"
          />
        </Form.Item>
        <Form.Item>
          <div className="login-form-forgot">
            <a className="register-forgotPass" onClick={handleChangeStatus}>
              Đăng ký ngay!
            </a>
            <ForgotPass />
          </div>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
            Đăng nhập
          </Button>
        </Form.Item>
      </Form>
    </Col>
  );
}
