import { DeleteOutlined } from '@ant-design/icons';
import { Form, Input, Modal, Tooltip, message } from 'antd';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { DelInventoryDoc } from '../../../../api/TSInventoryDoc';
import { manageDeviceError } from '../../../../config/Recoil';

const Cancel = ({ headerInfo }) => {
  const [visible, setVisible] = useState(false);
  const [getInventory, setInventory] = useRecoilState(manageDeviceError);
  const [form] = Form.useForm();
  const openModal = () => {
    setVisible(true);
  };
  const onOk = () => {
    form.submit();
  };
  const onCancel = () => {
    setVisible(false);
  };
  const onFinish = (elements) => {
    DelInventoryDoc({
      DocumentID: headerInfo.DocumentID,
      Note: elements.Note,
    }).then((res) => {
      if (res.RespCode === 0) {
        message.success('Đã hủy phiếu thành công');
        setVisible(false);
        setInventory(!getInventory);
      }
    });
  };
  return (
    <div>
      <Modal visible={visible} onOk={onOk} onCancel={onCancel} title="Hủy phiếu kiểm kê">
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            name="Note"
            label="Lý do"
            rules={[
              {
                required: true,
                message: 'Hãy nhập lý do',
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
      <Tooltip placement="bottom" title="Xóa phiếu kiểm kê">
        <DeleteOutlined onClick={openModal} style={{ fontSize: 20, color: 'red' }} />
      </Tooltip>
    </div>
  );
};

export default Cancel;
