import { LogoutOutlined } from "@ant-design/icons";
import React from "react";
import { useHistory } from "react-router-dom";

const Logout = () => {
  var history = useHistory();
  return (
    <div>
      <div
        onClick={() => {
          localStorage.clear();
          history.push("/");
          window.location.reload();
        }}
      >
        <LogoutOutlined style={{ marginRight: 5 }} />
        Đăng xuất
      </div>
    </div>
  );
};
export default Logout;
