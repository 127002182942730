import Request from '../config/Request';
import RequestAuth from '../config/Request/requestAuth';
export function UserLogin(data) {
    return RequestAuth({
        url: "/User/UserLogin",
        method: "POST",
        data: data,
    })
}
export function UpdateUserInfo(data) {
    return Request({
        url: "/User/UpdateUserInfo",
        method: "POST",
        data: data,
    })
}
export function GetUserInfo(data) {
    return Request({
        url: "/User/GetUserInfo",
        method: "POST",
        data: data,
    })
}
export function ChangePassword(data) {
    return Request({
        url: "/User/ChangePassword",
        method: "POST",
        data: data,
    })
}
export function LotPassword(data) {
    return Request({
        url: "/User/LotPassword",
        method: "POST",
        data: data,
    })
}
export function CheckOTPLostPassword(data) {
    return Request({
        url: "/User/CheckOTPLostPassword",
        method: "POST",
        data: data,
    })
}
export function UserRegister(data) {
    return Request({
        url: "/User/UserRegister",
        method: "POST",
        data: data,
    })
}
