import { HomeOutlined, MenuFoldOutlined, NotificationOutlined } from '@ant-design/icons';
import { Badge, Dropdown, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import Notification from '../../config/Noti';
import { organizationInfo, stateSideBar } from '../../config/Recoil';
import { API_NOTI_SYSTEMS } from '../../constants';
import {
  API_DEVICE_LST_ERROR,
  API_DEVICE_LST_REPORT,
  API_DEVICE_REPORT_REPAIR,
  API_DEVICE_REQUEST_REPAIR,
} from '../../constants/Device';
import { REPORT_ERROR_SOLUTION } from '../../constants/reportError';
import { DropDown } from '../../containers/User';
import './Header.scss';
const Header2 = () => {
  const [openSideBar, setOpenDisplay] = useRecoilState(stateSideBar);
  const orgInfo = useRecoilValue(organizationInfo);

  const [countMaintance, setCountMaintance] = useState(0);
  const [countAccreditation, setAccreditation] = useState(0);
  const [countDeviceError, setDeviceError] = useState(0);
  const [countDeviceCheck, setDeviceCheck] = useState(0);
  const [countDeviceRepair, setDeviceRepair] = useState(0);
  const [countResultRepair, setCountResultRepair] = useState(0);
  const displaySideBar = () => {
    setOpenDisplay(!openSideBar);
  };
  const history = useHistory();
  const redirectToNoti = () => {
    history.push(API_NOTI_SYSTEMS);
  };
  async function getNoti() {
    const noti = await Notification();
    setCountMaintance(noti[0].length);
    setAccreditation(noti[1].length);
    setDeviceError(noti[2].filter((device) => device.StatusReport !== 1).length);
    setDeviceCheck(noti[2].filter((device) => device.StatusReport === 1).length);
    setDeviceRepair(noti[2].filter((device) => device.Solution === REPORT_ERROR_SOLUTION[1].label).length);
    setCountResultRepair(noti[3].length);
  }
  useEffect(() => {
    getNoti();
  }, []);
  const menuNoti = (
    <Menu>
      <Menu.Item key="1" onClick={redirectToNoti}>
        <Link to={API_NOTI_SYSTEMS}>
          <b className="count-noti">{countMaintance}</b>Lịch bảo dưỡng
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to={API_NOTI_SYSTEMS}>
          <b className="count-noti">{countAccreditation}</b>Lịch kiểm định
        </Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to={API_DEVICE_LST_ERROR}>
          <b className="count-noti">{countDeviceError}</b>Thiết bị báo hỏng
        </Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link to={API_DEVICE_LST_REPORT}>
          <b className="count-noti">{countDeviceCheck}</b>Thiết bị kiểm tra
        </Link>
      </Menu.Item>
      <Menu.Item key="6">
        <Link to={API_DEVICE_REQUEST_REPAIR}>
          <b className="count-noti">{countDeviceRepair}</b>Thiết bị sửa chữa
        </Link>
      </Menu.Item>
      <Menu.Item key="7">
        <Link to={API_DEVICE_REPORT_REPAIR}>
          <b className="count-noti">{countResultRepair}</b>Kết quả bảo dưỡng
        </Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="header2">
      <div className="left">
        <MenuFoldOutlined className="hamburger" onClick={displaySideBar} />
        <Link to={`/dashboard`}>
          <h1>
            <HomeOutlined /> {orgInfo.OrganizationName}
          </h1>
        </Link>
      </div>

      <div className="btn-info-user">
        <DropDown />
        <Dropdown overlay={menuNoti} trigger={['click']}>
          <Badge
            count={
              countMaintance +
              countAccreditation +
              countDeviceError +
              countDeviceCheck +
              countDeviceRepair +
              countResultRepair
            }
            className="badge"
          >
            <NotificationOutlined style={{ fontSize: 20 }} />
          </Badge>
        </Dropdown>
      </div>
    </div>
  );
};

export default Header2;
