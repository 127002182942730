import { Button, Form, Input, Modal, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { GetOrganizationOfCompany } from '../../../../api/TSOrganization';
import { AddKeyToLst } from '../../../helper/addKeyToLst';
import './AddMemberOrganizations.scss';

const AddMemberOrganizations = () => {
  const [visible, setVisible] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [selectedRows, setSelectedRow] = useState([]);
  const [form] = Form.useForm();

  const getOrganizationByCompany = () => {
    GetOrganizationOfCompany({
      Search: '',
      OrganizationID: 77,
      NumberRow: '',
      PageNumber: 1,
    }).then((res) => {
      setOrganizations(AddKeyToLst(res.OrganizationLst));
    });
  };
  useEffect(() => {
    getOrganizationByCompany();
  }, []);
  const columns = [
    {
      title: 'STT',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Tên phòng ban',
      dataIndex: 'OrganizationName',
      key: 'OrganizationName',
    },
    {
      title: 'SL thành viên',
      dataIndex: 'Quantity',
      key: 'Quantity',
    },
    {
      title: 'Người tạo',
      dataIndex: 'Creater',
      key: 'Creater',
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRow(selectedRows);
    },
  };
  const onOk = () => {
    form.submit();
  };
  const onConfirm = (elements) => {
    console.log(elements);
  };
  return (
    <div className="add-member-organizations">
      <Modal
        visible={visible}
        title="Thêm người dùng"
        onCancel={() => setVisible(false)}
        onOk={onOk}
      >
        <Form form={form} onFinish={onConfirm} labelCol={{ span: 6 }}>
          <Form.Item
            label="Số điện thoại"
            name="PhoneNumber"
            rules={[{ required: true, message: 'Không được để trống' }]}
          >
            <Input autoFocus />
          </Form.Item>
          <Form.Item
            label="Phân quyền"
            name="role"
            rules={[{ required: true, message: 'Không được để trống' }]}
            initialValue="3"
          >
            <Select>
              <Select.Option value="2">Quản lý</Select.Option>
              <Select.Option value="3">Thành viên</Select.Option>
            </Select>
          </Form.Item>
        </Form>
        <div>
          <b style={{ fontSize: 12 }}>
            Người dùng sẽ được thêm vào danh sách phòng ban mà bạn đã chọn
          </b>
        </div>
      </Modal>
      <div className="actions-add">
        <Button
          type="primary"
          disabled={selectedRows.length === 0}
          onClick={() => setVisible(true)}
        >
          Nhập số điện thoại
        </Button>
        <b>Đã chọn {selectedRows.length} phòng ban</b>
      </div>
      <Table
        dataSource={organizations}
        columns={columns}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        pagination={false}
      ></Table>
    </div>
  );
};

export default AddMemberOrganizations;
