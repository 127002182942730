import { Button } from 'antd'
import React from 'react'

const ExportExcel = ({datas, nameBtn, headerData, headerExcel, fileName}) => {
    const exportExcel = ()=>{
        import('../../helpers/Export2Excel').then(excel =>{
            const filterVal = headerData
            const tHeader = headerExcel
            const data = formatJson(filterVal, datas)
            excel.export_json_to_excel({
                header: tHeader,
                data,
                filename: fileName,
                autoWidth: true,
                bookType: 'xlsx'
            })
        })
    }
    const formatJson = (filterVal, jsonData)=> {
        return jsonData.map(v => filterVal.map(j => {
            return v[j]
        //   if (j === 'timestamp') {
        //     return parseTime(v[j])
        //   } else {
        //     return v[j]
        //   }
        }))
    }
    return (
        <div>
            <Button type="dashed" onClick={exportExcel}>{nameBtn}</Button>
        </div>
    )
}

export default ExportExcel
