import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { GetCenser } from '../../../../api/Censer';
import { GetInventoryDocByID } from '../../../../api/TSInventoryDoc';
import { censersInven, contentReport } from '../../../../config/Recoil';
import { companyName } from '../../../../config/URL/Qlts';
import HanleCenser from '../../../Censer';
import { formatMoneyDisplay } from '../../../helper/mapping';
import PrintReportInventory from '../../Components/PrintReport';
const columns = [
  {
    title: 'STT',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Tên TB',
    dataIndex: 'DeviceName',
    key: 'DeviceName',
  },
  {
    title: 'Mã TB',
    dataIndex: 'DeviceCode',
    key: 'DeviceCode',
  },
  {
    title: 'Giá trị',
    dataIndex: 'DeviceValue',
    key: 'DeviceValue',
    render: (record) => {
      return formatMoneyDisplay(record);
    },
  },
  {
    title: 'Phòng ban',
    dataIndex: 'Location',
    key: 'Location',
  },
  {
    title: 'Ghi chú',
    dataIndex: 'NoteChek',
    key: 'NoteChek',
  },
];
const InfoInventory = ({ info }) => {
  const [visible, setVisible] = useState(false);
  const [infoHeader, setHeaderInfo] = useRecoilState(contentReport);
  const [get, setCenserInven] = useRecoilState(censersInven);
  const [censors, setCensors] = useState([]);
  const getInfoInven = () => {
    GetInventoryDocByID({
      DocumentID: info.DocumentID,
    }).then((res) => {
      if (res.RespCode === 0) {
        const response = res.InventoryHeaderInfo;
        setHeaderInfo(response);
        setVisible(true);
      }
    });
    GetCenser({
      CompanyName: companyName,
      Type: 'DeviceInventory',
    }).then((res) => {
      const response = res.Censers.sort((a, b) => {
        return a.SetupLine - b.SetupLine;
      });
      setCenserInven(response);
    });
  };
  return (
    <div>
      <Modal
        visible={visible}
        title="Thông tin báo cáo kiểm kê"
        onCancel={() => setVisible(false)}
        footer={null}
        width={800}
      >
        <div className="content">
          <p>
            Người thực hiện: {infoHeader.CreaterName} - ID: {infoHeader.CreaterID}
          </p>
          <p>Phòng ban: {infoHeader.OrganizationName}</p>
          <p>Thời gian thực hiện: {infoHeader.TimeStart}</p>
          <p>Ghi chú: {infoHeader.Comment}</p>
          <div>
            <Table
              dataSource={
                infoHeader.InventoryLineLst &&
                infoHeader.InventoryLineLst.map((line, index) => {
                  return {
                    ...line,
                    key: index + 1,
                  };
                })
              }
              columns={columns}
              pagination={false}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 20 }}>
          <Button
            onClick={() => {
              setVisible(false);
            }}
            style={{ marginRight: 10 }}
          >
            Hủy
          </Button>
          <PrintReportInventory contentReport={infoHeader} censors={censors} />
          <HanleCenser censerType="DeviceInventory" getCensers={(data) => setCensors(data)} />
        </div>
      </Modal>
      <Tooltip placement="bottom" title="Chi tiết">
        <InfoCircleOutlined onClick={getInfoInven} style={{ fontSize: 22 }} />
      </Tooltip>
    </div>
  );
};

export default InfoInventory;
