import { DatePicker, Form, Input, message, Modal } from 'antd'
import React, { useState } from 'react'
import { useHistory } from 'react-router';
import { CreateMaintenanceSchedule } from '../../../../../../api/TSMaintenance';
import { API_DEVICE_MAINTAIN } from '../../../../../../constants/Device';
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
const ContactRepair = ({devices}) => {
    const [visible, setVisible] = useState(false)
    const [form] = Form.useForm()
    const history = useHistory()
    const openModal = () => {
        setVisible(true)
    }
    const onOke = () => {
        form.submit()
    }
    const onCancel = () => {
        setVisible(false)
    }
    const onFinish = (elements) => {
        const req = {
            OrganizationID: localStorage.getItem('groupId'),
            TimeStart: elements.TimeStart.format('YYYY-MM-DD HH:mm:ss'),
            Description: `${elements.RepairCompany}, ${elements.RepairAddress}, ${elements.RepairPhoneNumber}`,
            Note: '20',
            Type: '10',
            DeviceLst: devices,
            Schedule: 1
        }
        CreateMaintenanceSchedule({
            MaintenanceScheduleInfo: req
        }).then(res => {
            if(res.RespCode === 0){
                message.success('Thành công')
                setVisible(false)
                history.push(API_DEVICE_MAINTAIN)
            }
        })
    }
    return (
        <div>
            <Modal title="Liên hệ sửa chữa" visible={visible} onOk={onOke} onCancel={onCancel}>
                <Form onFinish={onFinish} form={form} name="formContactRepair" {...layout}>
                    <Form.Item label="Đơn vị sửa chữa" name="RepairCompany" rules={[{
                        required: true,
                        message: 'Không được để trống'
                    }]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Địa chỉ" name="RepairAddress" rules={[{
                        required: true,
                        message: 'Không được để trống'
                    }]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="SĐT" name="RepairPhoneNumber" rules={[{
                        required: true,
                        message: 'Không được để trống'
                    }]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Thời gian bắt đầu" name="TimeStart" rules={[{
                        required: true,
                        message: 'Không được để trống'
                    }]}>
                        <DatePicker format='HH:mm DD/MM/YYYY' placeholder="Thời gian bắt đầu"/>
                    </Form.Item>
                </Form>
            </Modal>
            <div onClick={openModal}>
                Liên hệ sửa chữa
            </div>
        </div>
    )
}

export default ContactRepair
