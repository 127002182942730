import { PlusOutlined } from '@ant-design/icons';
import { Card, Input, message, Modal } from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import Organization from '../../../api/OrganizationApi';
import { getToken, getUserName } from '../../../helpers/auth';
import './styles.scss';

export default class CreateGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      name: '',
      description: '',
      address: '',
      phone: '',
      email: '',
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = () => {
    const api = Organization.create();
    axios
      .post(api, {
        UserName: getUserName(),
        Token: getToken(),
        OrganizationInfo: {
          OrganizationName: this.state.name,
          Description: this.state.description,
          Address: this.state.address,
          Phone: this.state.phone,
          Email: this.state.email,
        },
      })
      .then(function (response) {
        if (response.data.RespCode === 0) {
          message.success(`Tạo phòng ban thành công. ID: ${response.data.OrganizationID}`);
        } else {
          message.error(
            `Lỗi, tạo phòng ban thất bại. ${response.data.RespCode} ${response.data.RespText} ${response.data.OrganizationID} `
          );
        }
      })
      .catch(function (error) {
        alert(error);
      })
      .then(() =>
        this.setState(
          {
            visible: false,
          },
          () => window.location.reload()
        )
      );
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  changeName = (e) => {
    this.setState({
      name: e.target.value,
    });
  };
  changeDes = (e) => {
    this.setState({
      description: e.target.value,
    });
  };
  changeAdd = (e) => {
    this.setState({
      address: e.target.value,
    });
  };
  changePhone = (e) => {
    this.setState({
      phone: e.target.value,
    });
  };
  changeEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };
  render() {
    return (
      <div>
        <Card
          hoverable
          className="dashboard-add-card"
          cover={<PlusOutlined style={{ fontSize: 50 }} />}
          onClick={this.showModal}
        >
          <p>
            <b>Tạo phòng ban mới</b>
          </p>
        </Card>
        <Modal
          title="Tạo mới phòng ban"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okText="Tạo mới"
          cancelText="Hủy"
        >
          <div className="create-group-input">
            <h4>Tên: </h4>
            <Input size="large" value={this.state.name} onChange={this.changeName} />
          </div>
          <div className="create-group-input">
            <h4>Mô tả: </h4>
            <Input size="large" rows={10} value={this.state.description} onChange={this.changeDes} />
          </div>
          <div className="create-group-input">
            <h4>Địa chỉ: </h4>
            <Input size="large" value={this.state.address} onChange={this.changeAdd} />
          </div>
          <div className="create-group-input">
            <h4>Số điện thoại: </h4>
            <Input size="large" value={this.state.phone} onChange={this.changePhone} />
          </div>
          <div className="create-group-input">
            <h4>Email: </h4>
            <Input size="large" value={this.state.email} onChange={this.changeEmail} />
          </div>
        </Modal>
      </div>
    );
  }
}
