import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Modal, Popconfirm, Row, Space, Table } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { CreateCenser, DelCenser, GetCenser, ModifySetupLineCenser } from '../../api/Censer';
import { companyName } from '../../config/URL/Qlts';
const HanleCenser = ({ censerType = 'DeviceMaintance', getCensers = () => {} }) => {
  const [visible, setVisible] = useState(false);
  const [dislayLine, setDislayLine] = useState(false);
  const [censers, setCensers] = useState([]);
  const [element, setElement] = useState(null);
  const [form] = useForm();
  const handleModal = () => {
    setVisible(true);
    fetchData();
  };
  const fetchData = async () => {
    await GetCenser({
      CompanyName: companyName,
      Type: censerType,
    }).then((res) => {
      const responseCensers = res.Censers.sort((a, b) => {
        return a.SetupLine - b.SetupLine;
      });

      setCensers(setStatusButton(responseCensers));
      getCensers(setStatusButton(responseCensers));
    });
  };
  const updateLine = async (censers = []) => {
    await ModifySetupLineCenser({
      Type: censerType,
      Censers: censers,
    });
  };
  const setStatusButton = (arrs = []) => {
    return arrs.map((censer, index) => {
      return {
        ...censer,
        key: index + 1,
        statusButtonUp: censer.SetupLine === 1,
        statusButtonDown: censer.SetupLine === arrs.length,
      };
    });
  };
  const closeModal = () => {
    setVisible(false);
  };
  const addLine = () => {
    setDislayLine(true);
    form.resetFields();
  };
  const onClickAddSencer = () => {
    form.submit();
  };
  const submitLine = async (elements) => {
    const req = {
      ...elements,
      SetupLine: censers.length + 1,
      CompanyName: companyName,
      Type: censerType,
    };
    await CreateCenser({
      CenserInfo: req,
    });
    message.success('Thêm thành phần kiểm tra thành công');
    fetchData();
  };
  const confirmDel = async () => {
    await DelCenser({
      RowID: element.RowID,
    });
    message.success('Thành công');
    fetchData();
  };
  const editElement = (element) => {
    setElement(element);
  };
  const onClickNext = (_, index) => {
    const list = [...censers];
    const elementDelete = list.splice(index, 1);
    list.splice(index - 1, 0, elementDelete[0]);
    const newList = list
      .map((censer, index) => {
        return {
          ...censer,
          SetupLine: index + 1,
        };
      })
      .sort((a, b) => {
        return a.SetupLine - b.SetupLine;
      });
    setCensers(setStatusButton(newList));
    updateLine(newList);
  };
  const onClickDown = (_, index) => {
    const list = [...censers];
    const elementDelete = list.splice(index, 1);
    list.splice(index + 1, 0, elementDelete[0]);
    const newList = list
      .map((censer, index) => {
        return {
          ...censer,
          SetupLine: index + 1,
        };
      })
      .sort((a, b) => {
        return a.SetupLine - b.SetupLine;
      });
    setCensers(setStatusButton(newList));
    updateLine(newList);
  };
  const columns = [
    {
      title: 'STT',
      dataIndex: '',
      key: '',
      render: (text, record, index) => {
        return (
          <div>
            <span>{record.SetupLine}</span>
            <Button
              type="text"
              size="mini"
              style={{ width: 25 }}
              onClick={() => onClickNext(record, index)}
              disabled={record.statusButtonUp}
            >
              <i
                className="fa-solid fa-chevron-up"
                style={{
                  cursor: 'pointer',
                  border: '1px solid #ddd',
                  borderRadius: '2px',
                  padding: '3px',
                }}
              ></i>
            </Button>
            <Button
              type="text"
              size="mini"
              style={{ width: 25 }}
              onClick={() => onClickDown(record, index)}
              disabled={record.statusButtonDown}
            >
              <i
                className="fa-solid fa-chevron-down"
                style={{ cursor: 'pointer', border: '1px solid #ddd', borderRadius: '2px', padding: '3px' }}
              ></i>
            </Button>
          </div>
        );
      },
    },
    {
      title: 'Họ & tên',
      dataIndex: 'FullName',
      key: 'FullName',
    },
    {
      title: 'Chức vụ',
      dataIndex: 'Position',
      key: 'Position',
    },
    {
      title: 'Chức vụ trong hội đồng',
      dataIndex: 'Department',
      key: 'Department',
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (record) => (
        <Space>
          <Popconfirm title="Bạn có muốn xóa không?" onConfirm={confirmDel} okText="Xác nhận" cancelText="Huỷ">
            <DeleteOutlined
              style={{ fontSize: 16, cursor: 'pointer', color: 'red' }}
              onClick={() => {
                editElement(record);
              }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <div className="set-censer">
      <Modal visible={visible} title="Thành phần kiểm tra" onCancel={closeModal} onOk={closeModal} width={900}>
        <div style={{ textAlign: 'end', marginBottom: 15 }}>
          {dislayLine ? (
            <Button type="primary" onClick={() => setDislayLine(false)}>
              Ẩn
            </Button>
          ) : (
            <Button type="primary" onClick={addLine}>
              <PlusOutlined />
              Thêm thành phần kiểm tra
            </Button>
          )}
        </div>
        {dislayLine && (
          <div>
            <Form form={form} onFinish={submitLine} style={{ display: 'flex' }} layout="vertical">
              <Row gutter={20}>
                <Col span={6}>
                  <Form.Item
                    label="Họ và tên"
                    name="FullName"
                    rules={[{ required: true, message: 'Không được để trống' }]}
                  >
                    <Input placeholder="Hãy nhập tên" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Chức vụ trong bệnh viện"
                    name="Position"
                    rules={[{ required: true, message: 'Không được để trống' }]}
                  >
                    <Input placeholder="Nhập chức vụ" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Chức vụ trong hội đồng"
                    name="Department"
                    rules={[{ required: true, message: 'Không được để trống' }]}
                  >
                    <Input placeholder="Nhập chức vụ" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item style={{ alignSelf: 'flex-end' }}>
                    <div style={{ paddingBottom: 8 }}>
                      <label>&ensp;</label>
                    </div>
                    <Button type="primary" onClick={onClickAddSencer}>
                      Thêm thành phần
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        )}
        <Table dataSource={censers} columns={columns} pagination={false} />
      </Modal>
      <Button onClick={handleModal}>Thành phần kiểm tra</Button>
    </div>
  );
};

export default HanleCenser;
