import {
  CloseCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Button, Input, message, Modal, Row, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import ClassifyMember from "../ClassifyMember";
import NewMember from "../NewMember";
import {ApproveUserOrganization, GetUserOrganization} from '../../../../api/TSOrganization'
import "./styles.scss";
import { useRecoilState, useRecoilValue } from "recoil";
import { getRoleUser, manageMember } from "../../../../config/Recoil";
const { Search } = Input;
const { Column } = Table;

export default function MemberList(props) {
  const [members, setMembers] = useState(null) 
  const role = useRecoilValue(getRoleUser)
  const groupId = localStorage.getItem('groupId')
  const [searchInput, setSearchInput] = React.useState("");
  const [loading, setLoading] = useState(true)
  const [getStateMember, setStateMember] = useRecoilState(manageMember)
  useEffect(()=>{
    GetUserOrganization({
      OrganizationID: groupId
    }).then(res =>{
      setMembers(res.UserOrganizationLst.sort((a,b) => {
        return a.UserRole - b.UserRole
      }))
      setLoading(false)
    })
  },[getStateMember])
  const data =
    members &&
    members
      .filter((member) => member.UserRole >= 1)
      .filter(
        (member) =>
          member.UserName.toLowerCase().includes(searchInput) ||
          member.FullName.toLowerCase().includes(searchInput) ||
          member.Email.toLowerCase().includes(searchInput)
      );
  const showMemberInfo = (member) => {
    return Modal.info({
      title: "Thông tin người dùng",
      content: (
        <div>
          <p>{"Tên: " + member.FullName}</p>
          <p>{"SĐT: " + member.UserName}</p>
          <p>{"Email: " + member.Email}</p>
          <p>{`Chức vụ: ${
            member.UserRole === 2
              ? "Quản lý"
              : member.UserRole === 1
              ? "Admin"
              : "Thành viên"
          }`}</p>
        </div>
      ),
    });
  };
  const removeMember = (member) =>
    Modal.confirm({
      title: "Xóa người dùng khỏi nhóm? ",
      content: (
        <div>
          <p>{"Tên: " + member.FullName}</p>
          <p>{"SĐT: " + member.UserName}</p>
        </div>
      ),
      onCancel() {},
      onOk() {
        ApproveUserOrganization({
          OrganizationID: groupId,
          UserCode: member.UserName,
          Role: 0,
        }).then(res =>{
            if(res.RespCode === 0){
              setStateMember(!getStateMember)
              message.success("Đã xóa thành viên khỏi tổ chức")
            }else{
              message.error(res.RespText)
            }
        })
      },
    });

  return (
    <div style={{width:'100%'}}>
      <Row className="search_dowload">
        <Search
          placeholder="Tìm kiếm theo SĐT-Tên-Email"
          onChange={(e) => setSearchInput(e.target.value)}
          style={{ width: 250, height: 35 }}
          className="list-tool"
          autoFocus
          allowClear
        />
        <div className="list-tool">
          {role !== 3 && (
            <Button type="primary" className="list-tool">
              <NewMember id={props.id} members={members} />
            </Button>
          )}
        </div>
      </Row>
      {members &&
        <Table dataSource={data} id="member-table" loading={loading}>
            <Column
              title="Số Điện Thoại"
              width="50px"
              dataIndex="UserName"
              key="UserName"
            />
            <Column
              title="Họ Tên"
              width="80px"
              dataIndex="FullName"
              key="FullName"
            />
            <Column
              title="Email"
              dataIndex="Email"
              key="Email"
              width="120px"
              className="user_email"
            />
            <Column
              title="Địa chỉ"
              dataIndex="Address"
              key="Address"
              width="80px"
            />
            <Column
              title="Chức vụ"
              dataIndex="UserRole"
              key="UserRole"
              width="50px"
              render={(record) => {
                if (record === 2) {
                  return "Quản lý";
                } else if (record == 3) {
                  return "Thành viên";
                } else if (record === 1) {
                  return "Người tạo";
                } else if( record === 10){
                  return "Trưởng phòng"
                }
              }}
            />
            <Column
              title="Chức năng"
              key="action"
              width="50px"
              render={(text, record, rowIndex) => (
                <div className="action-col">
                  <Tooltip placement="bottom" title="thông tin">
                    <InfoCircleOutlined
                      className="action"
                      onClick={() => showMemberInfo(record)}
                    />
                  </Tooltip>
                  {role !== 3 && record.UserRole === 3 && (
                    <Tooltip placement="bottom" title="xóa">
                      <CloseCircleOutlined
                        className="action"
                        onClick={() => removeMember(record)}
                      />
                    </Tooltip>
                  )}
                  {role !== 3 && record.UserRole !== 1 && (
                    <Tooltip placement="bottom" title="phân quyền">
                      <ClassifyMember member={record} id={props.id} />
                    </Tooltip>
                  )}
                </div>
              )}
            />
        </Table>
}
    </div>
  );
}
