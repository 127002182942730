import Request from '../config/Request';
import Request2 from '../config/Request/request2';
export function GetMaintenanceScheduleByID(data) {
  return Request({
    url: '/TSMaintenance/GetMaintenanceScheduleByID',
    method: 'POST',
    data: data,
  });
}
export function CreateMaintenanceSchedule(data) {
  return Request({
    url: '/TSMaintenance/CreateMaintenanceSchedule',
    method: 'POST',
    data: data,
  });
}
export function UpdateMaintenanceSchedule(data) {
  return Request({
    url: '/TSMaintenance/UpdateMaintenanceSchedule',
    method: 'POST',
    data: data,
  });
}
export function DelMaintenanceSchedule(data) {
  return Request({
    url: '/TSMaintenance/DelMaintenanceSchedule',
    method: 'POST',
    data: data,
  });
}
export function GetMaintenanceScheduleByOrg(data) {
  return Request2({
    url: '/TSMaintenance/GetMaintenanceScheduleByOrg',
    method: 'POST',
    data: data,
  });
}

export function ApproveMaintenanceSchedule(data) {
  return Request2({
    url: '/TSMaintenance/ApproveMaintenanceSchedule',
    method: 'POST',
    data: data,
  });
}
export function ApproveDeviceMaintenanceSchedule(data) {
  return Request2({
    url: '/TSMaintenance/ApproveDeviceMaintenanceSchedule',
    method: 'POST',
    data: data,
  });
}
export function GetMaintenanceByDeviceID(data) {
  return Request2({
    url: '/TSMaintenance/GetMaintenanceByDeviceID',
    method: 'POST',
    data: data,
  });
}
export function GetMaintenanceScheduleNoti(data) {
  return Request2({
    url: '/TSMaintenance/GetMaintenanceScheduleNoti',
    method: 'POST',
    data: data,
  });
}
export function GetMaintenanceScheduleInfoByDeviceId(data) {
  return Request2({
    url: '/TSMaintenance/GetMaintenanceScheduleInfoByDeviceId',
    method: 'POST',
    data: data,
  });
}
