import React, { useRef, Component } from 'react'
import {Button} from 'antd'
import ReactToPrint from "react-to-print";
import FormDevices from './FormDevives';
class ComponentToPrint extends Component{
    render(){
        return(
            <div>
                <FormDevices contentReport={this.props.contentReport} />
            </div>
        )
    }
}
const PrintReportInvenDevice = ({contentReport}) => {
    const report = useRef()
    return (
        <div>
            <div style={{display:'none'}}>
                <ComponentToPrint ref={report} contentReport={contentReport}/>
            </div>
            <ReactToPrint
                documentTitle="Báo cáo thiết bị đã kiểm kê"
                trigger={() => <Button type="primary">In báo cáo</Button>}
                content={() => report.current}
            />
        </div>
    )
}

export default PrintReportInvenDevice
