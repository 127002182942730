export const URL_QLTS = 'https://emglab.vn/QLTS';
// export const URL_QLTS = "http://upharma.xyz/api";

// export const DOMAIN = "http://tbyt-dienbien.work";
// export const companyID = 145
// export const companyName = 'TTYT'
// export const TitleReport = 'TTYT HUYỆN ĐIỆN BIÊN'
// export const titleInventory = 'BIÊN BẢN KIỂM KÊ  VẬT TƯ CỐ ĐỊNH , MÁY MÓC Y TẾ NĂM 2021'

export const DOMAIN = 'https://bvdakhoa.tbyt-dienbien.work';
export const companyID = 77;
export const VTYT = 156;
export const companyName = 'BVDK';
export const TitleReport = 'Bệnh Viện Đa Khoa Tỉnh Điện Biên';
export const titleInventory = 'BIÊN BẢN KIỂM KÊ  VẬT TƯ CỐ ĐỊNH - TTB - Y DỤNG CỤ Y TẾ NĂM 2021';
