import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DeviceRepairCreate } from '../../../../api/Device';

const QRCodeReportRepair = ({ type }) => {
  const [visible, setVisible] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [form] = Form.useForm();
  const { scanCode } = useParams();
  const handleModal = () => {
    form.resetFields();
    setVisible(true);
  };
  const handleSubmit = async (values) => {
    setBtnLoading(true);
    const response = await DeviceRepairCreate({ DeviceRepairInfo: { ...values, DeviceQRCode: scanCode, Type: type } });
    setBtnLoading(false);
    if (response.RespCode === 0) {
      message.success('Xác nhận bảo dưỡng thành công');
      setVisible(false);
    }
  };
  return (
    <div>
      <Modal
        visible={visible}
        title={type === 'repair' ? 'Báo cáo bảo dưỡng thiết bị' : 'Báo cáo kiểm định thiết bị'}
        confirmLoading={btnLoading}
        onCancel={() => setVisible(false)}
        onOk={() => form.submit()}
        okText="Xác nhận"
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            label={type === 'repair' ? 'Tên người bảo dưỡng' : 'Tên người kiểm định'}
            name="CreaterName"
            rules={[{ required: true, message: 'Không được để trống' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="SDT" name="PhoneNumber" rules={[{ required: true, message: 'Không được để trống' }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Mô tả tình trạng trước và sau khi kiểm tra"
            name="Description"
            rules={[{ required: true, message: 'Không được để trống' }]}
            tooltip={{
              title: 'Tình trạng của thiết bị trước và sau khi bảo dưỡng xong',
              icon: <InfoCircleOutlined />,
            }}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="Ghi chú" name="Note">
            <Input.TextArea />
          </Form.Item>
          <b>Thông tin báo cáo này của bạn sẽ được gửi tới những bộ phận liên quan để xác nhận việc bảo dưỡng này.</b>
        </Form>
      </Modal>
      {type === 'repair' ? (
        <Button type="dashed" danger shape="round" size="large" onClick={handleModal}>
          Báo cáo bảo dưỡng
        </Button>
      ) : (
        <Button type="dashed" shape="round" size="large" onClick={handleModal}>
          Báo cáo kiểm định
        </Button>
      )}
    </div>
  );
};

export default QRCodeReportRepair;
