import React, { useRef, useState } from "react";
import QrReader from "react-qr-reader";
import { message, Form, Input, Button, Select } from "antd";
import Modal from "antd/lib/modal/Modal";
import { GetDeviceInfoByQRCode } from "../../../api/QrCode";
import {useRecoilState } from "recoil";
import { addLstDevice, deviceInventory } from "../../../config/Recoil";
import './ReadQr.scss'
import { DOMAIN } from "../../../config/URL/Qlts";
const layout = {
  labelCol:{span: 8},
  wrapperCol: { span: 16}
}
const ReaderQR = ({resInfoDevice, deviceLst}) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [infoDevice, setInfoDevice] = useState(null)
  const [device, setDevice] = useRecoilState(deviceInventory)
  const [displayScan, setDisplayScan] = useState(false)

  const ref = useRef();
  const [form] = Form.useForm()
  const handleScan = (data) => {
    if (data) {
        const link = `${DOMAIN}/qr-code/`
        const qrCode = data.slice(link.length, data.length)
        const check = deviceLst.find(device => device.QRCode === qrCode)
          if(check !== undefined){
            message.error('Thiết bị đã tồn tại')
            setDisplayScan(false)
            return
          }
        GetDeviceInfoByQRCode({
            QRCode: qrCode
        }).then(res =>{
          if(res.RespCode === 0){
            setVisible(true)
            setInfoDevice(res.DeviceInfo)
          }
          else{
            message.error(res.RespText)
          }
        })
    }
  };
  const handleError = (err) => {
    message.error(err)
  };
  const onOk = ()=>{
    form.submit()
  }
  const onCancel = ()=>{
    setVisible(false)
  }
  const submit = (elements)=>{
    const deviceInven = {
      ...infoDevice,
      StatusWork: parseInt(elements.StatusWork),
      NoteChek: elements.NoteChek
    }
    resInfoDevice(deviceInven)
    const newLst = addLstDevice(device, deviceInven)
    setDevice(newLst)
    setVisible(false)
  }
  const upLoadImg = () => {
    setDisplayScan(!displayScan)
  };

  return (
    <div className="readQr">
      <Modal visible={visible} title="Kiểm kê thiết bị" onOk={onOk} onCancel={onCancel} okText="Xác nhận" cancelText="Hủy">
        <Form form={form} onFinish={submit} {...layout}>
          <Form.Item label="Trạng thái" name="StatusWork" rules={[{
            required: true,
            message: 'Hãy chọn trạng thái'
            }]}>
            <Select>
              <Select.Option value="1">Bình thường</Select.Option>
              <Select.Option value="2">Lỗi</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Mô tả" name="NoteChek" rules={[{
            required: true,
            message: 'Nhập mô tả tình trạng'
          }]}>
            <Input.TextArea/>
          </Form.Item>
        </Form>
      </Modal>
      <Button loading={loading} className="btn_img_qrcode" onClick={upLoadImg}>
          Quét mã QR
      </Button>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        className="scan"
      >
        {
          displayScan && <QrReader
          // ref={ref}
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: "100%", height: "50%", display: "flex" }}
          className="qr_reader"
          // legacyMode
        />
        }
        
      </div>
    </div>
  );
};

export default ReaderQR;