import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { message, Modal } from 'antd';
import React from 'react';
import { DelMaintenanceSchedule } from '../../../../api/TSMaintenance';
import './DeleteDivide.scss';
const { confirm } = Modal;
const DeleteDivice = ({ record, rowIndex, listDiviDe, setListDivide }) => {
  const deleteDivide = async (DocumentID, rowIndex) => {
    const response = await DelMaintenanceSchedule({
      DocumentID: Number(DocumentID),
    });
    if (response.RespCode === 0) {
      const cloneData = [...listDiviDe];
      const newData = cloneData.filter((item, index) => {
        return index !== rowIndex;
      });
      setListDivide(newData);
      message.success('Đã xóa lịch bảo dưỡng thành công');
    }
  };
  const ShowConfirmDelete = (info, index) => {
    confirm({
      title: `Bạn có muốn xóa lịch bảo dưỡng của thiết bị do ${record.CreaterID} tạo lúc ${record.TimeCreate}??`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        deleteDivide(info.DocumentID, index);
      },
    });
  };
  return (
    <div>
      <div onClick={() => ShowConfirmDelete(record, rowIndex)}>
        <DeleteOutlined style={{ cursor: 'pointer' }} />
      </div>
    </div>
  );
};
export default DeleteDivice;
