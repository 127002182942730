const API_DEVICE_LST = '/ho-so-thiet-bi';
const API_DEVICE_ADD = '/them-thiet-bi-moi';
const API_DEVICE_MOV = '/ho-so-luan-chuyen';
const API_DEVICE_MOV_CREATE = '/ho-so-luan-chuyen/tao-moi';
const API_DEVICE_REPORT = '/ho-so-bao-cao';
const API_DEVICE_MAINTAIN = '/ho-so-lich-theo-doi';
const API_DEVICE_REPAIR = '/ho-so-lich-lien-he-bao-duong';
const API_DEVICE_ADD_EXCEL = '/them-thiet-bi/excel';
const API_DEVICE_PRINT_QR = '/in-ma-qr';
const API_DEVICE_LST_ERROR = '/ho-so-thiet-bi-bao-loi';
const API_DEVICE_REQUEST_ACCREDITATION = '/ho-so-yeu-cau-kiem-dinh';
const API_DEVICE_REQUEST_CHECK = '/ho-so-yeu-cau-hieu-chuan';
const API_DEVICE_LST_REPORT = '/danh-sach-thiet-bi-kiem-tra';
const API_DEVICE_REPAIR_QR = '/thiet-bi-yeu-cau-sua-chua';
const API_DEVICE_REQUEST_REPAIR = '/ho-so-yeu-cau-bao-duong';
//report result
const API_DEVICE_REPORT_ACCREDITATION = '/ho-so-bao-cao-kiem-dinh';
const API_DEVICE_REPORT_REPAIR = '/ho-so-bao-cao-bao-duong';
//move device to user
const API_MOVE_DEVICE_USER = '/phan-bo';

const API_REPORT = '/bao-cao';
const API_DEVICE_LIQUIDATION = '/danh-sach-thanh-ly';
const API_DEVICE_LIQUI_CREATE = '/tao-moi-phieu-thanh-ly';
const API_DEVICE_OFFER = '/de-xuat-thiet-bi-moi';
const API_DEVICE_CREATE_SCHEDULE = '/tao-lich-theo-doi';
const API_DEVICE_LINK = '/danh-sach-phan-bo';
const API_DEVICE_LINK_CREATE = '/phan-bo/tao-moi';
const API_DEVICE_CREATE = '/tao-moi-thiet-bi';
export {
  API_DEVICE_LST,
  API_DEVICE_REQUEST_REPAIR,
  API_DEVICE_REQUEST_ACCREDITATION,
  API_DEVICE_REQUEST_CHECK,
  API_REPORT,
  API_DEVICE_REPAIR,
  API_DEVICE_LINK,
  API_DEVICE_MOV_CREATE,
  API_DEVICE_CREATE_SCHEDULE,
  API_DEVICE_OFFER,
  API_DEVICE_ADD,
  API_DEVICE_MOV,
  API_MOVE_DEVICE_USER,
  API_DEVICE_REPORT,
  API_DEVICE_MAINTAIN,
  API_DEVICE_ADD_EXCEL,
  API_DEVICE_PRINT_QR,
  API_DEVICE_LINK_CREATE,
  API_DEVICE_LST_ERROR,
  API_DEVICE_LST_REPORT,
  API_DEVICE_LIQUIDATION,
  API_DEVICE_CREATE,
  API_DEVICE_REPORT_REPAIR,
  API_DEVICE_LIQUI_CREATE,
  API_DEVICE_REPORT_ACCREDITATION,
  API_DEVICE_REPAIR_QR,
};
