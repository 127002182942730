import React from 'react';
import { Button } from 'antd';
import XLSX from 'xlsx';

const ExportFormatExcel = () => {
  const exportExcel = () => {
    const createXLSLFormatObj = [];
    var filename = 'Định dạng thêm danh sách thiết bị.xlsx';
    const tHeader = [
      'Tên thiết bị',
      'Mã thiết bị',
      'Mã tham chiếu',
      'Giá trị thiết bị',
      'Model',
      'Serial',
      'Đơn vị',
      'Tình trạng',
      'Loại thiết bị',
      'Nhóm thiết bị',
      'Xuất xứ',
      'Nhà cung cấp',
      'Thời gian nhập',
      'Bắt đầu bảo hành',
      'Kết thúc bảo hành',
      'Thời gian bắt đầu',
      'Chu kỳ bảo dưỡng',
      'Ghi chú',
    ];
    createXLSLFormatObj.push(tHeader);
    const wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
    XLSX.utils.book_append_sheet(wb, ws, 'FreakySheet');
    XLSX.writeFile(wb, filename);
  };
  return (
    <div>
      <Button>
        <a href="/fileImport/dinh_dang_them_thiet_bi.xlsx" target="_blank">
          Xuất định dạng excel
        </a>
      </Button>
    </div>
  );
};
export default ExportFormatExcel;
