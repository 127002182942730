import React, {useRef} from 'react'
import ReactToPrint from 'react-to-print'
import './form.scss'
import FormReport from './form'
import { Button } from 'antd';
import { useLocation } from 'react-router';
import { API_DEVICE_LST_REPORT } from '../../../../../constants/Device';
class ComponentToPrint extends React.Component {
    render() {
        return (
            <div className='report'>
                <FormReport infoReport = {this.props.info}/>
            </div>
        );
    }
}
const FormPrint = ({infoPrint}) => {
    const location = useLocation()
    const value = useRef();
    return (
        <div>
            <div style={{ display: "none" }}>
                <ComponentToPrint ref={value} info={infoPrint} />
            </div>
            <ReactToPrint
                documentTitle="Báo hỏng thiết bị"
                trigger={() => <div>
                    <Button type='primary' disabled={infoPrint.length === 0}>
                        {
                            location.pathname === API_DEVICE_LST_REPORT ? 'In báo cáo' : 'In phiếu báo hỏng'
                        }
                    </Button>
                </div>}
                content={() => value.current}
            >
            </ReactToPrint>
        </div>
    )
}

export default FormPrint
