import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, message, Modal, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import Axios from 'axios';
import React, { useContext, useState } from 'react';
import CsvDownload from 'react-json-to-csv';
import reportDeviceAPI from '../../../api/ReportDeviceApi';
import { getToken, getUserName } from '../../../helpers/auth';
import mapLstTable from '../../helper/mapLstTable';
import Create from '../Maintain/Create';
import { MaintainContext } from '../Maintain/MainTainContext';
import InfoReport from './InfoReport';
import { GetDeviceReportByUser, GetDeviceReportByGId } from '../../../api/Device';
import './Report.scss';
const { RangePicker } = DatePicker;
const ReportEquipment = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const select = useContext(MaintainContext);
  const roleUser = props.role;
  const onFinish = async (value) => {
    if (value === null) return;
    if (typeof value[0] !== 'object' || typeof value[1] !== 'object') {
      message.info('Mẫu input không đúng');
      return;
    }
    const timeStart = value[0].format('YYYY-MM-DD HH:mm:ss');
    const timeEnd = value[1].format('YYYY-MM-DD HH:mm:ss');
    let devices = [];
    setLoading(true);
    if (roleUser === 3) {
      const apiUser = reportDeviceAPI.getInfoByUser();
      const requestUser = {
        TimeStart: timeStart,
        TimeEnd: timeEnd,
      };
      const responseReport = await GetDeviceReportByUser(requestUser);
      if (responseReport.RespCode === 0) {
        devices = responseReport.DeviceReportLst;
      }
    } else {
      const api = reportDeviceAPI.getInfoByGId();
      const request = {
        GroupId: props.groupId,
        TimeStart: timeStart,
        TimeEnd: timeEnd,
      };
      const responseReport = await GetDeviceReportByGId(request);
      devices = responseReport.DeviceReportLst;
      const dataSorted = devices.sort((a, b) => {
        return new Date(a.TimeCreate) - new Date(b.TimeCreate);
      });
      setData(mapLstTable(dataSorted));
    }
  };
  const confirmReport = (infoReport) => {
    const api = reportDeviceAPI.approveReport();
    const request = {
      Token: getToken(),
      UserName: getUserName(),
      DocumentId: infoReport.DocumentId,
      ReportStatus: 2,
      ReportComment: infoReport.Comment,
      DeviceStatus: 3,
      DeviceNote: infoReport.Note,
    };
    Axios.post(api, request)
      .then((res) => {
        if (res.data.RespCode === 0) {
          message.success('Đã phê duyệt');
          setData(data);
        } else {
          message.error(res.data.RespText);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const showModalConfirm = (infoReport) => {
    Modal.confirm({
      title: 'Xác nhận báo cáo',
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>Người tạo: {infoReport.CreaterId}</p>
          <p>Tên thiết bị: {infoReport.DeviceName}</p>
          <p>Địa chỉ: {infoReport.Location}</p>
          <p>Mô tả: {infoReport.Comment}</p>
          <p>Thời gian tạo: {infoReport.TimeCreate}</p>
        </div>
      ),
      onOk() {
        confirmReport(infoReport);
      },
    });
  };
  return (
    <div className="report-equipment">
      <div className="convert-csv">
        <div className="btn-create-report">
          <div>
            <Form form={form} onChange={onFinish} name="time">
              <Form.Item name="time_report" label="Thời gian">
                <RangePicker format="YYYY-MM-DD" onChange={onFinish} />
              </Form.Item>
            </Form>
          </div>
          <div>
            {select.selectEquipment.length > 0 ? (
              <div style={{ display: 'flex' }}>
                <CsvDownload
                  data={data}
                  filename="Báo cáo danh sách thiết bị.csv"
                  style={{
                    background: '#1890ff',
                    backgroundColor: '#1890ff',
                    borderRadius: '3px',
                    border: '1px solid #1890ff',
                    display: 'inline-block',
                    cursor: 'pointer',
                    color: '#fff',
                    fontSize: '15px',
                    textDecoration: 'none',
                    height: '32px',
                  }}
                >
                  Tạo báo cáo
                </CsvDownload>
                <div>
                  <Create length={select.selectEquipment.length} organizationID={props.groupId} />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className="table-report">
        <Table dataSource={data} key="DeviceId" className="list_report" loading={loading}>
          <div key={data.DeviceId}>
            <Column title="STT" dataIndex="key" key="key" />
            <Column title="Người báo cáo" dataIndex="CreaterId" key="CreaterId" />
            <Column title="Mã thiết bị" dataIndex="DeviceCode" key="DeviceCode" />
            <Column title="Tên thiết bị" dataIndex="DeviceName" key="DeviceName" />
            <Column title="Phòng ban" dataIndex="Location" key="Location" />
            <Column title="Thời gian tạo báo cáo" dataIndex="TimeCreate" key="TimeCreate" />
            <Column
              title="Trạng thái"
              dataIndex="Status"
              key="Status"
              render={(record) => {
                {
                  if (record === 1) return 'Báo hỏng';
                  else if (record === 2) return 'Đã xác nhận';
                  else if (record === 3) return 'Đã tạo lịch bảo dưỡng';
                }
              }}
            />
            <Column title="Mô tả" dataIndex="Comment" key="Comment" />
            <Column
              render={(text, record) => (
                <div className="action_report">
                  {props.role !== 3 && record.Status === 1 ? (
                    <Button onClick={() => showModalConfirm(record)}>phê duyệt</Button>
                  ) : (
                    ''
                  )}
                  {props.role !== 3 ? (
                    <div>
                      {record.Status === 2 ? (
                        <Button
                          onClick={() => {
                            select.chooseEquip(record);
                          }}
                        >
                          Chọn
                        </Button>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                  <InfoReport infoReport={record} />
                </div>
              )}
            />
          </div>
        </Table>
      </div>
    </div>
  );
};
export default ReportEquipment;
