import Request from "../config/Request";
import Request2 from "../config/Request/request2";
export function CreateInventoryDoc(data) {
    return Request({
        url: "/TSInventoryDoc/CreateInventoryDoc",
        method: "POST",
        data: data,
    });
}
export function GetInventoryDocLst(data) {
    return Request({
        url: "/TSInventoryDoc/GetInventoryDocLst",
        method: "POST",
        data: data,
    });
}
export function GetInventoryDocByID(data) {
    return Request({
        url: "/TSInventoryDoc/GetInventoryDocByID",
        method: "POST",
        data: data,
    });
}

export function UpdateInventoryDoc(data) {
    return Request2({
        url: "/TSInventoryDoc/UpdateInventoryDoc",
        method: "POST",
        data: data,
    });
}

export function DelInventoryDoc(data) {
    return Request2({
        url: "/TSInventoryDoc/DelInventoryDoc",
        method: "POST",
        data: data,
    });
}

export function ApproveInventoryDoc(data) {
    return Request2({
        url: "/TSInventoryDoc/ApproveInventoryDoc",
        method: "POST",
        data: data,
    });
}

export function GetInventoryDocByOrg(data) {
    return Request({
        url: "/TSInventoryDoc/GetInventoryDocByOrg",
        method: "POST",
        data: data,
    });
}

export function GetInventoryDocByUser(data) {
    return Request2({
        url: "/TSInventoryDoc/GetInventoryDocByUser",
        method: "POST",
        data: data,
    });
}
export function DBCreateInventoryDoc(data) {
    return Request2({
        url: "/TSInventoryDoc/DBCreateInventoryDoc",
        method: "POST",
        data: data,
    });
}
export function DBInventoryDeviceLst(data) {
    return Request2({
        url: "/TSInventoryDoc/DBInventoryDeviceLst",
        method: "POST",
        data: data,
    });
}
export function DBInvenDeviceLst(data) {
    return Request({
        url: "/TSInventoryDoc/DBInvenDeviceLst",
        method: "POST",
        data: data,
    });
}

export function GetDeviceInventoryCompany(data) {
    return Request({
        url: "/TSInventoryDoc/GetDeviceInventoryCompany",
        method: "POST",
        data: data,
    });
}

export function GetInventoryDocByCompany(data) {
    return Request({
        url: "/TSInventoryDoc/GetInventoryDocByCompany",
        method: "POST",
        data: data,
    });
}