import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { VerifyAdmin } from '../api/Admin';
import { companyID } from '../config/URL/Qlts';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { getToken } from '../helpers/auth';
const AdminRoute = ({ path, component: Component, layout: Layout, ...rest }) => {
  const [allowAccess, setAllowAccess] = useState(false);
  const history = useHistory();
  const token = getToken();
  function onClickBack() {
    history.goBack();
  }
  useEffect(() => {
    const users = async () => {
      const response = await VerifyAdmin({
        CompanyId: companyID,
      });
      setAllowAccess(response.RespCode === 0);
    };
    users();
  }, []);
  return (
    <Route
      {...rest}
      render={(props) => {
        return token ? (
          <Layout>
            {allowAccess ? (
              <Component />
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <b>Không có quyền truy cập</b>
                <div style={{ paddingTop: 15 }}>
                  <Button onClick={onClickBack}>Quay lại</Button>
                </div>
              </div>
            )}
          </Layout>
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
};
export default AdminRoute;
