import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm, Tooltip, notification } from 'antd';
import React from 'react';
import { useRecoilState } from 'recoil';
import { DelDeviceSet } from '../../../../api/DeviceSet';
import { manageSetDevice } from '../../../../config/Recoil';
const DelSetDevice = ({ infoSet, rowIndex, lstSetDevice, setLstSetDevice }) => {
  const [getDel, delSetDevice] = useRecoilState(manageSetDevice);
  const titleDel = `Bạn có muốn xóa bộ thiết bị ${infoSet.DeviceName}`;
  const confirmDel = () => {
    DelDeviceSet({
      DeviceSetID: parseInt(infoSet.DeviceId),
    }).then((res) => {
      notification.success({
        message: 'Xóa bộ thiết bị thành công',
        duration: 1,
      });
      delSetDevice(!getDel);
    });
  };
  return (
    <div>
      <Popconfirm
        title={titleDel}
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        onConfirm={confirmDel}
        okText="Xác nhận"
        cancelText="Hủy"
      >
        <Tooltip placement="bottom" title="Xóa">
          <DeleteOutlined style={{ color: '#eb4034', fontSize: 22 }} />
        </Tooltip>
      </Popconfirm>
    </div>
  );
};

export default DelSetDevice;
