import Request from '../config/Request'
import Request2 from '../config/Request/request2'

export function CreateOfferDeviceHeader(data){
    return Request2({
        url: "/DeviceOffer/CreateOfferDeviceHeader",
        method: "POST",
        data: data,
    })
}

export function UpdateOfferDeviceHeader(data){
    return Request({
        url: "/DeviceOffer/UpdateOfferDeviceHeader",
        method: "POST",
        data: data,
    })
}

export function DelOfferDeviceHeaderByID(data){
    return Request({
        url: "/DeviceOffer/DelOfferDeviceHeaderByID",
        method: "POST",
        data: data,
    })
}

export function GetOfferDeviceHeaderByID(data){
    return Request({
        url: "/DeviceOffer/GetOfferDeviceHeaderByID",
        method: "POST",
        data: data,
    })
}

export function GetOfferDeviceHeaderByTime(data){
    return Request2({
        url: "/DeviceOffer/GetOfferDeviceHeaderByTime",
        method: "POST",
        data: data,
    })
}

export function GetOfferDeviceByUser(data){
    return Request2({
        url: "/DeviceOffer/GetOfferDeviceByUser",
        method: "POST",
        data: data,
    })
}

export function GetOfferByOrgReceive(data){
    return Request2({
        url: "/DeviceOffer/GetOfferByOrgReceive",
        method: "POST",
        data: data,
    })
}

export function ApproveOfferDeviceHeaderByID(data){
    return Request2({
        url: "/DeviceOffer/ApproveOfferDeviceHeaderByID",
        method: "POST",
        data: data,
    })
}
export function CreateOfferMaintanceHeader(data){
    return Request2({
        url: "/DeviceOffer/CreateOfferMaintanceHeader",
        method: "POST",
        data: data,
    })
}
export function GetOfferMaintanceByID(data){
    return Request2({
        url: "/DeviceOffer/GetOfferMaintanceByID",
        method: "POST",
        data: data,
    })
}