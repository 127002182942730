import axios from 'axios';
import { getToken, getUserName } from '../../helpers/auth';
import { URL_QLTS } from '../URL/Qlts';

const service = axios.create({
  baseURL: URL_QLTS, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000, // request timeout
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

service.interceptors.request.use(
  (config) => {
    if (config.data == null) {
      config.data = {};
    }
    if (getToken()) {
      config.data.Token = getToken();
      config.data.UserName = getUserName();
    }
    return config;
  },
  (error) => {
    if (error.code === 'ECONNABORTED') {
      return Promise.reject(error);
    } else {
      // Handle other errors
      return Promise.reject(error);
    }
  }
);

service.interceptors.response.use(
  (response) => {
    if (response.data == null) {
      response.data = {};
    }
    const res = response.data;
    if (res.RespCode !== 0) {
      Promise.reject();
      return res;
    } else {
      return res;
    }
  },
  (error) => {
    // do something with request error
    if (error.code === 'ECONNABORTED') {
      return Promise.reject(error);
    } else {
      // Handle other errors
      return Promise.reject(error);
    }
  }
);
export default service;
