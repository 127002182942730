import moment from 'moment';
import React from 'react';
import { statusActionDevice } from '../../../helpers/mapping';
import { dateDisplay } from '../../helper/mapping';

const FormReportBVDK = ({ lstMoveDevice = [], censers }) => {
  const day = () => {
    return moment(lstMoveDevice.TimeCreate).day();
  };
  const month = () => {
    return moment(lstMoveDevice.TimeCreate).month();
  };
  const year = () => {
    return moment(lstMoveDevice.TimeCreate).year();
  };
  return (
    <div>
      {lstMoveDevice && (
        <div
          style={{
            fontFamily: '"Times New Roman", Times, serif',
            lineHeight: '1.6em',
            padding: '40px',
            fontSize: '16px',
          }}
        >
          <div style={{ textAlign: 'end', textTransform: 'uppercase', fontSize: '12px' }}>
            {lstMoveDevice.DocumentCode} - bm.05.qtql.05.vttbyt
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
              <div>bệnh viện đa khoa tỉnh điện biên</div>
              <div>Khoa: {lstMoveDevice.OrganizationNameOld}</div>
            </div>
            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
              <div style={{ textTransform: 'uppercase' }}>Cộng hòa xã hội chủ nghĩa việt nam</div>
              <div>Đôc lập - Tự do - Hạnh phúc</div>
            </div>
          </div>
          <div
            style={{
              textTransform: 'uppercase',
              fontSize: '20px',
              padding: '30px 0',
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            Biên bản bàn giao tài sản điều chuyển
          </div>
          <div>
            Căn cứ Quyết định số ........ ngày {dateDisplay(lstMoveDevice.TimeCreate)} của Giám đốc Bệnh viện đa khoa
            tỉnh Điện Biên (nếu có)
          </div>
          <div>
            Căn cứ phiếu đề xuất số ........ ngày {dateDisplay(lstMoveDevice.TimeCreate)} của Khoa/Phòng{' '}
            {lstMoveDevice.OrganizationNameOld} về việc xin điều chuyển tài sản (có danh mục kèm theo)
          </div>
          <div>
            Căn cứ phiếu đề xuất số ........ ngày {dateDisplay(lstMoveDevice.TimeCreate)} của Khoa/Phòng{' '}
            {lstMoveDevice.OrganizationNameNew} về việc xin tiếp nhận tài sản (có danh mục kèm theo)
          </div>
          <div>
            Căn cứ Biên bản kiểm tra hiện trạng TS (nếu có) số ........ ngày {dateDisplay(lstMoveDevice.TimeCreate)}
          </div>
          <div>
            Hôm nay, ngày {day()} tháng {month()} năm {year()}
          </div>
          <div>Chúng tôi gồm có:</div>
          {censers &&
            censers.map((censer, index) => (
              <div key={index}>
                {censer.SetupLine}. Ông/bà: {censer.FullName}, Chức vụ: {censer.Position}
              </div>
            ))}
          <div>
            Cùng tiền hành bàn giao tài sản từ Khoa/phòng {lstMoveDevice.OrganizationNameOld} sang Khoa/phòng{' '}
            {lstMoveDevice.OrganizationNameNew} theo danh mục sau:
          </div>
          <div>
            <table style={{ width: '100%', border: '1px solid #ddd', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={{ padding: '8px', border: '1px solid #ddd' }}>STT</th>
                  <th style={{ padding: '8px', border: '1px solid #ddd' }}>Tên tài sản</th>
                  <th style={{ padding: '8px', border: '1px solid #ddd' }}>Mã tài sản</th>
                  <th style={{ padding: '8px', border: '1px solid #ddd' }}>Model</th>
                  <th style={{ padding: '8px', border: '1px solid #ddd' }}>Serial</th>
                  <th style={{ padding: '8px', border: '1px solid #ddd' }}>Đơn vị tính</th>
                  <th style={{ padding: '8px', border: '1px solid #ddd' }}>Số lượng</th>
                  <th style={{ padding: '8px', border: '1px solid #ddd' }}>Tình trạng hiện tại</th>
                </tr>
              </thead>
              <tbody>
                {lstMoveDevice.DeviceLstPrint.map((device, index) => (
                  <tr key={device.DeviceId}>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{index + 1}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{device.DeviceName}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{device.DeviceCode}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{device.Model}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd', wordBreak: 'break-all' }}>
                      {device.Serial}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{device.Unit}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>1</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{statusActionDevice(device.Status)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div style={{ textAlign: 'end', paddingTop: '10px' }}>
            Điện biên, ngày {moment().date()} tháng {moment().month() + 1} năm {moment().year()}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '30px' }}>
            <div>
              <div style={{ textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center' }}>P.TCKT</div>
              <i>(Ký, ghi rõ họ tên)</i>
            </div>
            <div>
              <div style={{ textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center' }}>P.VTTBYT</div>
              <i>(Ký, ghi rõ họ tên)</i>
            </div>
            <div>
              <div style={{ textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center' }}>
                {lstMoveDevice.OrganizationNameOld}
              </div>
              <i>(Ký, ghi rõ họ tên)</i>
            </div>
            <div>
              <div style={{ textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center' }}>
                {lstMoveDevice.OrganizationNameNew}
              </div>
              <i>(Ký, ghi rõ họ tên)</i>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormReportBVDK;
