import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, Space, Table, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { convertDaysToDate } from '../../config/convertDateExcelToDate';
import { accreditationDisplay, formatMoneyDisplay, status } from '../helper/mapping';
import './MoveDevice.scss';
import { GetDeviceByOrganization } from '../../api/Device';
import { AddKeyToLst } from '../helper/addKeyToLst';
import TextArea from 'antd/lib/input/TextArea';
import { GetUserOrganization } from '../../api/TSOrganization';
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};
const MoveDeviceUser = () => {
  const [devices, setDevices] = useState([]);
  const [devicesDisplay, setDevicesDisplay] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tooltipDiffTime, setTooltipDiffTime] = useState('nothing');
  const [recivers, setRecivers] = useState([]);
  const [form] = Form.useForm();
  const groupId = localStorage.getItem('groupId');
  useEffect(() => {
    setLoading(true);
    const getDevices = async () => {
      await GetDeviceByOrganization({
        OrganizationID: groupId,
        RowNumber: '',
        PageNumber: '',
        Search: '',
        DeviceGroup: '',
        TypeDevice: 'Medical',
      }).then((res) => {
        const deviceLst = AddKeyToLst(res.DeviceLst.filter((device) => device.Status === 1)).map((device) => {
          return {
            ...device,
            Serial: device.Serial ?? '',
            Model: device.Model ?? '',
          };
        });
        setDevices(deviceLst);
        setDevicesDisplay(deviceLst);
      });
    };
    getDevices();
    setLoading(false);
  }, [groupId]);
  const onChangeSearch = (value = '') => {
    const filterDevice = [...devices].filter((device) => {
      return (
        device.DeviceName.toLowerCase().indexOf(value.trim().toLowerCase()) !== -1 ||
        device.DeviceCode.toLowerCase().indexOf(value.trim().toLowerCase()) !== -1 ||
        device.Serial.toLowerCase().indexOf(value.trim().toLowerCase()) !== -1 ||
        device.Model.toLowerCase().indexOf(value.trim().toLowerCase()) !== -1
      );
    });
    setDevicesDisplay(filterDevice);
  };
  const onOpenTimeDiff = (record) => {
    const TimeStart = moment(record.TimeStart);
    const timeCurrent = moment();
    const timeDiff = timeCurrent.diff(TimeStart, 'days');
    let tootipDisplay = '';
    if (timeDiff < 0) {
      tootipDisplay = 'Hết hạn sử dụng';
    } else {
      tootipDisplay = `Thời gian sử dụng: ${convertDaysToDate(timeDiff)}`;
    }
    setTooltipDiffTime(tootipDisplay);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onCreateMove = () => {
    setVisible(true);
    form.resetFields();
    const getUsers = async () => {
      await GetUserOrganization({
        OrganizationID: groupId,
      }).then((res) => {
        setRecivers(res.UserOrganizationLst);
      });
    };
    getUsers();
  };
  const onSubmitCreateMove = () => {
    form.submit();
  };
  const onConfirmCreateMove = (form) => {
    console.log(form);
  };
  const columns = [
    {
      title: 'STT',
      dataIndex: 'key',
      key: 'key',
      width: 50,
    },
    {
      title: 'Người tạo',
      dataIndex: 'CreaterName',
      key: 'CreaterName',
      width: 150,
    },
    {
      title: 'Tên TB',
      dataIndex: 'DeviceName',
      key: 'DeviceName',
      width: 150,
    },
    {
      title: 'Mã TB',
      dataIndex: 'DeviceCode',
      key: 'DeviceCode',
      width: 100,
    },
    {
      title: 'Serial',
      dataIndex: 'Serial',
      key: 'Serial',
      width: 150,
    },
    {
      title: 'Model',
      dataIndex: 'Model',
      key: 'Model',
      width: 150,
    },
    {
      title: 'Loại TB',
      dataIndex: 'Type',
      key: 'Type',
      width: 150,
    },
    {
      title: 'Giá trị ban đầu',
      dataIndex: 'DeviceValue',
      key: 'DeviceValue',
      render: (record) => {
        return formatMoneyDisplay(record);
      },
      width: 150,
    },
    {
      title: 'Giá trị hiện tại',
      dataIndex: '',
      key: '',
      width: 150,
      render: (record) => {
        return (
          <div>
            {formatMoneyDisplay(record.DeviceValueCurrent)}
            <Tooltip title={tooltipDiffTime} trigger={['click']} onClick={() => onOpenTimeDiff(record)}>
              <InfoCircleOutlined style={{ cursor: 'pointer', marginLeft: 4 }} />
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: 'TG bắt đầu sử dụng',
      dataIndex: 'TimeStart',
      key: 'TimeStart',
      width: 150,
      render: (record) => {
        if (record) {
          return moment(record).format('DD/MM/YYYY');
        }
      },
    },
    {
      title: 'Nhóm thiết bị',
      dataIndex: 'DeviceGroup',
      key: 'DeviceGroup',
      width: 150,
    },
    {
      title: 'Kiểm định',
      dataIndex: 'Accreditation',
      key: 'Accreditation',
      width: 150,
      render: (record) => {
        return <Tag color={accreditationDisplay(record).color}>{accreditationDisplay(record).label}</Tag>;
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'Status',
      key: 'Status',
      width: 150,
      fixed: 'right',
      render: (record) => {
        return <Tag color={status(record).color}>{status(record).label}</Tag>;
      },
    },
  ];
  return (
    <div className="move-device">
      <Modal
        visible={visible}
        title="Tạo phiếu phân bổ thiết bị"
        onCancel={() => setVisible(false)}
        onOk={() => onSubmitCreateMove()}
      >
        <div>
          <Form form={form} onFinish={onConfirmCreateMove} {...layout}>
            <Form.Item
              name="UserCode"
              label="Người nhận"
              rules={[
                {
                  required: true,
                  message: 'Hãy chọn người nhận',
                },
              ]}
            >
              <Select showSearch optionFilterProp="children" placeholder="Chọn người nhận thiết bị">
                {recivers.map((reciver) => (
                  <Select.Option value={reciver.UserName} key={reciver.UserName}>
                    {reciver.FullName} - {reciver.UserName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="Note"
              label="Lý do"
              rules={[
                {
                  required: true,
                  message: 'Hãy nhập lý do',
                },
              ]}
            >
              <TextArea placeholder="Nhập lý do phân bổ" />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <div className="move-device__action">
        <Space>
          <b>Đã chọn: {selectedRowKeys.length} TB</b>
          <Button type="primary" onClick={onCreateMove} disabled={selectedRowKeys.length === 0}>
            Tạo phiếu phân bổ
          </Button>
        </Space>
        <div>
          <Input
            autoFocus
            onChange={(e) => onChangeSearch(e.target.value)}
            style={{ width: 300 }}
            placeholder="Tìm kiếm thiết bị"
          />
        </div>
      </div>
      <Table
        rowSelection={rowSelection}
        dataSource={devicesDisplay}
        columns={columns}
        pagination={false}
        loading={loading}
      />
    </div>
  );
};

export default MoveDeviceUser;
