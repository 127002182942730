import Request from '../config/Request';
import Request2 from '../config/Request/request2';
export function GetOrganizationByID(data) {
  return Request({
    url: '/TSOrganization/GetOrganizationByID',
    method: 'POST',
    data: data,
  });
}
export function CreateOrganization(data) {
  return Request({
    url: '/TSOrganization/CreateOrganization',
    method: 'POST',
    data: data,
  });
}
export function UpdateOrganization(data) {
  return Request({
    url: '/TSOrganization/UpdateOrganization',
    method: 'POST',
    data: data,
  });
}
export function DelOrganization(data) {
  return Request({
    url: '/TSOrganization/DelOrganization',
    method: 'POST',
    data: data,
  });
}
export function GetOrganization(data) {
  return Request({
    url: '/TSOrganization/GetOrganization',
    method: 'POST',
    data: data,
  });
}
export function GetOrganizationJoinByUser(data) {
  return Request({
    url: '/TSOrganization/GetOrganizationJoinByUser',
    method: 'POST',
    data: data,
  });
}
export function GetDependentOrganization(data) {
  return Request({
    url: '/TSOrganization/GetDependentOrganization',
    method: 'POST',
    data: data,
  });
}
export function ApplyOrganization(data) {
  return Request({
    url: '/TSOrganization/ApplyOrganization',
    method: 'POST',
    data: data,
  });
}
export function LinkUserDevice(data) {
  return Request({
    url: '/TSOrganization/LinkUserDevice',
    method: 'POST',
    data: data,
  });
}
export function GetUserOrganization(data) {
  return Request({
    url: '/TSOrganization/GetUserOrganization',
    method: 'POST',
    data: data,
  });
}
export function ApproveUserOrganization(data) {
  return Request({
    url: '/TSOrganization/ApproveUserOrganization',
    method: 'POST',
    data: data,
  });
}
export function GetOrganizationOfCompany(data) {
  return Request({
    url: '/TSOrganization/GetOrganizationOfCompany',
    method: 'POST',
    data: data,
  });
}
export function CreateMoveDeviceDoc(data) {
  return Request({
    url: '/TSOrganization/CreateMoveDeviceDoc',
    method: 'POST',
    data: data,
  });
}

export function GetMoveDeviceDocInfo(data) {
  return Request({
    url: '/TSOrganization/GetMoveDeviceDocInfo',
    method: 'POST',
    data: data,
  });
}
export function GetHandoverHistoryLst(data) {
  return Request({
    url: '/TSOrganization/GetHandoverHistoryLst',
    method: 'POST',
    data: data,
  });
}

export function GetLinkUserByOrg(data) {
  return Request2({
    url: '/TSOrganization/GetLinkUserByOrg',
    method: 'POST',
    data: data,
  });
}

export function GetLinkUserByUserCreate(data) {
  return Request2({
    url: '/TSOrganization/GetLinkUserByUserCreate',
    method: 'POST',
    data: data,
  });
}
export function LinkUserDeviceCreate(data) {
  return Request({
    url: '/TSOrganization/LinkUserDeviceCreate',
    method: 'POST',
    data: data,
  });
}

export function GetLinkUserReceive(data) {
  return Request2({
    url: '/TSOrganization/GetLinkUserReceive',
    method: 'POST',
    data: data,
  });
}

export function ApproveLinkDevice(data) {
  return Request2({
    url: '/TSOrganization/ApproveLinkDevice',
    method: 'POST',
    data: data,
  });
}

export function DelLinkDevice(data) {
  return Request2({
    url: '/TSOrganization/DelLinkDevice',
    method: 'POST',
    data: data,
  });
}

export function ApproveMoveDeviceDoc(data) {
  return Request2({
    url: '/TSOrganization/ApproveMoveDeviceDoc',
    method: 'POST',
    data: data,
  });
}

export function DelMoveDeviceDoc(data) {
  return Request2({
    url: '/TSOrganization/DelMoveDeviceDoc',
    method: 'POST',
    data: data,
  });
}

export function GetMoveDeviceDocLst(data) {
  return Request2({
    url: '/TSOrganization/GetMoveDeviceDocLst',
    method: 'POST',
    data: data,
  });
}

export function GetMoveDocLstByCreater(data) {
  return Request2({
    url: '/TSOrganization/GetMoveDocLstByCreater',
    method: 'POST',
    data: data,
  });
}

export function GetMoveDocLstByReceive(data) {
  return Request2({
    url: '/TSOrganization/GetMoveDocLstByReceive',
    method: 'POST',
    data: data,
  });
}
export function ApproveMoveByDeviceId(data) {
  return Request2({
    url: '/TSOrganization/ApproveMoveByDeviceId',
    method: 'POST',
    data: data,
  });
}
