import { InfoCircleOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, Space, Table, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { GetDefaultValue } from '../../../../api/defaultValue';
import { GetDeviceCompanyByTime, GetDeviceOrgByTime, GetDeviceUserByTime } from '../../../../api/Device';
import { ExportExcel } from '../../../../config';
import { companyID } from '../../../../config/URL/Qlts';
import { formatYear } from '../../../../constants/DateTime';
import { statusActionDevice, statusImport } from '../../../../helpers/mapping';
import { current, yesterday } from '../../../helper/getTime';
import {
  displaySchedule,
  displayTimeUsed,
  formatDateImport,
  formatMoneyDisplay,
  roleUser,
  status,
  dateDisplay,
} from '../../../helper/mapping';
import TotalDevice from '../../../Print/TotalDevice';
import './GetOrgByTime.scss';
const { Search } = Input;

const formulaTimeUsed = 'Thời gian sử dụng tính theo công thức: (TG hiện tại - TG đưa vào sử dụng)';

const formatTime = 'HH:mm DD-MM-YYYY';
const headerData = [
  'CreaterId',
  'CreaterName',
  'DeviceName',
  'DeviceCode',
  'DeviceSet',
  'Schedule',
  'Status',
  'ImportStatus',
  'Location',
  'DeviceValue',
  'QRCode',
  'Origin',
  'Manufacturer',
  'TimeStart',
  'TimeEnd',
  'TimeCreate',
  'TimeImport',
  'WarrantyStart',
  'WarrantyEnd',
];
const headerExcel = [
  'ID Người Tạo',
  'Tên người tạo',
  'Tên TB',
  'Mã TB',
  'Loại TB',
  'Bảo dưỡng',
  'Trạng thái',
  'Trạng thái nhập',
  'Phòng ban',
  'Giá trị',
  'QRCode',
  'Xuất xứ',
  'Nhà cung cấp',
  'Thời gian bắt đầu',
  'Hạn sử dụng',
  'Thời gian tạo',
  'Thời gian nhập',
  'Thời gian bắt đầu bảo hành',
  'Thời gian kết thúc bảo hành',
];
const GetOrgByTime = () => {
  const [loading, setLoading] = useState(false);
  const [valueSearch, setValueSearch] = useState('');
  const [devices, setDevices] = useState([]);
  const [timeStart, setTimeStart] = useState(yesterday());
  const [timeEnd, setTimeEnd] = useState(current());
  const [deviceGroup, setDeviceGroup] = useState([]);
  const [valueGroup, setValueGroup] = useState('');
  const [fileName, setFileName] = useState('Danh sách thiết bị');
  const groupId = localStorage.getItem('groupId');
  const btnSearch = () => {
    const req = {
      TimeStart: formatDateImport(timeStart),
      TimeEnd: formatDateImport(timeEnd),
      OrganizationID: groupId,
      TypeDevice: 'Medical',
      DeviceGroup: '',
      Search: valueSearch,
    };
    setFileNameExport();
    setLoading(true);
    if (groupId == companyID) {
      GetDeviceCompanyByTime(req).then((res) => {
        const response = res.DeviceLst.map((device, index) => {
          return {
            ...device,
            key: index + 1,
            StatusDisplay: status(device.Status).label,
            Schedule: displaySchedule(device.Schedule),
            ValueDeviceCurrent: parseInt(device.ValueDeviceCurrent),
            DeviceGroup: device.DeviceGroup ? device.DeviceGroup : '',
          };
        });
        const result = valueGroup ? response.filter((de) => de.DeviceGroup === valueGroup) : response;
        setDevices(result);
        setLoading(false);
      });
    } else {
      GetDeviceOrgByTime(req).then((res) => {
        const response = res.DeviceLst.map((device, index) => {
          return {
            ...device,
            key: index + 1,
            StatusDisplay: status(device.Status).label,
            Schedule: displaySchedule(device.Schedule),
            ValueDeviceCurrent: parseInt(device.ValueDeviceCurrent),
            DeviceGroup: device.DeviceGroup ? device.DeviceGroup : '',
          };
        });
        const result = valueGroup ? response.filter((de) => de.DeviceGroup === valueGroup) : response;
        setDevices(result);
        setLoading(false);
      });
    }
  };
  const setFileNameExport = () => {
    setFileName(
      `Danh sách thiết bị từ ${dateDisplay(timeStart, 'HH-mm_DD-MM-YYYY')} - ${dateDisplay(
        timeEnd,
        'HH-mm_DD-MM-YYYY'
      )}`
    );
  };
  const getUser = () => {
    const req = {
      TimeStart: formatDateImport(timeStart),
      TimeEnd: formatDateImport(timeEnd),
      OrganizationID: localStorage.getItem('groupId'),
      DeviceGroup: valueGroup,
    };
    setLoading(true);
    GetDeviceUserByTime(req).then((res) => {
      setDevices(
        res.DeviceLst.map((device, index) => {
          return {
            ...device,
            key: index + 1,
            StatusDisplay: status(device.Status).label,
            Schedule: displaySchedule(device.Schedule),
          };
        })
      );
      setLoading(false);
    });
  };
  const getDeviceGroup = () => {
    GetDefaultValue({
      TableName: 'DeviceGroup',
    }).then((res) => {
      setDeviceGroup(res.DefaultValueLst);
    });
  };
  useEffect(() => {
    roleUser(localStorage.getItem('roleUser')) ? btnSearch() : getUser();
    getDeviceGroup();
    setFileNameExport();
  }, []);
  const columns = [
    {
      title: 'STT',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Tên TB',
      dataIndex: 'DeviceName',
      key: 'DeviceName',
    },
    {
      title: 'Mã TB',
      dataIndex: 'DeviceCode',
      key: 'DeviceCode',
    },
    {
      title: 'Loại TB',
      dataIndex: 'Type',
      key: 'Type',
    },
    {
      title: 'Giá trị ban đầu',
      dataIndex: 'DeviceValue',
      key: 'DeviceValue',
      render: (record) => {
        return formatMoneyDisplay(record);
      },
    },
    {
      title: 'Giá trị hiện tại',
      dataIndex: 'DeviceValueCurrent',
      key: 'DeviceValueCurrent',
      render: (record) => {
        return formatMoneyDisplay(record);
      },
    },

    {
      title: 'Năm đưa vào sử dụng',
      dataIndex: 'TimeStart',
      key: 'TimeStart',
      render: (record) => {
        return dateDisplay(record, formatYear);
      },
    },
    {
      title: 'Năm sản xuất',
      dataIndex: 'TimeManufacturer',
      key: 'TimeManufacturer',
      render: (record) => {
        return dateDisplay(record, formatYear);
      },
    },
    {
      title: (
        <Space size={6}>
          <div style={{ width: 'max-content' }}>TG sử dụng</div>
          <Tooltip title={formulaTimeUsed}>
            <InfoCircleOutlined style={{ fontSize: 14, cursor: 'pointer' }} />
          </Tooltip>
        </Space>
      ),
      dataIndex: 'TimeUsed',
      key: 'TimeUsed',
      render: (record) => {
        return displayTimeUsed(record);
      },
    },
    {
      title: 'Đã khấu hao',
      dataIndex: 'ValueDepreciated',
      key: 'ValueDepreciated',
      render: (record) => {
        return formatMoneyDisplay(record);
      },
    },

    {
      title: 'Trạng thái',
      dataIndex: 'Status',
      key: 'Status',
      render: (record) => {
        return <Tag color={status(record).color}>{status(record).label}</Tag>;
      },
    },
    {
      title: 'Ghi chú',
      dataIndex: 'Note',
      key: 'Note',
    },
  ];
  const handleChangeSearch = (e) => {
    setValueSearch(e.target.value);
  };
  return (
    <div className="get-device-organization-by-time">
      <div className="get-device-organization-by-time__header">
        <Space>
          <b>Thời gian tạo: </b>
          <DatePicker
            format={formatTime}
            clearIcon={false}
            value={moment(moment(timeStart).format(formatTime) ?? moment, formatTime)}
            onChange={(e) => {
              setTimeStart(e);
            }}
            placeholder="Thời gian bắt đầu"
          ></DatePicker>
          <span>Đến</span>
          <DatePicker
            format={formatTime}
            clearIcon={false}
            value={moment(moment(timeEnd).format(formatTime), formatTime)}
            onChange={(e) => {
              setTimeEnd(e);
            }}
            placeholder="Thời gian kết thúc"
          ></DatePicker>
          <Search onChange={handleChangeSearch} onPressEnter={btnSearch} placeholder="Tìm kiếm" />
          <Button onClick={btnSearch} type="dashed">
            Tìm kiếm
          </Button>
        </Space>
        <Space>
          <ExportExcel
            nameBtn="Xuất excel"
            headerData={headerData}
            headerExcel={headerExcel}
            fileName={fileName}
            datas={devices.map((device) => {
              return {
                ...device,
                DeviceSet: device.DeviceSet ? 'Đã ghép bộ' : 'Đơn',
                Schedule: device.Schedule + '/tháng',
                Status: statusActionDevice(device.Status),
                ImportStatus: statusImport(device.ImportStatus),
              };
            })}
          />
          <PrintQrCode deviceLst={devices} timeStart={timeStart} timeEnd={timeEnd} />
        </Space>
      </div>
      <Table dataSource={devices} loading={loading} columns={columns} />
    </div>
  );
};

class ComponentToPrint extends React.Component {
  render() {
    return (
      <div>
        <TotalDevice deviceLst={this.props.deviceLst} timeStart={this.props.timeStart} timeEnd={this.props.timeEnd} />
      </div>
    );
  }
}
const PrintQrCode = ({ deviceLst, timeStart, timeEnd }) => {
  const valueQrCode = useRef();
  return (
    <div style={{ paddingRight: 30 }}>
      <div style={{ display: 'none' }}>
        <ComponentToPrint ref={valueQrCode} deviceLst={deviceLst} timeStart={timeStart} timeEnd={timeEnd} />
      </div>
      <ReactToPrint
        documentTitle="Báo cáo tổng hợp thiết bị"
        trigger={() => (
          <Button icon={<PrinterOutlined />} type="primary">
            In báo cáo
          </Button>
        )}
        content={() => valueQrCode.current}
      />
    </div>
  );
};

export default GetOrgByTime;
