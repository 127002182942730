import {
  BellOutlined,
  FileExclamationOutlined,
  LaptopOutlined,
  MenuFoldOutlined,
  ScheduleOutlined,
  SettingOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserOutlined,
  RetweetOutlined,
  DiffOutlined,
  ApartmentOutlined,
  FileAddOutlined,
  QrcodeOutlined,
  WarningOutlined
} from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import DeleteGroup from "../../containers/Group/DeleteGroup";
import NewMember from "../../containers/Group/Members/NewMember";
import UpdateGroupInfo from "../../containers/Group/UpdateGroupInfo";
import "./styles.scss";

const { SubMenu } = Menu;
export default class Sider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: props.role,
    };
  }
  render() {
    return (
      <div id="sidebar-container">
        <Menu
          selectedKeys={this.props.currentTab}
          defaultOpenKeys={["member", "equipment"]}
          mode="inline"
          theme="dark"
          style={{ height: "100vh" }}
          className="side_bar"
        >
          <Menu.Item
            key="overview"
            icon={<TeamOutlined />}
            onClick={this.props.toOverview}
          >
            Tổng quan
          </Menu.Item>
          <SubMenu key="member" icon={<UserOutlined />} title="Thành viên">
            <Menu.Item
              key="members"
              icon={<UnorderedListOutlined />}
              onClick={this.props.toMemberList}
              style={{ background: "rbg(70,70,70)" }}
            >
              Hồ sơ thành viên
            </Menu.Item>

            {this.props.role !== 3 && (
              <Menu.Item key="add-member">
                <NewMember id={this.props.id} members={this.props.members} />
              </Menu.Item>
            )}
          </SubMenu>
          <SubMenu key="equipment" icon={<LaptopOutlined />} title="Thiết bị">
            <Menu.Item
              key="equipments"
              icon={<UnorderedListOutlined />}
              onClick={this.props.toEquipmentList}
            >
              Hồ sơ thiết bị
            </Menu.Item>
            {this.props.role !== 3 && (
              <Menu.Item
                key="addDevice"
                icon={<FileAddOutlined />}
                onClick={this.props.toAddDevice}
              >
                Thêm mới thiết bị
              </Menu.Item>
            )}
            {this.props.role !== 3 && (
              <Menu.Item
                key="deviceMove"
                icon={<RetweetOutlined />}
                onClick={this.props.toDeviceMove}
              >
                Danh sách luân chuyển
              </Menu.Item>
            )}
            <Menu.Item
              key="report"
              icon={<FileExclamationOutlined />}
              onClick={this.props.toReport}
            >
              Hồ sơ báo cáo
            </Menu.Item>
            {this.props.role !== 3 && (
              <Menu.Item
                key="list_divide"
                icon={<ScheduleOutlined />}
                onClick={this.props.toLstDevide}
              >
                Hồ sơ lịch bảo dưỡng
              </Menu.Item>
            )}

            <Menu.Item
              key="create_lst_device"
              icon={<FileAddOutlined />}
              onClick={this.props.toCreateLstDevice}
            >
              Thêm Excel
            </Menu.Item>
            <Menu.Item
              key="print_qrCode"
              icon={<QrcodeOutlined />}
              onClick={this.props.toPrintQrCode}
            >
              In mã QR
            </Menu.Item>
            <Menu.Item
              key="qr_code_lst_device_err"
              icon={<WarningOutlined />}
              onClick={this.props.toLstReportErr}
            >
              Hồ sơ thiết bị báo lỗi
            </Menu.Item>
          </SubMenu>

          <SubMenu
            title="Bộ thiết bị"
            key="setDevice"
            icon={<ApartmentOutlined />}
          >
            <Menu.Item
              key="set_device1"
              onClick={this.props.toSetDevice}
              icon={<DiffOutlined />}
            >
              Tạo bộ thiết bị
            </Menu.Item>
            <Menu.Item
              key="lst_set_device"
              onClick={this.props.toLstDevice}
              icon={<UnorderedListOutlined />}
            >
              Hồ sơ bộ thiết bị
            </Menu.Item>
          </SubMenu>
          {this.props.role !== 3 && (
            <SubMenu key="setting" icon={<SettingOutlined />} title="Cài đặt">
              <Menu.Item key="update-group">
                <UpdateGroupInfo
                  id={this.props.id}
                  name={this.props.name}
                  des={this.props.des}
                  email={this.props.email}
                  address={this.props.address}
                  phone={this.props.phone}
                />
              </Menu.Item>
              <Menu.Item key="delete-group">
                <DeleteGroup id={this.props.id} />
              </Menu.Item>
            </SubMenu>
          )}
          <div id="close-menu" onClick={this.props.menuTrigger}>
            <a>
              <MenuFoldOutlined style={{ color: "#ffffff" }} />
            </a>
          </div>
        </Menu>
      </div>
    );
  }
}
