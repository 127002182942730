import moment from 'moment';
export default function DateExcelToDate(date) {
  if (date) {
    const timelineExcel = new Date('1900-01-28');
    const timelineDate = new Date('1970-01-30');
    const timeAdd = timelineDate.getTime() - timelineExcel.getTime();
    const timeStart = date * 3600 * 24 * 1000;
    return moment(timeStart - timeAdd).format('YYYY-MM-DD HH:mm:ss');
  } else {
    return '';
  }
}
export function convertDateExcel(date, format = 'YYYY-MM-DD HH:mm:ss') {
  // format date input: DD/MM/YYYY
  // format date output: YYYY-MM-DD HH:mm:ss
  if (date || date == 0) {
    const dateNew = new Date(date);
    return moment(dateNew.getTime() + 4000).format('YYYY-MM-DD HH:mm:ss');
  } else {
    return moment().format(format);
  }
}
export function convertYearExcel(date) {
  if (!date) {
    return '';
  } else {
    if (!date || Number(date)) {
      return `${date}-01-01 00:00:00`;
    } else {
      return 'Invalid date';
    }
  }
}
export function isValidDate(date) {
  return moment(date).isValid();
}
export function isValidYear(date) {
  // 1990 < year < year current
  const year = moment(date).year();
  if (parseInt(year) === NaN || parseInt(year) < 1990 || parseInt(year) > new Date().getFullYear()) {
    return false;
  } else {
    return true;
  }
}
export function convertTimeExcel(value) {
  return value !== '' ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '';
}
export function convertSchedule(value) {
  if (value) {
    let arr = value.trim().toLowerCase().split(' ');
    switch (arr[1]) {
      case 'tuần':
        return arr[0] * 7;
      case 'tháng':
        return arr[0] * 30;
      case 'năm':
        return arr[0] * 365;
      default:
        return arr[0];
    }
  } else {
    return 0;
  }
}

export function convertDaysToDate(numberOfDays) {
  var years = Math.floor(numberOfDays / 365);
  var months = Math.floor((numberOfDays % 365) / 30);
  var days = Math.floor((numberOfDays % 365) % 30);

  var yearsDisplay = years > 0 ? years + ' năm ' : '';
  var monthsDisplay = months > 0 ? months + ' tháng ' : '';
  var daysDisplay = days > 0 ? days + ' ngày' : '';
  return yearsDisplay + monthsDisplay + daysDisplay;
}

export function convertAccreditation(value) {
  if (!value) {
    return 0;
  } else {
    if (value.toLowerCase() === 'có') {
      return 1;
    } else {
      return 0;
    }
  }
}
export function accreditationDisplay(value) {
  if (value === 1) {
    return 'Có';
  } else {
    return 'Không';
  }
}
