import moment from 'moment';
import React from 'react';
import { TitleReport } from '../../../config/URL/Qlts';
import { formatYear } from '../../../constants/DateTime';
import { getGroupName } from '../../../helpers/auth';
import { displaySchedule, timeDisplay, typeSchedule } from '../../helper/mapping';

const FormDeviceMaintance = ({ deviceLst = [], devices = [] }) => {
  return (
    <div style={{ padding: '40px', fontFamily: '"Times New Roman"', lineHeight: '1.5em' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '30px' }}>
        <div style={{ width: 'max-content', textAlign: 'center' }}>
          <div>
            <strong style={{ textTransform: 'uppercase' }}>SỞ Y TẾ TỈNH ĐIỆN BIÊN</strong>
          </div>
          <div>
            <strong style={{ textTransform: 'uppercase' }}>{TitleReport}</strong>
          </div>
          <div>
            <strong style={{ textTransform: 'uppercase' }}>Phòng ban: {getGroupName()}</strong>
          </div>
        </div>
      </div>
      <div>
        <h2 style={{ textTransform: 'uppercase', textAlign: 'center', fontWeight: 'bold', padding: 20 }}>
          Sổ theo dõi sửa chữa máy móc thiết bị
        </h2>
      </div>
      <div>
        <h3 style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>Danh sách thiết bị đang theo dõi</h3>
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Thời gian yêu cầu</th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Người tạo (ký, họ tên)</th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Người nhận (ký, họ tên)</th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Tên thiết bị</th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Mã thiết bị</th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Seria</th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Model</th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  Hiện trạng (Ghi rõ hiện trạng thiết bị hỏng)
                </th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Biện pháp xử lý sơ bộ/hoặc sửa chữa</th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Thời gian hoàn thành</th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Phòng ban thực hiện (ký, họ tên)</th>
                <th style={{ border: '1px solid #dddddd', padding: '8px', width: 120 }}>Kết quả</th>
              </tr>
            </thead>
            <tbody>
              {devices.map((device) => (
                <tr key={device.DeviceId}>
                  <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                    {timeDisplay(device.TimeCreate, formatYear)}
                  </td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{device.CreaterName}</td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{device.CreaterName}</td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{device.DeviceName}</td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{device.DeviceCode}</td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px', wordBreak: 'break-all' }}>
                    {device.Serial}
                  </td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{device.Model}</td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{device.Description}</td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                    {device.Solution && typeSchedule(device.Solution).label}
                  </td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{displaySchedule(device.Schedule)}</td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{getGroupName()}</td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{device.ApproveNote}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <div style={{ paddingBottom: '20px', textAlign: 'end' }}>
          Điện Biên, ngày {moment().date()} tháng {moment().month() + 1} năm {moment().year()}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', textTransform: 'uppercase' }}>
          <h3 style={{ fontWeight: 'bold' }}>Người tạo phiếu</h3>
          <h3 style={{ fontWeight: 'bold' }}>Trưởng khoa/phòng</h3>
        </div>
      </div>
    </div>
  );
};

export default FormDeviceMaintance;
