import { ImportOutlined } from '@ant-design/icons';
import { Card, Input, message, Modal } from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import Organization from '../../../api/OrganizationApi';
import { getToken, getUserName } from '../../../helpers/auth';

export default class RegisterRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      ID: null,
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    const api = Organization.apply();
    axios
      .post(api, {
        UserName: getUserName(),
        Token: getToken(),
        OrganizationID: this.state.ID,
      })
      .then(function (response) {
        message.success(`Đã gửi yêu cầu.`);
      })
      .catch(function (error) {
        message.error(`Lỗi.`);
      })
      .then(() =>
        this.setState({
          visible: false,
        })
      );
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  changeID = (e) => {
    this.setState({
      name: e.target.value,
    });
  };
  render() {
    return (
      <div>
        <Card
          hoverable
          className="dashboard-card"
          cover={<ImportOutlined style={{ fontSize: 50 }} />}
          onClick={this.showModal}
        >
          <p>
            <b>Gia nhập tổ chức</b>
          </p>
        </Card>
        <Modal title="Gia nhập tổ chức" visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel}>
          <div>
            <h4>ID: </h4>
            <Input size="large" value={this.state.ID} onChange={this.changeID} placeholder="Nhập ID tổ chức" />
          </div>
        </Modal>
      </div>
    );
  }
}
