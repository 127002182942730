import { Button, Col, Form, Input, InputNumber, message, Modal, Row, Select, Table } from 'antd'
import { FormOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { AddDefaultValue, GetDefaultValue, UpdateDefaultValue } from '../../api/defaultValue'
import { AddKeyToLst } from '../helper/addKeyToLst'
import { displaySchedule, transformDay } from '../helper/mapping'

const SettingSystem = () => {
  const [deviceGroup, setDeviceGroup] = useState([])
  const [loading, setLoading] = useState(false)
  const [lineInfo, setLineInfo] = useState([])
  const [lineInfo2, setLineInfo2] = useState({})
  const [visible, setVisible] = useState(false)
  const [typeDate, setTypeDate] = useState('day')
  const [form] = Form.useForm()
  const deviceGroupName = 'DeviceGroup'
  const hanleEdit = (obj) => {
    setVisible(true)
    setLineInfo2(obj)
    if (obj.RowID) {
      let result = []
      for (var val in obj) {
        result.push({
          name: [val],
          value: obj[val]
        })
      }
      setLineInfo(result)
    }
  }
  const handleConfim = (elements) => {
    if (lineInfo.length > 0) {
      const req = {
        ...elements,
        TimeAccreditation: transformDay(elements.TimeAccreditation, typeDate),
        DefaultValue: lineInfo2.DefaultValue,
        RowID: lineInfo2.RowID
      }
      UpdateDefaultValue(req).then(res => {
        if (res.RespCode === 0) {
          setVisible(false)
          message.success('Cập nhật thành công')
          getDeviceGroup()
        }
      })
    } else {
      AddDefaultValue({...elements, TableName: deviceGroupName}).then(res => {
        if (res.RespCode === 0) {
          setVisible(false)
          message.success('Thêm nhóm thiết bị thành công')
          getDeviceGroup()
        }
      })
    }

  }
  const columns = [
    {
      title: 'STT',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: 'Tên nhóm',
      dataIndex: 'DefaultValue',
      key: 'DefaultValue'
    },
    // {
    //   title: 'Giá trị khấu hao 1 năm',
    //   dataIndex: 'Depreciation',
    //   key: 'Depreciation'
    // },
    // {
    //   title: 'Hạn sử dụng',
    //   dataIndex: 'DateEnd',
    //   key: 'DateEnd'
    // },
    {
      title: 'khoảng thời gian kiểm định',
      dataIndex: 'TimeAccridation',
      key: 'TimeAccridation',
      render: (record) => {
        return displaySchedule(record)
      }
    },
    {
      title: 'Người tạo',
      dataIndex: 'CreaterID',
      key: 'CreaterID'
    },
    {
      render: (record) => (
        <FormOutlined onClick={() => hanleEdit(record)} style={{ fontSize: 20, cursor: 'pointer' }} />
      )
    }
  ]
  const getDeviceGroup = () => {
    setLoading(true)
    GetDefaultValue({
      TableName: deviceGroupName,
      Reference: ''
    }).then(res => {
      setLoading(false)
      setDeviceGroup(AddKeyToLst(res.DefaultValueLst))
    })
  }
  const handleTypeTimeAcc = (val) => {
    setTypeDate(val)
  }
  useEffect(() => {
    !visible && form.resetFields()
  }, [visible])
  useEffect(() => {
    getDeviceGroup()
  }, [])
  return (
    <Row gutter={20}>
      <Modal visible={visible} title={lineInfo2.RowID ? "Cập nhật" : "Thêm nhóm " + "thiết bị"} onCancel={() => setVisible(false)} onOk={() => form.submit()}>
        <Form form={form} onFinish={handleConfim} fields={lineInfo} layout="vertical">
          {
            !lineInfo2.RowID && <Form.Item label="Tên nhóm" name="DefaultValue" rules={[{ required: true, message: 'Không được để trống' }]}>
            <Input />
          </Form.Item>
          }
          {/* <Form.Item label="Giá trị khấu hao 1 năm" name="Depreciation" rules={[{ required: true, message: 'Không được để trống' }]}>
            <Input />
          </Form.Item> */}
          {/* <Row gutter={10}>
            <Col>
              <Form.Item label="Hạn sử dụng" name="DateEnd" rules={[{ required: true, message: 'Không được để trống' }]} initialValue={1}>
                <InputNumber value={lineInfo.DateEnd} />
              </Form.Item>
            </Col>
            <Col style={{ alignSelf: 'center' }}>
              <b>Năm</b>
            </Col>
          </Row> */}

          <Row gutter={20}>
            <Col>
              <Form.Item label="Khoảng thời gian kiểm định" name="TimeAccreditation" rules={[{ required: true, message: 'Không được để trống' }]}>
                <InputNumber placeholder='Nhập thời gian' value={lineInfo.TimeAccredition} min={1} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="TimeAccreditationType" label="Thời gian" initialValue={"day"}>
                <Select onChange={handleTypeTimeAcc} style={{ width: 150 }}>
                  <Select.Option value="day">Ngày</Select.Option>
                  <Select.Option value="month">Tháng</Select.Option>
                  <Select.Option value="year">Năm</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>


        </Form>
      </Modal>
      <Col span={12}>
        <div style={{ textAlign: 'end', paddingRight: 10 }}>
          <Button type='primary' onClick={() => hanleEdit({})}>Thêm nhóm thiết bị</Button>
        </div>
        <Table dataSource={deviceGroup} columns={columns} loading={loading} pagination={false} />
      </Col>
    </Row>
  )
}

export default SettingSystem