import { Button, Drawer, message, Modal, notification, Select, Space, Table, Upload } from 'antd';
import Column from 'antd/lib/table/Column';
import Text from 'antd/lib/typography/Text';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import xlsx from 'xlsx';
import { ExportFormatExcel } from '..';
import { CreateDeviceLst } from '../../api/Device';
import { GetOrganizationJoinByUser } from '../../api/TSOrganization';
import { AddKeyToLst } from '../../containers/helper/addKeyToLst';
import { createUid } from '../../containers/helper/createUid';
import { dateDisplay, formatMoneyDisplay } from '../../containers/helper/mapping';
import { getGroupId, getGroupName } from '../../helpers/auth';
import { convertCharAtFirst } from '../../helpers/mapping';
import {
  convertAccreditation,
  convertSchedule,
  convertYearExcel,
  isValidDate,
  isValidYear,
} from '../convertDateExcelToDate';
import './CsvToJson.scss';
const year = 'YYYY';
const columns = [
  {
    title: 'STT',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Tên TB',
    dataIndex: 'DeviceName',
    key: 'DeviceName',
  },
  {
    title: 'Mã TB',
    dataIndex: 'DeviceCode',
    key: 'DeviceCode',
  },
  {
    title: 'Giá trị',
    dataIndex: 'DeviceValue',
    key: 'DeviceValue',
    render: (record) => {
      return formatMoneyDisplay(record);
    },
  },
  {
    title: 'Đơn vị',
    dataIndex: 'Unit',
    key: 'Unit',
  },
  {
    title: 'Nhóm',
    dataIndex: 'DeviceGroup',
    key: 'DeviceGroup',
  },
  {
    title: 'Loại TB',
    dataIndex: 'Type',
    key: 'Type',
  },
  {
    title: 'Năm nhập',
    dataIndex: 'TimeImport',
    key: 'TimeImport',
    render: (record) => {
      return dateDisplay(record, year);
    },
  },
  {
    title: 'Năm sản xuất',
    dataIndex: 'TimeManufacturer',
    key: 'TimeManufacturer',
    render: (record) => {
      return dateDisplay(record, year);
    },
  },
  {
    title: 'Năm bắt đầu',
    dataIndex: 'TimeStart',
    key: 'TimeStart',
    render: (record) => {
      return dateDisplay(record, year);
    },
  },
  {
    title: 'Năm kết thúc',
    dataIndex: 'TimeEnd',
    key: 'TimeEnd',
    render: (record) => {
      return dateDisplay(record, year);
    },
  },
  {
    title: 'Kiểm định',
    dataIndex: 'Accreditation',
    key: 'Accreditation',
    render: (record) => {
      return !record ? 'Không' : record;
    },
  },
  {
    title: 'Ghi chú',
    dataIndex: 'Note',
    key: 'Note',
  },
];
const columnsNotImport = [
  {
    title: 'STT',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Mã TB',
    dataIndex: 'DeviceCode',
    key: 'DeviceCode',
  },
  {
    title: 'Tên TB',
    dataIndex: 'DeviceName',
    key: 'DeviceName',
  },
  {
    title: 'Model',
    dataIndex: 'Model',
    key: 'Model',
  },
  {
    title: 'Serial',
    dataIndex: 'Serial',
    key: 'Serial',
  },
  {
    title: 'Giá trị ban đầu',
    dataIndex: 'DeviceValue',
    key: 'DeviceValue',
    render: (record) => {
      return formatMoneyDisplay(record);
    },
  },
];
const excelFormatHeader = [
  'Tên thiết bị',
  'Mã thiết bị',
  'Mã tham chiếu',
  'Giá trị thiết bị',
  'Model',
  'Serial',
  'Đơn vị',
  'Tình trạng',
  'Loại thiết bị',
  'Nhóm thiết bị',
  'Xuất xứ',
  'Hãng sản xuất',
  'Thời gian nhập',
  'Bắt đầu bảo hành',
  'Kết thúc bảo hành',
  'Thời gian bắt đầu sử dụng',
  'Thời gian kết thúc',
  'Kiểm định',
  'Ghi chú',
  'Năm sản xuất',
];
const ConvertCsvToJson = () => {
  const OrganizationID = getGroupId();
  const organizationName = getGroupName();
  const [lstDevice, setLstDevice] = useState([]);
  const [devicesValidDate, setDevicesValidDate] = useState([]);
  const [devicesNotImport, setDevicesNotImport] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleDeviceValidDate, setVisibleDeviceValidDate] = useState(false);
  const [groups, setGroups] = useState([]);
  const [groupSelected, setGroupSelected] = useState(OrganizationID);
  const [groupNameSelected, setGroupNameSelected] = useState(organizationName);
  useEffect(() => {
    const getGroups = async () => {
      await GetOrganizationJoinByUser({
        NumberRow: '',
        PageNumber: 1,
        Search: '',
      }).then((res) => {
        setGroups(res.OrganizationLst);
      });
    };
    getGroups();
  }, []);
  const beforeUpload = async (file) => {
    handleSubmit(file);
    return false;
  };

  const handleSubmit = (file) => {
    const fileReader = new FileReader();
    const typeFile = /\.(xlsx|xls|csv)$/.test(file.name);
    if (typeFile) {
      if (file) {
        fileReader.readAsBinaryString(file);
        fileReader.onload = (e) => {
          let data = e.target.result;
          let workBook = xlsx.read(data, {
            type: 'binary',
            cellDates: true,
            dateNF: 'mm/dd/yyyy',
          });
          const firstSheetName = workBook.SheetNames[0];
          const workSheet = workBook.Sheets[firstSheetName];
          const result = xlsx.utils.sheet_to_json(workSheet);
          // valid format header
          const header = xlsx.utils.sheet_to_json(workSheet, { header: 1 });
          const header2 = _.compact(header && header[0]);
          const resultHeader = [];
          excelFormatHeader.forEach((coloumnHeader) => {
            resultHeader.push(
              header2.some((header) => header.toLowerCase().trim() === coloumnHeader.toLowerCase().trim())
            );
          });
          if (!resultHeader.every((status) => status)) {
            modalNofication();
            return;
          }
          //end
          const newKeys = {
            tênthiếtbị: 'DeviceName',
            mãthiếtbị: 'DeviceCode',
            mãthamchiếu: 'ReferenceCode',
            giátrịthiếtbị: 'DeviceValue',
            tìnhtrạng: 'ImportStatus',
            nhómthiếtbị: 'DeviceGroup',
            model: 'Model',
            serial: 'Serial',
            đơnvị: 'Unit',
            loạithiếtbị: 'Type',
            xuấtxứ: 'Origin',
            hãngsảnxuất: 'Manufacturer',
            thờigiannhập: 'TimeImport',
            nămsảnxuất: 'TimeManufacturer',
            bắtđầubảohành: 'WarrantyStart',
            kếtthúcbảohành: 'WarrantyEnd',
            thờigianbắtđầusửdụng: 'TimeStart',
            thờigiankếtthúc: 'TimeEnd',
            kiểmđịnh: 'Accreditation',
            ghichú: 'Note',
          };
          const LstDeviced = [];
          result &&
            result.forEach((item) => {
              const renameObj = renameKeys(item, newKeys);
              LstDeviced.push(renameObj);
            });
          LstDeviced.forEach((device) => {
            device.DeviceCode = convertCharAtFirst(groupNameSelected);
            device.TimeStart = convertYearExcel(device.TimeStart);
            device.TimeEnd = convertYearExcel(device.TimeEnd);
            device.TimeImport = convertYearExcel(device.TimeImport);
            device.WarrantyStart = convertYearExcel(device.WarrantyStart);
            device.WarrantyEnd = convertYearExcel(device.WarrantyEnd);
            device.Schedule = convertSchedule(device.Schedule);
            device.TimeManufacturer = `${device.TimeManufacturer}-01-01 00:00:00`;
          });
          const validDate = [...LstDeviced].filter((device) => {
            return (
              (!isValidDate(device.TimeStart) && device.TimeStart) ||
              (!isValidDate(device.TimeEnd) && device.TimeEnd) ||
              (!isValidDate(device.TimeImport) && device.TimeImport) ||
              (!isValidDate(device.WarrantyStart) && device.WarrantyStart) ||
              (!isValidDate(device.WarrantyEnd) && device.WarrantyEnd) ||
              (!isValidYear(device.TimeManufacturer) && device.TimeManufacturer)
            );
          });
          setDevicesValidDate(AddKeyToLst(validDate));
          setLstDevice(LstDeviced);
          if (validDate.length > 0) {
            handleModalDeviceValidDate();
          }
        };
      }
    } else {
      message.info('không phải file excel');
    }
  };
  const modalNofication = () => {
    Modal.warning({
      title: 'Thông báo',
      content: 'Định dạng excel không hợp lệ. Hãy thử lại sau.',
    });
  };
  const renameKeys = (obj, newKeys) => {
    const keyValues = Object.keys(obj).map((key) => {
      const replaceKey = key.replace(/\s+/g, '').toLowerCase();
      const newKey = newKeys[replaceKey] || replaceKey;
      return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
  };
  const handleModalConfirmAddDevice = () => {
    const organizationNameSelected = groups.find((group) => group.OrganizationID === groupSelected);
    organizationNameSelected &&
      Modal.confirm({
        title: `Xác nhận thêm danh sách thiết bị vào phòng ${organizationNameSelected.OrganizationName}`,
        content: 'Bạn có thực sự muốn thêm danh sách thiết bị có trong bảng vào phòng ban này?',
        okText: 'Xác nhận',
        cancelText: 'Hủy',
        onOk: () => {
          handleCreateLstDevice();
        },
      });
  };
  const handleCreateLstDevice = () => {
    const valueAddQrCode = lstDevice.map((device) => {
      return {
        ...device,
        QRCode: createUid(),
        Accreditation: convertAccreditation(device.Accreditation),
        ImportStatus: device.ImportStatus ? (device.ImportStatus.toLowerCase() === 'mới' ? 1 : 0) : 0,
      };
    });
    console.log(valueAddQrCode);
    CreateDeviceLst({
      OrganizationID: groupSelected,
      DeviceLst: valueAddQrCode,
    }).then((res) => {
      if (res.RespCode === 0) {
        notification.success({
          message: `Đã thêm thành công ${res.CountDeviceSuccess / lstDevice.length} thiết bị`,
        });
        if (res.DeviceLst.length > 0) {
          setDevicesNotImport(AddKeyToLst(res.DeviceLst));
          setVisible(true);
        }
      } else {
        notification.error({
          message: res.RespText,
          duration: 1,
        });
      }
    });
  };
  const resetImport = () => {
    window.location.reload();
  };
  const hideVisible = () => {
    setVisible(false);
  };
  const handleSelectGroup = (groupId) => {
    setGroupSelected(groupId);
    // set group name
    const group = groups.find((group) => group.OrganizationID === groupId);
    setGroupNameSelected(group.OrganizationName);
    setLstDevice([]);
  };
  const handleModalDeviceValidDate = () => {
    setVisibleDeviceValidDate(true);
  };
  const onCloseDrawer = () => {
    setVisibleDeviceValidDate(false);
  };
  return (
    <div>
      <Drawer
        title="Danh sách thiết bị không đúng định dạng thời gian"
        placement="right"
        onClose={onCloseDrawer}
        visible={visibleDeviceValidDate}
        width={600}
        footer={
          <div style={{ textAlign: 'center' }}>
            <Button type="primary" onClick={onCloseDrawer}>
              Đóng
            </Button>
          </div>
        }
      >
        <div>
          <Text type="warning" strong>
            Hãy kiểm tra lại dữ liệu của file excel để hoàn thành việc thêm thiết bị
          </Text>
          <Table dataSource={devicesValidDate} pagination={false}>
            <Column title="STT" dataIndex={'key'} key={'key'} />
            <Column title="Tên thiết bị" dataIndex={'DeviceName'} key={'DeviceName'} />
            <Column title="Mã TB" dataIndex={'DeviceCode'} key={'DeviceCode'} />
          </Table>
        </div>
      </Drawer>
      <Modal
        visible={visible}
        title="Danh sách thiết bị trùng mã thiết bị"
        onCancel={hideVisible}
        onOk={hideVisible}
        okText="Xác nhận"
        width={600}
      >
        <Table dataSource={devicesNotImport} pagination={false} columns={columnsNotImport}></Table>
      </Modal>
      <div className="csv_to_json">
        <Space>
          <ExportFormatExcel />
          <Upload accept="xlsx" name="file" showUploadList={false} maxCount={1} action="" beforeUpload={beforeUpload}>
            <Button type="primary">Tải file excel</Button>
          </Upload>
          <Button type="dashed" onClick={resetImport} disabled={lstDevice.length === 0}>
            Reset
          </Button>
        </Space>
        <Space className="submit">
          {devicesValidDate.length > 0 && (
            <Button type="dashed" danger onClick={handleModalDeviceValidDate}>
              Danh sách thiết bị không hợp lệ
            </Button>
          )}
          <Button
            onClick={handleModalConfirmAddDevice}
            type="primary"
            disabled={lstDevice.length === 0 || devicesValidDate.length > 0}
          >
            Thêm danh sách thiết bị
          </Button>
          <Select
            showSearch
            optionFilterProp="children"
            defaultValue={OrganizationID}
            onChange={(value) => handleSelectGroup(value)}
            style={{ width: 200 }}
          >
            {groups &&
              groups.map((group) => (
                <Select.Option value={group.OrganizationID}>{group.OrganizationName}</Select.Option>
              ))}
          </Select>
        </Space>
      </div>
      <Table dataSource={AddKeyToLst(lstDevice)} columns={columns} pagination={false} />
    </div>
  );
};
export default ConvertCsvToJson;
