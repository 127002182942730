import { InfoCircleOutlined } from '@ant-design/icons';
import { Input, message, Select, Space, Table, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { GetDefaultValue } from '../../../../api/defaultValue';
import { GetDeviceByCompany, GetDeviceByOrganization, GetDeviceByUser } from '../../../../api/Device';
import { GetMaintenanceScheduleInfoByDeviceId } from '../../../../api/TSMaintenance';
import { GetDependentOrganization } from '../../../../api/TSOrganization';
import { convertDaysToDate } from '../../../../config/convertDateExcelToDate';
import { manageDevice } from '../../../../config/Recoil';
import { formatDateDisplay } from '../../../../constants/DateTime';
import { API_DEVICE_LINK, API_DEVICE_MAINTAIN } from '../../../../constants/Device';
import { getRoleAdmin } from '../../../../constants/Group/Roles';
import { enCode } from '../../../../helpers/mappingCode';
import { accreditationDisplay, dateDisplay, formatMoneyDisplay, roleUser, status } from '../../../helper/mapping';
import DeleteEquipment from '../Delete';
import InfoEquipment from '../InfoEquipment';
import Update from '../Update';
import './GetList.scss';
const { Search } = Input;

const GetList = (props) => {
  const groupID = parseInt(localStorage.getItem('groupId'));
  const getStateDevice = useRecoilValue(manageDevice);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [deviceGroup, setDeviceGroup] = useState('');
  const [groupIdSelect, setGroupIdSelect] = useState(groupID);
  const [current, setCurrent] = useState(1);
  const [numberPage, setNumberPage] = useState(1);
  const [numberRow, setNumberRow] = useState(20);
  const [toggle, setToggle] = useState(false);
  const [groupDependent, setGroupDependent] = useState([]);
  const [types, setTypes] = useState([]);
  const [toolTipDiffTime, setTooltipDiffTime] = useState('nothing');
  const role = localStorage.getItem('roleUser');

  const history = useHistory();
  const allowDisplay = () => {
    return getRoleAdmin();
  };

  const tooltipCurrentValue = () => {
    return (
      <div>
        <div>Giá trị hiện tại của thiết bị được tính toán dựa trên giá trị ban ban đầu và phân loại của thiết bị.</div>
        <div>
          <b>GT hiện tại = GT ban đầu * (TG hiện tại - TG bắt đầu đưa vào sử dụng) * tỷ lệ khấu hao theo loại TB</b>
        </div>
      </div>
    );
  };
  const onOpenTimeDiff = (record) => {
    const TimeStart = moment(record.TimeStart);
    const timeCurrent = moment();
    const timeDiff = timeCurrent.diff(TimeStart, 'days');
    let tootipDisplay = '';
    if (timeDiff < 0) {
      tootipDisplay = 'Hết hạn sử dụng';
    } else {
      tootipDisplay = `Thời gian sử dụng: ${convertDaysToDate(timeDiff)}`;
    }
    setTooltipDiffTime(tootipDisplay);
  };

  const fetchDataCompay = () => {
    setLoading(true);
    GetDeviceByCompany({
      OrganizationID: groupID,
      RowNumber: numberRow,
      PageNumber: current,
      Search: search,
      DeviceGroup: deviceGroup,
      TypeDevice: 'Medical',
    }).then((res) => {
      setNumberPage(res.NumberPage);
      const resData = res.DeviceLst.map((device) => {
        return {
          ...device,
          DeviceSet: device.DeviceSet ? 1 : 0,
        };
      });
      const dataSorted = resData.sort((a, b) => {
        return new Date(b.TimeCreate) - new Date(a.TimeCreate);
      });
      const dataSortedAddIndex = dataSorted
        .map((data, index) => {
          let timeNext = new Date(data.TimeStart).getTime() + data.Schedule * 24 * 60 * 60 * 1000;
          return {
            ...data,
            key: index + 1 + (current - 1) * numberRow,
            TimeNext: timeNext,
            Warning: timeNext,
          };
        })
        .sort((a, b) => {
          return b.Status - a.Status;
        });
      setData(dataSortedAddIndex);
      setLoading(false);
    });
  };

  const fetchDataOrg = (orgID) => {
    setLoading(true);
    GetDeviceByOrganization({
      OrganizationID: orgID ? orgID : groupID,
      RowNumber: numberRow,
      PageNumber: current,
      Search: search,
      DeviceGroup: deviceGroup,
      TypeDevice: 'Medical',
    }).then((res) => {
      setNumberPage(res.NumberPage);
      const resData = res.DeviceLst.map((device) => {
        return {
          ...device,
          DeviceSet: device.DeviceSet ? 1 : 0,
        };
      });
      const dataSorted = resData.sort((a, b) => {
        return new Date(b.TimeCreate) - new Date(a.TimeCreate);
      });
      const dataSortedAddIndex = dataSorted
        .map((data, index) => {
          let timeNext = new Date(data.TimeStart).getTime() + data.Schedule * 24 * 60 * 60 * 1000;
          return {
            ...data,
            key: index + 1 + (current - 1) * numberRow,
            TimeNext: timeNext,
            Warning: timeNext,
          };
        })
        .sort((a, b) => {
          return b.Status - a.Status;
        });
      setData(dataSortedAddIndex);
      setLoading(false);
    });
  };
  const fetchDataUser = async () => {
    setLoading(true);
    GetDeviceByUser({
      OrganizationID: groupID,
      RowNumber: numberRow,
      PageNumber: current,
      Search: search,
      DeviceGroup: deviceGroup,
    })
      .then((res) => {
        setLoading(false);
        if (res.RespCode === 0) {
          const resData = res.DeviceLst;
          const dataSorted = resData.sort((a, b) => {
            return new Date(b.TimeCreate) - new Date(a.TimeCreate);
          });
          const dataSortedAddIndex = dataSorted.map((data, index) => {
            let timeNext = new Date(data.TimeStart).getTime() + data.Schedule * 24 * 60 * 60 * 1000;
            if (data.Note) {
              return {
                ...data,
                key: index + 1 + (current - 1) * numberRow,
                TimeNext: timeNext,
                Warning: timeNext,
              };
            } else {
              return {
                ...data,
                key: index + 1 + (current - 1) * numberRow,
                Note: '',
                TimeNext: new Date(data.TimeStart).getTime() + data.Schedule * 24 * 60 * 60 * 1000,
              };
            }
          });
          setData(dataSortedAddIndex);
        } else {
          message.error(res.RespText);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };
  useEffect(() => {
    roleUser(role) ? fetchDataOrg(groupIdSelect) : fetchDataUser();
  }, [getStateDevice, current, numberRow, search]);
  const btnSearch = (value) => {
    setLoading(true);
    setSearch(value);
  };
  const linkToApproveMove = () => {
    history.push(API_DEVICE_LINK);
  };
  const handleChange = (pagination, filters, sorter) => {
    setCurrent(pagination.current);
    setNumberRow(pagination.pageSize);
    setSearch(filters.Type && filters.Type[0]);
  };
  const getGroupByCompany = () => {
    GetDependentOrganization({
      OrganizationID: 77,
      Search: '',
      NumberRow: '',
      PageNumber: 1,
    }).then((res) => {
      setGroupDependent(
        res.OrganizationLst.map((org) => {
          return {
            text: org.OrganizationName,
            value: org.OrganizationID,
          };
        })
      );
    });
  };
  useEffect(() => {
    getGroupByCompany();
    GetDefaultValue({
      TableName: 'Medical',
      Reference: '',
    }).then((res) => {
      const result = res.DefaultValueLst.map((def) => {
        return {
          text: def.DefaultValue,
          value: def.DefaultValue,
        };
      });
      setTypes(result);
    });
  }, []);
  const handleSelectOrg = (val) => {
    setCurrent(1);
    setGroupIdSelect(val);
    if (val) {
      fetchDataOrg(val);
    } else {
      fetchDataCompay();
    }
  };
  const onClickRedirectDetail = async (record = {}) => {
    let documentID = '';
    // call api get infomation document by deviceId
    const response = await GetMaintenanceScheduleInfoByDeviceId({
      DeviceId: record.DeviceId,
    });
    documentID = response.MaintenanceScheduleInfo.DocumentCode;

    const urlDetailDocument = `${API_DEVICE_MAINTAIN}/${enCode(documentID)}`;
    history.push(urlDetailDocument);
  };
  const columns = [
    {
      title: 'STT',
      dataIndex: 'key',
      key: 'key',
      width: 50,
    },
    {
      title: 'Người tạo',
      dataIndex: 'CreaterName',
      key: 'CreaterName',
      width: 150,
    },
    {
      title: 'Tên TB',
      dataIndex: 'DeviceName',
      key: 'DeviceName',
      width: 150,
    },
    {
      title: 'Mã TB',
      dataIndex: 'DeviceCode',
      key: 'DeviceCode',
      width: 100,
    },
    {
      title: 'Serial',
      dataIndex: 'Serial',
      key: 'Serial',
      width: 150,
    },
    {
      title: 'Model',
      dataIndex: 'Model',
      key: 'Model',
      width: 150,
    },
    {
      title: 'Loại TB',
      dataIndex: 'Type',
      key: 'Type',
      width: 150,
    },
    {
      title: 'Giá trị ban đầu',
      dataIndex: 'DeviceValue',
      key: 'DeviceValue',
      render: (record) => {
        return formatMoneyDisplay(record);
      },
      width: 150,
    },
    {
      title: () => (
        <div>
          Giá trị hiện tại{' '}
          <Tooltip title={tooltipCurrentValue}>
            <InfoCircleOutlined style={{ cursor: 'pointer' }} />
          </Tooltip>
        </div>
      ),
      dataIndex: '',
      key: '',
      title: () => (
        <div>
          Giá trị hiện tại
          <Tooltip title={tooltipCurrentValue}>
            <InfoCircleOutlined style={{ cursor: 'pointer' }} />
          </Tooltip>
        </div>
      ),
      dataIndex: '',
      key: '',
      width: 150,
      render: (record) => {
        return (
          <div>
            {formatMoneyDisplay(record.DeviceValueCurrent)}
            <Tooltip title={toolTipDiffTime} trigger={['click']} onClick={() => onOpenTimeDiff(record)}>
              <InfoCircleOutlined style={{ cursor: 'pointer', marginLeft: 4 }} />
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: 'TG bắt đầu sử dụng',
      dataIndex: 'TimeStart',
      key: 'TimeStart',
      width: 150,
      render: (record) => {
        return dateDisplay(record, formatDateDisplay);
      },
    },
    {
      title: 'Nhóm thiết bị',
      dataIndex: 'DeviceGroup',
      key: 'DeviceGroup',
      width: 150,
    },
    {
      title: 'Kiểm định',
      dataIndex: 'Accreditation',
      key: 'Accreditation',
      width: 150,
      render: (record) => {
        return <Tag color={accreditationDisplay(record).color}>{accreditationDisplay(record).label}</Tag>;
      },
    },
    // {
    //   width: 150,
    //   render: (record) => {
    //     return (
    //       <div>
    //         {record.StatusAction === 1 ? (
    //           <Devision DeviceId={record.DeviceId} groupId={groupID} role={record.Status} infoDevice={record} />
    //         ) : (
    //           <Tooltip placement="bottom" title="Xác nhận luân chuyển">
    //             <Tag
    //               style={{ cursor: 'pointer' }}
    //               color={statusDeviceAction(record.StatusAction).color}
    //               onClick={linkToApproveMove}
    //             >
    //               {statusDeviceAction(record.StatusAction).label}
    //             </Tag>
    //           </Tooltip>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      title: 'Ghi chú',
      dataIndex: 'Note',
      key: 'Note',
      width: 150,
    },
    {
      title: 'Trạng thái',
      dataIndex: '',
      key: '',
      width: 180,
      fixed: 'right',
      sorter: (a, b) => {
        return b.Status - a.Status;
      },
      render: (record) => {
        return (
          <Space size={0}>
            <Tag color={status(record.Status).color}>{status(record.Status).label}</Tag>
            {record.Status !== 1 && (
              <i
                className="fa-solid fa-arrow-up-right-from-square"
                style={{ cursor: 'pointer' }}
                onClick={() => onClickRedirectDetail(record)}
              ></i>
            )}
          </Space>
        );
      },
    },
    {
      width: 150,
      fixed: 'right',
      render: (record) => {
        return (
          <div className="icon-active">
            {allowDisplay() && (
              <div style={{ display: 'flex' }}>
                <Update
                  data={record}
                  orgId={groupIdSelect}
                  change={() => {
                    setToggle(!toggle);
                  }}
                />
                <DeleteEquipment orgId={groupIdSelect} record={record} />
              </div>
            )}
            <div className="info-equipment">
              <InfoEquipment deviceId={record.DeviceId} deviceInfo={record} groupId={groupID} />
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="get-list">
      <div className="create-search">
        <div>
          {allowDisplay() && (
            <Select
              showSearch
              style={{ width: 200, marginRight: 10 }}
              placeholder="Lựa chọn phòng ban"
              optionFilterProp="children"
              allowClear
              onChange={handleSelectOrg}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {groupDependent &&
                groupDependent.map((gr) => (
                  <Select.Option value={gr.value} key={gr.value}>
                    {gr.text}
                  </Select.Option>
                ))}
            </Select>
          )}
          <Search
            placeholder="Nhập thông tin tìm kiếm"
            allowClear
            onSearch={btnSearch}
            autoFocus
            style={{
              width: 250,
              marginLeft: 'auto',
              marginRight: 20,
              marginBottom: 20,
            }}
          />
        </div>
      </div>
      <Table
        scroll={{
          x: 1500,
        }}
        dataSource={data}
        loading={loading}
        columns={columns}
        onChange={handleChange}
        pagination={{
          current: current,
          pageSize: numberRow,
          total: numberPage * numberRow,
          defaultCurrent: 20,
        }}
        locale={{
          filterConfirm: 'Xác nhận',
          emptyText: 'Không có dữ liệu',
        }}
      />
    </div>
  );
};
export default GetList;
